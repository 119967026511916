import clsx from "clsx"
import { FC } from "react"
import { FeesDuePanel } from "./FeesDuePanel"
import { FeeSummaryPanel } from "./FeeSummaryPanel"

type Props = {
    className?: string
}
export const FeeSummaryView: FC<Props> = ({ className }) => {
    return (
        <div className={clsx(className)}>
            <div className='gy-5 max-h-600px row'>
                <div className='col-xl-5'>
                    <FeeSummaryPanel />
                </div>
                <div className='col-xl-7'>
                    <FeesDuePanel className="mb-3" />
                </div>
            </div>
        </div>
    )
}
