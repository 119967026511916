import { Contact } from "../models";

export * from "../../_metronic/helpers";

export const contactFolderPath = (contact: Contact) => {
  return `${contact.fullName}_${contact.contactId.replaceAll('-', '').toUpperCase()}`
}

export const getFileNameFromUrl = (url: string) => { return url.split('/').pop() || '' }

export const getMediaType = (fileName: string) => {
  const file_name_array = fileName.split(".");
  const file_extension = file_name_array[file_name_array.length - 1];

  switch (file_extension) {
    case "xlsx":
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    case "xls":
      return "application/vnd.ms-excel";
    case "docx":
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    case "doc":
      return "application/msword";
    case "ppt":
      return "application/vnd.ms-powerpoint";
    case "pptx":
      return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
    case "pdf":
      return "application/pdf";
    case "gif":
      return "image/gif";
    case "png":
      return "image/png";
    case "jpg":
    case "jpeg":
      return "image/jpeg";
    case "bmp":
      return "image/bmp";
    case "txt":
      return "text/plain";
    default:
      return `application/${file_extension}`;
  }
}

export const toNumber = (p?: number) => (p || 0)