import { ApiTags, rootApi } from './api';

import { Attendance } from "../models";
import { CalendarPeriod } from "../client-models";

const TAG = ApiTags.Attendance;
const getTags = (entities: Attendance[]) => [
    ...entities.map((p) => ({ type: TAG, id: p.mshiedAttendanceId })),
    TAG]

const attendanceApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getAttendance: builder.query<Attendance, string>({
            query: (id) => `/Attendances/${id}`,
            providesTags: (result) =>
                result ? [{ type: TAG, id: result.mshiedAttendanceId }, TAG] : [TAG]
        }),

        getAttendanceByAppointmentId: builder.query<Attendance, {
            studentId: string, appointmentId: string
        }>({
            query: ({ studentId, appointmentId }) => `/Attendances/get-attendance/${studentId}/${appointmentId}`,
            providesTags: (result) =>
                result ? [{ type: TAG, id: result.mshiedAttendanceId }, TAG] : [TAG]
        }),

        getAttendanceEntriesByPeriod: builder.query<Attendance[], {
            studentId: string, period: CalendarPeriod
        }>({
            query: ({ studentId, period }) => ({
                url: `/Attendances/${period}/${studentId}`,
                method: 'POST'
            }),
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),

        createAttendance: builder.mutation<Attendance, Partial<Attendance>>({
            query: (entity) => ({
                url: '/Attendances',
                method: 'POST',
                body: entity
            }),
            invalidatesTags: (result) =>
                result ? [{ type: TAG, id: result.mshiedAttendanceId }, TAG] : [TAG]
        }),

        updateAttendance: builder.mutation<Attendance, {
            id: string,
            entity: Partial<Attendance>
        }>({
            query: ({ id, entity }) => ({
                url: `/Attendances/${id}`,
                method: 'PUT',
                body: entity
            }),
            invalidatesTags: (result) =>
                result ? [{ type: TAG, id: result.mshiedAttendanceId }, TAG] : [TAG]
        }),

        deleteAttendance: builder.mutation<void, string>({
            query: (id: string) => ({
                url: `/Attendances/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [TAG]
        }),
    })
})

export const {
    useGetAttendanceQuery,
    useGetAttendanceByAppointmentIdQuery,
    useLazyGetAttendanceByAppointmentIdQuery,
    useGetAttendanceEntriesByPeriodQuery,
    useCreateAttendanceMutation,
    useUpdateAttendanceMutation,
    useDeleteAttendanceMutation,
} = attendanceApi;