import { AppointmentsView } from "../../../modules/appointments/hod-appointment";
import { FC } from "react";
import { PageTitle } from "../../../layout/core";
import { useUserContact } from "../../../hooks/user";

export const AppointmentsWrapper: FC = () => {
    return (<>
        <PageTitle breadcrumbs={[]}>{'Appointments'}</PageTitle>
        <AppointmentsInner />
    </>)
}

const AppointmentsInner = () => {
    const contact = useUserContact()
    return <>{contact && <AppointmentsView contact={contact} />} </>
}