import { CardPanel, CardPanelBody, CardPanelHeader, CardPanelToolbar, If, ListQueryStatus } from "../../widgets-core";
import React, { useCallback, useEffect, useState } from "react";

import Accordion from "react-bootstrap/esm/Accordion";
import { Link } from "react-router-dom";
import { ProgramRequirement } from "../../models";
import clsx from "clsx";
import { useLazyGetProgramRequirementsByProgramIdQuery } from "../../services/program-requirements.api";
import { useUserContact } from "../../hooks/user";
import { useGetProgramQuery } from "../../services/program.api";
import _ from "lodash";


export const ProgramDetailsView = () => {
    const contact = useUserContact();
    const [getProgramRequirements, { data: programRequirements, isLoading, isError, isFetching, isSuccess }]
        = useLazyGetProgramRequirementsByProgramIdQuery();

    const { data: program } = useGetProgramQuery(contact?.mshiedCurrentProgramId?.id || "");


    useEffect(() => {
        if (!contact?.mshiedCurrentProgramId?.id)
            return;
        getProgramRequirements(contact?.mshiedCurrentProgramId?.id)
        // eslint-disable-next-line
    }, [contact])
    return (
        <CardPanel className="bg-transparent">
            <CardPanelHeader
                title="Program Details"
                subTitle={contact?.mshiedCurrentProgramIdName}>
                <CardPanelToolbar>
                    <button
                        onClick={() => {
                            if (contact?.mshiedCurrentProgramId?.id)
                                getProgramRequirements(contact?.mshiedCurrentProgramId?.id)
                        }}
                        type="button"
                        title="Refresh"
                        className='btn btn-sm btn-link text-hover-primary p-0 me-3'>
                        <i className="las la-redo-alt fs-2"></i>
                    </button>
                </CardPanelToolbar>
            </CardPanelHeader>
            <CardPanelBody>
                <ListQueryStatus
                    isLoading={isLoading || isFetching}
                    isSuccess={isSuccess}
                    isError={isError}
                    data={programRequirements} />

                <If condition={!isLoading && !isFetching && isSuccess}>
                    {!!program?.slcmRegistrationType && Number(program.slcmRegistrationType) === 1 ?
                        <ProgramRequirementsYear
                            className="fs-7"
                            data={programRequirements} />
                        :
                        <ProgramRequirements
                            className="fs-7"
                            data={programRequirements} />}


                </If>
            </CardPanelBody>
        </CardPanel>
    )
}

export const ProgramRequirements = ({ data, className }: {
    data?: ProgramRequirement[]
    className?: string
}) => {
    const [semesters, setSemesters] = useState<string[]>();

    useEffect(() => {
        if (!data) return;

        const names = data.map<string>(p => (p.slcmSemesterName || 'NA').replaceAll('  ', ' '))
            .sort((a, b) => a.localeCompare(b, 'en', { numeric: true }));
        const semesters = Array.from(new Set(names));
        setSemesters(semesters);

        // eslint-disable-next-line
    }, [data])

    const getSemesterData = useCallback((semester: string) => {
        if (!data) return [];
        return data.filter(p => p.slcmSemesterName === semester && p.mshiedCourseIdName);
    }, [data]);

    return <>
        {(semesters)?.map((semester, i) =>
            <React.Fragment key={semester}>
                <Accordion className={className}>
                    <Accordion.Item eventKey={semester} className="mb-2 bg-transparent">
                        <Accordion.Header bsPrefix="accordion-header accordion-header-sm">
                            <span className="fs-6 fw-bolder text-gray-700 my-0">{semester}</span>
                        </Accordion.Header>
                        <Accordion.Body className="bg-transparent">
                            <ProgramRequirementsList
                                data={getSemesterData(semester)} />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </React.Fragment>
        )}
    </>
}

export const ProgramRequirementsYear = ({ data, className }: {
    data?: ProgramRequirement[]
    className?: string
}) => {
    const [years, setyears] = useState<string[]>();

    useEffect(() => {
        if (!data) return;

        const namesYear = data.map<any>(p => (p.slcmYear || "NA")).sort();
        const years = Array.from(new Set(namesYear));

        // setSemesters(semesters);
        setyears(years);

        // eslint-disable-next-line
    }, [data])


    const getSemesterData = useCallback((year: any) => {
        if (!data) return [];
        return data.filter(p => p.slcmYear === year && p.mshiedCourseIdName);
    }, [data]);
    return <>
        {(years)?.map((year, i) =>
            <React.Fragment key={year}>
                <Accordion className={className}>
                    <Accordion.Item eventKey={year} className="mb-2 bg-transparent">
                        <Accordion.Header bsPrefix="accordion-header accordion-header-sm">
                            <span className="fs-6 fw-bolder text-gray-700 my-0">{"Year " + year}</span>
                        </Accordion.Header>
                        <Accordion.Body className="bg-transparent">
                            <ProgramRequirementsList
                                data={getSemesterData(year)} />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </React.Fragment>
        )}
    </>
}

const ProgramRequirementsList = ({ data }: { data: ProgramRequirement[] }) => {
    
    const getSortedData = useCallback(() => {
        return _.sortBy(data, p => Number(p.slcmSemesterName?.replace('Semester', '').trim() || 0));
    }, [data]);
    return <>
        {getSortedData().map((item, i) =>
            <React.Fragment key={item.mshiedProgramRequirementId}>
                <ProgramRequirementItem
                    className="mb-2 fs-7"
                    model={item} />
            </React.Fragment>)}
    </>
}

const ProgramRequirementItem = ({ model, className }: {
    model: ProgramRequirement
    className?: string,
}) => {
    return <div className={clsx(className, "card card-body py-2")}>
        <div className="d-flex flex-wrap">

            <div className="w-200px flex-fill me-3 my-1">
                <span className="text-gray-600">Course</span>
                <div className="text-gray-700 fw-bolder">
                    <Link to={`/course-history/${model.mshiedCourseId?.id}`}>
                        {model.mshiedCourseIdName}
                    </Link>
                </div>
            </div>
            <div className="w-200px flex-fill me-3 my-1">
                <span className="text-gray-600">Semster</span>
                <div className="text-gray-700 fw-bolder">
                    <span>{model.slcmSemesterName}</span>
                </div>
            </div>
            <div className="w-125px me-3 my-1">
                <span className="text-gray-600">Required</span>
                <div className="text-gray-700 fw-bolder">
                    {!model.mshiedIsRequired ? 'Optional' : 'Yes'}
                </div>
            </div>
        </div>
    </div>
}
