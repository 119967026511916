import { Contact, RegistrationWindow } from "../../models";
import { useEffect, useState } from "react"

import { DateFormat } from "../../widgets-core";
import { NotificationIcon } from "../../assets/icons";
import { SlcmRegistrationtype } from "../../models/academic-period-details/enums/slcmRegistrationtype";
import clsx from "clsx";
import { getYear } from "../../models/registration-windows"
import { useGetRegistrationPeriod } from "../../hooks/academic-period-details.hooks";
import { useGetRegistrationStatusCodes } from "../../hooks/registration-statuses.hooks";
import { useLazyGetRegistrationWindowsByProgramAndBatchQuery } from "../../services/registration-windows.api";
import { useNavigate } from "react-router-dom";

export const RegistrationsAlert = ({ contact }: {
    contact: Contact
}) => {
    const [getRegistrationWindow] = useLazyGetRegistrationWindowsByProgramAndBatchQuery();
    const [registration, setRegistration] = useState<RegistrationWindow>();

    useEffect(() => {
        if (!contact.slcmBatch?.id || !contact.mshiedCurrentProgramId?.id)
            return;

        getRegistrationWindow({
            batchId: contact.slcmBatch?.id,
            programId: contact.mshiedCurrentProgramId?.id
        }).unwrap()
            .then((data) => {
                const registration = data.find(p =>
                    (p.slcmRegistrationType === SlcmRegistrationtype.Semester) ||
                    (p.slcmRegistrationType === SlcmRegistrationtype.Year)
                )

                setRegistration(registration)
            })

        // eslint-disable-next-line
    }, [contact])
    if (!registration) {
        return <></>
    }

    return (
        <div className="alert bg-secondary d-flex flex-column flex-sm-row p-5 mb-0">
            <NotificationIcon sx={{ fontSize: 40 }} className="text-primary me-3" />
            <div className="d-flex flex-column pe-0 pe-sm-10 text-gray-800">
                <h6 className="mb-1">Semester Registration</h6>
                <div className="d-flex">
                    {registration?.slcmRegistrationType === 0 &&
                        <span className="me-1 fw-bolder">{`Year ${registration.slcmYear}`}</span>
                    }
                    <span className="me-1 fw-bolder">{registration?.slcmRegistrationType === 0 ? registration?.slcmSemesterName : getYear(registration.slcmYear)}</span>
                    <span className="me-1">registrations are open from</span>
                    <DateFormat className="me-1 fw-bolder" date={registration.slcmRegistrationStartDate} />
                    <span className="me-1">to</span>
                    <DateFormat className="me-1 fw-bolder" date={registration.slcmRegistrationEndDate} />
                    <span>.</span>
                </div>
            </div>

            <RegistrationLink
                className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 ms-sm-auto"
                contact={contact}
                registration={registration} />
        </div>
    )
}

const RegistrationLink = ({ contact, registration, className }: {
    contact: Contact,
    registration: RegistrationWindow,
    className?: string
}) => {
    const navigate = useNavigate();
    const { SCH, PWS } = useGetRegistrationStatusCodes()
    const { period } = useGetRegistrationPeriod(contact.contactId, registration);

    const handleButtonClick = () => {
        if (period?.slcmSemesterRegistration?.id === SCH || period?.slcmSemesterRegistration?.id === PWS)
            navigate(`/semester-registration/${registration.slcmRegistrationwindowId}`)
        else
            navigate(`/view-semester-registration/${registration.slcmRegistrationwindowId}`)
    }

    return (
        <button
            type="button"
            onClick={handleButtonClick}
            className={clsx(className, "btn btn-primary btn-sm")}>
            {period?.slcmSemesterRegistration?.id === SCH ?
                'Click here to register' :
                'Check registration status'}
        </button>)
}
