import { FC } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import { QRScanner } from "../../modules/appointments/attendance/QRScanner";

export const QRScannerWrapper: FC = () => {
    return (<>
        <PageTitle breadcrumbs={[]}>{'Attendance Scanner'}</PageTitle>
        <QRScanner />
    </>)
}
