import { PageTitle } from "../../layout/core";
import { ProgramDetailsView } from "../../modules/program-requirements";

export const ProgramDetailsWrapper = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>
                Program Details
            </PageTitle>
            <ProgramDetailsView />
        </>
    )
}
