import { Appointment, Contact, CourseSection, getAppointmentStateCodeLabel, getLearningModeLabel } from "../../../models";
import { CardPanel, CardPanelBody, CardPanelHeader, CardPanelToolbar, CardQueryStatus, CardRefreshButton, DateTimeFormat, If } from "../../../widgets-core";
import { CourseDetailsIcon, EmailIcon, LocationIcon, PhoneIcon, TimeIcon } from "../../../assets/icons";

import { AttendanceStatus } from "./components/AttendanceStatus";
import clsx from "clsx";
import { useEffect } from "react";
import { useGetAppointmentQuery } from "../../../services/appointments.api";
import { useGetContactQuery } from "../../../services/contacts.api";
import { useLazyGetCourseSectionQuery } from "../../../services/course-sections.api";

export const ViewAppointment = ({ contact, appointmentId, className }: {
    contact: Contact,
    appointmentId: string
    className?: string,
}) => {
    const { data: appointment, isLoading, isError, isFetching, isSuccess, error, refetch } = useGetAppointmentQuery(appointmentId);
    const [getCourseSection, { data: courseSection }] = useLazyGetCourseSectionQuery();

    useEffect(() => {
        if (!appointment?.slcmCourseSection?.id) return;

        getCourseSection(appointment.slcmCourseSection?.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointment?.slcmCourseSection?.id])

    return (
        <CardPanel className='bg-transparent'>
            <CardPanelHeader
                className="ribbon ribbon-end align-items-center border-0"
                title={appointment?.subject}
                subTitle={courseSection?.mshiedCourseIdName}>
                <CardPanelToolbar>
                    <CardRefreshButton
                        className="me-3"
                        isLoading={isLoading || isFetching}
                        refetch={refetch} />

                    {appointment &&
                        <div className="d-flex flex-wrap">
                            <div className="me-3">
                                <span className="fs-7 text-gray-500 fw-bold">Attendance</span>
                                <div>
                                    <AttendanceStatus
                                        contact={contact}
                                        appointment={appointment}
                                        className="flex-column badge badge-light pe-3 text-white"
                                    />
                                </div>
                            </div>

                            <div>
                                <span className="fs-7 text-gray-500 fw-bold">Status</span>
                                <div>
                                    <span className="badge badge-primary">{getAppointmentStateCodeLabel(appointment?.stateCode)}</span>
                                </div>
                            </div>
                        </div>}
                </CardPanelToolbar>
            </CardPanelHeader>
            <CardPanelBody>
                <CardQueryStatus
                    isLoading={isLoading || isFetching}
                    isSuccess={isSuccess}
                    isError={isError}
                    error={error}
                    data={appointment} />

                <If condition={!isLoading && !isFetching && isSuccess}>
                    {appointment && <AppointmentPanel
                        className="mb-5 fs-7"
                        appointment={appointment}
                        courseSection={courseSection} />}

                    {appointment?.slcmInstructor?.id &&
                        <Instructor
                            className="fs-7"
                            id={appointment?.slcmInstructor?.id || ''} />}
                </If>
            </CardPanelBody>
        </CardPanel >
    )
}

const Instructor = ({ id, className }: { id: string, className?: string }) => {
    const { data: entity } = useGetContactQuery(id);

    return (
        <div className={clsx(className, "card card-body py-3")}>
            <div className="d-flex flex-wrap">
                {entity?.fullName && <>
                    <div className="w-xl-250px flex-fill my-1 pe-3">
                        <span className="text-gray-600">Instructor</span>
                        <div className="text-gray-700 fw-bold">
                            <span className="text-uppercase fw-bolder">{entity?.fullName}</span>
                        </div>
                    </div>
                </>}

                {entity?.eMailAddress1 && <>
                    <div className="w-xl-250px my-1 pe-3">
                        <span className="text-gray-600">Email</span>
                        <div className="text-gray-700 fw-bold">
                            <EmailIcon className="me-2" />
                            <a href={`mailto:${entity?.eMailAddress1}`}
                                className="fw-bolder">{entity?.eMailAddress1}</a>
                        </div>
                    </div>
                </>}

                {entity?.mobilePhone && <>
                    <div className="w-xl-250px my-1 pe-3">
                        <span className="text-gray-600">Phone</span>
                        <div className="text-gray-700 fw-bold">
                            <PhoneIcon className="me-2" />
                            <a href={`tel:${entity?.mobilePhone}`}
                                className="fw-bolder">
                                {entity?.mobilePhone}
                            </a>
                        </div>
                    </div>
                </>}
            </div>
        </div>
    )
}

export const AppointmentPanel = ({ appointment, courseSection, className }: {
    appointment: Appointment,
    courseSection?: CourseSection,
    className?: string,
}) => {
    return <div className={clsx(className, "card card-body py-3")}>
        <div className="d-flex flex-wrap">
            <div className="w-xl-25 flex-fill my-1 pe-3">
                <span className="text-gray-600">Topic</span>
                <div className="text-gray-700 fw-bold">
                    <span className="fw-bolder">{appointment?.subject}</span>
                </div>
            </div>

            {courseSection?.mshiedCourseIdName && <>
                <div className="w-xl-25 flex-fill my-1 pe-3">
                    <span className="text-gray-600">Subject</span>
                    <div className="text-gray-700 fw-bold">
                        <CourseDetailsIcon className="me-2" />
                        <span className="fw-bolder">{courseSection?.mshiedCourseIdName}</span>
                    </div>
                </div>
            </>}

            {courseSection?.slcmLearningMethod && <>
                <div className="w-xl-25 my-1 pe-3">
                    <span className="text-gray-600">Learning Method</span>
                    <div className="text-gray-700 fw-bold">
                        <span className="fw-bolder">
                            {getLearningModeLabel(courseSection?.slcmLearningMethod)}
                        </span>
                    </div>
                </div>
            </>}

            {(appointment?.slcmLocationName) && <>
                <div className="w-xl-25 flex-fill my-1 pe-3">
                    <span className="text-gray-600">Location</span>
                    <div className="text-gray-700 fw-bold">
                        <LocationIcon className="me-1" />
                        <span className="fw-bolder">{appointment?.slcmLocationName}</span>
                    </div>
                </div>
            </>}

            <div className="w-xl-25 my-1 pe-3">
                <span className="text-gray-600">Start Time</span>
                <div className="text-gray-700 fw-bold">
                    {appointment?.scheduledStart && <>
                        <TimeIcon className="me-1" />
                        <DateTimeFormat className="fw-bolder" date={appointment?.scheduledStart} />
                    </>}
                </div>
            </div>

            <div className="w-xl-25 my-1 pe-3">
                <span className="text-gray-600">End Time</span>
                <div className="text-gray-700 fw-bold">
                    {appointment?.scheduledEnd && <>
                        <TimeIcon className="me-1" />
                        <DateTimeFormat className="fw-bolder" date={appointment?.scheduledEnd} />
                    </>}
                </div>
            </div>
        </div>
    </div>
}

