import { TimePeriod } from "../../widgets-core";
import { ViewTimetable } from "../../modules/appointments/timetable/ViewTimetable";
import { useLocation } from "react-router-dom";
import { useUserContact } from "../../hooks/user";

export const TimetableWrapper = () => {
    const location = useLocation()
    const period = (location.hash.substring(1) || "ThisWeek") as TimePeriod
    const contact = useUserContact()

    return (<>
        {contact && <ViewTimetable
            contact={contact}
            period={period} />}
    </>)
}

