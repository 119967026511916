export const SelectYear = ({
    value,
    onChange,
    className
}: {
    value?: number,
    onChange?: (value: number) => void,
    className?: string,
}) => {
    return <select
        className="form-select form-select-sm"
        aria-label="Select year"
        value={value}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            onChange && onChange(Number(event.target.value));
        }}
        placeholder="Select year">
        <option></option>
        <option value="1">Year 1</option>
        <option value="2">Year 2</option>
        <option value="3">Year 3</option>
        <option value="4">Year 4</option>
        <option value="5">Year 5</option>
    </select>
}


