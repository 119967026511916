import { MshiedAchievementtype } from "./enums/mshiedAchievementtype";

export * from "./Achievement";

export const getAchievementTypeLabel = (status?: MshiedAchievementtype) => {
    switch (status) {
        case MshiedAchievementtype.Accomplishment: return "Accomplishment";
        case MshiedAchievementtype.Achievements: return "Achievements";
        case MshiedAchievementtype.Award: return "Award";
        case MshiedAchievementtype.Badge: return "Badge";
        case MshiedAchievementtype.CareerRelated: return "CareerRelated";
        case MshiedAchievementtype.Certification: return "Certification";
        case MshiedAchievementtype.Competency: return "Competency";
        case MshiedAchievementtype.Confirmed: return "Confirmed";
        case MshiedAchievementtype.Coop: return "Coop";
        case MshiedAchievementtype.CourseCompletion: return "CourseCompletion";
        case MshiedAchievementtype.Curricular: return "Curricular";
        case MshiedAchievementtype.Degree: return "Degree";
        case MshiedAchievementtype.EvidenceOfMastery: return "EvidenceOfMastery";
        case MshiedAchievementtype.GroupMembership: return "GroupMembership";
        case MshiedAchievementtype.GuildProfessional: return "GuildProfessional";
        case MshiedAchievementtype.Internship: return "Internship";
        case MshiedAchievementtype.Learning: return "Learning";
        case MshiedAchievementtype.License: return "License";
        case MshiedAchievementtype.Mentoring: return "Mentoring";
        case MshiedAchievementtype.Microcredential: return "Microcredential";
        case MshiedAchievementtype.Proficiency: return "Proficiency";
        case MshiedAchievementtype.Skill: return "Skill";
        case MshiedAchievementtype.VerifiableExtra: return "VerifiableExtra";
        case MshiedAchievementtype.Volunteering: return "Volunteering";
        default: return "";
    }
}