import { Course } from "../models";
import { rootApi } from './api';

const coursesApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getCourse: builder.query<Course, string>({
            query: (id) => `/Courses/${id}`,
        }),

        getCourses: builder.query<Course[], void>({
            query: () => `/Courses`,
        }),
    })
})

export const {
    useGetCourseQuery,
    useLazyGetCourseQuery,
    useGetCoursesQuery,
} = coursesApi;