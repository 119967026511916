import { Contact, HostelAvailed } from "../../../../models";
import { DateFormat, If, ListQueryStatus } from "../../../../widgets-core";

import { Accordion } from "react-bootstrap";
import { DateRangeIcon } from "../../../../assets/icons";
import React from "react";
import clsx from "clsx";
import { useGetHostelAvailedEntriesByStudentIdQuery } from "../../../../services/hostel-availed.api";

export const StudentHostelAvailed = ({ contact, className }: {
    contact: Contact,
    className?: string,
}) => {
    const { data: entities, isLoading, isError, isFetching, isSuccess, refetch }
        = useGetHostelAvailedEntriesByStudentIdQuery(contact.contactId);

    return (
        <Accordion className={className}>
            <Accordion.Item eventKey={""}>
                <Accordion.Header bsPrefix="accordion-header accordion-header-sm">
                    <h5 className='text-gray-700 my-0'>Hostel Details</h5>
                </Accordion.Header>
                <Accordion.Body>
                    <div className="text-end mb-3">
                        <button
                            onClick={refetch}
                            type="button"
                            title="Refresh"
                            className='btn btn-sm btn-link text-hover-primary p-0 me-3'>
                            <i className="las la-redo-alt fs-2"></i>
                        </button>
                    </div>

                    <ListQueryStatus
                        isLoading={isLoading || isFetching}
                        isSuccess={isSuccess}
                        isError={isError}
                        data={entities} />

                    <If condition={!isLoading && !isFetching && isSuccess}>
                        {entities?.map(item => <React.Fragment key={item.slcmHostelavailedId}>
                            <HostelAvailedCard
                                className="mb-2"
                                hostel={item} />
                        </React.Fragment>)}
                    </If>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

const HostelAvailedCard = ({ hostel, className }: {
    hostel: HostelAvailed,
    className?: string
}) => {
    return (
        <div className={clsx(className, "border px-5 rounded py-3 fs-7")}>
            <div className="d-flex flex-wrap flex-sm-row">
                <div className="min-w-25 flex-fill my-1 me-3">
                    <span className="text-gray-600">Campus</span>
                    <div className="text-gray-700 fw-bolder">
                        <span>{hostel.slcmCampusName}</span>
                    </div>
                </div>
                <div className="min-w-25 flex-fill my-1 me-3">
                    <span className="text-gray-600">Hostel Room</span>
                    <div className="text-gray-700 fw-bolder">
                        <span>{hostel.slcmHostelRoomName}</span>
                    </div>
                </div>
                <div className="w-xl-125px my-1 me-3">
                    <span className="text-gray-600">Start Date</span>
                    <div className="text-gray-700 fw-bolder">
                        {hostel.slcmStartDate && <DateRangeIcon className="me-1" />}
                        <DateFormat date={hostel.slcmStartDate} />
                    </div>
                </div>
                <div className="w-xl-125px my-1 me-3">
                    <span className="text-gray-600">End Date</span>
                    <div className="text-gray-700 fw-bolder">
                        {hostel.slcmEndDate && <DateRangeIcon className="me-1" />}
                        <DateFormat date={hostel.slcmEndDate} />
                    </div>
                </div>
                <div className="w-xl-125px my-1 me-3">
                    <span className="text-gray-600">Is Current</span>
                    <div className="text-gray-700 fw-bolder">
                        <span>{hostel.slcmCurrent ? "Yes" : ""}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}