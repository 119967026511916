import { Team } from "../models";
import { rootApi } from './api';

const teamsApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getTeam: builder.query<Team, string>({
            query: (id) => `/Teams/${id}`,
        }),

        getTeams: builder.query<Team[], void>({
            query: () => `/Teams`,
        }),
    })
})

export const {
    useGetTeamQuery,
    useGetTeamsQuery,
} = teamsApi;