import { Accordion } from "react-bootstrap"
import { AssociatedDocumentsView } from '../../payments/AssociatedDocumentsView'
import { DateFormat } from "../../../widgets-core";
import { DemandHeader } from "../../../models";
import { DemandLinesToggleView } from "./components/DemandLinesToggleView";
import { Money } from "../../../widgets-business";
import { TrackPaymentTransactions } from "../../payments/TrackPaymentTransactions";
import clsx from "clsx";
import { useUserContact } from '../../../hooks/user'

export const DemandAccordionItem = ({ demand, onChange, className }: {
    demand: DemandHeader,
    onChange?: () => void,
    className?: string,
}) => {
    const demandId = demand.slcmDemandheaderId;
    const contact = useUserContact()

    return (
        <Accordion.Item eventKey={demandId}>
            <Accordion.Header bsPrefix="accordion-header accordion-header-sm">
                <DemandHeaderView demand={demand} />
            </Accordion.Header>
            <Accordion.Body>
                <DemandDetailsView
                    className={className}
                    onChange={onChange}
                    demand={demand}
                    isInternational={!!contact?.slcmIsInternational} />
            </Accordion.Body>
        </Accordion.Item>
    )
}

const DemandHeaderView = ({ demand, className }: {
    demand: DemandHeader,
    className?: string
}) => {
    return (
        <div className={clsx(className, 'd-flex flex-wrap fs-7')}>
            <div className='w-xl-150px my-1 me-3'>
                <span className="text-gray-600">Demand No</span>
                <div className="text-gray-700 fw-bolder">{demand?.slcmDemandNo}</div>
            </div>
            <div className='w-xl-150px my-1 me-3'>
                <span className="text-gray-600">Due Date</span>
                <div className="text-gray-700 fw-bolder"><DateFormat date={demand?.slcmDueDate} /></div>
            </div>
            <div className='w-xl-150px my-1 me-3'>
                <span className="text-gray-600">Amount</span>
                <div className="text-gray-700 fw-bolder"><Money currencyId={demand?.transactionCurrencyId?.id}
                    amount={demand?.slcmTotalAmount} /></div>
            </div>

            {(!!demand?.slcmPTPTNTotal || !!demand?.slcmPTPTNAmount || !!demand?.slcmPTPTNRemaining) &&
                <div className="d-flex">
                    <div className="w-xl-125px me-3 my-2">
                        <span className="text-gray-600">PTPTN Total</span>
                        <div className="text-gray-700 fw-bolder">
                            <Money
                                currencyId={demand?.transactionCurrencyId?.id}
                                amount={demand?.slcmPTPTNTotal} />
                        </div>
                    </div>
                    <div className="w-xl-125px me-3 my-2">
                        <span className="text-gray-600">PTPTN Consumed</span>
                        <div className="text-gray-700 fw-bolder">
                            <Money
                                currencyId={demand?.transactionCurrencyId?.id}
                                amount={demand?.slcmPTPTNAmount} />
                        </div>
                    </div>
                    <div className="w-xl-125px me-3 my-2">
                        <span className="text-gray-600">PTPTN Remaining</span>
                        <div className="text-gray-700 fw-bolder">
                            <Money
                                currencyId={demand?.transactionCurrencyId?.id}
                                amount={demand?.slcmPTPTNRemaining} />
                        </div>
                    </div>
                </div>}

            <div className='w-xl-150px my-1 me-3'>
                <span className="text-gray-600">Net Payable</span>
                <div className="text-gray-700 fw-bolder"><Money currencyId={demand?.transactionCurrencyId?.id}
                    amount={(demand.slcmCurrentbalance || 0) - (demand.slcmInterimAmountPaid || 0)} /></div>
            </div>
        </div>
    )

}

const DemandDetailsView = ({ demand, isInternational, onChange, className }: {
    demand: DemandHeader,
    isInternational: boolean,
    onChange?: () => void,
    className?: string
}) => {
    return <div className={className}>
        <DemandLinesToggleView
            className="w-100"
            onChange={onChange}
            demand={demand} />

        <TrackPaymentTransactions
            className="my-5"
            paymentReference={demand.slcmDemandheaderId} />

        <AssociatedDocumentsView className="my-5" demandId={demand.slcmDemandheaderId} />
    </div>;
}

