import { VisaHistory } from '../models';
import { rootApi } from './api';

const visaHistoryApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getVisaHistory: builder.query<VisaHistory, string>({
            query: (id) => `/VisaHistory/${id}`,
        }),

        getVisaHistoryEntries: builder.query<VisaHistory[], void>({
            query: () => `/VisaHistory`,
        }),

        getVisaHistoryEntriesByStudentId: builder.query<VisaHistory[], string>({
            query: (studentId) => `/VisaHistory/contact/${studentId}`,
        }),
    })
})

export const {
    useGetVisaHistoryQuery,
    useGetVisaHistoryEntriesQuery,
    useGetVisaHistoryEntriesByStudentIdQuery,
} = visaHistoryApi;