import { FC } from "react"
import { PageTitle } from "../../../_metronic/layout/core"
import { DoNotDisturbIcon } from "../../assets/icons"

export const CommunityWrapper: FC = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>
                Community
            </PageTitle>
            <Community></Community>
        </>
    )
}

const Community: FC = () => {
    return (
        <span className="fs-3 text-danger fw-bolder">
            <DoNotDisturbIcon /> Page under construction
        </span>
    )
}