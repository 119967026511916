export type TimePeriod =
    "Today" |
    "Tomorrow" |
    "Yesterday" |
    "ThisWeek" |
    "LastWeek" |
    "NextWeek" |
    "ThisMonth" |
    "LastMonth" |
    "NextMonth";

export const getTimePeriodName = (timePeriod: TimePeriod) => {
    switch (timePeriod) {
        case "Today": return "Today";
        case "Tomorrow": return "Tomorrow";
        case "Yesterday": return "Yesterday";
        case "ThisWeek": return "This Week";
        case "LastWeek": return "Last Week";
        case "NextWeek": return "Next Week";
        case "ThisMonth": return "This Month";
        case "LastMonth": return "Last Month";
        case "NextMonth": return "Next Month";
    }
}

