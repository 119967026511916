import React, { useEffect, useState } from "react";

import { Dropdown } from "react-bootstrap";
import { LocationFacility } from "../../../../models";
import { SlcmLocationSlcmBookingtype } from "../../../../models/locations/enums/slcmLocationSlcmBookingtype";
import clsx from "clsx";
import { useGetFacilitiesQuery } from "../../../../services/facilities.api";
import { useGetLocationFacilitiesByLocationIdsQuery } from "../../../../services/locations-facilities.api";
import { useGetLocationsQuery } from "../../../../services/locations.api";

export const SelectLocation = ({ value, campusId, bookingType, occupancy, className, onChange }: {
    value?: string,
    campusId?: string,
    bookingType?: SlcmLocationSlcmBookingtype,
    occupancy?: number,
    className?: string,
    onChange?: (value: string) => void,
}) => {
    const { data: facilities } = useGetFacilitiesQuery();
    const [selectedIds, setSelectedIds] = useState<string[]>([]);

    type FacilitySelection = {
        id: string;
        name?: string;
        checked: boolean;
    };
    const [facilitySelection, setFacilitySelection] = useState<FacilitySelection[]>([]);

    useEffect(() => {
        if (!facilities)
            return;
            
        const facilityIds = facilities.map(p => ({ id: p.slcmFacilityId, name: p.slcmName, checked: false }))
        setFacilitySelection(facilityIds);
    }, [facilities])

    const handleChecked = (facility: FacilitySelection) => {
        const facilities = facilitySelection.map(p => p.id === facility.id ? facility : p) || [];
        setFacilitySelection(facilities)

        const selected = facilities.filter(p => p.checked === true).map(p => p.id);
        setSelectedIds(selected)
    }

    return (
        <div className={clsx(className, "d-flex align-items-center")}>
            <Dropdown className="me-2">
                <Dropdown.Toggle variant="secondary" size="sm">
                    Choose facilities
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <div className="d-flex flex-wrap px-5 min-w-500px">
                        {facilitySelection?.map(facility => <div className='w-50' key={facility.id}>
                            <div className='form-check form-check-sm form-check-custom'>
                                <input
                                    className='form-check-input'
                                    type="checkbox"
                                    name={`checkbox-${facility.id}`}
                                    id={facility.id}
                                    checked={facility.checked}
                                    onChange={(event) => handleChecked({ ...facility, checked: event.target.checked })}
                                />
                                <label htmlFor={facility.id}
                                    className='form-label text-gray-700 fs-7 py-0 my-1 ms-2'>
                                    {facility.name}
                                </label>
                            </div>
                        </div>)}
                    </div>
                </Dropdown.Menu>
            </Dropdown>
            <div className="flex-fill">
                {(!selectedIds || selectedIds.length === 0) ?
                    <AllLocations
                        value={value}
                        onChange={onChange}
                        campusId={campusId}
                        bookingType={bookingType}
                        occupancy={occupancy || 0} /> :
                    <FilteredLocations
                        ids={selectedIds}
                        value={value}
                        onChange={onChange}
                        campusId={campusId}
                        bookingType={bookingType}
                        occupancy={occupancy || 0} />}
            </div>
        </div>)
}

const FilteredLocations = ({ ids, value, campusId, bookingType, occupancy, onChange }: {
    ids: string[],
    value?: string,
    campusId?: string,
    bookingType?: SlcmLocationSlcmBookingtype,
    occupancy: number
    onChange?: (location: string) => void,
}) => {
    const { data: locations } = useGetLocationFacilitiesByLocationIdsQuery(ids)
    const [availableLocations, setAvailableLocations] = useState<LocationFacility[]>()

    useEffect(() => {
        const facilities = locations?.filter(
            (p) => p.slcmCampus?.id === campusId &&
                p.slcmFacilityAvailable === true &&
                (!bookingType || p.slcmBookingType === Number(bookingType)) &&
                !!p.slcmMaxCapacity &&
                p.slcmMaxCapacity >= occupancy)

        setAvailableLocations(facilities)
        // eslint-disable-next-line
    }, [locations])

    const SelectOptions = () => {
        return (<>
            <option>Select a location</option>
            {availableLocations?.map((location, i) => <option
                key={i}
                value={location.slcmLocation?.id}>
                {location.slcmLocationName}
            </option>)}
        </>)
    }

    return (
        <div>
            <select
                className="form-select form-select-sm"
                aria-label="Select Location"
                value={value}
                onChange={(event) => onChange && onChange(event.target.value)}
                placeholder={'Select location'}>
                {SelectOptions()}
            </select>
        </div>)
}

const AllLocations = ({ value, onChange, campusId, bookingType, occupancy }: {
    value?: string,
    campusId?: string,
    bookingType?: SlcmLocationSlcmBookingtype,
    occupancy: number,
    onChange?: (location: string) => void,
}) => {
    const { data: locations } = useGetLocationsQuery();

    const locationFilter = locations?.filter(
        (p) =>
            p.slcmCampus?.id === campusId &&
            p.slcmBookingAllowed === true &&
            (!bookingType || p.slcmBookingType === bookingType)
            && !!p.slcmMaxCapacity &&
            p.slcmMaxCapacity >= occupancy
    )

    const SelectOptions = () => {
        return (<>
            <option>Select a location</option>
            {locationFilter?.map((location, i) => <option
                key={i}
                value={location.slcmLocationId}>
                {location.slcmName}
            </option>)}
        </>)
    }

    return (
        <div>
            <select
                className={"form-select form-select-sm"}
                aria-label="Select Location"
                value={value}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    onChange && onChange(event.target.value);
                }}
                placeholder={'Select location'}>
                {SelectOptions()}
            </select>
        </div>)
}