import { Contact, DemandHeader, DemandLine } from '../../../models';
import { KTSVG, toAbsoluteUrl } from '../../../helpers';
import { useEffect, useState } from 'react'

import { DateFormat } from '../../../widgets-core';
import { Money } from '../../../widgets-business';
import _ from 'lodash';
import clsx from 'clsx';
import { useGetDemandLinesByDemandIdQuery } from '../../../services/demand-lines.api';
import { useGetDemandsByStudentIdQuery } from '../../../services/demands.api';

export const FeesDue = ({ contact, color = 'secondary', className }: {
    contact: Contact,
    imagePath?: string
    innerPadding?: string
    color?: string
    className?: string
}) => {
    const { data: demands } = useGetDemandsByStudentIdQuery(contact.contactId);
    const [demand, setDemand] = useState<DemandHeader>();


    useEffect(() => {
        if (!demands) return;

        const sorted = _.sortBy(demands, p => p.slcmDueDate);
        const lastDemand = _.findLast(sorted);
        setDemand(lastDemand);
    }, [demands, setDemand]);

    return (
        <div className={`card ${className}`}>
            <div className={clsx(
                'card-body',
                `bg-${color}`)}
            >
                <div
                    className={clsx('position-absolute top-0',
                        'bgi-no-repeat bgi-position-center bgi-size-cover',
                        'h-100 w-100 overflow-hidden')}
                    style={{
                        backgroundImage: `url('${toAbsoluteUrl('/media/products/24.png')}'`,
                    }}
                />

                <LastDemand demand={demand} />
            </div>
        </div>
    )
}

const BreakupModelDialog = ({ demand }: { demand: DemandHeader }) => {
    return <div className="modal fade" tabIndex={-1} id="kt_modal_1">
        <div className="modal-dialog dashboard-card w-800px mw-75">
            <div className="modal-content">
                <div className="modal-header h-70px m-0 border-0">
                    <h5 className="modal-title">Fee Breakup</h5>
                    <div
                        className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    >
                        <KTSVG
                            path="/media/icons/duotune/arrows/arr061.svg"
                            className="svg-icon svg-icon-2x" />
                    </div>
                </div>
                <div className="modal-body pt-0 pb-0 mb-0">
                    {demand && <DemandLinePanel demand={demand} />}
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-light btn-sm"
                        data-bs-dismiss="modal"
                    >
                        Close
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary btn-sm">
                        Pay
                    </button>
                </div>
            </div>
        </div>
    </div>
}

const LastDemand = ({ demand }: { demand?: DemandHeader }) => {
    if (!demand) {
        return <div className='card w-auto ml-auto w-xl-250px'>
            <div className='card-header align-items-center border-0'>
                <h3 className='fw-bolder fs-1 mb-1'>
                    DUES
                </h3>
            </div>
            <div className='card-body px-10 p-0 mb-5'>
                <span>No demands generated yet.</span>
            </div>
        </div>
    }

    return <div className='card w-auto ml-auto w-xl-250px'>
        <div className='card-header align-items-center border-0'>
            <h3 className='fw-bolder fs-1 mb-1'>
                {demand.slcmSemesterName}
            </h3>
        </div>
        <div className='card-body px-10 p-0 mb-5'>
            <div className='text-gray-600 ps-0'>Amount Due</div>
            <div className='text-dark pe-0 fw-bold mb-2'>
                <Money currencyId={demand.transactionCurrencyId?.id} amount={(demand.slcmCurrentbalance || 0) - (demand.slcmInterimAmountPaid || 0)} />
            </div>
            <div className='text-gray-600 ps-0'>Invoice Number</div>
            <div className='text-dark pe-0 fw-bold mb-2'>{demand.slcmDemandNo}</div>
            <div className='text-gray-600 ps-0'>Due Date</div>
            <div className='text-dark pe-0 fw-bold'>
                <DateFormat date={demand?.slcmDueDate} />
            </div>

            <div className='d-flex flex-wrap mt-5'>
                <button type="button"
                    className="flex-column flex-column-fluid align-items-center btn btn-success btn-sm mb-2"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_1"
                >
                    View Break Down
                </button>
                <button type="button"
                    className="flex-column flex-column-fluid align-items-center btn btn-primary btn-sm mb-2"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_1"
                >
                    Pay
                </button>
            </div>

            <BreakupModelDialog demand={demand} />
            {/*end::Info*/}
        </div>
        {/*end::Card Body*/}
    </div>;
}

const DemandLinePanel = ({ demand }: {
    demand: DemandHeader
}) => {
    const { data: demandLines } = useGetDemandLinesByDemandIdQuery(demand.slcmDemandheaderId);

    return <>
        <div className='dashboard-card__body'>
            {demandLines?.map((demandLine, i) => (
                <div key={demandLine.slcmDemandlineId} className={clsx("card bg-light mb-3")}>
                    <div className="card-body mt-0 py-3 mt-2">
                        <div className='row'>
                            <div className="col-xl-3 mb-3">
                                <span className="text-gray-600">Component</span>
                                <div className="text-gray-700 fw-bolder">
                                    <span>{demandLine.slcmFeeComponentName}&nbsp;</span>
                                </div>
                            </div>
                            <div className="col-xl-3 mb-3">
                                <span className="text-gray-600">Amount</span>
                                <div className="text-gray-700 fw-bolder">
                                    <Money
                                        currencyId={demandLine.transactionCurrencyId?.id}
                                        amount={(demand.slcmCurrentbalance || 0) - (demand.slcmInterimAmountPaid || 0)} />
                                </div>
                            </div>
                            <div className="col-xl-3 mb-3">
                                <span className="text-gray-600">Discount</span>
                                <div className="text-gray-700 fw-bolder">
                                    <Money
                                        currencyId={demandLine.transactionCurrencyId?.id}
                                        amount={demandLine?.slcmDiscount} />
                                </div>
                            </div>
                            <div className="col-xl-3 mb-3">
                                <span className="text-gray-600">Net Amount</span>
                                <div className="text-gray-700 fw-bolder">
                                    <Money
                                        currencyId={demandLine.transactionCurrencyId?.id}
                                        amount={demandLine?.slcmNetAmount} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
        <DemandLineTotalsPanel demand={demand} demandLines={demandLines || []} />
    </>
}

const DemandLineTotalsPanel = ({ demand, demandLines }: {
    demand: DemandHeader,
    demandLines: DemandLine[]
}) => {
    const [totals, setTotals] = useState<{
        amount: number,
        discount: number,
        netAmount: number
    }>();

    useEffect(() => {
        if (!demandLines) return;

        const totals = {
            amount: 0,
            discount: 0,
            netAmount: 0
        };

        demandLines.forEach(line => {
            totals.amount += line.slcmAmount || 0;
            totals.discount += line.slcmDiscount || 0;
            totals.netAmount += line.slcmNetAmount || 0;
        })

        setTotals(totals);
    }, [demandLines])

    return <div className='mt-3'>
        {totals && (
            <div className={clsx("card bg-secondary mb-3")}>
                <div className="card-body mt-0 py-3 mt-2">
                    <div className='row'>
                        <div className="col-xl-3 mb-3">
                            <div className="text-gray-700 fw-bolder">
                                <span>Total</span>
                            </div>
                        </div>
                        <div className="col-xl-3 mb-3">
                            <span className="text-gray-600">Amount</span>
                            <div className="text-gray-700 fw-bolder">
                                <Money
                                    currencyId={demand?.transactionCurrencyId?.id}
                                    amount={totals.amount} />
                            </div>
                        </div>
                        <div className="col-xl-3 mb-3">
                            <span className="text-gray-600">Discount</span>
                            <div className="text-gray-700 fw-bolder">
                                <Money
                                    currencyId={demand?.transactionCurrencyId?.id}
                                    amount={totals.discount} />
                            </div>
                        </div>
                        <div className="col-xl-3 mb-3">
                            <span className="text-gray-600">Net Amount</span>
                            <div className="text-gray-700 fw-bolder">
                                <Money
                                    currencyId={demand?.transactionCurrencyId?.id}
                                    amount={totals.netAmount} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}
    </div>
}

