export enum MshiedAchievementtype {
  Degree = 494280000, // Degree
  Certification = 494280001, // Certification
  License = 494280002, // License
  EvidenceOfMastery = 494280003, // Evidence of Mastery
  Microcredential = 494280004, // Micro-credential
  Badge = 494280005, // Badge
  CourseCompletion = 494280006, // Course Completion
  Learning = 494280007, // Learning
  Accomplishment = 494280008, // Accomplishment
  Proficiency = 494280009, // Proficiency
  Competency = 494280010, // Competency
  Award = 494280011, // Award
  Internship = 494280012, // Internship
  Coop = 494280013, // Co-op
  Confirmed = 494280014, // Confirmed
  Volunteering = 494280015, // Volunteering
  CareerRelated = 494280016, // Career Related
  Achievements = 494280017, // Achievements
  Mentoring = 494280018, // Mentoring
  GuildProfessional = 494280019, // Guild/Professional
  GroupMembership = 494280020, // Group Membership
  Skill = 494280021, // Skill
  VerifiableExtra = 494280022, // Verifiable Extra
  Curricular = 494280023, // Curricular
}