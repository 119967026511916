import { CardPanel, CardPanelBody, CardPanelHeader } from "../../widgets-core"

import { FeeSummary } from "./components"
import clsx from "clsx"
import { useUserContact } from "../../hooks/user"

export const FeeSummaryPanel = ({ className }: {
    className?: string
}) => {
    const contact = useUserContact()
    return <>
        <CardPanel className={clsx(className)}>
            <CardPanelHeader title="Fee Summary" className="mt-0 border-0 min-h-50px" >
            </CardPanelHeader>
            <CardPanelBody >
                {contact && <FeeSummary contact={contact} />}
            </CardPanelBody>
        </CardPanel>
    </>
}