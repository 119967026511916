import { useEffect, useState } from 'react'

import { IPaymentRequest } from "../../client-models"
import { KTSVG } from "../../helpers";
import { getFlywireUrlApi } from './flywire.service';
import { useUserContact } from '../../hooks/user';

export const FlywirePayment = ({ paymentInfo, onOpenPaymentLink, paymentType, className }: {
    paymentInfo: IPaymentRequest
    onOpenPaymentLink?: (transactionId: string) => void,
    paymentType?: number,
    className?: string
}) => {
    const contact = useUserContact();
    const [flywireUrl, setFlywireUrl] = useState<string>();
    const [transactionId, setTransactionId] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errMsg, setErrorMsg] = useState<string>();

    useEffect(() => {
        if (!contact) return;

        const request = {
            "callback_id": paymentInfo.invoiceId,
            "country": contact.slcmCountrynameIdName,
            "payment_type": paymentType,
            "items": {
                "tuition_fee": `${Math.floor(paymentInfo.tuitionFee * 100)}`,
                "hostel_fee": `${Math.floor(paymentInfo.hostelFee * 100)}`,
                "other_fee": `${Math.floor(paymentInfo.otherFee * 100)}`
            },
            "dynamic_fields": {
                "student_id": contact?.slcmRollNumber,
                "student_last_name": contact.fullName,
                "student_email": contact.eMailAddress1,
                "mobile_no": contact.mobilePhone,
            }
        }

        setErrorMsg('');
        setIsLoading(true)
        getFlywireUrlApi(request)
            .then(data => {
                setFlywireUrl(data.url);
                setTransactionId(data.transactionId);
            }).catch(err => {
                setErrorMsg('Failed to generate Payment Url');
            }).finally(() => setIsLoading(false))
        // eslint-disable-next-line
    }, [contact]);

    return (
        <div className={className}>
            {isLoading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
            )}

            {errMsg && (
                <div className='alert alert-danger'>{errMsg}</div>
            )}

            {!!flywireUrl && <div className="card-footer border-0 p-0 m-0">
                <a href={flywireUrl}
                    onClick={() => {
                        if (onOpenPaymentLink)
                            onOpenPaymentLink(transactionId)
                    }}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-lg btn-secondary">
                    <div className="symbol symbol-50px symbol-circle me-1">
                        <KTSVG
                            path='/media/svg/brand-logos/flywire-logo.svg'
                            className='svg-icon-4'
                        />
                    </div>
                    Pay with Flywire
                </a>
            </div>}
        </div>
    )
}