import { ApiTags, rootApi } from './api';

import { PaymentInstructions } from '../models/payment-instructions';

const TAG = ApiTags.PaymentInstructions;

const paymentInstructionsApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getPaymentInstruction: builder.query<PaymentInstructions, string>({
            query: (id) => `/PaymentInstructions/${id}`,
            providesTags: (result, err) =>
                result ?
                    [{ type: TAG, id: result.slcmPaymentinstructionsId }, TAG] : [TAG]
        }),

        getPaymentInstructions: builder.query<PaymentInstructions[], void>({
            query: () => `/PaymentInstructions`,
            providesTags: (result, err) =>
                result ?
                    [...result.map((p) => ({ type: TAG, id: p.slcmPaymentinstructionsId })), TAG] :
                    [TAG]
        }),
    })
})

export const {
    useGetPaymentInstructionQuery,
    useGetPaymentInstructionsQuery,
} = paymentInstructionsApi;