import { Currency } from "../models";
import { rootApi } from './api';

const currenciesApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getCurrency: builder.query<Currency, string>({
            query: (id) => `/Currencies/${id}`,
        }),

        getCurrencies: builder.query<Currency[], void>({
            query: () => `/Currencies`,
        }),
    })
})

export const {
    useGetCurrencyQuery,
    useLazyGetCurrencyQuery,
    useGetCurrenciesQuery,
} = currenciesApi;