import { Contact, Service } from "../../../../models";
import { If, ListQueryStatus } from "../../../../widgets-core";

import { Accordion } from "react-bootstrap";
import LazyRender from "../../../../widgets-core/LazyRender";
import { Link } from "react-router-dom";
import React from "react";
import clsx from "clsx";
import { useGetServicesByCategoryId } from "./services.hook";
import { SlcmRequestsSlcmRequeststructure } from "../../../../models/services/enums/slcmRequestsSlcmRequeststructure";

export const ServicesRoot = ({ contact, className }: {
    contact: Contact,
    className?: string,
}) => {
    const { data: services, isLoading, isFetching, isError, isSuccess } = useGetServicesByCategoryId(contact);

    return (
        <Accordion accessKey={""} flush className={className}>
            <ListQueryStatus
                isLoading={isLoading || isFetching}
                isSuccess={isSuccess}
                isError={isError}
                data={services} />

            {services.map(service =>
                <React.Fragment key={service.slcmRequestsId}>
                    <ServicesAccordion
                        className="mb-2"
                        contact={contact}
                        category={service} />
                </React.Fragment>
            )}
        </Accordion>
    )
}

const ServicesAccordion = ({ contact, category, className }: {
    contact: Contact,
    category: Service,
    className?: string,
}) => {
    return (
        <Accordion accessKey={""}>
            <Accordion.Item
                className={className}
                eventKey={category?.slcmRequestsId}>
                <Accordion.Header bsPrefix="accordion-header accordion-header-sm">
                    <span className="fs-6 fw-bolder text-gray-700 my-0">{category?.slcmName || category?.slcmCode}</span>
                </Accordion.Header>
                <Accordion.Body className="py-2 m-0">
                    <LazyRender>
                        <CategoryServices contact={contact} category={category} />
                    </LazyRender>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

export const CategoryAccordion = ({ contact, category, className }: {
    contact: Contact,
    category: Service,
    className?: string,
}) => {
    return (
        <Accordion accessKey={""} className="w-100">
            <Accordion.Item eventKey={category.slcmRequestsId} className={className}>
                <Accordion.Header bsPrefix="accordion-header accordion-header-sm">
                    <span className="fs-7 fw-bold text-gray-700 m-0">
                        {category.slcmName}
                    </span>
                </Accordion.Header>
                <Accordion.Body className="py-2 m-0">
                    <LazyRender>
                        <CategoryServices contact={contact} category={category} />
                    </LazyRender>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

const CategoryServices = ({ contact, category }: {
    contact: Contact,
    category: Service,
}) => {
    const { data: services, isLoading, isFetching, isError, isSuccess, refetch } = useGetServicesByCategoryId(contact, category.slcmRequestsId);

    return <>
        <ListQueryStatus
            isLoading={isLoading || isFetching}
            isSuccess={isSuccess}
            isError={isError}
            hideNoData={true}
            data={services} />

        <If condition={!isLoading && !isFetching && isSuccess}>
            <div className="animate__animated animate__fadeInDown">
                {(isSuccess && services.length === 0) ?
                    <div className="text-gray-600 fw-bold py-2 text-center">No services exists in this category</div> :
                    <div className="d-flex flex-wrap animate__fadeInDown">
                        {services.map((service) => (
                            <React.Fragment key={service.slcmRequestsId}>
                                <ServicesItem
                                    className="mb-2"
                                    contact={contact}
                                    serviceItem={service} />
                            </React.Fragment>
                        ))}
                    </div>
                }
            </div>
        </If>

        {(isError) && <div className="d-flex my-2 justify-content-center align-items-center">
            <button
                onClick={refetch}
                type="button"
                title="Refresh"
                className='btn btn-sm btn-link text-hover-primary p-0 me-3 mb-3'>
                <i className="las la-redo-alt fs-2"></i> Refresh
            </button>
        </div>}
    </>
}

const ServicesItem = ({ contact, serviceItem, className }: {
    contact: Contact,
    serviceItem: Service,
    className?: string,
}) => {
    switch (serviceItem.slcmRequestStructure) {
        case SlcmRequestsSlcmRequeststructure.Category:
            return <CategoryAccordion
                className={className}
                contact={contact}
                category={serviceItem} />;
        case SlcmRequestsSlcmRequeststructure.Service:
            return <ServicePageLink className={className} request={serviceItem} />
        default:
            return <></>
    }
}

const ServicePageLink = ({ request, className }: {
    request: Service,
    className?: string,
}) => {
    return <div className={clsx(className, 'fs-7 my-1 min-w-50')}>
        <Link to={`/services/new-service-request/${request.slcmRequestsId}`}>
            <i className="las la-link fs-4 me-1"></i>
            <span>{request.slcmName}</span>
        </Link>
    </div>;
}
