import { CardPanel, CardPanelBody, CardPanelHeader, CardPanelToolbar, CardQueryStatus, CardRefreshButton, If } from "../../../widgets-core";
import { Form, Formik, FormikValues } from "formik";
import { ServiceRequest, SlcmServicerequestsStatuscode } from "../../../models";
import { ServiceRequestDocuments, ServiceRequestFields } from "../components";
import { useEffect, useRef, useState } from "react";
import { useGetServiceRequestQuery, useUpdateServiceRequestMutation } from "../../../services/service-requests.api";

import { ServicePayableAmount } from "../components/ServicePayableAmount";
import { ServicePaymentPage } from "../../payments/ServicePaymentPage";
import { StepperComponent } from "../../../assets/components";
import { StepsBackButton } from "../../wizards/components/StepsBackButton";
import { StepsNavMenu } from "../components/StepsNavMenu";
import { StepsSubmitButton } from "../../wizards/components/StepsSubmitButton";
import { contactFolderPath } from "../../../helpers";
import moment from "moment";
import { useLazyGetServiceQuery } from "../../../services/services.api";
import { useNavigate } from "react-router-dom";
import { useUserContact } from "../../../hooks/user";
import { ServiceRequestNetPayable } from "../ServiceRequestNetPayable";
import { Money } from "../../../widgets-business";

export const EditServiceRequest = ({ requestId, className }: {
    className?: string,
    requestId: string,
}) => {
    const contact = useUserContact();
    const [getService, { data: service }] = useLazyGetServiceQuery();
    const { data: serviceRequest2, isLoading, isError, isFetching, isSuccess, error, refetch } = useGetServiceRequestQuery(requestId);
    const [updateServiceRequest] = useUpdateServiceRequestMutation();

    const [serviceRequest, setServiceRequest] = useState<ServiceRequest>({} as ServiceRequest);
    const [updated, setUpdated] = useState<boolean>(false);
    const [saving, setSaving] = useState(false)
    const [alertMsg, setAlertMsg] = useState<string>();
    const [state] = useState<"NEW" | "EDIT">("EDIT");
    const [documentsStatus, setDocumentsStatus] = useState<boolean>(false);
    const [stepIndex, setStepIndex] = useState<number>(1);

    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)

    const navigate = useNavigate();
    const folderPath = contact ? `${contactFolderPath(contact)}/${serviceRequest.slcmCode}` : '';

    const [netPayableAmount, setNetPayableAmount] = useState<number>();

    useEffect(() => {
        if (!service || !stepperRef.current) {
            return
        }

        loadStepper()
        // eslint-disable-next-line
    }, [stepperRef.current, service])

    // TODO:: this is not required, state variable should be removed
    useEffect(() => {
        if (serviceRequest2)
            setServiceRequest(serviceRequest2);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceRequest2])

    useEffect(() => {
        if (serviceRequest.slcmRequestType?.id)
            getService(serviceRequest.slcmRequestType?.id);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceRequest.slcmRequestType?.id])

    const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    }

    const saveRequest = (action: (request: ServiceRequest) => void) => {
        if (!updated) {
            action(serviceRequest)
            return;
        }

        if (!service || state !== "EDIT") return;
        if (service.slcmCampus && service.slcmExpectedOccupancy && service.slcmLocation && service.slcmStartDate && service.slcmEndDate) {
            if (moment(moment(serviceRequest.slcmStartDate).format('MM-DD-YYYY')).isAfter(moment(serviceRequest.slcmEndDate).format('MM-DD-YYYY'))) {
                setAlertMsg('End Date Should not be less than start date')
                return <></>
            }

            if (!serviceRequest.slcmCampus) {
                setAlertMsg('Campus can not be blank')
                return <></>
            }
            if (!serviceRequest.slcmExpectedOccupancy) {
                setAlertMsg('Occupancy can not be blank')
                return <></>
            }
            if (!serviceRequest.slcmLocation) {
                setAlertMsg('Location can not be blank')
                return <></>
            }
        }

        setSaving(true);

        const request = getDeltaObject(serviceRequest2, serviceRequest)

        updateServiceRequest({ id: serviceRequest.slcmServicerequestsId, entity: request })
            .unwrap()
            .then(() => {
                setUpdated(false);
                action(request)
            })
            .catch(() => {
                setAlertMsg("Failed to submit service request.");
            }).finally(() => {
                setSaving(false);
            });
    }

    const submitRequest = () => {
        setSaving(true);
        const request: Partial<ServiceRequest> = {
            statuscode: SlcmServicerequestsStatuscode.Open,
            slcmDate: moment(new Date()).format("yyyy-MM-DD") as any as Date
        }

        updateServiceRequest({ id: serviceRequest.slcmServicerequestsId, entity: request })
            .then(() => {
                navigate(`/service-requests/${SlcmServicerequestsStatuscode.Open}`)
            })
            .catch((reason) => {
                setAlertMsg("Failed to submit service request.")
                setSaving(false);
            });
    }

    const submitStep = (values: ServiceRequest, actions: FormikValues) => {
        const step = stepper?.current;
        if (!step) {
            return;
        }

        setAlertMsg('');
        switch (step.currentStepIndex) {
            case 1:
                saveRequest((request: ServiceRequest) => {
                    setServiceRequest(request);
                    setStepIndex(stepIndex + 1);
                    step.goNext();
                });
                break;
            case 2:
                if (stepper.current?.totatStepsNumber === 2) {
                    submitRequest();
                    break;
                } else {
                    switch (true) {
                        case !!service?.slcmDocumentChecklist:
                            if (documentsStatus) {
                                setStepIndex(stepIndex + 1);
                                step.goNext();
                            } else {
                                setAlertMsg('Please attach all required documents.');
                            }
                            break;
                        case service?.slcmPaidService:
                            setStepIndex(stepIndex + 1);
                            step.goNext();
                            break;
                        default:
                            submitRequest();
                            break;
                    }
                    break;
                }
            case 3:
                if (stepper.current?.totatStepsNumber === 3) {
                    submitRequest();
                    break;
                } else {
                    switch (true) {
                        case service?.slcmPaidService:
                            setStepIndex(stepIndex + 1);
                            step.goNext();
                            break;
                        default:
                            submitRequest();
                            break;
                    }
                    break;
                }
            case 4:
                submitRequest();
                break;
        }
    }

    const prevStep = () => {
        if (!stepper.current) {
            return
        }

        setAlertMsg('');
        setStepIndex(stepIndex - 1);
        stepper.current.goPrev()
    }

    const onChangeHandler = (request: ServiceRequest) => {
        setUpdated(true);
        setServiceRequest(request);
    }

    const handleDocumentsStatusChange = (status: boolean) => {
        setDocumentsStatus(status);
    }

    const amountNetPayable = () => {
        if (!contact?.slcmOutstandingAmount || contact?.slcmOutstandingAmount > 0) {
            setNetPayableAmount(Number(contact?.slcmOutstandingAmount) + Number(serviceRequest.slcmTotalFeeAmount))
        } else if (!contact?.slcmOutstandingAmount || contact?.slcmOutstandingAmount < 0) {
            setNetPayableAmount(Number(serviceRequest.slcmTotalFeeAmount) + Number(contact?.slcmOutstandingAmount))
        }

    }
    useEffect(() => {
        amountNetPayable()
        // eslint-disable-next-line
    }, [contact?.slcmOutstandingAmount, serviceRequest.slcmTotalFeeAmount])

    return (
        <CardPanel className="bg-transparent">
            <CardPanelHeader
                title={serviceRequest?.slcmCode}
                subTitle={service?.slcmName}>
                <CardPanelToolbar>
                    <CardRefreshButton
                        className="me-3"
                        isLoading={isLoading || isFetching}
                        refetch={refetch} />
                    <div className={"text-gray-600 py-3 me-6"} >
                        <span className="fw-bold">Previous Outstanding Amount</span>
                        <h3 className="text-danger my-0 text-left">
                            <Money
                                currencyId={contact?.transactionCurrencyId?.id}
                                amount={contact?.slcmOutstandingAmount} />
                        </h3>
                    </div>
                    <ServicePayableAmount serviceRequest={serviceRequest} label="Service request fee" />
                    <ServiceRequestNetPayable amount={netPayableAmount} serviceRequest={serviceRequest} label="Net Payable" />

                </CardPanelToolbar>
            </CardPanelHeader>
            <CardPanelBody>
                <CardQueryStatus
                    isLoading={isLoading || isFetching}
                    isSuccess={isSuccess}
                    isError={isError}
                    error={error}
                    data={serviceRequest2} />
                <If condition={!isLoading && !isFetching && isSuccess}>
                    {service &&
                        <div
                            ref={stepperRef}
                            className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                            id='kt_create_account_stepper'
                        >
                            <StepsNavMenu
                                className="me-5"
                                service={service}
                                stepIndex={stepper.current?.getCurrentStepIndex()} />

                            <div className='d-flex flex-row-fluid bg-white rounded'>
                                <Formik initialValues={serviceRequest} onSubmit={submitStep}>
                                    {() => (
                                        <Form
                                            className='card card-body py-5 fs-7'
                                            noValidate
                                            id='kt_edit_service_request'>
                                            <div className='current' data-kt-stepper-element='content'>
                                                <div className="w-100">
                                                    <ServiceRequestFields
                                                        key="fields"
                                                        onChange={onChangeHandler}
                                                        service={service} serviceRequest={serviceRequest} />
                                                </div>
                                            </div>

                                            {!!service.slcmDocumentChecklist && (
                                                <div data-kt-stepper-element='content'>
                                                    <div className="w-100">
                                                        <ServiceRequestDocuments
                                                            folderPath={folderPath}
                                                            onStatusChange={handleDocumentsStatusChange}
                                                            checklistId={service.slcmDocumentChecklist.id}
                                                            requestId={serviceRequest.slcmServicerequestsId} />
                                                    </div>
                                                </div>
                                            )}

                                            {!!service.slcmPaidService && (
                                                <div data-kt-stepper-element='content'>
                                                    <div className="w-100">
                                                        <ServicePaymentPage
                                                            folderPath={folderPath}
                                                            serviceId={serviceRequest.slcmServicerequestsId}
                                                            amount={netPayableAmount || 0}
                                                            currencyId={serviceRequest.transactionCurrencyId?.id} />
                                                    </div>
                                                </div>
                                            )}

                                            <div data-kt-stepper-element='content'>
                                                <div className="w-100">
                                                    <span className="m-10 text-gray-600 fs-6">
                                                        We have all necessary information to process your request,
                                                        now you can submit the request for approval.
                                                    </span>
                                                </div>
                                            </div>

                                            {alertMsg && (
                                                <div className='alert alert-warning mt-10'>
                                                    <span className='alert-text fw-bolder'>{alertMsg}</span>
                                                </div>
                                            )}

                                            <div className="flex-fill">{/* To align bottom */}</div>
                                            <div className='d-flex flex-stack pt-5 align-bottom'>
                                                <div className='me-2'>
                                                    <StepsBackButton
                                                        className="btn-sm"
                                                        onClick={prevStep} />
                                                </div>

                                                <div>
                                                    <StepsSubmitButton
                                                        className="btn-sm"
                                                        saving={saving}
                                                        label="Submit"
                                                        stepIndex={stepIndex}
                                                        totalSteps={stepper.current?.totatStepsNumber} />
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>}
                </If>
            </CardPanelBody>
        </CardPanel >
    );
}

const getDeltaObject = (original: any, updated: any) => {
    const delta: any = {};

    for (const key in updated) {
        if (updated[key] !== original[key]) {
            delta[key] = updated[key];
        }
    }

    return delta;
}
