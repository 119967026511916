import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import AdfScannerOutlinedIcon from '@mui/icons-material/AdfScannerOutlined';
import AirplaneTicketOutlinedIcon from '@mui/icons-material/AirplaneTicketOutlined';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardIcon2 from '@mui/icons-material/ArrowForward';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CastForEducationOutlinedIcon from '@mui/icons-material/CastForEducationOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import DescriptionOutlined from '@mui/icons-material/DescriptionOutlined';
import DoNotDisturbOutlinedIcon from '@mui/icons-material/DoNotDisturbOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { FC } from 'react';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import HeartBrokenOutlinedIcon from '@mui/icons-material/HeartBrokenOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import MaterialAddIcon from '@mui/icons-material/Add';
import MaterialEmailIcon from '@mui/icons-material/Email';
import MetNotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import OutboxOutlinedIcon from '@mui/icons-material/OutboxOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import PrintOutlined from '@mui/icons-material/PrintOutlined';
import QrCodeScannerOutlined from '@mui/icons-material/QrCodeScannerOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import RemoveCircle from '@mui/icons-material/RemoveCircle';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import TimelapseOutlinedIcon from '@mui/icons-material/TimelapseOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';

type Props = {
    className?: string,
    sx?: object
}

export const DeleteIcon: FC<Props> = (props) => <DeleteOutline {...props} />
export const DoneIcon: FC<Props> = (props) => <DoneOutlinedIcon {...props} />
export const RemoveIcon: FC<Props> = (props) => <RemoveCircle {...props} />
export const OutboxIcon: FC<Props> = (props) => <OutboxOutlinedIcon {...props} />
export const EmailDraftIcon: FC<Props> = (props) => <DraftsOutlinedIcon {...props} />
export const EmailUnreadIcon: FC<Props> = (props) => <MarkEmailUnreadOutlinedIcon {...props} />
export const EmailReadIcon: FC<Props> = (props) => <MarkEmailReadOutlinedIcon {...props} />
export const PrintIcon: FC<Props> = (props) => <PrintOutlined {...props} />
export const QRScannerIcon: FC<Props> = (props) => <QrCodeScannerOutlined {...props} />
export const DescriptionIcon: FC<Props> = (props) => <DescriptionOutlined {...props} />
export const ReceiptIcon: FC<Props> = (props) => <ReceiptOutlinedIcon {...props} />
export const PaymentsIcon: FC<Props> = (props) => <PaymentsOutlinedIcon {...props} />
export const CalendarIcon: FC<Props> = (props) => <CalendarMonthOutlinedIcon {...props} />
export const LedgerIcon: FC<Props> = (props) => <ReceiptLongOutlinedIcon {...props} />
export const WalletIcon: FC<Props> = (props) => <AccountBalanceWalletOutlinedIcon {...props} />
export const SummaryIcon: FC<Props> = (props) => <SummarizeOutlinedIcon {...props} />
export const AppointmentIcon: FC<Props> = (props) => <AccessAlarmOutlinedIcon {...props} />
export const NotificationIcon: FC<Props> = (props) => <MetNotificationsIcon {...props} />
export const EmailIcon2: FC<Props> = (props) => <MaterialEmailIcon {...props} />
export const AddIcon: FC<Props> = (props) => <MaterialAddIcon {...props} />
export const RightArrowIcon: FC<Props> = (props) => <ArrowForwardIcon2 {...props} />
export const CircleIcon: FC<Props> = (props) => <CircleOutlinedIcon {...props} />
export const LinkIcon: FC<Props> = (props) => <LinkOutlinedIcon {...props} />
export const VerifiedIcon: FC<Props> = (props) => <VerifiedOutlinedIcon {...props} />
export const ProgramIcon: FC<Props> = (props) => <CastForEducationOutlinedIcon {...props} />
export const GroupIcon: FC<Props> = (props) => <GroupOutlinedIcon {...props} />
export const VerifiedUserIcon: FC<Props> = (props) => <VerifiedUserOutlinedIcon {...props} />
export const DateRangeIcon: FC<Props> = (props) => <DateRangeOutlinedIcon {...props} />
export const BrokenIcon: FC<Props> = (props) => <HeartBrokenOutlinedIcon {...props} />
export const ArrowForwardIcon: FC<Props> = (props) => <ArrowForwardIosOutlinedIcon {...props} />
export const ArrowBackwardIcon: FC<Props> = (props) => <ArrowBackIosOutlinedIcon {...props} />
export const ArrowBackIcon: FC<Props> = (props) => <ArrowBackOutlinedIcon {...props} />
export const DoNotDisturbIcon: FC<Props> = (props) => <DoNotDisturbOutlinedIcon {...props} />
export const LocationIcon: FC<Props> = (props) => <FmdGoodOutlinedIcon {...props} />

export const DashboardIcon: FC<Props> = (props) => <DashboardOutlinedIcon {...props} />
export const TimetableIcon: FC<Props> = (props) => <AccessTimeOutlinedIcon {...props} />
export const CourseDetailsIcon: FC<Props> = (props) => <AutoStoriesOutlinedIcon {...props} />
export const FeeManagementIcon: FC<Props> = (props) => <AdfScannerOutlinedIcon {...props} />
export const ServicesIcon: FC<Props> = (props) => <ContactSupportOutlinedIcon {...props} />
export const PerformanceIcon: FC<Props> = (props) => <AssessmentOutlinedIcon {...props} />
export const CommunityIcon: FC<Props> = (props) => <PeopleOutlinedIcon {...props} />
export const SettingsIcon: FC<Props> = (props) => <SettingsOutlinedIcon {...props} />
export const AddingIcon: FC<Props> = (props) => <AddIcon {...props} />

export const ArrowDownIcon: FC<Props> = (props) => <KeyboardArrowDownOutlinedIcon {...props} />
export const PersonIcon: FC<Props> = (props) => <PersonOutlineOutlinedIcon {...props} />
export const TimeIcon: FC<Props> = (props) => <AccessTimeOutlinedIcon {...props} />
export const HierarchyIcon: FC<Props> = (props) => <AccountTreeOutlinedIcon {...props} />
export const PhoneIcon: FC<Props> = (props) => <PhoneIphoneOutlinedIcon {...props} />
export const EmailIcon: FC<Props> = (props) => <EmailOutlinedIcon {...props} />
export const PassportIcon: FC<Props> = (props) => <AirplaneTicketOutlinedIcon {...props} />
export const BadgeIcon: FC<Props> = (props) => <BadgeOutlinedIcon {...props} />
export const TimelapseIcon: FC<Props> = (props) => <TimelapseOutlinedIcon {...props} />

