import { FC, useEffect, useRef, useState } from "react"
import { getUserByToken, msalLogin } from "./_requests"

import { AuthModel } from "./_models"
import { Loading } from "../../../widgets-core"
import { loginRequest } from "../msal"
import { useAuth } from "./Auth"
import { useMsal } from "@azure/msal-react"

export const AuthInit: FC = ({ children }) => {
    const { auth, logout, saveAuth } = useAuth()
    const didRequest = useRef(false)
    const [showSplashScreen, setShowSplashScreen] = useState(true)
    const { instance, accounts } = useMsal();

    useEffect(() => {
        if (!auth) {
            logout()
            setShowSplashScreen(false)
        }

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!auth || auth.authType !== "MSAL") return;

        if (!didRequest.current)
            getUserByMsaToken();

        return () => { didRequest.current = true }
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        if (!auth || auth.authType !== "Custom") return;

        if (!didRequest.current)
            getUserByJwtToken(auth);

        return () => { didRequest.current = true }
        // eslint-disable-next-line
    }, [])

    const getUserByJwtToken = async (auth: AuthModel) => {
        try {
            const { data: userName } = await getUserByToken(auth.api_token)
            if (auth && userName) {
                saveAuth({ ...auth, userName: userName });
            }
        } catch (error) {
            console.error(error)
            logout()
        } finally {
            setShowSplashScreen(false)
        }
    }

    const appLogin = async (userName: string, token: string) => {
        const { data: auth } = await msalLogin(userName, token);
        saveAuth({ ...auth });
        setShowSplashScreen(false)
    }

    const getUserByMsaToken = async () => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        try {
            const response = await instance.acquireTokenSilent(request);
            await appLogin(response.account?.username || '', response.accessToken);
        } catch (e) {
            const response = await instance.acquireTokenPopup(request)
            await appLogin(response.account?.username || '', response.accessToken);
        }
    }

    return <>
        {showSplashScreen ?
            <div className="d-flex h-100 w-100 align-items-center justify-content-center">
                <Loading />
            </div> :
            <>{children}</>}
    </>
}
