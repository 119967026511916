import { AcademicPeriodDetail, Contact } from '../../../../models'
import { If, ListQueryStatus } from "../../../../widgets-core"

import { Accordion } from "react-bootstrap"
import React from "react"
import clsx from 'clsx'
import { useGetStudentAcademicPeriodDetailsQuery } from '../../../../services/academic-period-details.api'

export const SemesterProgression = ({ contact, className }: {
    contact: Contact,
    className?: string,
}) => {
    const { data: entities, isLoading, isError, isFetching, isSuccess, refetch }
        = useGetStudentAcademicPeriodDetailsQuery(contact?.contactId);

    return (
        <Accordion className={className}>
            <Accordion.Item eventKey={""}>
                <Accordion.Header bsPrefix="accordion-header accordion-header-sm">
                    <h5 className='text-gray-700 my-0'>Semester Progression</h5>
                </Accordion.Header>
                <Accordion.Body>
                    <div className="text-end mb-3">
                        <button
                            onClick={refetch}
                            type="button"
                            title="Refresh"
                            className='btn btn-sm btn-link text-hover-primary p-0 me-3'>
                            <i className="las la-redo-alt fs-2"></i>
                        </button>
                    </div>

                    <ListQueryStatus
                        isLoading={isLoading || isFetching}
                        isSuccess={isSuccess}
                        isError={isError}
                        data={entities} />

                    <If condition={!isLoading && !isFetching && isSuccess}>
                        {entities?.map((item) =>
                            <React.Fragment key={item.mshiedAcademicperioddetailsId}>
                                <SemesterProgressionPanel
                                    className="my-2 me-5 min-w-25"
                                    item={item} />
                            </React.Fragment>
                        )}
                    </If>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

const SemesterProgressionPanel = ({ item, className }: {
    item: AcademicPeriodDetail,
    className?: string
}) => {
    return (
        <div className={clsx(className, "bg-transparent border rounded px-5 py-1 fs-7")}>
            <div className="d-flex flex-wrap">
                <div className="w-xl-250px flex-fill me-5 my-1">
                    <span className="text-gray-600">Semester</span>
                    <div className="text-gray-700 fw-bolder">
                        {item.slcmSemesterName || item.mshiedProgramIdName}
                    </div>
                </div>
                <div className="w-xl-125px me-5 my-1">
                    <span className="text-gray-600">SGPA</span>
                    <div className="text-gray-700 fw-bolder">
                        {item.slcmSGPA || 'N/A'}
                    </div>
                </div>
                <div className="w-xl-125px my-1">
                    <span className="text-gray-600">CGPA</span>
                    <div className="text-gray-700 fw-bolder">
                        {item.slcmCGPA || 'N/A'}
                    </div>
                </div>
            </div>
        </div>)
}

