import { Appointment, Contact } from "../../../../models";
import { useEffect, useState } from "react";

import { AppointmentGroup } from "../models";
import { CalendarPeriod } from "../../../../client-models";
import { Timeline } from "../../../../widgets-core";
import { TimelineGroups } from ".";
import _ from "lodash";
import clsx from "clsx";
import moment from "moment";

export const TimetableTimeline = ({ contact, timetableEvents, timePeriod, className }: {
    contact: Contact,
    timetableEvents: Appointment[],
    className?: string,
    timePeriod: CalendarPeriod,
}) => {
    const [timetableGroups, setTimetableGroups] = useState<AppointmentGroup[]>();

    useEffect(() => {
        const sortTimetable = () => {
            const groupedData = _.groupBy(timetableEvents, (p) => {
                return moment(p.scheduledStart).format("hh:mmA");
            });

            const data: AppointmentGroup[] = [];
            for (const label in groupedData) {
                const appointments = _.sortBy(groupedData[label], p => p.scheduledStart);
                const date = appointments[0].scheduledStart;
                if (date) {
                    data.push({ label, date, appointments })
                }
            }

            return _.sortBy(data, p => p.date);
        }

        const sortedData = sortTimetable();
        setTimetableGroups(sortedData);
    }, [timetableEvents])

    if (!timetableGroups || timetableGroups.length === 0) {
        return <></>
    }

    return (
        <Timeline className={clsx(className, 'card-xl-stretch mb-xl-8 fs-7')}>
            <TimelineGroups
                contact={contact}
                timePeriod={timePeriod}
                groups={timetableGroups}
            />
        </Timeline>
    );
}
