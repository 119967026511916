import { FC } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import { UtilityChargesView } from "../../modules/utility-charges/UtilityChargesView";

export const UtilityChargesWrapper: FC = () => {
    return (<>
        <PageTitle breadcrumbs={[]}>{'Utility Charges'}</PageTitle>
        <UtilityChargesView />
    </>)
}
