import { Connection } from "../models";
import { rootApi } from './api';

const connectionsApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getConnection: builder.query<Connection, string>({
            query: (id) => `/Connections/${id}`,
        }),

        getConnections: builder.query<Connection[], void>({
            query: () => `/Connections`,
        }),

        getConnectionsByStudentId: builder.query<Connection[], string>({
            query: (studentId) => `/Connections/student/${studentId}`,
        }),
    })
})

export const {
    useGetConnectionQuery,
    useGetConnectionsQuery,
    useGetConnectionsByStudentIdQuery,
    useLazyGetConnectionsByStudentIdQuery,
} = connectionsApi;