import { ApiTags, rootApi } from './api';

import { DemandLine } from "../models";

const TAG = ApiTags.DemandLine;
const getTags = (entities: DemandLine[]) => [...entities.map((p) => ({ type: TAG, id: p.slcmDemandlineId })), TAG]

const demandLinesApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getDemandLine: builder.query<DemandLine, string>({
            query: (id) => `/DemandLines/${id}`,
            providesTags: (result) =>
                result ? [{ type: TAG, id: result.slcmDemandlineId }, TAG] : [TAG]
        }),

        getDemandLines: builder.query<DemandLine[], void>({
            query: () => `/DemandLines`,
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),

        getDemandLinesByStudentId: builder.query<DemandLine[], string>({
            query: (studentId) => `/DemandLines/student/${studentId}`,
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),

        getDemandLinesByDemandId: builder.query<DemandLine[], string>({
            query: (demandId) => `/DemandLines/demand/${demandId}`,
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),

        updateDemandLine: builder.mutation<void, {
            id: string,
            entity: Partial<DemandLine>
        }>({
            query: ({ id, entity }) => ({
                url: `/DemandLines/${id}`,
                method: 'PUT',
                body: entity
            }),
            invalidatesTags: (result, err, args) =>
                [{ type: TAG, id: args.id }, TAG, "Demand"]
        }),

    })
})

export const {
    useGetDemandLineQuery,
    useGetDemandLinesQuery,
    useGetDemandLinesByStudentIdQuery,
    useGetDemandLinesByDemandIdQuery,
    useLazyGetDemandLinesByDemandIdQuery,
    useUpdateDemandLineMutation,
} = demandLinesApi;