import { EntityDocument } from "../models";
import { rootApi } from "./api";

const entityDocumentsApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getDocuments: builder.query<EntityDocument[], string>({
            query: (entityId) => `/EntityDocuments/entity(${entityId})`,
        }),

        getContactDocumentContent: builder.query<string, string>({
            query: (documentId) => ({
                url: `/EntityDocuments/file(${documentId})`,
                responseHandler: (response: { text: () => any }) => response.text()
            })
        }),

        getDocumentsInFolder: builder.query<EntityDocument[], {
            entityId: string,
            folder: string
        }>({
            query: ({ entityId, folder }) => `/EntityDocuments/entity(${entityId})/${folder}`,
        }),
    })
})

export const {
    useGetDocumentsQuery,
    useGetContactDocumentContentQuery,
    useLazyGetContactDocumentContentQuery,
    useGetDocumentsInFolderQuery,
    useLazyGetDocumentsInFolderQuery,
} = entityDocumentsApi;