import Accordion from "react-bootstrap/esm/Accordion";
import { DateFormat } from "../../widgets-core";
import { Money } from "../../widgets-business"
import { PaymentTransaction } from "../../models"
import React from "react";
import clsx from "clsx"

export const PaymentTransactionsView = ({ entities, className }: {
    entities: PaymentTransaction[],
    className?: string,
}) => {
    const entries = entities; //.filter(entity => entity.slcmStatus === "guaranteed" || entity.slcmStatus === "delivered")

    if (!entries || entries.length === 0) return <></>

    return (
        <Accordion className={className}>
            <Accordion.Item eventKey={""}>
                <Accordion.Header bsPrefix="accordion-header accordion-header-sm">
                    <h5 className='text-gray-700 my-0'>Payments</h5>
                </Accordion.Header>
                <Accordion.Body>
                    <div className="scroll-y mh-250px">
                        {entries.map((entity) =>
                            <React.Fragment key={entity.slcmPaymenttransactionId}>
                                <PaymentTransactionPanel entity={entity} className="mb-2 fs-8" />
                            </React.Fragment>
                        )}
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

const PaymentTransactionPanel = ({ entity, className }: {
    entity: PaymentTransaction,
    className?: string
}) => {
    return (
        <div className={clsx('py-1 px-5 border rounded', className)}>
            <div className={'d-flex flex-wrap'}>
                <div className="w-xl-125px me-3 my-2">
                    <span className="text-gray-600">Payment ID</span>
                    <div className="text-gray-700 fw-bolder">
                        {entity.slcmPaymentid}
                    </div>
                </div>
                <div className="w-xl-125px me-3 my-2">
                    <span className="text-gray-600">Date</span>
                    <div className="text-gray-700 fw-bolder">
                        <DateFormat date={entity.slcmPaymentDate} />
                    </div>
                </div>
                <div className="w-xl-125px me-3 my-2">
                    <span className="text-gray-600">Tuition Fee</span>
                    <div className="text-gray-700 fw-bolder">
                        <Money
                            currencyId={entity.slcmCurrencyTo?.id}
                            amount={entity.slcmTuitionfee} />
                    </div>
                </div>
                <div className="w-xl-125px me-3 my-2">
                    <span className="text-gray-600">Hostel Fee</span>
                    <div className="text-gray-700 fw-bolder">
                        <Money
                            currencyId={entity.slcmCurrencyTo?.id}
                            amount={entity.slcmHostelFee} />
                    </div>
                </div>
                <div className="w-xl-125px me-3 my-2">
                    <span className="text-gray-600">Other Fee</span>
                    <div className="text-gray-700 fw-bolder">
                        <Money
                            currencyId={entity.slcmCurrencyTo?.id}
                            amount={entity.slcmOther} />
                    </div>
                </div>
                <div className="w-xl-125px me-3 my-2">
                    <span className="text-gray-600">Status</span>
                    <div className="text-gray-700 fw-bolder">
                        {entity.slcmStatus}
                    </div>
                </div>
            </div>
        </div>)
}
