import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core';

import { AuthInit } from './modules/auth';
import { I18nProvider } from '../_metronic/i18n/i18nProvider';
import { MasterInit } from '../_metronic/layout/MasterInit';
import { Outlet } from 'react-router-dom';
import { Suspense } from 'react';
import { UserProvider } from './store';

export const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <UserProvider>
              <Outlet />
              <MasterInit />
            </UserProvider>
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}