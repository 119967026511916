import { Location } from "../models";
import { rootApi } from './api';

const locationsApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getLocation: builder.query<Location, string>({
            query: (id) => `/Locations/${id}`,
        }),

        getLocations: builder.query<Location[], void>({
            query: () => `/Locations`,
        })
    })
})

export const {
    useGetLocationQuery,
    useGetLocationsQuery,
} = locationsApi;