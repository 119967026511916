import { ColDef } from "ag-grid-community";
import moment from "moment";

export const DefaultGridColDef: ColDef = {
    flex: 1,
    minWidth: 120,
    filter: false,
    sortable: true,
    resizable: true,
    wrapHeaderText: true,
    headerClass: 'fw-bolder fs-7 text-gray-700',
    autoHeaderHeight: true,
    autoHeight: true,
    cellClass: 'fw-bold fs-7 text-gray-700',
}

export const DateFilterParams = {
    comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
        if (moment(filterLocalDateAtMidnight).isSame(cellValue, 'date'))
            return 0;
        else if (moment(cellValue).isBefore(filterLocalDateAtMidnight))
            return -1;
        else
            return 1;
    },
}

export const formatDate = (date?: Date | string, format?: string) => {
    if (!date) return "";

    return moment(date).format(format || "DD-MMM-yyyy");
}

export const formatDateTime = (date?: Date | string, format?: string) => {
    if (!date) return "";
    
    return moment(date).local(false).format(format || "DD-MMM-yyyy hh:mm A")
}

export const formatTime = (date?: Date | string, format?: string) => {
    if (!date) return "";
    
    return moment(date).local(false).format(format || "hh:mm A")
}