import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"
import { Loading } from "."
import { SerializedError } from "@reduxjs/toolkit"
import clsx from "clsx"

export const If = ({ condition, children }: { condition: boolean, children?: React.ReactNode }) => {
    return <>{condition && <>{children}</>}</>
}

export const CardRefreshButton = ({ isLoading, refetch, className }: {
    isLoading: boolean,
    refetch: () => void,
    className?: string
}) => {
    return (
        <button
            onClick={refetch}
            type="button"
            title="Refresh"
            className={clsx(className, 'btn btn-sm btn-link text-hover-primary p-0 my-1')}>
            {(isLoading) ?
                <span className='indicator-progress' style={{ display: 'inline-block' }}>
                    <span className='spinner-border spinner-border-sm align-middle'></span>
                </span> : <i className="las la-redo-alt fs-2"></i>}
        </button>
    )
}

export const CardQueryStatus = ({ isLoading, isError, isSuccess, data }: {
    isSuccess: boolean,
    isError: boolean,
    error?: FetchBaseQueryError | SerializedError
    isLoading?: boolean,
    data?: any,
}) => {
    if (isLoading) {
        return <Loading status={"Pending"} />
    }

    if (isError) {
        return <div className="text-danger fw-bold d-flex align-items-center justify-content-center">
            <i className="las la-exclamation-triangle fs-2 text-danger me-1"></i>
            <span>Failed to load data.</span>
        </div>
    }

    if (isSuccess && !data) {
        return <div className="text-gray-600 fw-bold d-flex align-items-center justify-content-center">
            <i className="las la-info-circle fs-2 text-gray-600 me-1"></i>
            <span>There is no data to display.</span>
        </div>
    }

    return <></>
}

export const ListQueryStatus = ({ isLoading, isError, isSuccess, data, hideNoData, noDataMsg }: {
    isLoading: boolean,
    isError: boolean,
    isSuccess: boolean,
    data?: any[],
    hideNoData?: boolean,
    noDataMsg?: string,
    error?: FetchBaseQueryError | SerializedError
}) => {
    if (isLoading) {
        return <Loading status={"Pending"} />
    }

    if (isError || !isSuccess) {
        return <div className="text-danger fw-bold d-flex align-items-center justify-content-center">
            <i className="las la-exclamation-triangle fs-2 text-danger me-1"></i>
            <span>Failed to load data.</span>
        </div>
    }

    if (isSuccess && data?.length === 0 && !hideNoData) {
        return <div className="text-gray-600 fw-bold d-flex align-items-center justify-content-center">
            <i className="las la-info-circle fs-2 text-gray-600 me-1"></i>
            <span>{noDataMsg || 'There is no data to display.'}</span>
        </div>
    }

    return <></>
}
