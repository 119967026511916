/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React from 'react'
import { Link } from 'react-router-dom'
import { AppSvgIcon, SvgIconName } from '../../assets/icons'

type Props = {
  title: string
  description?: string
  to: string
  color: string
  svgIcon: SvgIconName
  className?: string
}

export const RouterLinkImageButton: React.FC<Props> = ({
  title,
  description,
  to,
  color,
  svgIcon,
  className,
}) => {
  return (
    <Link to={to} className={`card bg-${color} hoverable ${className}`}>
      <div className='card-body p-5'>
        <div className='d-flex container-fluid p-0'>
          <div className='flex-column flex-grow-0 text-center px-2 my-auto'>
            <AppSvgIcon iconName={svgIcon} sx={{ fontSize: 30 }} className={
              clsx(`text-inverse-${color} ms-n1`)} />
          </div>
          <div className='flex-column flex-grow-1'>
            <div className={`text-inverse-secondary fs-6 fw-bold`}>{title}</div>
            <div className={`text-primary fw-bold fs-7`}>{description}</div>
          </div>
        </div>
      </div>
    </Link>
  )
}
