import { SlcmPaymentSlcmPaymentmethod } from "./enums/slcmPaymentSlcmPaymentmethod";
import { SlcmPaymentStatuscode } from "./enums/slcmPaymentStatuscode";
import { SlcmPaymentSlcmPaymenttype } from "./enums/slcmPaymentSlcmPaymenttype";

export * from "./Payment";

export const getPaymentStatusLabel = (statusCode?: SlcmPaymentStatuscode) => {
    switch(statusCode) {
        case SlcmPaymentStatuscode.Active: return "Active";
        case SlcmPaymentStatuscode.Inactive: return "Inactive";
        default: return "";
    }
}

export const getPaymentMethodLabel = (method?: SlcmPaymentSlcmPaymentmethod) => {
    switch(method) {
        case SlcmPaymentSlcmPaymentmethod.Cash: return "Cash"
        case SlcmPaymentSlcmPaymentmethod.DD: return "DD"
        case SlcmPaymentSlcmPaymentmethod.Cheque: return "Cheque"
        case SlcmPaymentSlcmPaymentmethod.CreditCard: return "Credit Card"
        default: return ""
    }
}

export const getPaymentType = (type?: SlcmPaymentSlcmPaymenttype) => {
    switch(type) {
        case SlcmPaymentSlcmPaymenttype.Online: return "Online"
        case SlcmPaymentSlcmPaymenttype.Offline: return "Offline"
        default: return ""
    }
}