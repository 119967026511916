import { CourseHistoryView } from "../../modules/course-history";
import { FC } from "react"
import { PageTitle } from "../../../_metronic/layout/core"
import { useParams } from "react-router-dom";
import { useUserContact } from "../../hooks/user";

export const CourseHistoryWrapper: FC = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>
                Course History
            </PageTitle>
            <CourseHistory></CourseHistory>
        </>
    )
}

const CourseHistory: FC = () => {
    const { id }: { id?: string } = useParams();
    const contact = useUserContact()

    return (<>
        {(id && contact) && <CourseHistoryView
            contact={contact}
            courseId={id} />}
    </>)
}