import { useEffect, useState } from 'react'

import { PaymentTransaction } from '../../models'
import { PaymentTransactionsView } from './PaymentTransactionsView'
import _ from 'lodash'
import { useGetPaymentTransactionByRefQuery } from '../../services/payment-transactions.api'

export const TrackPaymentTransactions = ({ paymentReference, className }: {
    className?: string,
    paymentReference: string,
}) => {
    const [entities, setEntities] = useState<PaymentTransaction[]>();
    const { data: payments, isSuccess } = useGetPaymentTransactionByRefQuery(paymentReference);

    useEffect(() => {
        let entities = payments?.filter(p => !!p.slcmPaymentid);
        entities = _.sortBy(entities, p => new Date(p.slcmPaymentDate || 0)).reverse();
        setEntities(entities);
        // eslint-disable-next-line
    }, [isSuccess, payments])

    if (!entities) return <></>

    return (<PaymentTransactionsView entities={entities} className={className} />)
}

