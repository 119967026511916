import { Deferment, DemandLine } from "../../../../models";
import React, { useState } from "react"
import { useGetDefermentByDemandNoQuery, useUpdateDefermentMutation } from "../../../../services/deferments.api";

import { DemandHeader } from "../../../../models";
import { DemandLinesTable } from './DemandLinesTable';
import { PayableAmount } from "./PayableAmount";
import { SlcmDefermentStatuscode } from "../../../../models/deferment/enums/slcmDefermentStatuscode"
import { useNavigate } from 'react-router-dom'

export const DemandPayDefermentView = ({ demand, demandLines, submitLabel, className }: {
    demand: DemandHeader,
    demandLines: DemandLine[],
    submitLabel?: string,
    className?: string,
}) => {
    const navigate = useNavigate()
    const [amount, setAmount] = useState<number>(0);

    const { data: defermentByDemand } = useGetDefermentByDemandNoQuery(demand.slcmDemandNo!)
    const [updateDeferment, { isLoading }] = useUpdateDefermentMutation()

    const updateDefermentStatus = async () => {
        let paymentsLinesToUpdate = defermentByDemand?.filter(item => item.slcmDefermentId)
        const updateDefermentStatusInner = async () => {
            if (!paymentsLinesToUpdate || paymentsLinesToUpdate?.length <= 0)
                return;

            const deferment: Deferment = paymentsLinesToUpdate[0];
            const request: Partial<Deferment> = {
                statuscode: SlcmDefermentStatuscode.Submitted
            };

            await updateDeferment({ id: deferment.slcmDefermentId, entity: request });
            paymentsLinesToUpdate = paymentsLinesToUpdate.filter((_, i) => i !== 0);
            updateDefermentStatusInner();
        }

        try {
            await updateDefermentStatusInner();
            navigate('/service-requests/407450000')
        } catch (err) {
        }
    }

    const isDraftDeferment = () => {
        const draft = defermentByDemand?.find(p => p.statuscode === SlcmDefermentStatuscode.Draft || p.statuscode === SlcmDefermentStatuscode.Rejected)
        return (!!draft);
    }

    return (
        <div className={className}>
            <DemandLinesTable
                demand={demand}
                demandLines={demandLines}
                selection={"Expand"}
                onChange={(amount) => {
                    setAmount(amount);
                }}
            />

            {isDraftDeferment() &&
                <div className="d-flex flex-wrap align-items-end mt-5">
                    <PayableAmount
                        className="my-2"
                        label="Total Amount Requested for Deferment"
                        amount={amount}
                        currencyId={demand.transactionCurrencyId?.id} />

                    <div className="flex-fill text-right ">
                        <button
                            disabled={isLoading}
                            className='btn btn-sm btn-primary'
                            onClick={updateDefermentStatus}>
                            {isLoading ?
                                <span className='indicator-progress' style={{ display: 'inline-block' }}>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                                : <>
                                    <i className="las la-share my-0 fs-3 me-1"></i>
                                    <span className='indicator-label'>
                                        {submitLabel || 'Submit Deferment Request'}
                                    </span>
                                </>}
                        </button>
                    </div>
                </div>
            }
        </div>)
}
