import { FC } from "react";

type Props = {
    value?: number,
    onChange?: (value: number) => void,
    className?: string,
};

export const SelectRepeatSemester: FC<Props> = ({
    value,
    onChange,
    className
}) => {
    return <select
        className="form-select form-select-sm"
        aria-label="Select a reason"
        value={value}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            onChange && onChange(Number(event.target.value));
        }}
        placeholder="Select a reason">
        <option></option>
        <option value="407450000">Self Study</option>
        <option value="407450001">Remedial</option>
    </select>
}

