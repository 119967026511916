import clsx from "clsx";
import { useEffect } from "react";
import { useLazyGetSemesterQuery } from "../../../../services/semesters.api";
import { useUserContact } from "../../../../hooks/user";

export const SelectSemester = ({ value, onChange, className }: {
    value?: string,
    onChange?: (value: string) => void,
    className?: string,
}) => {
    const contact = useUserContact();
    const [getSemester, { data: semester, isSuccess }] = useLazyGetSemesterQuery();

    useEffect(() => {
        if (value) {
            getSemester(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    useEffect(() => {
        if (!value && contact?.slcmCurrentSemester?.id) {
            getSemester(contact?.slcmCurrentSemester?.id);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contact?.slcmCurrentSemester?.id, value])

    useEffect(() => {
        if (!value && semester) {
            onChange && onChange(semester.slcmSemesterId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [semester])

    if (!isSuccess) return <></>;

    const SelectOptions = () => {
        return (
            <option
                key={semester?.slcmSemesterId}
                value={semester?.slcmSemesterId}>
                {`Year ${semester?.slcmYear} - ${semester?.slcmName}`}
            </option>
        )
    }

    return <select
        className={clsx(className, "form-select form-select-sm")}
        aria-label="Select semester"
        value={value}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            onChange && onChange(event.target.value);
        }}
        placeholder={'Select semester'}>
        {SelectOptions()}
    </select>
}