import { ApiTags, rootApi } from './api';

import { Result } from "../models";

const TAG = ApiTags.HallTicket;

const resultApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getResult: builder.query<Result, string>({
            query: (id) => `/Result/${id}`,
            providesTags: (result) =>
                result ?
                    [{ type: TAG, id: result?.slcmStudent?.id }, TAG] : [TAG]
        }),

        getresults: builder.query<Result[], void>({
            query: () => `/Result`,
            providesTags: (result) =>
                result ?
                    [...result.map((p) => ({ type: TAG, id: p?.slcmStudent?.id })), TAG] :
                    [TAG]
        }),

        getStudentResults: builder.query<Result[], string>({
            query: (studentId) => `/Result/student(${studentId})`,
            providesTags: (result) =>
                result ?
                    [...result.map((p) => ({ type: TAG, id: p?.slcmStudent?.id })), TAG] :
                    [TAG]
        }),



    })
})

export const {
    useGetStudentResultsQuery,
    useLazyGetStudentResultsQuery,
} = resultApi;