import { FC } from "react";
import { useParams } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { ViewDemand } from "../../modules/fee-management";

export const ViewDemandWrapper: FC = () => {
    const pageLinks: PageLink[] = [
        { title: 'Demands', isActive: false, path: "/demands" },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]

    return (<>
        <PageTitle breadcrumbs={pageLinks}>{'Service Request'}</PageTitle>
        <ViewDemandPage />
    </>)
}

const ViewDemandPage = () => {
    const { demandId }: { demandId?: string } = useParams();

    if (!demandId) return <></>;

    return <ViewDemand demandId={demandId} />
}

