import { SlcmDemandheaderSlcmDocumenttype } from "./enums/slcmDemandheaderSlcmDocumenttype";
import { SlcmDemandheaderStatuscode } from "./enums/slcmDemandheaderStatuscode";

export * from "./DemandHeader";

export const getDemandStatusLabel = (statusCode?: SlcmDemandheaderStatuscode) => {
    switch (statusCode) {
        case SlcmDemandheaderStatuscode.Active: return "Active";
        case SlcmDemandheaderStatuscode.Inactive: return "Inactive";
        default: return ""
    }
} 

export const getDemandDocumentType = (statusCode?: SlcmDemandheaderSlcmDocumenttype) => {
    switch (statusCode) {
        case SlcmDemandheaderSlcmDocumenttype.Demand: return "Registration Fee";
        case SlcmDemandheaderSlcmDocumenttype.Receipt: return "Receipt";
        case SlcmDemandheaderSlcmDocumenttype.Request: return "Service Request";
        case SlcmDemandheaderSlcmDocumenttype.Utility: return "Utility Charges";
        default: return "NA"
    }
}