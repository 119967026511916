/* eslint-disable react/jsx-no-target-blank */

import { AsideMenuItem } from './AsideMenuItem'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { SlcmServicerequestsStatuscode } from '../../../models';
import { useUserContact } from '../../../hooks/user';

export function AsideMenuMain() {
  const contact = useUserContact();
  
  if (!!contact?.slcmUndertaking && contact?.slcmUndertaking === 407450000) {
    return (
      <>
        <AsideMenuItem
          to='/dashboard'
          svgIcon="Dashboard"
          title={'Dashboard'}
          fontIcon='bi-app-indicator'
        />

        <AsideMenuItem
          to='/studenthandbook'
          svgIcon="Summary"
          title={'Student Handbook'}
          fontIcon='bi-app-indicator'
        />
      </>
    )
  }

  if (!!contact?.slcmUndertaking && contact?.slcmUndertaking === 407450001) {
    return (
      <>
        <AsideMenuItem
          to='/dashboard'
          svgIcon="Dashboard"
          title={'Dashboard'}
          fontIcon='bi-app-indicator'
        />
        
        <AsideMenuItem
          to='/studenthandbook'
          svgIcon="Summary"
          title={'Student Handbook'}
          fontIcon='bi-app-indicator'
        />

        <AsideMenuItemWithSub
          to=''
          title='Finance'
          svgIcon='Wallet'
        >
          <AsideMenuItem
            to='/fee-summary'
            svgIcon='Summary'
            title={'Summary'}
          />
          <AsideMenuItem
            to='/demands'
            svgIcon='Receipt'
            title={'Invoices'}
          />
          <AsideMenuItem
            to='/payments'
            svgIcon='Payments'
            title={'Receipts'}
          />
          <AsideMenuItem
            to='/student-ledger'
            svgIcon='Ledger'
            title={'Student Ledger'}
          />
          <AsideMenuItem
            to='/fee-structure'
            svgIcon='Description'
            title={'Fee Structure'}
          />
          <AsideMenuItem
            to='/payment-instructions'
            svgIcon='Payments'
            title={'Payment Instructions'}
          />
        </AsideMenuItemWithSub>
      </>
    )
  }

  if (!!contact?.slcmUndertaking && contact?.slcmUndertaking === 407450002) {
    return (
      <>
        <AsideMenuItem
          to='/dashboard'
          svgIcon="Dashboard"
          title={'Dashboard'}
          fontIcon='bi-app-indicator'
        />

        <AsideMenuItem
          to='/studenthandbook'
          svgIcon="Summary"
          title={'Student Handbook'}
          fontIcon='bi-app-indicator'
        />


        <AsideMenuItem
          to='/academicschedule'
          svgIcon="Outbox"
          title={'Academic Calendar'}
          fontIcon='bi-app-indicator'
        />

        <AsideMenuItem
          to='/timetable'
          svgIcon='Calendar'
          title={'Timetable'}
        />
        <AsideMenuItemWithSub
          to=''
          title='Appointments'
          svgIcon='Appointment'
        >
          <AsideMenuItem
            to='/appointments'
            svgIcon='EMail'
            title={'Faculty / Mentor'}
          />
          <AsideMenuItem
            to='/counsellor-appointments'
            svgIcon='EMail'
            title={'Counsellor'}
          />
          <AsideMenuItem
            to='/hod-appointments'
            svgIcon='EMail'
            title={'HOD'}
          />
          <AsideMenuItem
            to='/dean-appointments'
            svgIcon='EMail'
            title={'Dean'}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItem
          to='/program-details'
          svgIcon='Program'
          title={'Program Details'}
        />

        <AsideMenuItemWithSub
          to=''
          title='Finance'
          svgIcon='Wallet'
        >
          <AsideMenuItem
            to='/fee-summary'
            svgIcon='Summary'
            title={'Summary'}
          />
          <AsideMenuItem
            to='/demands'
            svgIcon='Receipt'
            title={'Invoices'}
          />
          <AsideMenuItem
            to='/payments'
            svgIcon='Payments'
            title={'Receipts'}
          />
          <AsideMenuItem
            to='/student-ledger'
            svgIcon='Ledger'
            title={'Student Ledger'}
          />
          <AsideMenuItem
            to='/fee-structure'
            svgIcon='Description'
            title={'Fee Structure'}
          />
          <AsideMenuItem
            to='/payment-instructions'
            svgIcon='Payments'
            title={'Payment Instructions'}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub
          to=''
          title='Service Requests'
          svgIcon='Outbox'
        >
          <AsideMenuItem
            to={`/service-requests/${SlcmServicerequestsStatuscode.Draft}`}
            svgIcon='EmailDraft'
            title={'New/Draft service Requests'}
          />
          <AsideMenuItem
            to={`/service-requests/${SlcmServicerequestsStatuscode.Open}`}
            svgIcon='EmailUnread'
            title={'In Progress'}
          />
          <AsideMenuItem
            to={`/service-requests/${SlcmServicerequestsStatuscode.Closed}`}
            svgIcon='EmailRead'
            title={'Closed'}
          />
        </AsideMenuItemWithSub>
        
        {/* <AsideMenuItemWithSub
            to=''
            title='Results'
            svgIcon='Program'
        >
            <AsideMenuItem
                to={`/transcript`}
                svgIcon='Ledger'
                title={'Transcript'}
            />
        </AsideMenuItemWithSub> */}
      </>
    )
  }

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        svgIcon="Dashboard"
        title={'Dashboard'}
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/studenthandbook'
        svgIcon="Summary"
        title={'Student Handbook'}
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/academicschedule'
        svgIcon="Outbox"
        title={'Academic Calendar'}
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/timetable'
        svgIcon='Calendar'
        title={'Timetable'}
      />
      <AsideMenuItem
        to='/scan-attendance'
        svgIcon='QRScanner'
        title={'Scan Attendance'}
      />
      <AsideMenuItemWithSub
        to=''
        title='Appointments'
        svgIcon='Appointment'
      >
        <AsideMenuItem
          to='/appointments'
          svgIcon='EMail'
          title={'Faculty / Mentor'}
        />
        <AsideMenuItem
          to='/counsellor-appointments'
          svgIcon='EMail'
          title={'Counsellor'}
        />
        <AsideMenuItem
          to='/hod-appointments'
          svgIcon='EMail'
          title={'HOD'}
        />
        <AsideMenuItem
          to='/dean-appointments'
          svgIcon='EMail'
          title={'Dean'}
        />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to='/program-details'
        svgIcon='Program'
        title={'Program Details'}
      />

      <AsideMenuItemWithSub
        to=''
        title='Finance'
        svgIcon='Wallet'
      >
        <AsideMenuItem
          to='/fee-summary'
          svgIcon='Summary'
          title={'Summary'}
        />
        <AsideMenuItem
          to='/demands'
          svgIcon='Receipt'
          title={'Invoices'}
        />
        <AsideMenuItem
          to='/payments'
          svgIcon='Payments'
          title={'Receipts'}
        />
        <AsideMenuItem
          to='/student-ledger'
          svgIcon='Ledger'
          title={'Student Ledger'}
        />
        <AsideMenuItem
          to='/fee-structure'
          svgIcon='Description'
          title={'Fee Structure'}
        />
        <AsideMenuItem
          to='/payment-instructions'
          svgIcon='Payments'
          title={'Payment Instructions'}
        />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to=''
        title='Service Requests'
        svgIcon='Outbox'
      >
        <AsideMenuItem
          to={`/service-requests/${SlcmServicerequestsStatuscode.Draft}`}
          svgIcon='EmailDraft'
          title={'New/Draft Service Requests'}
        />
        <AsideMenuItem
          to={`/service-requests/${SlcmServicerequestsStatuscode.Open}`}
          svgIcon='EmailUnread'
          title={'In Progress'}
        />
        <AsideMenuItem
          to={`/service-requests/${SlcmServicerequestsStatuscode.Closed}`}
          svgIcon='EmailRead'
          title={'Closed'}
        />
      </AsideMenuItemWithSub>

      {/* <AsideMenuItemWithSub
            to=''
            title='Results'
            svgIcon='Program'
        >
            <AsideMenuItem
                to={`/transcript`}
                svgIcon='Ledger'
                title={'Transcript'}
            />
        </AsideMenuItemWithSub> */}
    </>
  )
}

