export enum SystemUserCALType {
  Professional = 0, // Professional
  Administrative = 1, // Administrative
  Basic = 2, // Basic
  DeviceProfessional = 3, // Device Professional
  DeviceBasic = 4, // Device Basic
  Essential = 5, // Essential
  DeviceEssential = 6, // Device Essential
  Enterprise = 7, // Enterprise
  DeviceEnterprise = 8, // Device Enterprise
  Sales = 9, // Sales
  Service = 10, // Service
  FieldService = 11, // Field Service
  ProjectService = 12, // Project Service
}