export enum SlcmServicerequestsSlcmSemester {
  Semester1 = 407450000, // Semester 1
  Semester2 = 407450001, // Semester 2
  Semester3 = 407450002, // Semester 3
  Semester4 = 407450003, // Semester 4
  Semester5 = 407450004, // Semester 5
  Semester6 = 407450005, // Semester 6
  Semester7 = 407450006, // Semester 7
  Semester8 = 407450007, // Semester 8
  Semester9 = 407450008, // Semester 9
  Semester10 = 407450009, // Semester 10
}