export const SelectSemester = ({
    value,
    onChange,
    className
}: {
    value?: number,
    onChange?: (value: number) => void,
    className?: string,
}) => {
    return <select
        className="form-select form-select-sm"
        aria-label="Select semester"
        value={value}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            onChange && onChange(Number(event.target.value));
        }}
        placeholder="Select semester">
        <option></option>
        <option value="407450000">Semester 1</option>
        <option value="407450001">Semester 2</option>
        <option value="407450002">Semester 3</option>
        <option value="407450003">Semester 4</option>
        <option value="407450004">Semester 5</option>
        <option value="407450005">Semester 6</option>
        <option value="407450006">Semester 7</option>
        <option value="407450007">Semester 8</option>
        <option value="407450008">Semester 9</option>
        <option value="407450009">Semester 10</option>
    </select>
}
