import { Hostel } from "../models";
import { rootApi } from './api';

const hostelsApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getHostel: builder.query<Hostel, string>({
            query: (id) => `/Hostels/${id}`,
        }),

        getHostels: builder.query<Hostel[], void>({
            query: () => `/Hostels`,
        })
    })
})

export const {
    useGetHostelQuery,
    useGetHostelsQuery,
} = hostelsApi;