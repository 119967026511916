import axios, { AxiosResponse } from 'axios'

import { AuthModel } from './_models'

const API_URL = process.env.REACT_APP_API_URL;
const AUTHENTICATE_URL = `${API_URL}/Authenticate`;

export async function msalLogin(userName: string, token: string) {
  const result = await axios.post<{ token: string, refreshToken: string }>(
    `${AUTHENTICATE_URL}/MSALLogin`,
    { userName, token });

  return Promise.resolve<AxiosResponse<AuthModel>>({
    ...result,
    data: {
      authType: "MSAL",
      userName: userName,
      api_token: result.data.token,
      refreshToken: result.data.refreshToken
    }
  });
}

export interface IMSALConfig  {
  clientId: string,
  tenantId: string,
  redirectUri: string,
}

export async function getMSALConfig() {
  const result = await axios.get<IMSALConfig>(`${AUTHENTICATE_URL}/MSALConfig`)
  return result;
}

export async function login(username: string, password: string) {
  const result = await axios.post<{ token: string, refreshToken: string }>(
    `${AUTHENTICATE_URL}/login`,
    {
      username,
      password,
    });

  return Promise.resolve<AxiosResponse<AuthModel>>({
    ...result,
    data: {
      authType: "Custom",
      userName: username,
      api_token: result.data.token,
      refreshToken: result.data.refreshToken
    }
  });
}

// Server should return AuthModel
export function register(
  email: string,
  rollNumber: string,
  username: string,
  password: string
) {
  return axios.post(`${AUTHENTICATE_URL}/register`, {
    email,
    rollNumber: rollNumber,
    username: username,
    password
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(
    `${AUTHENTICATE_URL}/forgot_password`,
    {
      email,
    })
}

export async function getUserByToken(token: string) {
  const options = {
    headers: {
      "accept": "text/plain",
      "Content-Type": "application/json"
    }
  };

  return await axios.post<string>(
    `${AUTHENTICATE_URL}/verify-token`,
    token,
    options);
}
