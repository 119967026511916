import { useEffect, useState } from "react"

import { useGetRegistrationStatusesQuery } from "../services/registration-statuses.api"

export const useGetRegistrationStatusCodes = () => {
    interface StatusCodes {
        SCH?: string
        PWS?: string
        PWA?: string,
        PWF?: string,      
        COM?: string,      
    }

    const { data: statuses, isSuccess } = useGetRegistrationStatusesQuery()
    const [statusCodes, setStatusCodes] = useState<StatusCodes>({})

    useEffect(() => {
        if (!isSuccess) return;

        const SCH = statuses.find(p => p.mshiedCode === 'SCH')?.mshiedRegistrationstatusId;
        const PWS = statuses.find(p => p.mshiedCode === 'PWS')?.mshiedRegistrationstatusId;
        const PWA = statuses.find(p => p.mshiedCode === 'PWA')?.mshiedRegistrationstatusId;
        const PWF = statuses.find(p => p.mshiedCode === 'PWF')?.mshiedRegistrationstatusId;
        const COM = statuses.find(p => p.mshiedCode === 'COM')?.mshiedRegistrationstatusId;

        setStatusCodes({ SCH, PWS, PWA, PWF,COM })
        
        // eslint-disable-next-line
    }, [isSuccess])

    return { ...statusCodes }
}
