import { Contact } from "../../../../models";
import { SlcmRequestsStatecode } from "../../../../models/services/enums/slcmRequestsStatecode";
import _ from "lodash";
import { useCallback } from "react";
import { useGetServicesByCategoryIdQuery } from "../../../../services/services.api";

export const useGetServicesByCategoryId = (contact: Contact, categoryId?: string) => {
    const { data: services, isLoading, isFetching, isError, isSuccess, refetch } = useGetServicesByCategoryIdQuery(categoryId || '');

    const getRequestCategories = useCallback((categoryId?: string) => {
        if (!isSuccess) return [];
        const services2 = services.filter(p =>
            p.slcmRelatedto?.id === categoryId &&
            p.statecode === SlcmRequestsStatecode.Active &&
            (!p.slcmCampusSpecific || p.slcmCampusLookup?.id === contact.slcmCampus?.id) &&
            (!p.slcmProgramSpecific || p.slcmProgram?.id === contact?.mshiedCurrentProgramId?.id)
        );
        const sortedServices = _.sortBy(services2, p => p.slcmName);
        return sortedServices;

    }, [isSuccess, services, contact])

    return { data: getRequestCategories(categoryId), isLoading, isFetching, isError, isSuccess, refetch }
}
