import { useUser as useUser2 } from '../store/providers';

export const useUser = () => {
    const user = useUser2()
    return user;
}

export const useUserContact = () => {
    const { contact } = useUser2()
    return contact;
}

export const useUpdateUserImage = () => {
    const { setImage } = useUser2()
    
    return (image: string) => {
        if (!image) return;
        setImage(image)
    }
}
