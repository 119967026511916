import { PersonIcon } from "../../assets/icons";
import clsx from "clsx";
import { useEffect } from "react"
import { useLazyGetEntityImageQuery } from "../../services/entity-image.api";

export const EntityImage = ({ entity, id, className, iconClassName }: {
    entity: string,
    id: string,
    className?: string,
    iconClassName?: string
}) => {
    const [getEntityImage, { data: image }] = useLazyGetEntityImageQuery();

    useEffect(() => {
        if (!entity || !id) return;
        getEntityImage({ entity, id });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, entity])

    return (
        <div className={clsx('symbol symbol-20px', className)}>
            {image ?
                <img alt={entity} src={`data:image/bmp;base64,${image}`} /> :
                <PersonIcon className={iconClassName} />}
        </div>
    )
}