import { ApiTags, rootApi } from './api';

import { Achievement} from "../models";

const TAG = ApiTags.Achievement;

const achievementApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getAchievement: builder.query<Achievement, string>({
            query: (id) => `/Achievement/${id}`,
            providesTags: (result, err) =>
                result ?
                    [{ type: TAG, id: result.mshiedAchievementId }, TAG] : [TAG]
        }),

        getAchievements: builder.query<Achievement[], void>({
            query: () => `/Achievement`,
            providesTags: (result, err) =>
                result ?
                    [...result.map((p) => ({ type: TAG, id: p.mshiedAchievementId })), TAG] :
                    [TAG]
        }),

        getAchievementsByStudentId: builder.query<Achievement[], string>({
            query: (contactId) => `/Achievement/Contact(${contactId})`,
            providesTags: (result, err) =>
                result ?
                    [...result.map((p) => ({ type: TAG, id: p.mshiedAchievementId })), TAG] :
                    [TAG]
        }),
    })
})

export const {
    useGetAchievementQuery,
    useGetAchievementsQuery,
    useGetAchievementsByStudentIdQuery,
    useLazyGetAchievementsByStudentIdQuery
} = achievementApi;