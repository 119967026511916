import { useEffect, useRef, useState } from "react";

import { PaymentTransaction } from "../../models";
import { useGetPaymentTransactionMutation } from "../../services/payment-transactions.api";

export const useTrackPaymentStatus = (transactionId: string, intervals: number) => {
    const [status, setStatus] = useState<string>("idle");
    const [entity, setEntity] = useState<PaymentTransaction>();
    const [getPaymentTransaction] = useGetPaymentTransactionMutation();
    const savedCallback = useRef(getPaymentTransaction);

    useEffect(() => {
        savedCallback.current = getPaymentTransaction;
        // eslint-disable-next-line
    }, [getPaymentTransaction])

    useEffect(() => {
        let id: NodeJS.Timeout;

        const getPaymentStatus = async () => {
            const entity = await savedCallback.current(transactionId).unwrap();
            setEntity(entity)
            setStatus(entity.slcmStatus || "idle");
            
            if (!entity.slcmStatus || entity.slcmStatus === "processed" || entity.slcmStatus === "initiated")
                id = setTimeout(getPaymentStatus, intervals);
        }

        if (transactionId)
            id = setTimeout(getPaymentStatus, intervals);

        return () => id && clearTimeout(id);

        // eslint-disable-next-line
    }, [transactionId, intervals]);

    return { status, entity }
} 