import { KTSVG } from "../../../helpers";
import clsx from "clsx";

export const StepsBackButton = ({ className, onClick }: {
    className?: string,
    onClick: () => void,
}) => {
    return <button
        onClick={onClick}
        type='button'
        className={clsx(className, 'btn btn-lg btn-light-primary')}
        data-kt-stepper-action='previous'
    >
        <KTSVG
            path='/media/icons/duotune/arrows/arr063.svg'
            className='svg-icon-4 me-1' />
        Back
    </button>;
}
