import React from "react"
import { NotificationIcon } from "../../assets/icons";
import { Contact } from "../../models";
import { DownloadButton } from "../../modules/payments/DownloadButton";
import { useGetProgramQuery } from "../../services/program.api";

export const StudentHandbook = ({ contact }: {
    contact: Contact
}) => {
    const { data: studenthandbook } = useGetProgramQuery(contact.mshiedCurrentProgramId?.id || "");

    if (!studenthandbook?.slcmStudentsHandbookLink) {
        return <></>
    }
    return <>
        <React.Fragment key={studenthandbook.slcmStudentsHandbookLink}>
            <div style={{ marginTop: "5px" }} className="alert bg-secondary d-flex flex-column flex-sm-row px-5 py-3 mb-0 fs-7">
                <NotificationIcon sx={{ fontSize: 40 }} className="text-primary me-3" />
                <div className="d-flex flex-column pe-0 pe-sm-10 text-gray-800">
                    <h6 className="mb-1" style={{ marginTop: "13px" }}>Download your handbook</h6>
                </div>
                <div className="flex-fill" />
                <div>
                    <DownloadButton
                        className={"btn btn-primary btn-sm my-1"}
                        label='Download'
                        link={studenthandbook.slcmStudentsHandbookLink} />
                </div>
            </div>
        </React.Fragment>
    </>
}