import { CardPanelToolbar } from "../../../widgets-core";
import { PayableAmount } from "../../fee-management/demand/components/PayableAmount";
import { ServiceRequest } from "../../../models";

export const ServicePayableAmount = ({ serviceRequest, label }: { serviceRequest: ServiceRequest, label?: string }) => {
    if (!serviceRequest.slcmTotalFeeAmount || serviceRequest.slcmTotalFeeAmount === 0)
        return <></>

    return <CardPanelToolbar>
        <PayableAmount
            className="text-left text-gray-600 py-3 me-6"
            label={label || "Total Payable Amount"}
            amount={serviceRequest.slcmTotalFeeAmount || 0}
            currencyId={serviceRequest.transactionCurrencyId?.id} />
    </CardPanelToolbar>;
}
