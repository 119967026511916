import { getFileNameFromUrl, getMediaType } from "../../helpers";

import { useGetFileContentMutation } from "../../services/associated-documents.api";
import { useState } from "react";

export const DownloadButton = ({ label, link, className }: {
    label: string,
    link?: string,
    className?: string
}) => {
    
    const [getFileContentApi] = useGetFileContentMutation();
    const [downloading, setDownloading] = useState<boolean>(false);

    const handlePreviewClick = (fileName?: string) => {
        if (!fileName) return;

        setDownloading(true);
        getFileContentApi(fileName)
            .unwrap()
            .then(async (data) => {
                const url = `data:${getMediaType(fileName)};base64,${data}`;
                const link = document.createElement('a');

                link.href = url;
                link.setAttribute(
                    'download',
                    getFileNameFromUrl(fileName),
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();
                link?.parentNode?.removeChild(link);
            })
            .catch((err) => console.error(err))
            .finally(() => {
                setDownloading(false);
            });
    };
    if (!link) return <></>

    return (
        <button disabled={downloading}
            className={className}
            onClick={() => handlePreviewClick(link)}>
            {downloading ?
                <span className='indicator-progress' style={{ display: 'inline-block' }}>
                    <span className='spinner-border spinner-border-sm align-middle me-1'></span>
                </span> :
                <i className="las la-link fs-4"></i>}
            {label}
        </button>
    )
}