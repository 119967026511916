import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
import './styles.scss'

import { AuthProvider, setupAxios } from './app/modules/auth';
import { Chart, registerables } from 'chart.js'
import { QueryClient, QueryClientProvider } from 'react-query'

import { AppRoutes } from './app/routing/AppRoutes'
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'
import ReactDOM from 'react-dom'
import { StoreProvider } from './app/store'
import axios from 'axios'

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)

Chart.register(...registerables)

const queryClient = new QueryClient()

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <MetronicI18nProvider>
      <StoreProvider>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </StoreProvider>
    </MetronicI18nProvider>
  </QueryClientProvider>,
  document.getElementById('root')
)
