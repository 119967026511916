import { CardPanel, CardPanelBody, CardPanelHeader, CardPanelToolbar, CardQueryStatus, CardRefreshButton, If } from "../../widgets-core";
import { Contact, Result } from "../../models";

import { DownloadButton } from "../../modules/payments/DownloadButton";
import { NotificationIcon } from "../../assets/icons";
import React from "react"
import clsx from "clsx";
import { useGetStudentResultsQuery } from "../../services/result.api";

export const Transcript = ({ contact, className }: {
    contact: Contact,
    className?: string
}) => {
    const { data: results, isLoading, isFetching, isSuccess, isError, error, refetch } = useGetStudentResultsQuery(contact.contactId);

    return (
        <CardPanel className={clsx(className, "bg-transparent")}>
            <CardPanelHeader
                className="ribbon ribbon-top"
                title={'Transcript'}
                subTitle="Transcript Details">
                <CardPanelToolbar>
                    <CardRefreshButton
                        className="me-3"
                        isLoading={isLoading || isFetching}
                        refetch={() => {
                            refetch();
                        }} />
                </CardPanelToolbar>
            </CardPanelHeader>
            <CardPanelBody>
                <CardQueryStatus
                    isLoading={isLoading || isFetching}
                    isSuccess={isSuccess}
                    isError={isError}
                    error={error}
                    data={results} />
                <If condition={!isLoading && !isFetching && isSuccess}>
                    {results?.map(item => <React.Fragment key={item.slcmResultId}>
                        <ResultInner result={item} />
                    </React.Fragment>)}
                </If>
            </CardPanelBody>
        </CardPanel>
    )
}

const ResultInner = ({ result }: { result: Result }) => {
    if (!result?.slcmResultLink) {
        return <></>
    }

    return (
        <div style={{ marginTop: "5px" }} className="alert bg-secondary d-flex flex-column flex-sm-row px-5 py-3 mb-0 fs-7">
            <NotificationIcon sx={{ fontSize: 40 }} className="text-primary me-3" />
            <div className="d-flex flex-column pe-0 pe-sm-10 text-gray-800">
                <h6 className="mb-1">{result.slcmName}</h6>
                <div className="d-flex">
                    <span className="me-1 fw-semibold">Download your result</span>
                </div>
            </div>
            <div className="flex-fill" />
            <div>
                <DownloadButton
                    className={"btn btn-primary btn-sm"}
                    label='Download'
                    link={result.slcmResultLink} />
            </div>
        </div>
    )
}