import clsx from "clsx";
import { useGetApplicationReasonsByServiceIdQuery } from "../../../../services/application-reasons.api";

export const SelectApplicationReason = ({ serviceId, value, onChange, className }: {
    serviceId: string;
    value?: string,
    onChange?: (value: string) => void,
    className?: string,
}) => {
    const { data: reasons, isSuccess } = useGetApplicationReasonsByServiceIdQuery(serviceId);

    const SelectOptions = () => {
        if (!isSuccess) return <></>;

        return (
            <>
                <option></option>
                {reasons?.map(
                    reason => <option
                        key={reason.slcmApplicationReasonId}
                        value={reason.slcmApplicationReasonId}>
                        {reason.slcmReason}
                    </option>)}
            </>
        )
    }

    return <select
        className={clsx(className, "form-select form-select-sm")}
        aria-label="Select reason"
        placeholder="Select reason"
        value={value}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            onChange && onChange(event.target.value);
        }}
    >
        {SelectOptions()}
    </select>
}