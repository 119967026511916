import { FC } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import { FeeSummaryView } from "../../modules/fee-management/FeeSummaryView";

export const FeeSummaryWrapper: FC = () => {
    return (<>
        <PageTitle breadcrumbs={[]}>{'Fee Management'}</PageTitle>
        <FeeSummaryView></FeeSummaryView>
    </>)
}
