import { UtilityCharges } from "../models";
import { rootApi } from './api';

const utilityChargesApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getUtilityCharge: builder.query<UtilityCharges, string>({
            query: (id) => `/UtilityCharges/${id}`,
        }),

        getUtilityCharges: builder.query<UtilityCharges[], void>({
            query: () => `/UtilityCharges`,
        }),

        getUtilityChargesByStudentId: builder.query<UtilityCharges[], string>({
            query: (studentId) => `/UtilityCharges/student/${studentId}`,
        }),
    })
})

export const {
    useGetUtilityChargeQuery,
    useGetUtilityChargesQuery,
    useGetUtilityChargesByStudentIdQuery,
} = utilityChargesApi;