import { FC } from "react";
import moment from "moment";

type Props = {
    className?: string;
    date?: Date | string;
    format?: string;
}

export const DateFormat: FC<Props> = ({ date, format, className }) => {
    return (
        <span className={className}>
            {date && <>{moment(date).format(format || "DD-MMM-yyyy")}</>}
        </span>
    )
}

export const DateTimeFormat: FC<Props> = ({ date, format, className }) => {
    return (
        <span className={className}>
            {date && <>{moment(date).local(false).format(format || "DD-MMM-yyyy hh:mm A")}</>}
        </span>
    )
}