import { FeeStructure } from "../models";
import { rootApi } from './api';

const feeStructureApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getFeeStructure: builder.query<FeeStructure, string>({
            query: (id) => `/FeeStructure/${id}`,
        }),

        getFeeStructures: builder.query<FeeStructure[], void>({
            query: () => `/FeeStructure`,
        }),

        getFeeStructureByProgramId: builder.query<FeeStructure[], {
            programId: string,
            batchId: string
        }>({
            query: ({ programId, batchId }) => `/FeeStructure/program(${programId})/batch(${batchId})`,
        }),
    })
})

export const {
    useGetFeeStructureQuery,
    useGetFeeStructuresQuery,
    useGetFeeStructureByProgramIdQuery,
} = feeStructureApi;