import { AppointmentsView } from "../../../modules/appointments/counsellor-appointment";
import { PageTitle } from "../../../layout/core";
import { useUserContact } from "../../../hooks/user";

export const AppointmentsWrapper = () => {
    return (<>
        <PageTitle breadcrumbs={[]}>{'Appointments'}</PageTitle>
        <AppointmentsViewInner />
    </>)
}

const AppointmentsViewInner = () => {
    const contact = useUserContact()
    return <>{contact && <AppointmentsView contact={contact} />}</>
}
