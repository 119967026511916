import { Connection, Contact } from '../../../models';
import { If, ListQueryStatus } from '../../../widgets-core';
import React, { useEffect } from 'react'

import Accordion from 'react-bootstrap/esm/Accordion';
import { toAbsoluteUrl } from '../../../helpers';
import { useGetConnectionsByStudentIdQuery } from '../../../services/connections.api';
import { useLazyGetContactQuery } from '../../../services/contacts.api';
import { useLazyGetEntityImageQuery } from '../../../services/entity-image.api';

export const StudentConnections = ({ contact, className }: {
  contact: Contact,
  className?: string,
}) => {
  const { data: entities, isLoading, isError, isFetching, isSuccess, refetch }
    = useGetConnectionsByStudentIdQuery(contact.contactId);

  return (
    <Accordion className={className}>
      <Accordion.Item eventKey={""}>
        <Accordion.Header bsPrefix="accordion-header accordion-header-sm">
          <h5 className='text-gray-700 my-0'>Connections</h5>
        </Accordion.Header>
        <Accordion.Body>
          <div className="text-end mb-3">
            <button
              onClick={refetch}
              type="button"
              title="Refresh"
              className='btn btn-sm btn-link text-hover-primary p-0 me-3'>
              <i className="las la-redo-alt fs-2"></i>
            </button>
          </div>

          <ListQueryStatus
            isLoading={isLoading || isFetching}
            isSuccess={isSuccess}
            isError={isError}
            data={entities} />

          <If condition={!isLoading && !isFetching && isSuccess}>
            <div className='d-flex flex-wrap'>
              {entities?.map(item => <React.Fragment key={item.connectionId}>
                <div className='mw-25 pe-5 my-1' >
                  <ViewConnection connection={item} />
                </div>
              </React.Fragment>)}
            </div>
          </If>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

const ViewConnection = ({ connection }: { connection: Connection }) => {
  const [getEntityImage, { data: image }] = useLazyGetEntityImageQuery();
  const [getContact, { data: contact }] = useLazyGetContactQuery();

  useEffect(() => {
    if (connection.record2Id?.id) {
      getContact(connection.record2Id?.id);
      getEntityImage({ entity: "contacts", id: connection.record2Id?.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection.record2Id?.id])

  return (
    <div className='card card-body border text-center fs-7'>
      <div className='symbol symbol-50px symbol-circle mb-2'>
        {image ?
          <img alt='user' src={`data:image/bmp;base64,${image}`} /> :
          <img alt='user' src={toAbsoluteUrl('/media/avatars/user.png')} />
        }
      </div>

      <div className='text-gray-800 text-hover-primary fw-bolder my-1'>
        {connection.record2IdName}
      </div>

      <div className='fw-bold text-gray-400'>{connection.record2RoleIdName}</div>
      <div className='fw-bold text-gray-400'>{contact?.eMailAddress1}</div>
      <div className='fw-bold text-gray-400'>{contact?.mobilePhone}</div>
    </div>
  )
}
