import * as authHelper from '../modules/auth/core/AuthHelpers';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const BASE_URL = process.env.REACT_APP_API_URL;
export const ApiTags = {
    Contact: "Contact",
    Appointment: "Appointment",
    Deferment: "Deferment",
    Demand: "Demand",
    DemandLine: "DemandLine",
    Comment: "Comment",
    Attendance: "Attendance",
    PaymentTransaction: "PaymentTransaction",
    AcademicPeriodDetail: "AcademicPeriodDetail",
    HallTicket: "HallTicket",
    AcademicPeriodSchedules: "AcademicPeriodSchedules",
    AssociatedDocument: "AssociatedDocument",
    ServiceRequest: "ServiceRequest",
    Program: "Program",
    AssessmentScore: "AssessmentScore",
    Assessment: "Assessment",
    PaymentInstructions: "PaymentInstructions",
    Achievement: "Achievement",
    Result: "Result"
}

export const rootApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        prepareHeaders: (headers) => {
            const auth = authHelper.getAuth();
            // If we have a token set in state, let's assume that we should be passing it.
            if (auth?.api_token) {
                headers.set('authorization', `Bearer ${auth?.api_token}`)
            }
    
            headers.set('Connection', 'Keep Alive')
            headers.set('Keep-Alive', 'timeout=5, max=1000')
            return headers
        },
    }),
    tagTypes: [
        ApiTags.Contact,
        ApiTags.Appointment,
        ApiTags.Deferment,
        ApiTags.Demand,
        ApiTags.DemandLine,
        ApiTags.Comment,
        ApiTags.Attendance,
        ApiTags.PaymentTransaction,
        ApiTags.AcademicPeriodDetail,
        ApiTags.HallTicket,
        ApiTags.AssociatedDocument,
        ApiTags.ServiceRequest,
        ApiTags.Program,
        ApiTags.AssessmentScore,
        ApiTags.Assessment,
        ApiTags.PaymentInstructions,
        ApiTags.Achievement,
        ApiTags.Result
    ],
    endpoints: () => ({}),
})

