import { Notification } from "../models";
import { rootApi } from './api';

const notificationsApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getNotification: builder.query<Notification, string>({
            query: (id) => `/Notification/${id}`,
        }),

        getNotificationEntries: builder.query<Notification[], void>({
            query: () => `/Notification`,
        }),
    })
})

export const {
    useGetNotificationQuery,
    useGetNotificationEntriesQuery,
} = notificationsApi