import { HostelAvailed } from '../models';
import { rootApi } from './api';

const hostelAvailedApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getHostelAvailed: builder.query<HostelAvailed, string>({
            query: (id) => `/HostelAvailed/${id}`,
        }),

        getHostelAvailedEntries: builder.query<HostelAvailed[], void>({
            query: () => `/HostelAvailed`,
        }),

        getHostelAvailedEntriesByStudentId: builder.query<HostelAvailed[], string>({
            query: (studentId) => `/HostelAvailed/contact(${studentId})`,
        }),
    })
})

export const {
    useGetHostelAvailedQuery,
    useGetHostelAvailedEntriesQuery,
    useGetHostelAvailedEntriesByStudentIdQuery,
} = hostelAvailedApi;