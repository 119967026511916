import "./loading.scss";

import { ApiExecutionStatus } from "../../store/types";
import { FC } from "react"
import clsx from "clsx";

type LoadingProps = {
    status?: ApiExecutionStatus | ApiExecutionStatus[],
    failedMessage?: string,
    message?: string,
    className?: string,
}

export const Loading: FC<LoadingProps> = ({ message, className, children, status, failedMessage }) => {
    if ((Array.isArray(status) && (status.indexOf("Rejected") !== -1)) || (status === "Rejected")) {
        return (
            <div className='mb-lg-15 alert alert-danger'>
                <span className='alert-text fs-6 fw-bold text-gray-600'>
                    {failedMessage || 'An error occurred while retrieving data.'}
                </span>
            </div>
        )
    }

    return (
        <div className={clsx("loading", className)}>
            {children && <div className="mb-2">{children}</div>}
            <div className="py-3 fs-7 align-center">
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                <span className="loading__text">{message || "Loading..."}</span>
            </div>
        </div>
    )
}
