import { Appointment, Contact, EntityReference, getLearningModeLabel } from "../../../../models";
import { CardPanel, DateRange } from "../../../../widgets-core";
import { LocationIcon, PersonIcon } from "../../../../assets/icons";

import { AttendanceStatus } from "./AttendanceStatus";
import { EntityImage } from "../../../../widgets-business/entity-images";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { useEffect } from "react";
import { useLazyGetCourseSectionQuery } from "../../../../services/course-sections.api";

export const TimetableEventCard = ({ contact, appointment, className }: {
    contact: Contact,
    appointment: Appointment,
    className?: string,
}) => {
    const [getCourseSection, { data: courseSection }] = useLazyGetCourseSectionQuery();

    useEffect(() => {
        if (!appointment.slcmCourseSection?.id) return;
        getCourseSection(appointment.slcmCourseSection?.id);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointment.slcmCourseSection?.id])

    return (
        <CardPanel className={className}>
            <div className="card-header ribbon ribbon-end align-items-center border-0 min-h-25px">
                <Link to={`/timetable/event/${appointment.activityId}`}>
                    <div className="d-flex flex-wrap align-items-start fs-6">
                        <div className="">
                            {(courseSection?.mshiedCourseIdName) &&
                                <div className="my-1 me-3">
                                    <span className="fw-bolder text-primary">
                                        {courseSection?.mshiedCourseIdName}
                                    </span>
                                </div>}

                            {appointment.subject &&
                                <div className="my-1 fw-bolder text-primary">
                                    {appointment?.subject}
                                </div>}
                        </div>
                        {courseSection?.slcmLearningMethod &&
                            <div className="badge badge-light my-1 ms-3">
                                <span className="fs-8 text-primary">
                                    {getLearningModeLabel(courseSection?.slcmLearningMethod)}
                                </span>
                            </div>}
                    </div>
                </Link>

                <AttendanceStatus
                    contact={contact}
                    appointment={appointment}
                    className="badge fs-7 ms-2" />
            </div>
            <div className="card-body py-0">
                <AppointmentTime appointment={appointment} className="my-1 fs-7" />
                <Instructor appointment={appointment} className="my-1 fs-7" />
                <Location appointment={appointment} className="my-1 fs-7" />
            </div>
        </CardPanel >
    )
}

const AppointmentTime = ({ appointment, className }: {
    appointment: Appointment,
    className?: string
}) => {
    if (!appointment?.scheduledStart) return <></>;

    return <DateRange
        format="Time Only"
        className={clsx(className, "fw-bolder text-gray-700 me-2")}
        startDate={appointment?.scheduledStart}
        endDate={appointment?.scheduledEnd}>
    </DateRange>
}

const Location = ({ appointment, className }: {
    appointment: Appointment,
    className?: string
}) => {
    if (!appointment || !appointment.slcmLocationName) return <></>;

    return <div className={clsx(className, "d-flex")}>
        <LocationIcon className="text-gray-700 me-2" />
        <span className="fw-bolder text-gray-700">{appointment.slcmLocationName}</span>
    </div>
}

const Instructor = ({ appointment, className }: {
    appointment: Appointment,
    className?: string
}) => {
    if (!appointment || !appointment.slcmInstructorName) return <></>;

    return <div className={clsx(className, 'd-flex')}>
        {appointment.slcmInstructor &&
            <InstructorImage
                className="text-gray-700"
                instructor={appointment.slcmInstructor} />}

        <div className="flex-fill d-flex">
            <div className='pe-2'>
                <span className="text-gray-700 fw-bolder ">{appointment.slcmInstructorName}</span>
            </div>
            <div className='badge badge-light ms-2'>
                <span className="fs-8">Instructor</span>
            </div>
        </div>
    </div>
}

const InstructorImage = ({ instructor, className }: {
    instructor: EntityReference,
    className?: string
}) => {
    switch (instructor?.logicalName) {
        case "contact":
            return <EntityImage entity="contacts" id={instructor.id}
                className={clsx("flex-column me-2", className)} />
        case "systemuser":
            return <EntityImage entity="systemusers" id={instructor.id}
                className={clsx("flex-column me-2", className)} />
        default:
            return <PersonIcon className={clsx("flex-column me-2", className)} />;
    }
}
