import { If, ListQueryStatus } from "../../../widgets-core";
import React, { useEffect, useState } from 'react'
import { getMediaType, toAbsoluteUrl } from "../../../helpers";
import { useGetDocumentsInFolderQuery, useLazyGetContactDocumentContentQuery } from "../../../services/entity-documents.api";

import Accordion from "react-bootstrap/esm/Accordion";
import { EntityDocument } from "../../../models";
import clsx from "clsx";

export const Documents = ({ title, entityId, className }: {
  title?: string,
  entityId: string,
  className?: string
}) => {
  const [currFolder, setCurrFolder] = useState<string>('')
  const [parentFolder, setParentFolder] = useState<string>('')

  const { data: documents, isLoading, isError, isFetching, isSuccess, refetch }
    = useGetDocumentsInFolderQuery({ entityId: entityId, folder: currFolder || '' })

  useEffect(() => {
    if (!currFolder) return;

    const old_folders = currFolder?.split("/");
    const folders = old_folders?.splice(old_folders.length);
    const newFolderPath = folders?.join("/");
    setParentFolder(newFolderPath)
  }, [currFolder])

  return (
    <Accordion className={className}>
      <Accordion.Item eventKey={""}>
        <Accordion.Header bsPrefix="accordion-header accordion-header-sm">
          <h5 className='text-gray-700 my-0'>{title || 'Documents'}</h5>
        </Accordion.Header>
        <Accordion.Body>
          <If condition={!isLoading && !isFetching && isSuccess}>
            {documents && <ViewFolderDocuments
              documents={documents}
              parentFolder={parentFolder}
              folderName={currFolder}
              refetch={refetch}
              onParentFolder={() => setCurrFolder(parentFolder)}
              onFolderChange={(path) => setCurrFolder(path)} />}
          </If>

          <ListQueryStatus
            isLoading={isLoading || isFetching}
            isSuccess={isSuccess}
            isError={isError}
            data={documents} />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

const ViewFolderDocuments = ({ documents, folderName, parentFolder, className, refetch, onParentFolder, onFolderChange }: {
  documents: EntityDocument[],
  parentFolder: string,
  folderName: string,
  className?: string,
  refetch: () => void,
  onParentFolder: () => void,
  onFolderChange: (folderPath: string) => void;
}) => {

  const handleChangeFolder = (folderPath: string) => {
    onFolderChange(folderPath)
  }

  if (documents.length === 0 && !folderName) return <></>

  return (
    <div className={clsx(className, "card bg-transparent p-0")}>
      <div className="card-header border-0 min-h-5px">
        <div>
          <div className="d-flex align-items-center">
            {folderName && (
              <>
                <button
                  onClick={onParentFolder}
                  className='btn btn-link btn-sm p-0 m-0 fw-bolder'>
                  <span className="fs-6">{parentFolder || 'Root'}</span>
                </button>
                <i className="las la-angle-right fs-4 mx-1"></i>
              </>
            )}
            <label className="fs-6 fw-bolder text-gray-700 my-0">{folderName || "Root"}</label>
          </div>
          <p className="fs-8 text-gray-600 my-0">
            Files and folders
          </p>
        </div>
        <div className="card-toolbar">
          <button
            onClick={refetch}
            type="button"
            title="Refresh"
            className='btn btn-sm btn-link text-hover-primary p-0 me-3'>
            <i className="las la-redo-alt fs-2"></i>
          </button>
        </div>
      </div>
      <div className="card-body">
        <div className='d-flex flex-wrap'>
          {documents?.map(document => (
            <div className='flex-fill mw-xl-25 px-2' key={document.documentId}>
              <ViewDocumentCard
                className="my-2"
                document={document}
                onChangeFolder={handleChangeFolder} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const ViewDocumentCard = ({ document: file, className, onChangeFolder }: {
  document: EntityDocument,
  className?: string,
  onChangeFolder?: (path: string) => void,
}) => {
  const [downloading, setDownloading] = useState<boolean>(false);
  const [getFileContent] = useLazyGetContactDocumentContentQuery();

  const handleDownloadClick = (file: EntityDocument) => {
    setDownloading(true);
    getFileContent(file.documentId)
      .unwrap()
      .then(async (data) => {
        const url = `data:${getMediaType(file.name)};base64,${data}`;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          file.name,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();
        link?.parentNode?.removeChild(link);
      }).finally(() => {
        setDownloading(false);
      });
  }

  return (
    <div className={clsx(className, 'card card-body p-5 border')}>
      {file.isFolder ? (
        <div className="text-center d-flex flex-column">
          <div className='symbol symbol-45px'>
            <img src={toAbsoluteUrl('/media/svg/files/folder-document.svg')} alt='' />
          </div>
          <button type="button"
            onClick={() => onChangeFolder && onChangeFolder(file.name)}
            className='btn btn-link text-truncate'>
            <div className="text-truncate" title={file.name}>
              <span className='fs-7 fw-bolder'>{file.name}</span>
            </div>
          </button>
        </div>
      ) : (
        <div className="text-center d-flex flex-column">
          <div className='symbol symbol-45px mb-2'>
            {getDocumentImage(file.name)}
          </div>
          <button type="button"
            onClick={() => handleDownloadClick(file)}
            className='btn btn-link'>
            {downloading && <span className='indicator-progress' style={{ display: 'inline-block' }}>
              <span className='spinner-border spinner-border-sm align-middle me-1'></span>
            </span>}
            <div className="text-truncate" title={file.name}>
              <span className='fs-7 fw-bolder'>{file.name}</span>
            </div>
          </button>
        </div>
      )}
    </div>
  )
}

const getDocumentImage = (fileName: string) => {
  const file_name_array = fileName.split(".");
  const file_extension = file_name_array[file_name_array.length - 1];

  switch (file_extension) {
    case "xlsx":
    case "xls":
      return <img src={toAbsoluteUrl('/media/svg/files/xlsx.svg')} alt='' />;
    case "docx":
    case "doc":
      return <img src={toAbsoluteUrl('/media/svg/files/doc.svg')} alt='' />;
    case "gif":
      return <img src={toAbsoluteUrl('/media/svg/files/gif.svg')} alt='' />;
    case "pdf":
      return <img src={toAbsoluteUrl('/media/svg/files/gif.svg')} alt='' />;
    case "png":
      return <img src={toAbsoluteUrl('/media/svg/files/png.svg')} alt='' />;
    case "jpg":
    case "jpeg":
      return <img src={toAbsoluteUrl('/media/svg/files/png.jpg')} alt='' />;
    case "bmp":
      return <img src={toAbsoluteUrl('/media/svg/files/bmp.jpg')} alt='' />;
    case "txt":
      return <img src={toAbsoluteUrl('/media/svg/files/txt.jpg')} alt='' />;
    case "ppt":
    case "pptx":
      return <img src={toAbsoluteUrl('/media/svg/files/ppt.jpg')} alt='' />;
    default:
      return <img src={toAbsoluteUrl('/media/svg/files/doc.svg')} alt='' />
  }
}
