import { FC } from "react";
import { PageLink, PageTitle } from "../../../layout/core";
import { NewAppointment } from "../../../modules/appointments/dean-appointment";

export const NewAppointmentWrapper: FC = () => {
    const pageLinks: PageLink[] = [
        { title: 'Appointments', isActive: false, path: "/appointments" },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]

    return (<>
        <PageTitle breadcrumbs={pageLinks}>{'Book Appointment'}</PageTitle>
        <NewAppointment />
    </>)
}
