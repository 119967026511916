import { CardPanel, CardPanelBody, CardPanelHeader, CardPanelToolbar, CardQueryStatus, CardRefreshButton, If } from "../../widgets-core";
import { Contact, RegistrationWindow } from "../../models";

import { CoursesList } from "./CoursesList";
import { RegistrationDetails } from "./RegistrationDetails";
import { SemesterRegApproval } from "./components/SemesterRegApproval";
import { SemesterRegComments } from "./components/SemesterRegComments";
import { useGetRegistrationAcademicPeriod } from "../../hooks/academic-period-details.hooks";
import { useGetRegistrationWindowQuery } from "../../services/registration-windows.api";

export const ViewSemesterRegistration = ({ contact, windowId }: {
    contact: Contact,
    windowId: string
}) => {
    const { data: registration, isLoading, isError, isFetching, isSuccess, error, refetch } = useGetRegistrationWindowQuery(windowId)

    return (
        <CardPanel className='bg-transparent'>
            <CardPanelHeader
                title='Semester Registration'
                subTitle='Semester registration details'
            >
                <CardPanelToolbar>
                    <CardRefreshButton
                        className="me-3"
                        isLoading={isLoading || isFetching}
                        refetch={refetch} />
                </CardPanelToolbar>
            </CardPanelHeader>
            <CardPanelBody>
                <CardQueryStatus
                    isLoading={isLoading || isFetching}
                    isSuccess={isSuccess}
                    isError={isError}
                    error={error}
                    data={registration} />

                <If condition={!isLoading && !isFetching && isSuccess}>
                    {registration && <ViewSemesterRegistrationInner
                        contact={contact}
                        registration={registration} />}
                </If>
            </CardPanelBody>
        </CardPanel>
    )
}

const ViewSemesterRegistrationInner = ({ contact, registration }: {
    contact: Contact,
    registration: RegistrationWindow,
}) => {
    const { period, periodStatus } = useGetRegistrationAcademicPeriod(contact.contactId, registration);

    return (
        <>
            <div className='card card-body py-3 mb-5'>
                <RegistrationDetails
                    className="fs-7"
                    period={period}
                    registration={registration} />
            </div>

            <CoursesList
                className='mb-5 fs-7'
                programId={registration.slcmProgram?.id}
                registration={registration} />

            {(periodStatus === "PWF" || periodStatus === "PWA") &&
                <div className="row">
                    <div className="col-xl-8">
                        <SemesterRegComments
                            className="mt-5"
                            requestId={period?.mshiedAcademicperioddetailsId} />
                    </div>
                    <div className="col-xl-4">
                        <SemesterRegApproval
                            className="mt-5"
                            requestId={period?.mshiedAcademicperioddetailsId} />
                    </div>
                </div>
            }
        </>
    )
}
