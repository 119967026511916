import { FC } from 'react'
import { RequestField } from '../../../../client-models'
import { SelectApplicationReason } from './SelectApplicationReason'
import { SelectCampus } from './SelectCampus'
import { SelectCourse } from './SelectCourse'
import { SelectHostel } from './SelectHostel'
import { SelectLocation } from './SelectLocation'
import { SelectSemester } from './SelectSemester'
import { SelectTeam } from './SelectTeam'

type Props = {
  field: RequestField
  serviceId: string
  value: any
  onChange: (value: string) => void,
  campusId?: string,
  occupancy?: any
  bookingType?: any,

}

export const RequestLookupField: FC<Props> = ({ field, serviceId, value, onChange, campusId, bookingType, occupancy }) => {
  switch (field.target) {
    case 'slcmCourse': // Course
      return <SelectCourse value={value} onChange={onChange} />
    case 'slcmReason': // Application Reason
      return <SelectApplicationReason value={value} onChange={onChange} serviceId={serviceId} />
    case 'slcmCampus': // Account
      return <SelectCampus value={value} onChange={onChange} />
    case 'slcmLocation': // Account
      return <SelectLocation value={value} onChange={onChange} campusId={campusId} bookingType={bookingType} occupancy={occupancy} />
    case 'slcmDepartmentSpeciality': // Team
      return <SelectTeam value={value} onChange={onChange} />
    case 'slcmHostelRoomNo': // Hostel
      return <SelectHostel value={value} onChange={onChange} />
    case 'slcmSemesterRegisteringfor': // Semester
      return <SelectSemester value={value} onChange={onChange} />
    default:
      return <label className='form-control form-control-sm'>{field.name}</label>
  }
}
