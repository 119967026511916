import { AcademicPeriodDetail, Contact, RegistrationWindow, getRegistrationLabel, getYear } from '../../../../models';
import { DateFormat, If, ListQueryStatus } from '../../../../widgets-core';
import React, { useEffect, useState } from 'react'

import Accordion from 'react-bootstrap/esm/Accordion';
import { SlcmRegistrationtype } from '../../../../models/registration-windows/enums/slcmRegistrationtype';
import clsx from 'clsx';
import { useGetRegistrationStatusCodes } from '../../../../hooks/registration-statuses.hooks';
import { useGetStudentAcademicPeriodDetailsQuery } from '../../../../services/academic-period-details.api';
import { useLazyGetRegistrationWindowsByProgramAndBatchQuery } from '../../../../services/registration-windows.api';
import { useNavigate } from 'react-router-dom';

export const SemesterRegistrations = ({ contact, className }: {
  contact: Contact,
  className?: string,
}) => {
  const { data: entities, isLoading, isError, isFetching, isSuccess, refetch }
    = useGetStudentAcademicPeriodDetailsQuery(contact.contactId);

  return (
    <Accordion className={className}>
      <Accordion.Item eventKey={""}>
        <Accordion.Header bsPrefix="accordion-header accordion-header-sm">
          <h5 className='text-gray-700 my-0'>Semester Registrations</h5>
        </Accordion.Header>
        <Accordion.Body>
          <div className="text-end mb-3">
            <button
              onClick={refetch}
              type="button"
              title="Refresh"
              className='btn btn-sm btn-link text-hover-primary p-0 me-3'>
              <i className="las la-redo-alt fs-2"></i>
            </button>
          </div>

          <ListQueryStatus
            isLoading={isLoading || isFetching}
            isSuccess={isSuccess}
            isError={isError}
            data={entities} />

          <If condition={!isLoading && !isFetching && isSuccess}>
            {entities?.map(item => <React.Fragment key={item.mshiedAcademicperioddetailsId}>
              <AcademicPeriodPanel
                className="mb-2"
                period={item} />
            </React.Fragment>)}
          </If>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

const AcademicPeriodPanel = ({ period, className }: {
  period: AcademicPeriodDetail,
  className?: string
}) => {
  return (
    <div className={clsx("bg-transparent border rounded px-5 py-1 fs-7", className)}>
      <div className="d-flex flex-wrap">
        <div className="w-xl-250px flex-fill my-1 me-3">
          <span className="text-gray-600">Program</span>
          <div className="text-gray-700 fw-bolder">
            <SemesterRegistrationLink
              className='py-0 fw-bolder'
              label={period?.mshiedProgramIdName}
              period={period} />
          </div>
        </div>
        <div className="w-xl-125px my-1 me-3">
          <span className="text-gray-600">Batch</span>
          <div className="text-gray-700 fw-bolder">
            <span>{period?.slcmBatchName}</span>
          </div>
        </div>
        <div className="w-xl-125px my-1 me-3">
          <span className="text-gray-600">{getRegistrationLabel(period.slcmRegistrationFrequency)}</span>
          <div className="text-gray-700 fw-bolder">
            {(period?.slcmRegistrationFrequency === 0) ?
              period?.slcmSemesterName :
              getYear(period.slcmYear)
            }
          </div>
        </div>
        <div className="w-xl-125px my-1 me-3">
          <span className="text-gray-600">Registration Date</span>
          <div className="text-gray-700 fw-bolder">
            <DateFormat date={period?.slcmRegistrationDate} />
          </div>
        </div>
        <div className="mw-xl-250px flex-fill my-1">
          <span className="text-gray-600">Status</span>
          <div className="text-gray-700 fw-bolder">
            <span>{period?.slcmSemesterRegistrationName}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

const SemesterRegistrationLink = ({ label, period, className }: {
  label?: string,
  period: AcademicPeriodDetail,
  className?: string
}) => {
  const navigate = useNavigate();
  const { SCH, PWS } = useGetRegistrationStatusCodes()
  const [getRegistrationWindow, { data: registrations }] = useLazyGetRegistrationWindowsByProgramAndBatchQuery()
  const [registration, setRegistration] = useState<RegistrationWindow>()

  useEffect(() => {
    if (!period.slcmBatch?.id || !period.mshiedProgramId?.id)
      return;

    getRegistrationWindow({
      batchId: period.slcmBatch?.id,
      programId: period.mshiedProgramId?.id
    })
      .unwrap()
      .then((data) => {
        const registration = data.find(p =>
          (p.slcmRegistrationType === SlcmRegistrationtype.Semester &&
            p.slcmSemester?.id === period.slcmSemester?.id) ||
          (p.slcmRegistrationType === SlcmRegistrationtype.Year &&
            p.slcmYear === period.slcmYear)
        )

        setRegistration(registration)
      })
    // eslint-disable-next-line
  }, [period, registrations])

  const handleButtonClick = () => {
    if (!registration)
      return;

    if (period.slcmSemesterRegistration?.id === SCH || period.slcmSemesterRegistration?.id === PWS)
      navigate(`/semester-registration/${registration.slcmRegistrationwindowId}`)
    else
      navigate(`/view-semester-registration/${registration.slcmRegistrationwindowId}`)
  }

  if (!registration) return <>{label}</>

  return (
    <button
      type="button"
      onClick={handleButtonClick}
      className={clsx(className, "btn btn-sm btn-link")}>
      {label ?
        label :
        period?.slcmSemesterRegistration?.id === SCH ?
          'Click here to register' :
          'Check registration status'}
    </button>)
}
