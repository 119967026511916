/* eslint-disable jsx-a11y/anchor-is-valid */

import * as Yup from 'yup'

import { getUserByToken, login } from '../core/_requests'

import { SignInButton } from '../msal'
import { useAuth } from '../core/Auth'
import { useFormik } from 'formik'

const loginSchema = Yup.object().shape({
    username: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Username is required'),
    password: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
})

const initialValues = {
    username: '',
    password: '',
}

export function Login() {
    const { saveAuth } = useAuth();

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
         
            try {
                const { data: auth } = await login(values.username, values.password);
                const { data: userName } = await getUserByToken(auth.api_token);
                saveAuth({ ...auth, userName });
            } catch (error) {
                console.error(error)
                saveAuth(undefined)
                setStatus('The login detail is incorrect')
                setSubmitting(false)
                
            }
        },
    })

    return (
        <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
        >
            {/* begin::Heading */}
            <div className='text-center mb-10'>
                <h1 className='text-dark mb-3'>Sign In to Student Portal</h1>
            </div>
            {/* begin::Heading */}

            {formik.status && (
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
            )}


            {/* begin::Action */}
            <div className='text-center'>
                <SignInButton className='w-100 mb-5' />
            </div>
            {/* end::Action */}
        </form>
    )
}
