import { useEffect, useState } from "react";
import { useLazyGetNotificationEventsByBatchIdQuery, useLazyGetNotificationEventsByCampusIdQuery, useLazyGetNotificationEventsByStudentIdQuery } from "../services/notifications-event.api";

import { NotificationEvent } from "../models";
import _ from "lodash";
import moment from "moment";
import { useUserContact } from "./user";

export const useGetNotifications = (limit: number = 0) => {
  const contact = useUserContact()

  // , isLoading, isError, isFetching, isSuccess, refetch
  const [getNotifications, {
    data: studentNotifications,
    isLoading,
    isFetching,
    isError,
    isSuccess,
  }] = useLazyGetNotificationEventsByStudentIdQuery();

  const [getBatchNotifications, {
    data: batchNotifications,
    isLoading: batchIsLoading,
    isFetching: batchIsFetching,
    isError: batchIsError,
    isSuccess: batchIsSuccess
  }] = useLazyGetNotificationEventsByBatchIdQuery();
  
  const [getCampusNotifications, {
    data: campusNotifications,
    isLoading: campusIsLoading,
    isFetching: campusIsFetching,
    isError: campusIsError,
    isSuccess: campusIsSuccess
  }] = useLazyGetNotificationEventsByCampusIdQuery();

  const [notifications, setNotifications] = useState<NotificationEvent[]>()

  const refetch = () => {
    if (!contact) return;

    if (contact.contactId)
      getNotifications(contact.contactId)

    if (contact.slcmBatch?.id)
      getBatchNotifications(contact.slcmBatch.id)

    if (contact.slcmCampus?.id)
      getCampusNotifications(contact.slcmCampus.id)
  }

  useEffect(() => {
    refetch()
    // eslint-disable-next-line
  }, [contact])

  useEffect(() => {
    let allNotifications: NotificationEvent[] = [
      ...(studentNotifications || []),
      ...(batchNotifications || []),
      ...(campusNotifications || [])];

    allNotifications = _.uniqBy(allNotifications, (item) => item.slcmNotificationeventId);
    const items = allNotifications.sort((a, b) => moment(b.slcmStartDate).diff(a.slcmStartDate));

    if (limit === 0)
      setNotifications(items)
    else
      setNotifications(items.slice(0, limit));

    // eslint-disable-next-line
  }, [studentNotifications, batchNotifications, campusNotifications])

  return {
    isLoading: isLoading || batchIsLoading || campusIsLoading,
    isFetching: isFetching || batchIsFetching || campusIsFetching,
    isSuccess: isSuccess && batchIsSuccess && campusIsSuccess,
    isError: isError && batchIsError && campusIsError,
    notifications: notifications || [],
    refetch,
  }
}