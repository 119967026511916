import { SvgIconProps } from '@mui/material';
import { FC } from 'react';
import {
    ArrowBackIcon,
    ArrowDownIcon,
    ArrowForwardIcon,
    BadgeIcon,
    BrokenIcon,
    CommunityIcon,
    CourseDetailsIcon,
    DashboardIcon,
    DateRangeIcon,
    DoNotDisturbIcon,
    EmailIcon,
    FeeManagementIcon,
    GroupIcon,
    HierarchyIcon,
    LinkIcon,
    LocationIcon,
    PassportIcon,
    PerformanceIcon,
    PersonIcon,
    PhoneIcon,
    ProgramIcon,
    ServicesIcon,
    SettingsIcon,
    TimeIcon,
    TimelapseIcon,
    TimetableIcon,
    VerifiedIcon,
    AppointmentIcon,
    SummaryIcon,
    LedgerIcon,
    CalendarIcon,
    WalletIcon,
    PaymentsIcon,
    ReceiptIcon,
    DescriptionIcon,
    QRScannerIcon,
    EmailReadIcon,
    EmailUnreadIcon,
    EmailDraftIcon,
    OutboxIcon
} from './icon-collection';

export type SvgIconName =
    "DateRange" | "Broken" | "Dashboard" | "Timetable" | "CourseDetails" |
    "FeeManagement" | "Services" | "Performance" | "Community" | "Settings" |
    "ArrowForward" | "DoNotDisturb" | "Location" | "ArrowDown" | "Person" |
    "Time" | "Hierarchy" | "ArrowBack" | "Phone" | "EMail" | "EmailRead" |
    "EmailUnread" | "Passport" | "Badge" | "Timelapse" | "Group" | "Program" |
    "Verified" | "Link" | "Appointment" | "Summary" | "Wallet" |
    "Ledger" | "Calendar" | "Payments" | "Receipt" | "Description" |
    "QRScanner" | "EmailDraft" | "Outbox";

interface Props extends SvgIconProps {
    iconName?: SvgIconName,
    sx?: object,
    className?: string,
}

export const AppSvgIcon: FC<Props> = ({ iconName, sx, className }) => {
    const props = { sx, className };

    switch (iconName) {
        case "Outbox":
            return (<OutboxIcon {...props} />);
        case "EmailDraft":
            return (<EmailDraftIcon {...props} />);
        case "EmailRead":
            return (<EmailReadIcon {...props} />);
        case "EmailUnread":
            return (<EmailUnreadIcon {...props} />);
        case "QRScanner":
            return (<QRScannerIcon {...props} />);
        case "Description":
            return (<DescriptionIcon {...props} />);
        case "Appointment":
            return (<AppointmentIcon {...props} />);
        case "Link":
            return (<LinkIcon {...props} />);
        case "Verified":
            return (<VerifiedIcon {...props} />);
        case "Program":
            return (<ProgramIcon {...props} />);
        case "Group":
            return (<GroupIcon {...props} />);
        case "DateRange":
            return (<DateRangeIcon {...props} />);
        case "Broken":
            return (<BrokenIcon {...props} />);
        case "Dashboard":
            return (<DashboardIcon {...props} />);
        case "Timetable":
            return (<TimetableIcon {...props} />);
        case "CourseDetails":
            return (<CourseDetailsIcon {...props} />);
        case "FeeManagement":
            return (<FeeManagementIcon {...props} />);
        case "Services":
            return (<ServicesIcon {...props} />);
        case "Performance":
            return (<PerformanceIcon {...props} />);
        case "Community":
            return (<CommunityIcon {...props} />);
        case "Settings":
            return (<SettingsIcon {...props} />);
        case "ArrowForward":
            return (<ArrowForwardIcon {...props} />);
        case "DoNotDisturb":
            return (<DoNotDisturbIcon {...props} />);
        case "Location":
            return (<LocationIcon {...props} />);
        case "ArrowDown":
            return (<ArrowDownIcon {...props} />);
        case "Person":
            return (<PersonIcon {...props} />);
        case "Time":
            return (<TimeIcon {...props} />);
        case "Hierarchy":
            return (<HierarchyIcon {...props} />);
        case "ArrowBack":
            return (<ArrowBackIcon {...props} />);
        case "Phone":
            return (<PhoneIcon {...props} />);
        case "EMail":
            return (<EmailIcon {...props} />);
        case "Passport":
            return (<PassportIcon {...props} />);
        case "Badge":
            return (<BadgeIcon {...props} />);
        case "Timelapse":
            return (<TimelapseIcon {...props} />);
        case "Summary":
            return (<SummaryIcon {...props} />);
        case "Ledger":
            return (<LedgerIcon {...props} />);
        case "Calendar":
            return (<CalendarIcon {...props} />);
        case "Wallet":
            return (<WalletIcon {...props} />);
        case "Payments":
            return (<PaymentsIcon {...props} />);
        case "Receipt":
            return (<ReceiptIcon {...props} />);
       
        default:
            return <></>;
    }
}
