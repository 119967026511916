import { KTSVG } from "../../helpers";
import { OfflineUploadDocument } from "../registrations/OfflineUploadDocument";
import { useState } from "react";

export const OfflinePaymentButton = ({ serviceId, folderPath, onClick, amount }: {
    serviceId: string,
    folderPath: string,
    onClick?: () => void,
    amount?: number
}) => {
    type PaymentStage = "Default" | "Upload" | "Completed";
    const [state, setStage] = useState<PaymentStage>("Default");

    const RenderStage = () => {
        switch (state) {
            case "Default":
                return (
                    <div>
                        <input
                            type='radio'
                            className='btn-check'
                            name='offline-payment'
                            value='1'
                            id='payment'
                            onClick={() => {
                                onClick && onClick()
                                setStage("Upload");
                            }} />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default p-5'
                            htmlFor='payment'
                        >
                            <div className="d-flex">
                                <KTSVG
                                    path='/media/icons/duotune/finance/fin006.svg'
                                    className='svg-icon-3x me-3' />

                                <span className='d-block fw-bold text-start'>
                                    <span className='text-gray-700 fw-bolder d-block fs-5'>Pay Offline</span>
                                   
                                </span>
                            </div>
                        </label>
                    </div>
                )
            case "Upload":
                return (
                    <OfflineUploadDocument
                        folderPath={folderPath}
                        onComplete={() => setStage("Completed")}
                        amount={amount}
                        associatedEntity={{
                            entity: "ServiceRequest",
                            requestId: serviceId
                        }} />)
            case "Completed":
                return (
                    <div className="alert border-primary text-primary fw-bold d-flex align-items-center">
                        <i className="las la-thumbs-up fs-2x text-primary me-1"></i>
                        <span>Your offline payment details updated successfully.</span>
                    </div>
                )
        }
    }

    return (
        <div>{RenderStage()}</div>
    )
}
