import { SystemUser } from "../models";
import { rootApi } from './api';

const systemUsersApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getSystemUser: builder.query<SystemUser, string>({
            query: (id) => `/SystemUsers/${id}`,
        }),

        getSystemUsers: builder.query<SystemUser[], void>({
            query: () => `/SystemUsers`,
        })
    })
})

export const {
    useGetSystemUserQuery,
    useLazyGetSystemUserQuery,
    useGetSystemUsersQuery,
} = systemUsersApi;