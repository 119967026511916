import { CardPanel, CardPanelBody, CardPanelHeader, CardPanelToolbar, If, ListQueryStatus, getTimePeriodName } from "../../../widgets-core";
import { NavMenu, NavMenuItem, NavMenuLink, TimePeriod } from "../../../widgets-core";

import { CalendarPeriod } from "../../../client-models";
import { Contact } from "../../../models";
import { TimetableTimeline } from "./components";
import { useGetAppointmentsByPeriodQuery } from "../../../services/appointments.api";
import { useState } from "react";

export const TimetableCard = ({ contact, className }: {
    contact: Contact,
    className?: string
}) => {
    const [timePeriod, setTimePeriod] = useState<CalendarPeriod>("Today");
    const { data: timetableEvents, isLoading, isError, isFetching, isSuccess, refetch } = useGetAppointmentsByPeriodQuery({
        studentId: contact.contactId,
        period: timePeriod
    })

    return (
        <CardPanel className={className}>
            <CardPanelHeader
                title="Timetable"
                subTitle={`${getTimePeriodName(timePeriod)}'s timetable`}>
                <CardPanelToolbar>
                    <TimetableNavItems onClick={setTimePeriod} active={timePeriod} />
                    <button
                        onClick={refetch}
                        type="button"
                        title="Refresh"
                        className='btn btn-sm btn-link text-hover-primary p-0 ms-3'>
                        <i className="las la-redo-alt fs-2"></i>
                    </button>
                </CardPanelToolbar>
            </CardPanelHeader>
            <CardPanelBody>
                <div className="dashboard-card__body">
                    <ListQueryStatus
                        isLoading={isLoading || isFetching}
                        isSuccess={isSuccess}
                        isError={isError}
                        data={timetableEvents} />

                    <If condition={!isLoading && !isFetching && isSuccess}>
                        <TimetableTimeline
                            timePeriod={timePeriod}
                            contact={contact}
                            timetableEvents={timetableEvents || []} />
                    </If>
                </div>
            </CardPanelBody>
        </CardPanel>
    )
}

const TimetableNavItems = ({ onClick, active }: {
    onClick: (timePeriod: TimePeriod) => void;
    active: TimePeriod
}) => {
    return (
        <NavMenu>
            <NavItem
                onClick={() => onClick("Yesterday")}
                activeTimePeriod={active}
                itemTimePeriod="Yesterday"
                label="Yesterday" />
            <NavItem
                onClick={() => onClick("Today")}
                activeTimePeriod={active}
                itemTimePeriod="Today"
                label="Today" />
            <NavItem
                onClick={() => onClick("Tomorrow")}
                activeTimePeriod={active}
                itemTimePeriod="Tomorrow"
                label="Tomorrow" />
        </NavMenu>)
}

const NavItem = ({ activeTimePeriod, itemTimePeriod, label, onClick }: {
    activeTimePeriod: TimePeriod,
    itemTimePeriod: TimePeriod,
    label: string,
    onClick: (event: React.MouseEvent) => void,
}) => {
    return <NavMenuItem>
        <NavMenuLink
            onClick={onClick}
            href={"#" + itemTimePeriod}
            active={activeTimePeriod === itemTimePeriod}>
            {label}
        </NavMenuLink>
    </NavMenuItem>
}