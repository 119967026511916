import { CourseSection } from "../models";
import { rootApi } from './api';

const courseSectionsApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getCourseSection: builder.query<CourseSection, string>({
            query: (id) => `/CourseSections/${id}`,
        }),

        getCourseSections: builder.query<CourseSection[], void>({
            query: () => `/CourseSections`,
        }),
        
        getCourseSectionByBatchId: builder.query<CourseSection, {
            batchId: string,
            courseId: string,
        }>({
            query: ({batchId, courseId}) => `/CourseSections/batch(${batchId})/course(${courseId})`,
        }),
    })
})

export const {
    useGetCourseSectionQuery,
    useLazyGetCourseSectionQuery,
    useGetCourseSectionsQuery,
    useGetCourseSectionByBatchIdQuery,
    useLazyGetCourseSectionByBatchIdQuery,
} = courseSectionsApi;