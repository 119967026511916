import { Program } from "../models";
import { rootApi } from './api';

const programsApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getProgram: builder.query<Program, string>({
            query: (id) => `/Programs/${id}`,
        }),

        getPrograms: builder.query<Program[], void>({
            query: () => `/Programs`,
        }),

        getProgramsByProgramId: builder.query<Program[], string>({
            query: (programId) => `/Programs/programId/${programId}`,
        }),
    })
})

export const {
    useGetProgramsQuery,
    useGetProgramQuery,
    useGetProgramsByProgramIdQuery,
    useLazyGetProgramsByProgramIdQuery,
} = programsApi;