import { DateFormat, DateTimeFormat } from "../../../../widgets-core"
import {
    ServiceRequest,
    SlcmServicerequestsSlcmCollectiontype,
    SlcmServicerequestsSlcmRegistrationtype,
    SlcmServicerequestsSlcmRepeatsemester,
} from "../../../../models"

import { RequestField } from "../../../../client-models"
import { SlcmServicerequestsSlcmCourseflag } from "../../../../models/service-requests/enums/slcmServicerequestsSlcmCourseflag"
import { SlcmServicerequestsSlcmSemester } from "../../../../models/service-requests/enums/slcmServicerequestsSlcmSemester"
import { SlcmServicerequestsSlcmSemesterbreak } from "../../../../models/service-requests/enums/slcmServicerequestsSlcmSemesterbreak"
import { SlcmYear } from "../../../../models/service-requests/enums/slcmYear"
import clsx from "clsx"
import { useCallback } from "react"

export const ViewServiceRequestField = ({ field, request, className }: {
    className?: string,
    field: RequestField,
    request: ServiceRequest,
    serviceId: string,
}) => {
    const getValue = useCallback(() => {
        switch (field.target) {
            case "slcmReason":
                return request.slcmReasonName;
            case "slcmCourse":
                return request.slcmCourseName;
            case "slcmCampus":
                return request.slcmCampusName;
            case "slcmLocation":
                return request.slcmLocationName;
            case "slcmDepartmentSpeciality":
                return request.slcmDepartmentSpecialityName;
            case "slcmHostelRoomNo":
                return request.slcmHostelRoomNoName;
            case "slcmTotalFeeAmount":
                return request.slcmTotalFeeAmount;
            case "slcmAutoDebit":
                return request.slcmAutoDebit;
            case "slcmQuantity":
                return request.slcmQuantity;
            case "slcmSemesterRegisteringfor":
                return request.slcmSemesterRegisteringforName;
            default:
                return (request as any)[field.target];
        }
    }, [request, field.target])

    const getOptionValue = () => {
        switch (field.target) {
            case "slcmCollectionType":
                switch (request.slcmCollectionType) {
                    case SlcmServicerequestsSlcmCollectiontype.Self: return "Self";
                    case SlcmServicerequestsSlcmCollectiontype.Courier: return "Courier";
                    default: return "";
                }
            case "slcmRegistrationType":
                switch (request.slcmRegistrationType) {
                    case SlcmServicerequestsSlcmRegistrationtype.Regular: return "Regular";
                    case SlcmServicerequestsSlcmRegistrationtype.Repeat: return "Repeat";
                    default: return "";
                }
            case "slcmRepeatSemester":
                switch (request.slcmRepeatSemester) {
                    case SlcmServicerequestsSlcmRepeatsemester.Remedial: return "Remedial";
                    case SlcmServicerequestsSlcmRepeatsemester.SelfStudy: return "Self Study";
                    default: return "";
                }
            case "slcmYear":
                switch (request.slcmYear) {
                    case SlcmYear.Year1: return "Year 1";
                    case SlcmYear.Year2: return "Year 2";
                    case SlcmYear.Year3: return "Year 3";
                    case SlcmYear.Year4: return "Year 4";
                    case SlcmYear.Year5: return "Year 5";
                    default: return "";
                }
            case "slcmSemester":
                switch (request.slcmSemester) {
                    case SlcmServicerequestsSlcmSemester.Semester1: return "Semester 1"
                    case SlcmServicerequestsSlcmSemester.Semester2: return "Semester 2"
                    case SlcmServicerequestsSlcmSemester.Semester3: return "Semester 3"
                    case SlcmServicerequestsSlcmSemester.Semester4: return "Semester 4"
                    case SlcmServicerequestsSlcmSemester.Semester5: return "Semester 5"
                    case SlcmServicerequestsSlcmSemester.Semester6: return "Semester 6"
                    case SlcmServicerequestsSlcmSemester.Semester7: return "Semester 7"
                    case SlcmServicerequestsSlcmSemester.Semester8: return "Semester 8"
                    case SlcmServicerequestsSlcmSemester.Semester9: return "Semester 9"
                    case SlcmServicerequestsSlcmSemester.Semester10: return "Semester 10"
                    default: return ""
                }
            case "slcmCourseFlag":
                switch (request.slcmCourseFlag) {
                    case SlcmServicerequestsSlcmCourseflag.Complete: return "Complete"
                    case SlcmServicerequestsSlcmCourseflag.Withdrawn: return "Withdrawn"
                    default: return ""
                }
            case "slcmSemesterBreak":
                switch (request.slcmSemesterBreak) {
                    case SlcmServicerequestsSlcmSemesterbreak.Drop: return "Drop"
                    case SlcmServicerequestsSlcmSemesterbreak.Defer: return "Defer"
                    default: return ""
                }
            default: return "";
        }
    }

    const FieldInput = () => {
        switch (field.datatype) {
            case "multiline":
            case "number":
            case "string":
            case "boolean":
            case "EntityReference":
                return <>
                    <span className='fw-bolder text-gray-700'>
                        {getValue() || <>&nbsp;</>}
                    </span>
                </>;
            case "EntityReferenceLocation":
                return <>
                    <span className='fw-bolder text-gray-700'>
                        {getValue() || <>&nbsp;</>}
                    </span>
                </>;
            case "Date":
                return <>
                    <span className='fw-bolder text-gray-700'>
                        <DateFormat date={getValue()} />
                    </span>
                </>;
            case "Datetime":
                return <>
                    <span className='fw-bolder text-gray-700'>
                        <DateTimeFormat date={getValue()} />
                    </span>
                </>;
            case "OptionSet":
                return <>
                    <span className='fw-bolder text-gray-700'>
                        {getOptionValue() || <>&nbsp;</>}
                    </span>
                </>;
            default:
                return <span className='fw-bolder text-gray-700'>{field.datatype}</span>;
        }
    }

    return (
        <div className={clsx("my-1 me-3", {
            'min-w-50': field.datatype === "multiline",
            'w-xl-150px': field.datatype !== "multiline"
        }, className)}>
            <span className="text-gray-600">{field.label}</span>
            <div className="text-gray-700 fw-bolder">
                {FieldInput()}
            </div>
        </div>
    )
}