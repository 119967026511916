import { RequestField } from "../../../../client-models"

export const requestFields: RequestField[] = [
    { name: "slcmVolume", target: "slcmVolume", label: "Volume", datatype: "string" },
    { name: "slcmCourse", target: "slcmCourse", label: "Course", datatype: "EntityReference" },
    { name: "slcmAwards", target: "slcmAwards", label: "Awards", datatype: "string" },
    { name: "slcmNumberOfPax", target: "slcmNumberOfPax", label: "Number Of Pax", datatype: "string" },
    { name: "slcmCurrentEmployment", target: "slcmCurrentEmployment", label: "Current Employment", datatype: "string" },
    { name: "slcmNewMobile", target: "slcmNewMobile", label: "New Mobile", datatype: "string" },
    { name: "slcmPurpose", target: "slcmPurpose", label: "Purpose", datatype: "multiline" },
    { name: "slcmStartDate", target: "slcmStartDate", label: "Start Date", datatype: "Datetime" },
    { name: "slcmBookTitle", target: "slcmBookTitle", label: "Book Title", datatype: "string" },
    { name: "slcmEndDate", target: "slcmEndDate", label: "End Date", datatype: "Datetime" },
    { name: "slcmBarcode", target: "slcmBarcode", label: "Bar Code", datatype: "string" },
    { name: "slcmArticleTitle", target: "slcmArticleTitle", label: "Article Title", datatype: "string" },
    { name: "slcmReason", target: "slcmReason", label: "Reason", datatype: "EntityReference" },
    { name: "slcmCarPlateNo", target: "slcmCarPlateNo", label: "Car Plate No.", datatype: "string" },
    { name: "slcmJournalTitle", target: "slcmJournalTitle", label: "Journal Title", datatype: "string" },
    { name: "slcmDesignation", target: "slcmDesignation", label: "Designation", datatype: "string" },
    { name: "slcmNewPassportNo", target: "slcmNewPassportNumber", label: "New Passport No", datatype: "string" },
    { name: "slcmOwnerPhoneNo", target: "slcmOwnerPhoneNo", label: "Owner Phone No.", datatype: "string" },
    { name: "slcmDirectorName", target: "slcmDirectorName", label: "Director Name", datatype: "string" },
    { name: "slcmFeeName", target: "slcmFeeName", label: "Fee Name", datatype: "string" },
    { name: "slcmCollectionType", target: "slcmCollectionType", label: "Collection Type", datatype: "OptionSet" },
    { name: "slcmCampus", target: "slcmCampus", label: "Campus", datatype: "EntityReference" },
    { name: "slcmLocation", target: "slcmLocation", label: "Location", datatype: "EntityReferenceLocation" },
    { name: "slcmHospitalName", target: "slcmHospitalName", label: "Hospital Name", datatype: "string" },
    { name: "slcmOwnerName", target: "slcmOwnerName", label: "Owner Name", datatype: "string" },
    { name: "slcmNewPersonalEmail", target: "slcmNewPersonalEmail", label: "New Personal Email", datatype: "string" },
    { name: "slcmNewAddress", target: "slcmNewAddress", label: "New Address", datatype: "string" },
    { name: "slcmRegistrationType", target: "slcmRegistrationType", label: "Registration Type", datatype: "OptionSet" },
    { name: "slcmAchievements", target: "slcmAchievements", label: "Achievements", datatype: "string" },
    { name: "slcmCorrespondenceAddress", target: "slcmCorrespondenceAddress", label: "Correspondence Address", datatype: "string" },
    { name: "slcmRepeatSemester", target: "slcmRepeatSemester", label: "Registration mode", datatype: "OptionSet" },
    { name: "slcmDepartmentSpeciality", target: "slcmDepartmentSpeciality", label: "Department / Speciality", datatype: "EntityReference" },
    { name: "slcmIssue", target: "slcmIssue", label: "Issue", datatype: "string" },
    { name: "slcmDate", target: "slcmDate", label: "Date", datatype: "Date" },
    { name: "slcmSemesterRegisteringfor", target: "slcmSemesterRegisteringfor", label: "Registering For", datatype: "EntityReference" },
    { name: "slcmRelationship", target: "slcmRelationship", label: "Relationship", datatype: "string" },
    { name: "slcmHostelRoomNo", target: "slcmHostelRoomNo", label: "Hostel Room No.", datatype: "EntityReference" },
    { name: "slcmRemarks", target: "slcmRemarks", label: "Description", datatype: "multiline" },
    { name: "slcmTotalFeeAmount", target: "slcmTotalFeeAmount", label: "Total Fee Amount", datatype: "string" },
    { name: "slcmQuantity", target: "slcmQuantity", label: "Quantity", datatype: "string" },
    { name: "slcmAutoDebit", target: "slcmAutoDebit", label: "Auto Debit", datatype: "string" },
    { name: "slcmExpectedOccupancy", target: "slcmExpectedOccupancy", label: "Occupancy", datatype: "string" },
    { name: "slcmCarType", target: "slcmCarType", label: "Car Type", datatype: "string" },
    { name: "slcmCarColour", target: "slcmCarColour", label: "Car Color", datatype: "string" },
    { name: "slcmStudentEmailID", target: "slcmStudentEmailID", label: "Student Mail ID", datatype: "string" },
    { name: "slcmTransactionID", target: "slcmTransactionID", label: "Transaction ID", datatype: "string" },
    { name: "slcmVehiclemake", target: "slcmVehiclemake", label: "Vehicle ID", datatype: "string" },    
    { name: "slcmYear", target: "slcmYear", label: "Year", datatype: "OptionSet" },
    { name: "slcmCourseFlag", target: "slcmCourseFlag", label: "Course Flag", datatype: "OptionSet" },
    { name: "slcmSemesterBreak", target: "slcmSemesterBreak", label: "Semester Break", datatype: "OptionSet" },
    { name: "slcmSemester", target: "slcmSemester", label: "Semester", datatype: "OptionSet" },
]

export const requestDocuments: RequestField[] = []