import { FC } from "react"
import { useParams } from "react-router-dom"
import { PageLink, PageTitle } from "../../../layout/core"
import { ViewAppointment } from "../../../modules/appointments/counsellor-appointment"

export const ViewAppointmentWrapper: FC = () => {
    const pageLinks: PageLink[] = [
        { title: 'Appointments', isActive: false, path: "/appointments" },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]

    return (<>
        <PageTitle breadcrumbs={pageLinks}>{'Appointment'}</PageTitle>
        <ViewAppointmentPage />
    </>)
}

const ViewAppointmentPage = () => {
    const { activityId }: { activityId?: string } = useParams();

    if (!activityId) return <></>;

    return <ViewAppointment activityId={activityId} />
}
