import { FC } from "react";

type Props = {
    value?: number,
    onChange?: (value: number) => void,
    className?: string,
};

export const SelectCollectionType: FC<Props> = ({
    value,
    onChange,
    className
}) => {
    return <select
        className="form-select form-select-sm"
        aria-label="Select collection type"
        value={value}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            onChange && onChange(Number(event.target.value));
        }}
        placeholder="Collection type">
        <option></option>
        <option value={1}>Self</option>
        <option value={2}>Courier</option>
    </select>
}
