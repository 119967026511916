import { Appointment, Contact, MshiedIsPresent } from "../../../../models";
import { useEffect, useState } from "react";

import clsx from "clsx";
import moment from "moment";
import { useGetAttendanceByAppointmentIdQuery } from "../../../../services/attendances.api";

export const AttendanceStatus = ({ contact, appointment, className }: {
    contact: Contact
    appointment: Appointment,
    className?: string,
}) => {
    const [statusText, setStatusText] = useState<string>();
    const { data: attendance } = useGetAttendanceByAppointmentIdQuery({
        studentId: contact?.contactId,
        appointmentId: appointment.activityId
    });

    useEffect(() => {
        switch (attendance?.mshiedIsPresent) {
            case MshiedIsPresent.Yes:
                setStatusText("Present");
                break;
            case MshiedIsPresent.No:
                setStatusText("Absent");
                break;
            default:
                if (!appointment?.scheduledStart) {
                    setStatusText("");
                    return;
                } else {
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);
                    setStatusText(moment(appointment?.scheduledStart).isBefore(moment(today)) ? "Absent" : "");
                    break;
                }
        }

        // eslint-disable-next-line
    }, [attendance])

    if (!statusText) {
        return <></>
    }

    return <span className={
        clsx({
            'bg-success': statusText === "Present",
            'bg-danger': statusText === "Absent"
        }, className)}>{statusText}</span>
}