import * as Yup from 'yup'

import { CardPanel, CardPanelBody } from "../../widgets-core"

import { Contact } from "../../models"
import React from 'react'
import { SlcmContactSlcmUndertaking } from '../../models/contacts/enums/slcmContactSlcmUndertaking'
import moment from "moment"
import { useFormik } from "formik"
import { useGetPaymentInstructionsQuery } from '../../services/payment-instructions.api'
import { useUpdateContactMutation } from "../../services/contacts.api"
import { useUserContact } from "../../hooks/user"

const initialValues = {
    acceptTerms: false,
    acceptTerms2: false
}

const registrationSchema = Yup.object().shape({
    acceptTerms: Yup.bool()
        .oneOf([true], 'You must accept the terms and conditions.')
        .required('You must accept the terms and conditions.'),
    acceptTerms2: Yup.bool()
        .oneOf([true], 'You must accept the terms and conditions.')
        .required('You must accept the terms and conditions.')
})

const today = new Date();
export const UndertakingPage = () => {
    const contact = useUserContact();
    const [updateContact, { isLoading }] = useUpdateContactMutation();
    const { data: underTakings } = useGetPaymentInstructionsQuery();

    const formik = useFormik({
        initialValues,
        validationSchema: registrationSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            if (!contact) return;

            const undertakingDate = moment(today).format("YYYY-MM-DD") as any as Date;
            const entity: Partial<Contact> = {
                slcmUndertaking: SlcmContactSlcmUndertaking.Submitted,
                slcmUndertakingDate: undertakingDate,
            }

            updateContact({ id: contact.contactId, entity: entity })
                .unwrap()
                .finally()
        },
    })

    return (
        <CardPanel>
            {/* <CardPanelHeader title="Welcome to Student Portal" subTitle="Student Acknowledgement page" /> */}
            <div className="justify-content-center m-auto my-4">
                <h3 style={{ color: "red" }}>Welcome to Student Portal</h3>
                <h6 className='text-gray-600 fw-bold me-1'>Student Acknowledgement page</h6>
            </div>
            <CardPanelBody>
                <form
                    className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                    noValidate
                    id='kt_login_signup_form'
                    onSubmit={formik.handleSubmit}
                >
                    {formik.status && (
                        <div className='mb-lg-15 alert alert-danger'>
                            <div className='alert-text font-weight-bold'>{formik.status}</div>
                        </div>
                    )}
                    {/* begin::Form group */}
                    <div className='fv-row mb-10'>
                        <div className='form-check form-check-custom form-check-solid'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                id='kt_login_toc_agree'
                                {...formik.getFieldProps('acceptTerms')}
                            />
                            <div className="story px-5" id="domestic">
                                {underTakings &&
                                    underTakings.map((entity) =>
                                        <React.Fragment key={entity.slcmPaymentinstructionsId}>
                                            <div dangerouslySetInnerHTML={{ __html: entity.slcmFirstLoginUndertaking1 || "" }}></div>
                                        </React.Fragment>)}
                            </div>
                        </div>
                        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                            <div className='fv-plugins-message-container text-danger'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.acceptTerms}</span>
                                </div>
                            </div>
                        )}<br />
                        <div style={{ marginLeft: "40px" }}>
                            <table className="table table-striped table-hover">
                                <tbody>
                                    <tr>
                                        <td>Name :</td>
                                        <td>{contact?.fullName}</td>
                                    </tr>
                                    <tr>
                                        <td>Mykad :</td>
                                        <td>{contact?.slcmMYKADNo}</td>
                                    </tr>
                                    <tr>
                                        <td>Passport No. :</td>
                                        <td>{contact?.slcmPassportNumber}</td>
                                    </tr>
                                    <tr>
                                        <td>Phone :</td>
                                        <td>{contact?.mobilePhone}</td>
                                    </tr>
                                    <tr>
                                        <td>Street 1 :</td>
                                        <td>{contact?.address1Line1}</td>
                                    </tr>
                                    <tr>
                                        <td>Street 2 :</td>
                                        <td>{contact?.address1Line2}</td>
                                    </tr>
                                    <tr>
                                        <td>City  :</td>
                                        <td>{contact?.address1City}</td>
                                    </tr>
                                    <tr>
                                        <td>State/Province :</td>
                                        <td>{contact?.address1StateOrProvince}</td>
                                    </tr>
                                    <tr>
                                        <td>ZIP/Postal Code :</td>
                                        <td>{contact?.address1PostalCode}</td>
                                    </tr>
                                    <tr>
                                        <td>Country/Region :</td>
                                        <td>{contact?.address1Country}</td>
                                    </tr>
                                    <tr>
                                        <td>Father's contact number :</td>
                                        <td>{contact?.slcmFatherscontactnumber}</td>
                                    </tr>
                                    <tr>
                                        <td>Father's email :</td>
                                        <td>{contact?.slcmFathersemail}</td>
                                    </tr>
                                    <tr>
                                        <td>Mother's contact number :</td>
                                        <td>{contact?.slcmMotherscontactnumber}</td>
                                    </tr>
                                    <tr>
                                        <td>Mother's email :</td>
                                        <td>{contact?.slcmMothersemail}</td>
                                    </tr>
                                    <tr>
                                        <td>Emergency Contact Person :</td>
                                        <td>{contact?.slcmEmergencyContactPerson}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className='form-check form-check-custom form-check-solid'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                id='kt_login_toc_agree'
                                {...formik.getFieldProps('acceptTerms2')}
                            />
                            <div className="story px-5" id="domestic">
                                {underTakings &&
                                    underTakings.map((entity) =>
                                        <React.Fragment key={entity.slcmPaymentinstructionsId}>
                                            <div dangerouslySetInnerHTML={{ __html: entity.slcmFirstLoginUndertaking2 || "" }}></div>
                                        </React.Fragment>)}
                            </div>
                        </div>
                        {formik.touched.acceptTerms2 && formik.errors.acceptTerms2 && (
                            <div className='fv-plugins-message-container text-danger'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.acceptTerms2}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* end::Form group */}
                    {/* begin::Form group */}
                    <div className=''>
                        <button
                            type='submit'
                            id='kt_sign_up_submit'
                            className='btn btn-lg btn-primary mb-5'
                        >
                            {!isLoading && <span className='indicator-label'>Submit</span>}
                            {isLoading && (
                                <span className='indicator-progress' style={{ display: 'inline-block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                    {/* end::Form group */}
                </form>
            </CardPanelBody>
        </CardPanel>
    )
}
