import { SlcmServicerequestsStatuscode } from "./enums/slcmServicerequestsStatuscode";

export * from "./ServiceRequest";
export * from "./enums/slcmServicerequestsStatuscode";
export * from "./enums/slcmServicerequestsSlcmCollectiontype";
export * from "./enums/slcmServicerequestsSlcmRegistrationtype";
export * from "./enums/slcmServicerequestsSlcmRepeatsemester";

export const getServiceRequestStatusLabel = (statusCode?: SlcmServicerequestsStatuscode) => {
    switch (statusCode) {
        case SlcmServicerequestsStatuscode.Draft: return "Draft";
        case SlcmServicerequestsStatuscode.Open: return "Open";
        case SlcmServicerequestsStatuscode.Closed: return "Closed";
        case SlcmServicerequestsStatuscode.Cancelled: return "Cancelled";
        case SlcmServicerequestsStatuscode.Inactive: return "Inactive";
        default: return "";
    }
}