export const SelectSemesterBreak = ({
    value,
    onChange,
    className
}: {
    value?: number,
    onChange?: (value: number) => void,
    className?: string,
}) => {
    return <select
        className="form-select form-select-sm"
        aria-label="Select semester break"
        value={value}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            onChange && onChange(Number(event.target.value));
        }}
        placeholder="Select semester break">
        <option></option>
        <option value="407450000">Drop</option>
        <option value="407450001">Defer</option>
    </select>
}


