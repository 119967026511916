/* eslint-disable jsx-a11y/anchor-is-valid */

import { DemandHeader, DemandLine } from "../../../../models";
import { DemandLinesTable, ILineSelection } from './DemandLinesTable';
import React, { useState } from "react"

import { IFeeBreakup } from "../../../../client-models";
import { OnlinePaymentButton } from "../../../payments/OnlinePaymentButton";
import { PayableAmount } from './PayableAmount';
import { SlcmDemandlineSlcmModeofpayment } from "../../../../models/demand-lines/enums/slcmDemandlineSlcmModeofpayment";
import { useUserContact } from '../../../../hooks/user'
import { useUpdateDemandLineMutation } from "../../../../services/demand-lines.api";

export const DemandPayOnlineView = ({ demand, demandLines, onChange, className }: {
    demand: DemandHeader,
    demandLines: DemandLine[],
    onChange?: () => void,
    className?: string,
}) => {
    const contact = useUserContact()
    const [updateDemandLine] = useUpdateDemandLineMutation();

    const [amount, setAmount] = useState<number>(0);
    const [selection, setSelection] = useState<ILineSelection[]>([])
    const [alertMsg, setAlertMsg] = useState<string>();
    const [feeBreakup, setFeeBreakup] = useState<IFeeBreakup>({
        hostelFee: 0, tuitionFee: 0, otherFee: 0
    });

    const updateInterimAmounts = () => {
        const promises: Promise<void>[] = []
        selection.forEach(item => {
            if (item.checked) {
                const demandLine: Partial<DemandLine> = {
                    slcmInterimAmountPaid: item.value,
                    slcmModeofPayment: SlcmDemandlineSlcmModeofpayment.Online
                }
                const promise = updateDemandLine({ id: item.id, entity: demandLine }).unwrap()
                promises.push(promise);
            }
        })

        Promise.all(promises)
            .then(() => {
                onChange && onChange()
            })
            .catch(() => setAlertMsg('Could not update payment details'))
    }

    return (
        <div className={className}>
            <DemandLinesTable
                demand={demand}
                demandLines={demandLines}
                selection={"Checkbox"}
                onChange={(amount, breakup, selection) => {
                    setAmount(amount)
                    setFeeBreakup(breakup)
                    setSelection(selection)
                }}
            />
            {alertMsg && <div className="alert alert-danger my-2">{alertMsg}</div>}

            <OnlinePaymentDetails
                amount={amount}
                feeBreakup={feeBreakup}
                demandId={demand.slcmDemandheaderId}
                currencyId={demand?.transactionCurrencyId?.id}
                onComplete={() => updateInterimAmounts()}

                isInternational={contact?.slcmIsInternational === true} />
        </div>)
}

const OnlinePaymentDetails = ({ demandId, amount, feeBreakup, currencyId, isInternational, onComplete }: {
    amount: number,
    feeBreakup: IFeeBreakup,
    demandId: string,
    currencyId?: string,
    isInternational: boolean,
    onComplete?: () => void,
}) => {
    if (!amount) return <></>;

    if (!isInternational) {
        return <div className='alert alert-warning'>
            Only international students can pay online
        </div>;
    }

    return (
        <div className='d-flex align-items-center my-5'>
            <PayableAmount
                className="me-5 mb-6"
                label="Selected Amount to Pay"
                amount={amount}
                currencyId={currencyId} />

            <OnlinePaymentButton
                className='flex-fill'
                invoiceId={demandId}
                currencyId={currencyId}
                paymentType={407450000}
                amount={amount}
                feeBreakup={feeBreakup}
                onComplete={onComplete}
            />
        </div>
    )
}