import { FC } from 'react'
import { RequestField } from '../../../../client-models'
import { RequestLookupField } from '../lookup-components/RequestLookupField'
import { RequestOptionSetField } from '../dropdown-components/RequestOptionSetField'
import clsx from 'clsx'

type Props = {
  className?: string
  field: RequestField
  value: any
  serviceId: string
  onChange: (field: RequestField, value: any) => void,
  campusId?: string
  bookingType?: any,
  occupancy?: number
}

export const ServiceRequestField: FC<Props> = ({ field, serviceId, value, onChange, className, campusId, bookingType, occupancy }) => {
  const FieldInput = () => {
    switch (field.datatype) {
      case 'string':
        return (
          <input
            type='text'
            className='form-control form-control-sm'
            value={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onChange(field, event.target.value)
            }}
            id={field.target}
            placeholder={field.label}
          />
        )
      case 'multiline':
        return (
          <textarea
            className='form-control form-control-sm'
            value={value}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
              onChange(field, event.target.value)
            }}
            id={field.target}
            placeholder={field.label}
          />
        )
      case 'number':
        return (
          <input
            type='number'
            className='form-control form-control-sm'
            value={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onChange(field, event.target.value)
            }}
            id={field.target}
            placeholder={field.label}
          />
        )
      case 'boolean':
        return (
          <div className='form-check form-check-custom form-check-solid form-check-sm'>
            <input
              className='form-check-input'
              type='checkbox'
              value={value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onChange(field, event.target.value)
              }}
              id={field.target}
            />
          </div>
        )
      case 'Date':
        return (
          <input
            type='date'
            className='form-control form-control-sm'
            value={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onChange(field, event.target.value)
            }}
            id={field.target}
            placeholder={field.label}
          />
        )
      case 'Datetime':
        return (
          <input
            type='datetime-local'
            className='form-control form-control-sm'
            value={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onChange(field, event.target.value)
            }}
            id={field.target}
            placeholder={field.label}
          />
        )
      case 'EntityReference':
        return (
          <RequestLookupField
            field={field}
            value={value}
            onChange={(value) => onChange(field, value)}
            serviceId={serviceId}
            campusId={campusId}
            bookingType={bookingType}
            occupancy={occupancy}
          />
        )
      case 'EntityReferenceLocation':
        return (
          <RequestLookupField
            field={field}
            value={value}
            onChange={(value) => onChange(field, value)}
            serviceId={serviceId}
            campusId={campusId}
            bookingType={bookingType}
            occupancy={occupancy}

          />
        )
      case 'OptionSet':
        return (
          <RequestOptionSetField
            field={field}
            value={value}
            onChange={(value) => onChange(field, value)}
          />
        )
      default:
        return <label>{field.datatype}</label>
    }
  }

  return (
    <>
      <div className={
        clsx('flex-fill px-3 my-2', {
          'min-w-100': field.datatype === 'multiline' || field.datatype === 'EntityReferenceLocation',
          'min-w-50': field.datatype !== 'multiline',
        })}
      >
        <label className='form-label text-gray-600 fs-7'>{field.label}</label>
        {FieldInput()}
      </div>
    </>
  )
}
