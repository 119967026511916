/* eslint-disable jsx-a11y/anchor-is-valid */

import * as Yup from 'yup'

import { AssociatedDocument } from '../../models'
import { AttachmentRequestModel } from '../../client-models'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useUploadAssociatedDocumentMutation } from '../../services/associated-documents.api'

const MAX_FILE_SIZE = 2 * 1024 * 1024

type DemandEntity = { entity: "Demand", demandId: string }
type ServiceRequestEntity = { entity: "ServiceRequest", requestId: string }

const initialValues = {
    depositedAmount: 0,
    referenceNo: '',
    postingDate: '',
    attachmentFile: '',
    attachment: null
}

export const OfflineUploadDocument = ({ associatedEntity, folderPath, amount, onComplete, className }: {
    associatedEntity: DemandEntity | ServiceRequestEntity
    folderPath: string,
    amount?: number,
    className?: string,
    onComplete?: (document: AssociatedDocument) => void
}) => {
    const [uploadAssociatedDocument] = useUploadAssociatedDocumentMutation()
    const [loading, setLoading] = useState(false);

    const loginSchema = Yup.object().shape({
        depositedAmount: Yup.number()
            .min(0, 'Deposited Amount is not valid')
            .max(amount || 0, `Deposited Amount cannot be more than ${amount}`)
            .required('Deposited Amount is required'),
        referenceNo: Yup.string()
            .min(3, 'Minimum 3 symbols')
            .max(50, 'Maximum 50 symbols')
            .required('Reference No is required'),
        postingDate: Yup.date()
            .max(new Date(), 'Posting Date is invalid')
            .required('Posting Date is required'),
        attachmentFile: Yup.mixed()
            .required("Proof of Payment is required")
            .test('fileType', 'Upload file having extensions .jpeg/.jpg/.png/.pdf only.', (value, content) => {
                var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i
                return allowedExtensions.test(value)
            }),
        attachment: Yup.mixed()
            .required("Proof of Payment is required")
            .test('fileSize', 'Attached file size exceeding the 2 MiB limit.', (value, content) => {
                const file = value as File | null
                return !!file && file?.size <= MAX_FILE_SIZE;
            })
    })

    const fileToBase64 = (file: File) => {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => {
                try {
                    const content = reader.result
                    const base64 = btoa(
                        new Uint8Array(content as ArrayBuffer)
                            .reduce((data, byte) => data + String.fromCharCode(byte), ''))

                    resolve(base64);
                } catch (err) {
                    reject(err)
                }
            }

            reader.readAsArrayBuffer(file);
        })
    }

    const formik = useFormik({
        initialValues: { ...initialValues, depositedAmount: amount || 0 },
        validationSchema: loginSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setStatus('');
            const file = values.attachment as (File | null)
            if (!file) {
                setStatus('Proof of Payment is not attached.')
                return;
            }

            setLoading(true);

            try {
                const base64 = await fileToBase64(file);
                const request: AttachmentRequestModel = {
                    associatedEntity: associatedEntity.entity === "Demand" ? {
                        logicalName: "slcm_demandheader",
                        id: associatedEntity.demandId
                    } : {
                        logicalName: "slcm_servicerequests",
                        id: associatedEntity.requestId
                    },
                    slcmAmountDeposited: values.depositedAmount,
                    SlcmPostingDate: values.postingDate,
                    SlcmReferenceNumber: values.referenceNo,
                    folderPath: folderPath,
                    fileName: file.name,
                    fileType: file.type,
                    base64content: base64 as string,
                }

                uploadAssociatedDocument(request)
                    .unwrap()
                    .then((document) => {
                        onComplete && onComplete(document)
                    })
                    .catch((err) => {
                        setStatus("An error occurred while uploading proof of payment document.")
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            } catch (error) {
                console.error(error)
                setStatus("An error occurred while uploading proof of payment document.")
                setLoading(false)
            }
        }
    })

    return (
        <>
            <form
                //onSubmit={formik.handleSubmit}
                noValidate
                id='kt_upload_attachment'
            >
                <div className={clsx(className, "card border mt-5")}>
                    <div className='card-header min-h-5px border-0'>
                        <h6 className='mt-7 mb-5 text-gray-800'>Kindly attach the receipt</h6>
                    </div>
                    <div className='card-body pt-0'>
                        {formik.status && (
                            <div className='my-5 alert alert-danger'>
                                <div className='alert-text font-weight-bold'>{formik.status}</div>
                            </div>
                        )}

                        <div className="my-2">
                            <label className='form-label fs-7'>Proof Of Payment</label>
                            <input
                                type="file"
                                accept="image/jpeg,image/png,application/pdf"
                                placeholder="Proof Of Payment"
                                {...formik.getFieldProps('attachmentFile')}
                                onChange={async (event) => {
                                    const file = event.currentTarget.files && event.currentTarget.files.length > 0 ?
                                        event.currentTarget.files[0] : null;
                                    formik.setFieldValue("attachment", file, true);

                                    formik.handleChange(event);
                                }}

                                className={clsx(
                                    'form-control form-control-sm',
                                    { 'is-invalid': (formik.touched.attachmentFile && formik.errors.attachmentFile) || (formik.errors.attachment) },
                                    { 'is-valid': formik.touched.attachmentFile && !formik.errors.attachmentFile && !formik.errors.attachment }
                                )}
                            />

                            {formik.touched.attachmentFile && (formik.errors.attachmentFile || formik.errors.attachment) && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert'>{formik.errors.attachmentFile || formik.errors.attachment}</span>
                                </div>
                            )}
                        </div>

                        <div className='d-flex'>
                            <div className="flex-fill my-2 pe-3">
                                <label className='form-label fs-7'>Deposited Amount</label>
                                <input
                                    type="number"
                                    placeholder="Deposited Amount"
                                    autoComplete='off'
                                    {...formik.getFieldProps('depositedAmount')}
                                    className={clsx(
                                        'form-control form-control-sm',
                                        { 'is-invalid': formik.touched.depositedAmount && formik.errors.depositedAmount },
                                        { 'is-valid': formik.touched.depositedAmount && !formik.errors.depositedAmount }
                                    )}
                                />

                                {formik.touched.depositedAmount && formik.errors.depositedAmount && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert'>{formik.errors.depositedAmount}</span>
                                    </div>
                                )}
                            </div>

                            <div className="flex-fill my-2 pe-3">
                                <label className='form-label fs-7'>Reference Number</label>
                                <input
                                    type="text"
                                    placeholder="Reference Number"
                                    autoComplete='off'
                                    {...formik.getFieldProps('referenceNo')}
                                    className={clsx(
                                        'form-control form-control-sm',
                                        { 'is-invalid': formik.touched.referenceNo && formik.errors.referenceNo },
                                        { 'is-valid': formik.touched.referenceNo && !formik.errors.referenceNo }
                                    )}
                                />

                                {formik.touched.referenceNo && formik.errors.referenceNo && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert'>{formik.errors.referenceNo}</span>
                                    </div>
                                )}
                            </div>

                            <div className="flex-fill my-2">
                                <label className='form-label fs-7'>Posting Date</label>
                                <input
                                    type="date"
                                    placeholder="Posting Date"
                                    autoComplete='off'
                                    {...formik.getFieldProps('postingDate')}
                                    className={clsx(
                                        'form-control form-control-sm',
                                        { 'is-invalid': formik.touched.postingDate && formik.errors.postingDate },
                                        { 'is-valid': formik.touched.postingDate && !formik.errors.postingDate }
                                    )}
                                />

                                {formik.touched.postingDate && formik.errors.postingDate && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert'>{formik.errors.postingDate}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='card-footer pt-0 border-0'>
                        <div className='text-end'>
                            <button
                                type='button'
                                disabled={loading}
                                className='btn btn-sm btn-primary'
                                onClick={() => { formik.handleSubmit() }}
                            >
                                {loading ? (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                ) : (
                                    <span>Upload & Save</span>
                                )}
                            </button>
                        </div>

                    </div>
                </div>
            </form>

            <PaymentInstructions className='mt-5 bg-white' />
        </>
    )
}

const PaymentInstructions = ({ className }: { className?: string }) => {
    return (
        <div className={clsx(className, 'card border')}>
            <div className='card-header min-h-5px border-0'>
                <h6 className='mt-7 mb-5 text-danger'>Bank Transfer / Bank Counter / ATM Deposit</h6>
            </div>
            <div className='card-body pt-0'>
                <table className='table table-border table-striped fs-7'>
                    <thead>
                        <tr className='fw-bolder'>
                            <th>Account Name</th>
                            <th>Bank</th>
                            <th>Branch</th>
                            <th>Account Name</th>
                            <th>Swift Code</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='text-gray-700'>
                            <td className='px-2'>JVMC Corporation Sdn Bhd</td>
                            <td className='px-2'>Malayan Banking Berhad</td>
                            <td className='px-2'>Melaka Main Branch</td>
                            <td className='px-2'>5040 2124 8969</td>
                            <td className='px-2'>MBBEMYKL</td>
                        </tr>
                    </tbody>
                </table>
                <div className='text-danger fs-8 fst-italic'>
                    <p className='my-1'>
                        Credit Card / Debit Card / Over the Counter at the Finance Office, Ground Floor, Academic Building, Manipal University College Malaysia, Melaka.
                    </p>
                    <p className='my-1'>
                        For International payments, Swift copy or Transfer receipt issued by Bank with clear details of amount of payment to be uploaded.
                    </p>
                </div>
            </div>
        </div>
    )
}