import { ProgramRequirement, RegistrationWindow } from '../../models'
import React, { useEffect, useState } from 'react'

import Accordion from 'react-bootstrap/esm/Accordion'
import { SlcmRegistrationtype } from '../../models/registration-windows/enums/slcmRegistrationtype'
import { useGetProgramRequirementsByProgramIdQuery } from '../../services/program-requirements.api'

export const CoursesList = ({ programId, registration, className }: {
  registration: RegistrationWindow,
  programId?: string,
  className?: string
}) => {
  if (!programId) return <></>

  return <CoursesListInner
    className={className}
    programId={programId}
    registration={registration} />
}

const CoursesListInner = ({ programId, registration, className }: {
  programId: string,
  registration: RegistrationWindow,
  className?: string
}) => {
  const { data: programRequirements } = useGetProgramRequirementsByProgramIdQuery(programId)
  const [list, setFilteredProgramRequirements] = useState<ProgramRequirement[]>()

  useEffect(() => {
    if (!programRequirements) {
      return
    }

    switch (registration?.slcmRegistrationType) {
      case SlcmRegistrationtype.Semester:
        const dataSemester = programRequirements.filter(
          (p) => p.slcmSemester?.id === registration.slcmSemester?.id
        )
        if (dataSemester) {
          setFilteredProgramRequirements(dataSemester)
        }
        break
      case SlcmRegistrationtype.Year:
        const dataYear = programRequirements.filter((p) => p.slcmYear === registration.slcmYear)
        if (dataYear) {
          setFilteredProgramRequirements(dataYear)
        }
        break
    }

    // eslint-disable-next-line
  }, [programRequirements, registration])

  if (!list) return <></>

  return (
    <Accordion className={className}>
      <Accordion.Item eventKey={""}>
        <Accordion.Header bsPrefix="accordion-header accordion-header-sm">
          <span className='fs-6 fw-bolder text-gray-700 my-0'>Course Requirements</span>
        </Accordion.Header>
        <Accordion.Body>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead className='fw-bolder'>
                <tr>
                  <th className="px-2 w-125px">Semester</th>
                  {/* <th className="px-2 w-100px">Code</th> */}
                  <th className="px-2">Course Name</th>
                </tr>
              </thead>
              <tbody>
                {list.map((item, index) => (
                  <React.Fragment key={item.mshiedProgramRequirementId}>
                    <CoursePanel className="" entity={item} />
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

const CoursePanel = ({ entity, className }: {
  entity: ProgramRequirement,
  className?: string
}) => {
  return (
    <tr>
      <td className="px-2">{entity.slcmSemesterName}</td>
      {/* <td className="px-2">{entity.slcmCourseNumber}</td> */}
      <td className="px-2">{entity.mshiedCourseIdName}</td>
    </tr>
  )
}