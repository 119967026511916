import { RegistrationWindow } from "../models";
import { rootApi } from './api';

const registrationWindowsApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getRegistrationWindow: builder.query<RegistrationWindow, string>({
            query: (id) => `/RegistrationWindows/${id}`,
        }),

        getRegistrationWindows: builder.query<RegistrationWindow[], void>({
            query: () => `/RegistrationWindows`,
        }),

        getRegistrationWindowsByProgramAndBatch: builder.query<RegistrationWindow[], {
            batchId: string, programId: string
        }>({
            query: ({ batchId, programId }) => `/RegistrationWindows/batch(${batchId})/program(${programId})`,
        }),
    })
})

export const {
    useGetRegistrationWindowQuery,
    useGetRegistrationWindowsQuery,
    useGetRegistrationWindowsByProgramAndBatchQuery,
    useLazyGetRegistrationWindowsByProgramAndBatchQuery
} = registrationWindowsApi;