import { NewAppointment } from "../../modules/appointments/faculty-appointment";
import { useUserContact } from "../../hooks/user";

export const NewAppointmentWrapper = () => {
    const contact = useUserContact()

    return (<>
        {contact && <NewAppointment contact={contact} />}
    </>)
}
