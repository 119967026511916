import * as Yup from 'yup'

import { Appointment, Contact } from '../../../models'
import { CardPanel, CardPanelBody, CardPanelHeader } from '../../../widgets-core'
import {
  SlcmAppointmentSlcmPreferredday as PreferredDay,
  SlcmAppointmentSlcmPreferredtime as PreferredTimeSlot,
  SlcmAppointmentSlcmPurpose,
} from '../../../models/appointments'
import React, { useState } from 'react' // changeEvent delete From this line

import { UserBasicProfile } from '../../../widgets-business'
import clsx from 'clsx'
import moment from 'moment'
import { useCreateAppointmentMutation } from '../../../services/appointments.api'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'

const today = new Date()
today.setHours(0, 0, 0, 0)

const appointmentSchema = Yup.object().shape({
  preferredDate: Yup.date()
    .min(today, 'Preferred date cannot be in the past')
    .required('Preferred date is required'),
  preferredDay: Yup.string().required('Preferred day is required'),
  preferredTime: Yup.string().required('Preferred time is required'),
  remarks: Yup.string().required('Remarks time is required'),
})

const initialValues = {
  preferredDate: '',
  preferredDay: '',
  preferredTime: '',
  remarks: '',
}

export const NewAppointment = ({ contact }: {
  contact: Contact
}) => {
  const [loading, setLoading] = useState(false)
  const [addAppointment] = useCreateAppointmentMutation()
  const navigate = useNavigate()
  const [date, setDate] = useState(); //Updated Line

  const formik = useFormik({
    initialValues,
    validationSchema: appointmentSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      const newAppointment: Appointment = prepareNewAppointment(values)

      setSubmitting(true)

      addAppointment(newAppointment)
        .unwrap()
        .then((data) => navigate('/appointments'))
        .catch((reason) => {
          setStatus('Failed to submit appointment.')
        })
        .finally(() => {
          setLoading(false)
          setSubmitting(false)
        })
    },
  })

  function prepareNewAppointment(values: {
    preferredDate: string
    preferredDay: string
    preferredTime: string
    remarks: string
  }) {
    const newAppointment: Appointment = {} as Appointment

    newAppointment.slcmPreferredDate = new Date(values.preferredDate)
    newAppointment.scheduledStart = newAppointment.slcmPreferredDate

    const year = newAppointment.slcmPreferredDate.getFullYear()
    const month = newAppointment.slcmPreferredDate.getMonth()
    const date = newAppointment.slcmPreferredDate.getDate()

    switch (values.preferredTime) {
      case "07AM - 08AM":
        newAppointment.slcmPreferredTime = PreferredTimeSlot.TIME_SLOT_07AM_08AM;
        newAppointment.scheduledStart = new Date(year, month, date, 7);
        newAppointment.scheduledEnd = new Date(year, month, date, 8);
        break;
      case "08AM - 09AM":
        newAppointment.slcmPreferredTime = PreferredTimeSlot.TIME_SLOT_08AM_09AM;
        newAppointment.scheduledStart = new Date(year, month, date, 8);
        newAppointment.scheduledEnd = new Date(year, month, date, 9);
        break;
      case '09AM - 10AM':
        newAppointment.slcmPreferredTime = PreferredTimeSlot.TIME_SLOT_09AM_10AM
        newAppointment.scheduledStart = new Date(year, month, date, 9)
        newAppointment.scheduledEnd = new Date(year, month, date, 10)
        break
      case '10AM - 11AM':
        newAppointment.slcmPreferredTime = PreferredTimeSlot.TIME_SLOT_10AM_11AM
        newAppointment.scheduledStart = new Date(year, month, date, 10)
        newAppointment.scheduledEnd = new Date(year, month, date, 11)
        break
      case '11AM - 12PM':
        newAppointment.slcmPreferredTime = PreferredTimeSlot.TIME_SLOT_11AM_12PM
        newAppointment.scheduledStart = new Date(year, month, date, 11)
        newAppointment.scheduledEnd = new Date(year, month, date, 12)
        break
      case '12PM - 01PM':
        newAppointment.slcmPreferredTime = PreferredTimeSlot.TIME_SLOT_12PM_01PM
        newAppointment.scheduledStart = new Date(year, month, date, 12)
        newAppointment.scheduledEnd = new Date(year, month, date, 13)
        break
      case '02PM - 03PM':
        newAppointment.slcmPreferredTime = PreferredTimeSlot.TIME_SLOT_02PM_03PM
        newAppointment.scheduledStart = new Date(year, month, date, 14)
        newAppointment.scheduledEnd = new Date(year, month, date, 15)
        break
      case '03PM - 04PM':
        newAppointment.slcmPreferredTime = PreferredTimeSlot.TIME_SLOT_03PM_04PM
        newAppointment.scheduledStart = new Date(year, month, date, 15)
        newAppointment.scheduledEnd = new Date(year, month, date, 16)
        break
      case '04PM - 05PM':
        newAppointment.slcmPreferredTime = PreferredTimeSlot.TIME_SLOT_04PM_05PM
        newAppointment.scheduledStart = new Date(year, month, date, 16)
        newAppointment.scheduledEnd = new Date(year, month, date, 17)
        break
      case "05PM - 06PM":
        newAppointment.slcmPreferredTime = PreferredTimeSlot.TIME_SLOT_05PM_06PM;
        newAppointment.scheduledStart = new Date(year, month, date, 17);
        newAppointment.scheduledEnd = new Date(year, month, date, 18);
        break;
      case "06PM - 07PM":
        newAppointment.slcmPreferredTime = PreferredTimeSlot.TIME_SLOT_06PM_07PM;
        newAppointment.scheduledStart = new Date(year, month, date, 18);
        newAppointment.scheduledEnd = new Date(year, month, date, 19);
        break;
      default:
        newAppointment.slcmPreferredTime = undefined
        break
    }

    switch (values.preferredDay) {
      case 'Monday':
        newAppointment.slcmPreferredDay = PreferredDay.Monday
        break
      case 'Tuesday':
        newAppointment.slcmPreferredDay = PreferredDay.Tuesday
        break
      case 'Wednesday':
        newAppointment.slcmPreferredDay = PreferredDay.Wednesday
        break
      case 'Thursday':
        newAppointment.slcmPreferredDay = PreferredDay.Thursday
        break
      case 'Friday':
        newAppointment.slcmPreferredDay = PreferredDay.Friday
        break
      case "Saturday":
        newAppointment.slcmPreferredDay = PreferredDay.Saturday;
        break;
      case "Sunday":
        newAppointment.slcmPreferredDay = PreferredDay.Sunday;
        break;
      default:
        newAppointment.slcmPreferredDay = undefined
        break
    }

    newAppointment.requiredattendees = []
    if (contact?.contactId) {
      newAppointment.requiredattendees.push({
        id: contact?.contactId,
        logicalName: 'contact',
      })
    }

    if (contact?.mshiedAdvisorId) {
      newAppointment.requiredattendees.push({
        id: contact?.mshiedAdvisorId?.id,
        logicalName: contact?.mshiedAdvisorId.logicalName,
      })
    }

    if (contact?.mshiedAdvisorId?.id) {
      newAppointment.slcmMentor = { logicalName: 'contact', id: contact.mshiedAdvisorId?.id }
    }

    if (contact?.slcmBatch?.id) {
      newAppointment.slcmBatch = { logicalName: 'slcm_batch', id: contact.slcmBatch?.id }
    }

    newAppointment.scheduledDurationMinutes = 60
    newAppointment.slcmPurpose = SlcmAppointmentSlcmPurpose.Mentor_MenteeMeeting
    newAppointment.subject = "Request for Mentor's Appointment"
    newAppointment.slcmRemarks = values.remarks;
    newAppointment.regardingObjectId = { logicalName: 'contact', id: contact?.contactId || '' }

    return newAppointment
  }

  const handlePreferredDateChange = (e: any) => {
    const allowedDays = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']
    const date = moment(e.target.value)
    const day = date.format('dddd')
    setDate(e.target.value) // Updated Line
    if (allowedDays.indexOf(day.toUpperCase()) === -1) {
      formik.setFieldError('preferredDate', 'Selected date is not valid.')
      return
    }

    formik.setFieldValue('preferredDate', date.format('yyyy-MM-DD'))
    formik.setFieldValue('preferredDay', day)
  }

  const todayDateTime = new Date();
  const currentDate = todayDateTime.toISOString().slice(0, 10);
  const currentTime = todayDateTime.getHours();

  return (
    <div>
      <CardPanel className='bg-transparent'>
        <CardPanelHeader
          className='min-h-5px'
          title="Request Appointment"
          subTitle="Request Faculty / Mentor's Appointment" />
        <CardPanelBody>
          <UserBasicProfile className='fs-7 mb-5' />
          <form
            className='form card card-body py-5 fs-7'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_new_application_form'
          >
            {formik.status && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}

            <div className='row'>
              <div className='col-xl-6 my-2'>
                <label className='form-label text-gray-600 fs-7'>Faculty / Mentor</label>
                <input
                  className='form-control form-control-sm'
                  disabled={true}
                  value={contact.mshiedAdvisorIdName} />
              </div>
              <div className='col-xl-6 my-2'>
                <label className='form-label text-gray-600 fs-7'>Campus</label>
                <input
                  className='form-control form-control-sm'
                  disabled={true}
                  value={contact.slcmCampusName} />
              </div>
              <div className='col-xl-6 my-2'>
                <label className='required form-label text-gray-600 fs-7'>Preferred Date</label>
                <input
                  {...formik.getFieldProps('preferredDate')}
                  className={clsx(
                    'form-control form-control-sm',
                    { 'is-invalid': formik.touched.preferredDate && formik.errors.preferredDate },
                    { 'is-valid': formik.touched.preferredDate && !formik.errors.preferredDate }
                  )}
                  onChange={handlePreferredDateChange}
                  name='preferredDate'
                  type='date'
                  aria-label='Preferred Date'
                />

                {formik.touched.preferredDate && formik.errors.preferredDate && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{formik.errors.preferredDate}</span>
                  </div>
                )}
              </div>
              <div className='col-xl-6 my-2'>
                <label className='form-label text-gray-600 fs-7'>Preferred Day</label>
                <select
                  {...formik.getFieldProps('preferredDay')}
                  className={clsx(
                    'form-select form-select-sm',
                    { 'is-invalid': formik.touched.preferredDay && formik.errors.preferredDay },
                    { 'is-valid': formik.touched.preferredDay && !formik.errors.preferredDay }
                  )}
                  required
                  disabled
                  name='preferredDay'
                  aria-label='Preferred Day'
                >
                  <option>Select Day</option>
                  <option value='Monday'>Monday</option>
                  <option value='Tuesday'>Tuesday</option>
                  <option value='Wednesday'>Wednesday</option>
                  <option value='Thursday'>Thursday</option>
                  <option value='Friday'>Friday</option>
                  <option value="Saturday">Saturday</option>
                  <option value="Sunday">Sunday</option>
                </select>

                {formik.touched.preferredDay && formik.errors.preferredDay && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{formik.errors.preferredDay}</span>
                  </div>
                )}
              </div>
              <div className='col-xl-6 my-2'>
                <label className='required form-label text-gray-600 fs-7'>Preferred Time Slot</label>
                <select
                  {...formik.getFieldProps('preferredTime')}
                  className={clsx(
                    'form-select form-select-sm',
                    { 'is-invalid': formik.touched.preferredTime && formik.errors.preferredTime },
                    { 'is-valid': formik.touched.preferredTime && !formik.errors.preferredTime }
                  )}
                  name='preferredTime'
                  aria-label='Preferred Time Slots'
                >
                  <option>Select Time-slots</option>
                  <option value="07AM - 08AM" disabled={currentTime >= 8 && date === currentDate}>07AM - 08AM</option>
                  <option value="08AM - 09AM" disabled={currentTime >= 9 && date === currentDate}>08AM - 09AM</option>
                  <option value='09AM - 10AM' disabled={currentTime >= 10 && date === currentDate}>09AM - 10AM</option>
                  <option value='10AM - 11AM' disabled={currentTime >= 11 && date === currentDate}>10AM - 11AM</option>
                  <option value='11AM - 12PM' disabled={currentTime >= 12 && date === currentDate}>11AM - 12pm</option>
                  <option value='12PM - 01PM' disabled={currentTime >= 13 && date === currentDate}>12PM - 01PM</option>
                  <option value='02PM - 03PM' disabled={currentTime >= 15 && date === currentDate}>02PM - 03PM</option>
                  <option value='03PM - 04PM' disabled={currentTime >= 16 && date === currentDate}>03PM - 04PM</option>
                  <option value='04PM - 05PM' disabled={currentTime >= 17 && date === currentDate}>04PM - 05PM</option>
                  <option value="05PM - 06PM" disabled={currentTime >= 18 && date === currentDate}>05PM - 06PM</option>
                  <option value="06PM - 07PM" disabled={currentTime >= 19 && date === currentDate}>06PM - 07PM</option>
                </select>
                {formik.touched.preferredTime && formik.errors.preferredTime && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{formik.errors.preferredTime}</span>
                  </div>
                )}
              </div>
              <div className="col-xl-6 my-2">
                <label className="required form-label text-gray-600 fs-7">Remarks</label>
                <input
                  {...formik.getFieldProps('remarks')}
                  className={clsx(
                    'form-control form-control-sm',
                    { 'is-invalid': formik.touched.remarks && formik.errors.remarks },
                    { 'is-valid': formik.touched.remarks && !formik.errors.remarks }
                  )}
                  name="remarks"
                  type="text"
                  aria-label="Remarks" />
                {formik.touched.remarks && formik.errors.remarks && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{formik.errors.remarks}</span>
                  </div>
                )}
              </div>
            </div>

            <div className='text-end mt-3'>
              <button
                type="submit"
                disabled={loading}
                className="btn btn-sm btn-primary">
                {loading ?
                  <span className='indicator-progress' style={{ display: 'inline-block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span> :
                  <span className='indicator-label'>
                    <i className="las la-paper-plane fs-1 me-1"></i>
                    Submit
                  </span>
                }
              </button>
            </div>
          </form>
        </CardPanelBody>
      </CardPanel>

    </div>
  )
}
