import { CourseHistory } from "../models";
import { rootApi } from './api';

const courseHistoriesApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getCourseHistory: builder.query<CourseHistory, string>({
            query: (id) => `/CourseHistory/${id}`,
        }),

        getCourseHistories: builder.query<CourseHistory[], void>({
            query: () => `/CourseHistory`,
        }),

        getCourseHistoriesByStudentId: builder.query<CourseHistory[], string>({
            query: (studentId) => `/CourseHistory/student/${studentId}`,
        }),
    })
})

export const {
    useGetCourseHistoryQuery,
    useGetCourseHistoriesQuery,
    useGetCourseHistoriesByStudentIdQuery,
    useLazyGetCourseHistoriesByStudentIdQuery,
} = courseHistoriesApi;