import { CardPanel, CardPanelBody, CardPanelHeader } from "../../widgets-core"

import { FeesDue } from "./components/FeesDue"
import clsx from "clsx"
import { useUserContact } from "../../hooks/user"

export const FeesDuePanel = ({ className }: {
    className?: string
}) => {
    const contact = useUserContact()

    return (
        <CardPanel className={clsx(className)}>
            <CardPanelHeader title="Fees Due" className="min-h-45px" >
            </CardPanelHeader>
            <CardPanelBody>
                {contact && <FeesDue contact={contact} />}
            </CardPanelBody>
        </CardPanel>
    );
}