import { Account } from "../models";
import { rootApi } from './api';

const accountsApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getAccount: builder.query<Account, string>({
            query: (id) => `/Accounts/${id}`,
        }),

        getAccounts: builder.query<Account[], void>({
            query: () => `/Accounts`,
        }),

        getCampuses: builder.query<Account[], void>({
            query: () => `/Accounts/campus`,
        }),
    })
})

export const {
    useGetAccountQuery,
    useGetAccountsQuery,
    useGetCampusesQuery
} = accountsApi;