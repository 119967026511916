import axios from "axios";

const SERVICE_URL = `${process.env.REACT_APP_API_URL}/Flywire`;

export const getFlywireUrlApi = async (request: any) => {
    const { data, status, statusText } = await axios.post<any>(`${SERVICE_URL}/redirect-url`, request);
    if (status !== 200 && status !== 201) {
        throw new Error(statusText);
    }

    return data;
}
