import { AcademicPeriodDetail, RegistrationWindow, getRegistrationLabel, getYear } from "../../models"

import { DateFormat } from "../../widgets-core"
import clsx from "clsx"

export const RegistrationDetails = ({ period, registration, className }: {
    period?: AcademicPeriodDetail,
    registration: RegistrationWindow,
    className?: string
}) => {
    return (
        <div className={clsx(className, 'd-flex flex-wrap')}>
            <div className='w-xl-250px flex-fill my-1'>
                <span className="text-gray-600">Program</span>
                <div className='text-gray-700 fw-bolder'>
                    {period?.mshiedProgramIdName}
                </div>
            </div>

            <div className='w-150px my-1'>
                <span className="text-gray-600">
                    {getRegistrationLabel(registration?.slcmRegistrationType)}
                </span>
                <div className='text-gray-700 fw-bolder'>
                    {registration?.slcmRegistrationType === 0 ?
                        registration?.slcmSemesterName :
                        getYear(registration.slcmYear)}
                </div>
            </div>

            <div className='w-150px my-1'>
                <span className="text-gray-600">Batch</span>
                <div className='text-gray-700 fw-bolder'>
                    {period?.slcmBatchName}
                </div>
            </div>

            <div className='w-125px my-1'>
                <span className="text-gray-600">Registration Date</span>
                <div className='text-gray-700 fw-bolder'>
                    <DateFormat date={period?.slcmRegistrationDate} />
                </div>
            </div>

            <div className='w-200px flex-fill my-1'>
                <span className="text-gray-600">Status</span>
                <div className='text-gray-700 fw-bolder'>
                    {period?.slcmSemesterRegistrationName}
                </div>
            </div>
        </div>)
}
