import { AttendanceStatisticsWidget } from '../../modules/attendance-statistics'
import { Contact } from '../../models'
import { FeesDuePanel } from '../../modules/fee-management'
import { HallTicketsAlert } from '../../modules/registrations/HallTicketsAlert'
import { Loading } from '../../widgets-core'
import { NotificationsCard } from '../../modules/notifications/NotificationsCard'
import { QuickLinksCard } from '../../widgets-business'
import { RegistrationsAlert } from '../../modules/registrations'
import { SlcmContactSlcmUndertaking } from '../../models/contacts/enums/slcmContactSlcmUndertaking'
import { TimetableCard } from '../../modules/appointments'
import { UndertakingPage } from './UndertakingPage'
import { useUserContact } from '../../hooks/user'

export const DashboardWrapper = () => {
  const contact = useUserContact();

  if (!contact) {
    return (
      <div className='h-100 d-flex align-items-center justify-content-center'>
        <Loading />
      </div>)
  }

  if (contact?.slcmUndertaking === SlcmContactSlcmUndertaking.Required) {
    return <UndertakingPage />
  }

  return <DashboardPage contact={contact} />
}

const DashboardPage = ({ contact }: {
  contact: Contact
}) => {
  return <>
    <div className='row gy-5 g-xl-8 mb-5'>
      <div className='col-xl-12'>
        {contact && <><RegistrationsAlert contact={contact} /> <HallTicketsAlert contact={contact} /></>}
      </div>
      <div className='col-xl-6'>
        <TimetableCard
          contact={contact}
          className='dashboard-card mb-3' />
      </div>
      <div className='col-xl-6'>
        <NotificationsCard
          limit={5}
          className={'dashboard-card mb-3'} />
      </div>
      <div className='col-xl-6'>
        <AttendanceStatisticsWidget
          contact={contact}
          className='dashboard-card mb-3' />
      </div>
      <div className='col-xl-6 mb-0'>
        <FeesDuePanel className="mb-3" />
      </div>
      <div className='col-xl-6 mb-0'>
        {contact.slcmUndertaking === SlcmContactSlcmUndertaking.RegistrationCompleted && <QuickLinksCard />}
      </div>
    </div>
  </>
}