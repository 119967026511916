import { CardPanel, CardPanelBody, CardPanelHeader, CardPanelToolbar, If, ListQueryStatus, Loading } from "../../../widgets-core";
import React, { useEffect } from "react"
import { ServiceRequestApproval, SlcmApprovalCardStatusCode } from "../../../models";

import _ from "lodash";
import moment from "moment";
import { toAbsoluteUrl } from "../../../helpers";
import { useGetServiceRequestApprovalsByRequestIdQuery } from "../../../services/service-request-approvals.api";
import { useLazyGetEntityImageQuery } from "../../../services/entity-image.api";

export const ServiceRequestApprovals = ({ requestId, className }: {
    requestId: string,
    className?: string,
}) => {
    const { data: approvals, isLoading, isError, isFetching, isSuccess, refetch }
        = useGetServiceRequestApprovalsByRequestIdQuery(requestId);

    if (isLoading || isFetching) {
        return <Loading />
    }

    const sortEntries = () => _.sortBy(approvals, p => p.modifiedOn).reverse()

    return (
        <CardPanel className={className}>
            <CardPanelHeader title='Approvals' className="min-h-5px">
                <CardPanelToolbar>
                    <button
                        onClick={refetch}
                        type="button"
                        title="Refresh"
                        className='btn btn-sm btn-link text-hover-primary p-0 ms-3'>
                        <i className="las la-redo-alt fs-2"></i>
                    </button>
                </CardPanelToolbar>
            </CardPanelHeader>
            <CardPanelBody className="pt-0 scroll-y mh-300px">
                <ListQueryStatus
                    isLoading={isLoading || isFetching}
                    isSuccess={isSuccess}
                    isError={isError}
                    data={approvals} />

                <If condition={!isLoading && !isFetching && isSuccess}>
                    <div className='timeline-label timeline-label__empty' >
                        {sortEntries().map(approval => <React.Fragment key={approval.activityId} >
                            <ApprovalPanel approval={approval} />
                        </React.Fragment>)}
                    </div>
                </If>
            </CardPanelBody>
        </CardPanel>
    )
}

const UserInfo = ({ entry }: { entry: ServiceRequestApproval }) => {
    const [getEntityImage, { data: image }] = useLazyGetEntityImageQuery();

    useEffect(() => {
        if (entry.ownerId?.id)
            getEntityImage({ id: entry.ownerId?.id, entity: `${entry.ownerId.logicalName}s` })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entry.ownerId?.id])

    const statusText = (code?: SlcmApprovalCardStatusCode) => {
        switch (code) {
            case SlcmApprovalCardStatusCode.Approved: return "Approved by";
            case SlcmApprovalCardStatusCode.AutoApproved: return "Approved by";
            case SlcmApprovalCardStatusCode.Open: return "Pending with";
            case SlcmApprovalCardStatusCode.Rejected: return "Rejected by";
            case SlcmApprovalCardStatusCode.Scheduled: return "Scheduled by";
            default: return "";
        }
    }

    return (
        <div className='mt-3 mb-3'>
            <div className="fs-6 fw-bold text-gray-700 ms-3 mb-2">
                {statusText(entry.statusCode)} :
            </div>
            <div className="symbol symbol-25px symbol-circle ms-3">
                {image ?
                    <img alt='User' src={`data:image/bmp;base64,${image}`} /> :
                    <img alt='User' src={toAbsoluteUrl('/media/avatars/user.png')} />}
                <span
                    className='fs-6 fw-bold text-gray-700 text-hover-primary ms-2'
                >
                    {entry.ownerIdName}
                </span>
            </div>
        </div>
    )
}

const ApprovalPanel = ({ approval }: { approval: ServiceRequestApproval }) => {
    return <div className='timeline-item'>
        <div className='timeline-badge'>
            <i className='fa fa-genderless text-warning fs-1'></i>
        </div>
        <div>
            <div className='fw-bolder text-gray-800 fs-6 ps-3'>
                {moment(approval.modifiedOn).fromNow()}
            </div>
            <UserInfo entry={approval} />
            <div className='fw-normal timeline-content text-muted ps-3'>
                <p className="p-0 m-0">{approval.subject}</p>
                <p className="p-0 m-0">{approval.description}</p>
            </div>
        </div>
    </div>
}

