import { CardPanel, CardPanelBody, CardPanelHeader, CardPanelToolbar, If, ListQueryStatus } from '../../../widgets-core'
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { DateFilterParams, DefaultGridColDef, formatDate } from '../../../helpers/grid';
import React, { useEffect, useState } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { DownloadButton } from '../../payments/DownloadButton';
import { Money } from '../../../widgets-business'
import { Payment } from '../../../models'
import clsx from 'clsx'
import { getPaymentType } from '../../../models/payments'
import { useLazyGetPaymentsByStudentIdQuery } from '../../../services/payments.api'
import { useUserContact } from '../../../hooks/user';

export const PaymentsView = ({ className }: {
  className?: string
}) => {
  const contact = useUserContact();
  const [getPayments, { data: payments, isLoading, isFetching, isError, isSuccess }]
    = useLazyGetPaymentsByStudentIdQuery();

  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'slcmReceiptNo',
      headerName: 'Receipt',
      filter: true,
      flex: 0,
      initialWidth: 100,
      cellRenderer: (props: ICellRendererParams) => {
        const payment: Payment = props.data;
        if (!payment.slcmDocumentLink) {
          return <span>{payment.slcmReceiptNo}</span>
        }

        return <DownloadButton
          className='btn btn-sm btn-link py-0'
          label={payment.slcmReceiptNo || 'Download'}
          link={payment.slcmDocumentLink} />
      }
    },
    {
      field: 'slcmPaidOn',
      headerName: 'Date',
      resizable: true,
      type: 'dateColumn',
      filter: 'agDateColumnFilter',
      filterParams: DateFilterParams,
      cellClass: 'dateISO fw-bold fs-7 text-gray-700',
      valueFormatter: (params) => formatDate(params.value),
      flex: 0,
      initialWidth: 100
    },

    {
      field: 'slcmPaymentDescription',
      filter: true,
      headerName: 'Description',
      initialWidth: 150
    },
    {
      field: 'slcmPaymentType',
      headerName: 'Payment Type',
      resizable: true,
      valueFormatter: (params) => getPaymentType(params.value),
      flex: 0,
      initialWidth: 100
    },
    {
      field: 'slcmChequeNumber',
      headerName: 'Reference Number',
      filter: true,
      resizable: true,
      initialWidth: 100
    },
    {
      field: 'slcmAmount',
      headerName: 'Amount',
      filter: 'agNumberColumnFilter',
      flex: 0,
      initialWidth: 125,
      cellRenderer: (props: ICellRendererParams) => {
        const payment: Payment = props.data;
        return <Money currencyId={payment.transactionCurrencyId?.id} amount={payment.slcmAmount} />
      },
    }
  ]);

  const refetch = () => {
    if (contact?.contactId)
      getPayments(contact?.contactId)
  }

  useEffect(() => {
    refetch()

    // eslint-disable-next-line
  }, [contact])

  return (
    <CardPanel className={clsx(className, "bg-transparent")} >
      <CardPanelHeader title="Receipts" subTitle='Your receipt details' className='min-h-5px'>
        <CardPanelToolbar>
          <button
            onClick={refetch}
            type="button"
            title="Refresh"
            className='btn btn-sm btn-link text-hover-primary p-0'>
            <i className="las la-redo-alt fs-2"></i>
          </button>
        </CardPanelToolbar>
      </CardPanelHeader>
      <CardPanelBody>
        <ListQueryStatus
          isLoading={isLoading || isFetching}
          isSuccess={isSuccess}
          isError={isError}
          data={payments} />

        <If condition={!isLoading && !isFetching && isSuccess && (payments || [])?.length > 0}>
          <div className="ag-theme-alpine" style={{ height: 400, width: 'auto' }}>
            <AgGridReact
              rowData={payments}
              columnDefs={columnDefs}
              pagination={true}
              serverSideInfiniteScroll={true}
              paginationPageSize={10}
              cacheBlockSize={10}
              rowHeight={30}
              defaultColDef={DefaultGridColDef}
              columnTypes={{ 'dateColumn': { field: '' } }}
            />
          </div>
        </If>
      </CardPanelBody>
    </CardPanel>
  )
}
