import { ApiTags, rootApi } from './api';

import { Deferment } from "../models";

const TAG = ApiTags.Deferment;
const getTags = (entities: Deferment[]) => [...entities.map((p) => ({ type: TAG, id: p.slcmDefermentId })), TAG]

const defermentsApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getDeferment: builder.query<Deferment, string>({
            query: (id) => `/Deferments/${id}`,
            providesTags: (result) =>
                result ? [{ type: TAG, id: result.slcmDefermentId }, TAG] : [TAG]
        }),

        getDeferments: builder.query<Deferment[], void>({
            query: () => `/Deferments`,
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),

        getDefermentByDemandLineId: builder.query<Deferment[], string>({
            query: (demandLineId) => `/Deferments/demand-line(${demandLineId})`,
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),

        getDefermentByDemandNo: builder.query<Deferment[], string>({
            query: (demandNo) => `/Deferments/demand(${demandNo})`,
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),

        createDeferment: builder.mutation<Deferment, Partial<Deferment>>({
            query: (entity) => ({
                url: '/Deferments',
                method: 'POST',
                body: entity
            }),
            invalidatesTags: [TAG]
        }),

        updateDeferment: builder.mutation<void, { id: string, entity: Partial<Deferment> }>({
            query: ({ id, entity }) => ({
                url: `/Deferments/${id}`,
                method: 'PUT',
                body: entity
            }),
            invalidatesTags: [TAG]
        }),

        deleteDeferment: builder.mutation<void, string>({
            query: (id: string) => ({
                url: `/Deferments/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [TAG]
        }),
    })
})

export const {
    useGetDefermentQuery,
    useGetDefermentsQuery,
    useGetDefermentByDemandLineIdQuery,
    useGetDefermentByDemandNoQuery,
    useCreateDefermentMutation,
    useUpdateDefermentMutation,
    useDeleteDefermentMutation,
} = defermentsApi;