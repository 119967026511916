import { ApiTags, rootApi } from "./api";
import { ServiceRequest, SlcmServicerequestsStatuscode } from "../models";

const TAG = ApiTags.ServiceRequest;
const getTags = (entities: ServiceRequest[]) => [
    ...entities.map((p) => ({ type: TAG, id: p.slcmServicerequestsId })), TAG]

const serviceRequestsApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getServiceRequest: builder.query<ServiceRequest, string>({
            query: (id) => `/ServiceRequests/${id}`,
            providesTags: (result) =>
                result ? [{ type: TAG, id: result.slcmServicerequestsId }, TAG] : [TAG]
        }),

        getServiceRequests: builder.query<ServiceRequest[], void>({
            query: () => `/ServiceRequests`,
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),

        getAssociatedDocumentsByDemandId: builder.query<ServiceRequest[], string>({
            query: (demandId) => `/ServiceRequests/demand(${demandId})`,
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),

        getAssociatedDocumentsByServiceRequestId: builder.query<ServiceRequest[], string>({
            query: (requestId) => `/ServiceRequests/service-request(${requestId})`,
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),

        getServiceRequestsByStudentId: builder.query<ServiceRequest[], {
            studentId: string,
            status: SlcmServicerequestsStatuscode
        }>({
            query: ({ studentId, status }) =>
                `/ServiceRequests/student(${studentId})/${status}`,
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),

        createServiceRequest: builder.mutation<ServiceRequest, Partial<ServiceRequest>>({
            query: (entity) => ({
                url: '/ServiceRequests',
                method: 'POST',
                body: entity
            }),
            invalidatesTags: (result) =>
                result ? [{ type: TAG, id: result.slcmServicerequestsId }, TAG] : [TAG]
        }),

        updateServiceRequest: builder.mutation<ServiceRequest, {
            id: string,
            entity: Partial<ServiceRequest>
        }>({
            query: ({ id, entity }) => ({
                url: `/ServiceRequests/${id}`,
                method: 'PUT',
                body: entity
            }),
            invalidatesTags: (result) =>
                result ? [{ type: TAG, id: result.slcmServicerequestsId }, TAG] : [TAG]
        }),

        deleteServiceRequest: builder.mutation<void, string>({
            query: (id: string) => ({
                url: `/ServiceRequests/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [TAG]
        }),
    })
})

export const {
    useGetServiceRequestQuery,
    useGetServiceRequestsQuery,
    useGetAssociatedDocumentsByDemandIdQuery,
    useGetServiceRequestsByStudentIdQuery,
    useLazyGetServiceRequestsByStudentIdQuery,
    useGetAssociatedDocumentsByServiceRequestIdQuery,
    useCreateServiceRequestMutation,
    useUpdateServiceRequestMutation,
    useDeleteServiceRequestMutation
} = serviceRequestsApi;