import { FC } from "react"
import { ViewSemesterRegistration } from "../../modules/registrations/ViewSemesterRegistration";
import { useParams } from "react-router-dom";
import { useUserContact } from "../../hooks/user";

export const ViewSemesterRegistrationWrapper: FC = () => {
    const { windowId }: { windowId?: string } = useParams();
    const contact = useUserContact();

    if (!windowId || !contact) return <></>;

    return <ViewSemesterRegistration
        windowId={windowId}
        contact={contact} />
}
