import { AssociatedDocument, getApprovalStatusModeLabel } from "../../models/associated-documents/index"

import { Accordion } from "react-bootstrap";
import { DateFormat } from "../../widgets-core";
import { DownloadButton } from "./DownloadButton";
import React from "react"
import clsx from "clsx";
import { useGetAssociatedDocumentsByDemandIdQuery } from "../../services/associated-documents.api";

export const AssociatedDocumentsView = ({ demandId, className }: {
    demandId: string
    className?: string,
}) => {
    const { data: associatedDocuments } = useGetAssociatedDocumentsByDemandIdQuery(demandId);

    if (!associatedDocuments || associatedDocuments.length === 0)
        return <></>

    return (
        <Accordion className={className}>
            <Accordion.Item eventKey={demandId}>
                <Accordion.Header bsPrefix="accordion-header accordion-header-sm">
                    <h5 className='text-gray-700 my-0'>Associated Documents</h5>
                </Accordion.Header>
                <Accordion.Body>
                    <div className="scroll-y mh-250px">
                        {associatedDocuments?.map((entity) =>
                            <React.Fragment key={entity.slcmAssociatedDocumentsId}>
                                <AssociatedDocumentPanel
                                    className="mb-2 fs-8"
                                    entity={entity} />
                            </React.Fragment>
                        )}
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

export const AssociatedDocumentPanel = ({ entity, className }: {
    entity: AssociatedDocument,
    className?: string
}) => {
    return <div className={clsx("border rounded px-5 py-1", className)}>
        <div className='d-flex flex-wrap align-items-center'>
            <div className="flex-fill my-1 me-3">
                {!!entity.slcmDocumentLink ?
                    <DownloadButton
                        label={`${entity.slcmAssociatedDocumentName}`}
                        link={entity.slcmDocumentLink}
                        className="btn btn-sm btn-link" /> :
                    <div className="fw-bold py-2 text-danger">
                        {entity.slcmAssociatedDocumentName}
                    </div>}
            </div>

            <div className="my-1 d-flex flex-wrap">
                <div className="me-5 my-1 me-3">
                    <span className="text-gray-600">Deposited Amount</span>
                    <div className='text-gray-700 fw-bolder'>{entity.slcmAmountDeposited}</div>
                </div>

                <div className="me-5 my-1 me-3">
                    <span className="text-gray-600">Reference Number</span>
                    <div className='text-gray-700 fw-bolder'>{entity.slcmReferenceNumber}</div>
                </div>

                <div className="me-5 my-1 me-3">
                    <span className="text-gray-600">Date of payment</span>
                    <div className='text-gray-700 fw-bolder'><DateFormat date={entity.slcmPostingDate} /></div>
                </div>

                {entity.slcmApprovalStatus &&
                    <div className="me-5 my-1">
                        <span className="text-gray-600">Approval Status</span>
                        <div className='text-gray-700 fw-bolder'>{getApprovalStatusModeLabel(entity.slcmApprovalStatus)}</div>
                    </div>}
            </div>
        </div>
    </div>;
}
