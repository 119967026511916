import { IFeeBreakup, IPaymentRequest } from "../../client-models";
import { useEffect, useState } from "react";

import { FlywirePayment } from "../flywire-payments";
import { KTSVG } from "../../helpers";
import { PaymentTransaction } from "../../models";
import { useTrackPaymentStatus } from "./trackPaymentStatus.hook";

export const OnlinePaymentButton = ({
    invoiceId,
    currencyId,
    paymentType,
    amount,
    feeBreakup,
    onComplete,
    onClick,
    className,
}: {
    invoiceId: string,
    currencyId?: string,
    paymentType: number,
    amount: number,
    feeBreakup: IFeeBreakup,
    className?: string,
    onComplete?: () => void,
    onClick?: () => void,
}) => {
    type PaymentStage = "Default" | "GetUrl" | "TrackStatus" | "Completed";
    const [state, setStage] = useState<PaymentStage>("Default");
    const [transactionId, setTransactionId] = useState<string>('');
    const [entity, setEntity] = useState<PaymentTransaction>();

    if (!currencyId || amount < 50)
        return <></>


    const amountToFlywire = (currencyId: string): IPaymentRequest => {
        return {
            invoiceId: invoiceId,
            otherFee: Math.round(feeBreakup.otherFee),
            tuitionFee: Math.round(feeBreakup.tuitionFee),
            hostelFee: Math.round(feeBreakup.hostelFee),
            currencyId: currencyId
        }
    }

    const RenderStage = () => {
        switch (state) {
            case "Default":
                return (
                    <ConfirmPayOnline
                        onClick={() => {
                            setStage("GetUrl")
                            onClick && onClick()
                        }} />
                )
            case "GetUrl":
                return (
                    <FlywirePayment
                        onOpenPaymentLink={(transactionId) => {
                            setTransactionId(transactionId);
                            setStage("TrackStatus")
                        }}
                        paymentInfo={amountToFlywire(currencyId)}
                        paymentType={paymentType}
                    />
                )
            case "TrackStatus":
                return <ShowPaymentStatus
                    className="alert border border-danger rounded text-danger"
                    onCompleted={(entity) => {
                        setEntity(entity);
                        setStage("Completed");
                        
                        if (onComplete)
                            onComplete();
                    }}
                    transactionId={transactionId} />
            case "Completed":
                switch (entity?.slcmStatus) {
                    case "guaranteed":
                    case "delivered":
                        return (
                            <div className="alert border-primary text-primary fw-bold d-flex align-items-center">
                                <i className="las la-thumbs-up fs-2x text-primary me-1"></i>
                                <span>Your payment transaction is successful.</span>
                                <span className="fw-bold text-gray-700 ms-5">Reference No: {entity?.slcmPaymentid}</span>
                            </div>
                        )
                    case "cancelled":
                        return (
                            <div className="alert border-danger text-danger fw-bold d-flex align-items-center">
                                <i className="las la-ban fs-2x text-danger me-1"></i>
                                <span>Your payment transaction is cancelled. </span>
                            </div>
                        )
                    default:
                        return (
                            <div className="alert border-danger text-danger fw-bold d-flex align-items-center">
                                <i className="las la-exclamation-circle fs-2x text-danger me-1"></i>
                                <span>Your payment transaction was not successful.</span>
                                <span className="fw-bold text-gray-700 ms-5">Reference No: {entity?.slcmPaymentid}</span>
                            </div>
                        )
                }
        }
    }

    return (
        <div className={className}>
            {RenderStage()}
        </div>
    )
}

const ConfirmPayOnline = ({ className, onClick }: {
    className?: string,
    onClick?: () => void
}) => {
    return (
        <div className={className}>
            <input
                type='radio'
                className='btn-check'
                name='payment_mode'
                value='0'
                id='online-payment'
                onClick={onClick} />
            <label
                className='btn btn-outline btn-outline-dashed btn-outline-default p-5'
                htmlFor='online-payment'
            >
                <div className="d-flex">
                    <KTSVG
                        path='/media/svg/brand-logos/flywire-logo.svg'
                        className='svg-icon-3x me-3' />

                    <span className='d-block fw-bold text-start'>
                        <span className='text-gray-700 fw-bolder d-block fs-5'>Pay Online</span>
                        <span className='text-gray-500 fw-semibold fs-7'>
                            Credit / Debit Card / Net banking
                        </span>
                    </span>
                </div>
            </label>
        </div>)
}

const ShowPaymentStatus = ({ transactionId, onCompleted, className }: {
    transactionId: string,
    onCompleted?: (entity?: PaymentTransaction) => void,
    className?: string
}) => {
    const { status, entity } = useTrackPaymentStatus(transactionId, 5000);

    useEffect(() => {
        if (!onCompleted)
            return;

        if (status === "guaranteed" || status === "delivered" || status === "cancelled") {
            onCompleted(entity)
        }

        // eslint-disable-next-line
    }, [status, onCompleted])

    const statusMessage = () => {
        switch (status) {
            case "guaranteed":
            case "delivered":
                return 'Your payment completed successfully.';
            case "cancelled":
                return 'You have cancelled the payment, please try again.';
            case "idle":
                return 'You need to enter the needed details in the payment page.';
            case "initiated":
                return 'You are doing good, please fill the needed details in the payment page.';
            case "processed":
                return 'Your payment is processed, please wait for the confirmation.';
            default:
                return 'Status unknown, please wait...'
        }
    }

    return (
        <div className={className}>
            {(status === "idle" || status === "processed" || status === "initiated") &&
                <span className='indicator-progress' style={{ display: 'inline-block' }}>
                    <span className='spinner-border spinner-border-sm align-middle me-1'></span>
                </span>}
            <span className="ms-2">{statusMessage()}</span>
        </div>
    )
}


