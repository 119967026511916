import { ApiTags, rootApi } from './api';

import { Contact } from "../models";

const TAG = ApiTags.Contact;
const getTags = (entities: Contact[]) => [
    ...entities.map((p) => ({
        type: TAG,
        id: p.contactId
    })),
    TAG]

const contactsApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getContact: builder.query<Contact, string>({
            query: (id) => `/Contacts/${id}`,
            providesTags: (result) =>
                result ? [{ type: TAG, id: result.contactId }, TAG] : [TAG]
        }),

        getContacts: builder.query<Contact[], void>({
            query: () => `/Contacts`,
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),

        getDeansByCampusId: builder.query<Contact[], string>({
            query: (campusId) => `/Contacts/Dean(${campusId})`,
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),

        getHODsByCampusId: builder.query<Contact[],  {
            campusId: string, teamsId: string
        }>({
            query: ({campusId, teamsId}) => `/Contacts/HOD(${campusId})/teams(${teamsId})`,
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),

        getCounsellorsByCampusId: builder.query<Contact[], string>({
            query: (campusId) => `/Contacts/counsellors(${campusId})`,
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),

        getContactByUserName: builder.query<Contact, { authType: string, userName: string }>({
            query: (model) => ({
                url: `/Contacts/login-contact`,
                method: 'POST',
                body: model
            }),
            providesTags: (result) =>
                result ? [{ type: TAG, id: result.contactId }, TAG] : [TAG]
        }),

        updateContact: builder.mutation<void, { id: string, entity: Partial<Contact> }>({
            query: ({ id, entity }) => ({
                url: `/Contacts/${id}`,
                method: 'PUT',
                body: entity
            }),
            invalidatesTags: (result, error, args) =>
                !error ? [{ type: TAG, id: args.id }, TAG] : [TAG]
        }),
    })
})

export const {
    useGetContactQuery,
    useLazyGetContactQuery,
    useGetContactsQuery,
    useGetDeansByCampusIdQuery,
    useLazyGetDeansByCampusIdQuery,
    useGetHODsByCampusIdQuery,
    useLazyGetHODsByCampusIdQuery,
    useGetCounsellorsByCampusIdQuery,
    useLazyGetCounsellorsByCampusIdQuery,
    useGetContactByUserNameQuery,
    useLazyGetContactByUserNameQuery,
    useUpdateContactMutation,
} = contactsApi;