import ApexCharts, { ApexOptions } from 'apexcharts'
import { CardPanel, CardPanelBody, CardPanelHeader, CardPanelToolbar, If, ListQueryStatus } from '../../widgets-core'
import React, { useEffect, useRef, useState } from 'react'
import { getCSS, getCSSVariableValue } from '../../assets/_utils'

import { Contact } from '../../models'
import Dropdown from 'react-bootstrap/esm/Dropdown'
import { SlcmLearningmethod } from '../../models/attendance-statistics/enums/slcmLearningmethod'
import { SlcmYear } from '../../models/attendance-statistics/enums/slcmYear'
import _ from 'lodash'
import { useGetAttendanceStatisticsByStudentIdQuery } from '../../services/attendance-statistics.api'

type AttendanceData = {
    course: string,
    year: string,
    semester: string,
    month: string,
    method: string,
    completed: number,
    classes: number,
    attended: number,
}

type GraphData = {
    attended: number,
    absent: number,
    remaining: number,
}

type SliceSelection = {
    name: string,
    selected: boolean
}

export const AttendanceStatisticsWidget = ({ contact, className }: {
    contact: Contact,
    className: string
}) => {
    const { data: entities, isLoading, isError, isFetching, isSuccess, refetch } = useGetAttendanceStatisticsByStudentIdQuery(contact.contactId);
    const [rowData, setRowData] = useState<AttendanceData[]>([]);
    const [chartData, setChartData] = useState<GraphData>({ attended: 0, absent: 0, remaining: 0 });

    const [courseSelections, setCourseSelections] = useState<SliceSelection[]>([]);
    const [yearSelections, setYearSelections] = useState<SliceSelection[]>([]);
    const [semesterSelections, setSemesterSelections] = useState<SliceSelection[]>([]);
    const [monthSelections, setMonthSelections] = useState<SliceSelection[]>([]);
    const [methodsSelections, setMethodsSelections] = useState<SliceSelection[]>([]);

    useEffect(() => {
        if (!entities) {
            return;
        }

        const data = entities.map<AttendanceData>(p => ({
            course: p.slcmCourseName || 'Others',
            semester: p.slcmSemesterName?.replaceAll('  ', ' ') || 'Others',
            year: getYearName(p.slcmYear),
            month: p.slcmYearMonth || 'Others',
            method: getLearningMethod(p.slcmLearningMethod),
            completed: p.slcmTotalClassesCompleted || 0,
            classes: p.slcmTotalClasses || 0,
            attended: p.slcmAttendedClasses || 0
        }));

        // Courses
        const courses = _.groupBy(data, p => p.course) as Record<string, AttendanceData[]>;
        const selections = Object.keys(courses).sort((a, b) => a.localeCompare(b)).map<SliceSelection>(item => ({
            name: item,
            selected: true
        }));

        setCourseSelections(selections);

        // Years
        const years = _.groupBy(data, p => p.year) as Record<string, AttendanceData[]>;
        const yearSelections = Object.keys(years).sort((a, b) => a.localeCompare(b)).map<SliceSelection>(item => ({
            name: item,
            selected: true
        }));

        setYearSelections(yearSelections);

        const semesters = _.groupBy(data, p => p.semester) as Record<string, AttendanceData[]>;
        const semesterSelections = Object.keys(semesters).sort((a, b) => a.localeCompare(b)).map<SliceSelection>(item => ({
            name: item,
            selected: true
        }));

        setSemesterSelections(semesterSelections);

        // Months
        const months = _.groupBy(data, p => p.month) as Record<string, AttendanceData[]>;
        const monthSelections = Object.keys(months).sort((a, b) => a.localeCompare(b)).map<SliceSelection>(item => ({
            name: item,
            selected: true
        }));

        setMonthSelections(monthSelections);

        // Months
        const methods = _.groupBy(data, p => p.method) as Record<string, AttendanceData[]>;
        const methodsSelections = Object.keys(methods).sort((a, b) => a.localeCompare(b)).map<SliceSelection>(item => ({
            name: item,
            selected: true
        }));

        setMethodsSelections(methodsSelections);

        setRowData(data);
        // eslint-disable-next-line
    }, [entities])

    useEffect(() => {
        if (!rowData) {
            return
        }

        const courses = courseSelections.filter(p => p.selected === true).map(p => p.name) || [];
        let filteredData = rowData.filter(data => data.course && courses.indexOf(data.course) !== -1);

        const years = yearSelections.filter(p => p.selected === true).map(p => p.name) || [];
        filteredData = filteredData.filter(data => data.year && years.indexOf(data.year) !== -1);

        const semesters = semesterSelections.filter(p => p.selected === true).map(p => p.name) || [];
        filteredData = filteredData.filter(data => data.semester && semesters.indexOf(data.semester) !== -1);

        const months = monthSelections.filter(p => p.selected === true).map(p => p.name) || [];
        filteredData = filteredData.filter(data => data.month && months.indexOf(data.month) !== -1);

        const methods = methodsSelections.filter(p => p.selected === true).map(p => p.name) || [];
        filteredData = filteredData.filter(data => data.method && methods.indexOf(data.method) !== -1);

        let totalClasses = 0, attendedClasses = 0, completedClasses = 0;
        filteredData.forEach((p) => {
            totalClasses += p.classes;
            attendedClasses += p.attended;
            completedClasses += p.completed;
        })

        if (completedClasses < attendedClasses)
            completedClasses = attendedClasses;

        if (totalClasses < completedClasses)
            totalClasses = completedClasses;

        setChartData({
            attended: attendedClasses,
            absent: completedClasses - attendedClasses,
            remaining: totalClasses - completedClasses
        });
        // eslint-disable-next-line
    }, [rowData, courseSelections, yearSelections, semesterSelections, monthSelections, methodsSelections])

    return (
        <CardPanel className={className}>
            <CardPanelHeader
                title="Attendance Stats">
                <CardPanelToolbar>
                    <button
                        onClick={refetch}
                        type="button"
                        title="Refresh"
                        className='btn btn-sm btn-link text-hover-primary p-0 me-3'>
                        <i className="las la-redo-alt fs-2"></i>
                    </button>
                </CardPanelToolbar>
            </CardPanelHeader>
            <CardPanelBody>
                <div style={{ height: isLoading || isFetching ? '365px' : '0' }}>
                    <ListQueryStatus
                        isLoading={isLoading || isFetching}
                        isSuccess={isSuccess}
                        isError={isError}
                        data={['']} />
                </div>
                <If condition={!isLoading && !isFetching && isSuccess}>
                    <div className='d-flex flex-wrap mb-5'>
                        <p className='min-w-100 font-italic text-gray-600 fs-7 mb-2'>
                            You can slice and dice the chart by applying the below filters.
                        </p>
                        <GraphSlicer
                            title='Filter Courses'
                            setSelections={setCourseSelections}
                            selections={courseSelections} />

                        <GraphSlicer
                            title='Filter Years'
                            setSelections={setYearSelections}
                            selections={yearSelections} />

                        <GraphSlicer
                            title='Filter Semesters'
                            setSelections={setSemesterSelections}
                            selections={semesterSelections} />

                        <GraphSlicer
                            title='Filter Months'
                            setSelections={setMonthSelections}
                            selections={monthSelections} />

                        <GraphSlicer
                            title='Filter Learning Methods'
                            setSelections={setMethodsSelections}
                            selections={methodsSelections} />
                    </div>

                    <Chart data={chartData} />
                </If>
            </CardPanelBody>
        </CardPanel >
    )
}

const Chart = ({ data }: { data: GraphData }) => {
    const chartRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (!chartRef.current) {
            return
        }

        const height = parseInt(getCSS(chartRef.current, 'height'))

        const chart = new ApexCharts(chartRef.current, getChartOptions(data, height))
        if (chart) {
            chart.render()
        }

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartRef, data])

    return (
        <div ref={chartRef} id='kt_charts_widget_1_chart' style={{ height: '240px' }} />
    )
}

function getChartOptions(data: GraphData, height: number): ApexOptions {
    const primaryColor = getCSSVariableValue('--bs-primary')
    const successColor = getCSSVariableValue('--bs-gray-600')
    const dangerColor = getCSSVariableValue('--bs-danger')

    return {
        series: [data.remaining, data.attended, data.absent],
        labels: [
            `Remaining`,
            `Attended`,
            `Absent`
        ],
        colors: [successColor, primaryColor, dangerColor],
        chart: {
            fontFamily: 'inherit',
            type: 'donut',
            height: height,
            toolbar: {
                show: false,
            },
        },
        legend: {
            show: true,
        },
        tooltip: {
            style: {
                fontSize: '12px',
            },
            y: {
                formatter: function (val) {
                    return val + ' classes';
                },
            },
        }
    }
}

const getYearName = (year?: SlcmYear) => {
    switch (year) {
        case SlcmYear.Year1: return "Year 1";
        case SlcmYear.Year2: return "Year 2";
        case SlcmYear.Year3: return "Year 3";
        case SlcmYear.Year4: return "Year 4";
        case SlcmYear.Year5: return "Year 5";
        default: return `Others`
    }
}

// spell-checker:disable
const getLearningMethod = (method?: SlcmLearningmethod) => {
    switch (method) {
        case SlcmLearningmethod.Lecture: return "Lecture";
        case SlcmLearningmethod.Tutorial: return "Tutorial";
        case SlcmLearningmethod.Practical: return "Practical";
        case SlcmLearningmethod.Exam: return "Exam";
        case SlcmLearningmethod.ClinicalPosting: return "Clinical Posting";
        default: return "Others";
    }
}
// spell-checker:enable

const GraphSlicer = ({ title, setSelections, selections }: {
    title: string,
    setSelections: (selections: SliceSelection[]) => void,
    selections: SliceSelection[]
}) => {
    return <Dropdown className="me-2 mb-2">
        <Dropdown.Toggle variant="secondary" size='sm'>
            {title}
        </Dropdown.Toggle>

        <Dropdown.Menu>
            <div className='d-flex align-items-center justify-content-start flex-wrap p-3 px-5 w-550px'>
                <div className='mb-5 w-100 text-end'>
                    <button className='btn btn-secondary btn-sm me-2 py-1' onClick={() => {
                        setSelections(selections.map(selection => ({ ...selection, selected: true })))
                    }}>Select all</button>

                    <button className='btn btn-secondary btn-sm py-1' onClick={() => {
                        setSelections(selections.map(selection => ({ ...selection, selected: false })))
                    }}>Unselect all</button>
                </div>

                {selections.map(item => <React.Fragment key={item.name}>
                    <div className='pe-5 pb-1 flex-fill'>
                        <div className='form-check form-check-sm fs-7'>
                            <input
                                type="checkbox"
                                onChange={() => {
                                    const items = selections.map(p => item.name === p.name ? {
                                        ...p,
                                        selected: !item.selected
                                    } : p)
                                    setSelections(items)
                                }}
                                className="form-check-input"
                                id={item.name}
                                checked={item.selected} />
                            <label className='form-check-label' htmlFor={item.name}>{item.name}</label>
                        </div>
                    </div>
                </React.Fragment>)}
            </div>
        </Dropdown.Menu>
    </Dropdown>
}
