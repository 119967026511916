import { FC, useEffect } from "react";

import clsx from "clsx";

type Props = {
    value?: number,
    onChange?: (value: number) => void,
    className?: string,
};

export const SelectRegistrationType: FC<Props> = ({
    value,
    onChange,
    className
}) => {
    useEffect(() => {
        if (!value) {
            onChange && onChange(407450001);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <select
            className={clsx(className, "form-select form-select-sm")}
            aria-label="Select registration type"
            value={value}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                onChange && onChange(Number(event.target.value));
            }}
            placeholder="Registration Type">
            {/* <option value={407450000}>Regular</option> */}
            <option value={407450001}>Repeat</option>
        </select>
    )
}