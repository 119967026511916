import { Attendance, Contact, MshiedIsPresent } from "../../../models";
import { CardPanel, CardPanelBody, CardPanelHeader, CardQueryStatus, If } from "../../../widgets-core";
import { useCreateAttendanceMutation, useGetAttendanceByAppointmentIdQuery, useUpdateAttendanceMutation } from "../../../services/attendances.api";
import { useEffect, useState } from "react";

import { AppointmentPanel } from "../timetable";
import clsx from "clsx";
import moment from "moment";
import { useGetAppointmentQuery } from "../../../services/appointments.api";

export const AttendanceScanner = ({ appointmentId, contact, className }: {
    appointmentId: string,
    contact: Contact,
    className?: string,
}) => {
    const {
        data: appointment,
        isLoading: isAppointmentLoading,
        isFetching: isAppointmentFetching,
        isSuccess: isAppointmentSuccess,
        isError: isAppointmentError,
        error: appointmentError
    } = useGetAppointmentQuery(appointmentId);
    const {
        data: attendance,
        isLoading: isAttendanceLoading,
        isFetching: isAttendanceFetching,
        isSuccess: isAttendanceSuccess,
        isError: isAttendanceError,
        error: attendanceError
    } = useGetAttendanceByAppointmentIdQuery({ studentId: contact.contactId, appointmentId })
    const [isUpdating, setIsUpdating] = useState<boolean>(false)

    const [addAttendance, {
        isError: isAddError,
        error: addError
    }] = useCreateAttendanceMutation();
    const [updateAttendance, {
        isError: isUpdateError,
        error: updateError
    }] = useUpdateAttendanceMutation();

    const [restrictions, setRestrictions] = useState<string>()

    useEffect(() => {
        if (!isAppointmentSuccess || !appointment || !isAttendanceSuccess)
            return;

        if (attendance && attendance.mshiedIsPresent === MshiedIsPresent.Yes) {
            return;
        }

        const startTime = moment(appointment.scheduledStart).add(-15, 'minute');
        const endTime = moment(appointment.scheduledEnd).add(15, 'minute');
        const now = moment(new Date());

        setRestrictions('');

        if (now.isBefore(startTime)) {
            setRestrictions(`Sorry, You are too early. You can try within +/-15 mins scheduled time.`);
            return;
        }

        if (now.isAfter(endTime)) {
            if (attendance && attendance.mshiedIsPresent !== MshiedIsPresent.No) {
                setRestrictions(`Sorry, Attendance window is closed for this event.`);
            }

            return;
        }

        if (!attendance) {
            const attendance: Partial<Attendance> = {
                slcmRelatedSchedule: { id: appointment?.activityId, logicalName: "appointment" },
                mshiedStudentId: { id: contact.contactId, logicalName: "contact" },
                mshiedCourseSectionID: appointment.slcmCourseSection,
                mshiedAttendanceDate: appointment.scheduledStart,
                mshiedIsPresent: MshiedIsPresent.Yes,
                mshiedIsExcused: false,
                mshiedName: appointment.subject,
            }

            setIsUpdating(true)
            addAttendance(attendance)
                .unwrap()
                .finally(() => setIsUpdating(false))
        } else {
            const updatedAttendance: Partial<Attendance> = { mshiedIsPresent: MshiedIsPresent.Yes };

            setIsUpdating(true)
            updateAttendance({ id: attendance.mshiedAttendanceId, entity: updatedAttendance })
                .unwrap()
                .finally(() => setIsUpdating(false))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contact, attendance, appointment])

    return (
        <CardPanel className={clsx(className, "bg-transparent")}>
            <CardPanelHeader
                className="ribbon ribbon-top"
                title="Attendance" />
            <CardPanelBody>
                <CardQueryStatus
                    isLoading={isAppointmentLoading || isAppointmentFetching || isAttendanceLoading || isAttendanceFetching}
                    isSuccess={isAppointmentSuccess && isAttendanceSuccess}
                    isError={isAppointmentError || isAttendanceError}
                    error={appointmentError || attendanceError}
                    data={appointment} />

                <If condition={!isAppointmentLoading && !isAppointmentFetching && isAppointmentSuccess}>
                    {attendance &&
                        <AttendanceStatus className="my-5" attendance={attendance} />}

                    {(isUpdating) && <div className="my-5 fs-7 fw-bold">
                        <div className="d-flex align-items-center justify-content-center">
                            <span className='spinner-border spinner-border-sm align-middle me-2'></span>
                            Updating attendance...
                        </div>
                    </div>}

                    <div className="mb-5">
                        {(isAddError && addError) &&
                            <div className="alert alert-warning py-2 fs-7 fw-bold">
                                <div className="d-flex align-items-center">
                                    <i className="las la-exclamation-circle fs-2x text-warning me-2"></i>
                                    Failed to create attendance record.
                                </div>
                            </div>}
                        {(isUpdateError && updateError) &&
                            <div className="alert alert-warning py-2 fs-7 fw-bold">
                                <div className="d-flex align-items-center">
                                    <i className="las la-exclamation-circle fs-2x text-warning me-2"></i>
                                    Failed to update attendance record.
                                </div>
                            </div>}

                        {restrictions &&
                            <div className="alert alert-warning py-2 fs-7 fw-bold">
                                <div className="d-flex align-items-center">
                                    <i className="las la-exclamation-circle fs-2x me-2"></i>
                                    {restrictions}
                                </div>
                            </div>}
                    </div>

                    {appointment &&
                        <AppointmentPanel appointment={appointment} />}
                </If>
            </CardPanelBody>
        </CardPanel>
    )
}

const AttendanceStatus = ({ attendance, className }: { attendance?: Attendance, className?: string }) => {
    if (!attendance || !attendance?.mshiedIsPresent) return <></>

    const getAttendanceStatus = () => {
        switch (attendance?.mshiedIsPresent) {
            case MshiedIsPresent.Yes:
                return "Present";
            case MshiedIsPresent.No:
                return "Absent";
            case MshiedIsPresent.NULL:
                return "Cancelled";
            default:
                return "Not Sure";
        }
    }

    return (
        <div className={clsx(className, "animate__animated animate__zoomIn d-flex flex-row align-items-center justify-content-center")}>
            <div className="w-50px h-50px rounded-circle bg-success d-flex align-items-center justify-content-center">
                {(attendance?.mshiedIsPresent === MshiedIsPresent.Yes) ?
                    <i className="las la-check fs-2x text-white"></i> :
                    <i className="las la-times fs-2x text-white"></i>}
            </div>
            <span className="text-success fs-2 ms-5">{getAttendanceStatus()}</span>
        </div>)
}