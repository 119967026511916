import { SlcmAssociateddocumentsSlcmApprovalstatus } from "./enums/slcmAssociateddocumentsSlcmApprovalstatus";

export * from "./AssociatedDocument";

export const getApprovalStatusModeLabel = (approvalStatus?: SlcmAssociateddocumentsSlcmApprovalstatus) => {
    switch (approvalStatus) {
        case SlcmAssociateddocumentsSlcmApprovalstatus.Approved: return "Approved"; // Approved
        case SlcmAssociateddocumentsSlcmApprovalstatus.PendingWithFinanceTeam: return "Pending With Finance Team"; // Pending With Finance Team
        case SlcmAssociateddocumentsSlcmApprovalstatus.Rejected: return "Rejected"; // Rejected
        default: return "";
    }
}