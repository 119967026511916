import * as authHelper from './AuthHelpers'

import { FC, createContext, useContext, useState } from 'react'

import { AuthModel } from './_models'
import { AuthProvider as MSALAuthProvider } from "../msal";

type AuthContextProps = {
  auth: AuthModel | undefined;
  saveAuth: (auth: AuthModel | undefined) => void;
  logout: () => void;
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => { },
  logout: () => { },
  setAuthType: () => { },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

export const useAuth = () => {
  return useContext(AuthContext)
}

export const AuthProvider: FC = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined);
  }

  return (
    <AuthContext.Provider value={{ auth, saveAuth, logout }}>
      <MSALAuthProvider>
        {children}
      </MSALAuthProvider>
    </AuthContext.Provider>
  )
}