import { SlcmServiceRequestCommentSlcmCommentedBy } from "./enums/SlcmServiceRequestCommentSlcmCommentedBy";

export * from "./ServiceRequestComment";
export * from "./enums/SlcmServiceRequestCommentSlcmCommentedBy";


export const commentedByLabel = (commentedBy?: SlcmServiceRequestCommentSlcmCommentedBy): "Staff" | "Student" => {
    switch (commentedBy) {
        case SlcmServiceRequestCommentSlcmCommentedBy.Staff: return "Staff";
        case SlcmServiceRequestCommentSlcmCommentedBy.Student: return "Student";
        default: return "Staff";
    }
}