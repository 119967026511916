import { If, ListQueryStatus } from '../../widgets-core'

import { Link } from 'react-router-dom'
import { NotificationPanel } from '../../modules/notifications'
import React from 'react'
import { RightArrowIcon } from '../../assets/icons'
import { toAbsoluteUrl } from '../../helpers'
import { useGetNotifications } from '../../hooks/notification-event.hooks'

export const NotificationsMenu = () => {
    const { notifications, isLoading, isError, isFetching, isSuccess, refetch } = useGetNotifications()

    return (
        <div
            className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
            data-kt-menu='true'
        >
            <div
                className='d-flex flex-row bgi-no-repeat rounded-top'
                style={{ backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')` }}
            >
                <h3 className='text-white fw-bold px-5 my-5'>
                    Notifications <span className='fs-8 opacity-75 ps-3'>{notifications?.length} notifications</span>
                </h3>

                <div className='flex-fill' />
                <button
                    onClick={refetch}
                    type="button"
                    title="Refresh"
                    className='btn btn-sm btn-link text-hover-primary p-0 me-3'>
                    <i className="las la-redo-alt fs-2"></i>
                </button>
            </div>
            <div className='scroll-y mh-325px my-5 px-8'>
                <ListQueryStatus
                    isLoading={isLoading || isFetching}
                    isSuccess={isSuccess}
                    isError={isError}
                    data={notifications} />

                <If condition={!isLoading && !isFetching && isSuccess}>
                    {notifications.map(
                        notification => <React.Fragment key={notification.slcmNotificationeventId}>
                            <NotificationPanel
                                className='mb-2'
                                notificationEvent={notification} />
                        </React.Fragment>)}
                </If>
            </div>

            <If condition={!isLoading && !isFetching && isSuccess}>
                <ViewAll />
            </If>
        </div>
    )
}

const ViewAll = () => (
    <div className='py-1 text-center border-top'>
        <Link
            to='/notifications'
            className='btn btn-sm btn-color-gray-600 btn-active-color-primary'
        >
            View All <RightArrowIcon />
        </Link>
    </div>)
