import { ApiTags, rootApi } from './api';

import { AcademicPeriodDetail } from "../models";

const TAG = ApiTags.AcademicPeriodDetail;

const academicPeriodDetailsApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getAcademicPeriodDetail: builder.query<AcademicPeriodDetail, string>({
            query: (id) => `/AcademicPeriodDetails/${id}`,
            providesTags: (result, err) =>
                result ?
                    [{ type: TAG, id: result.mshiedAcademicperioddetailsId }, TAG] : [TAG]
        }),

        getAcademicPeriodDetails: builder.query<AcademicPeriodDetail[], void>({
            query: () => `/AcademicPeriodDetails`,
            providesTags: (result, err) =>
                result ?
                    [...result.map((p) => ({ type: TAG, id: p.mshiedAcademicperioddetailsId })), TAG] :
                    [TAG]
        }),

        getStudentAcademicPeriodDetails: builder.query<AcademicPeriodDetail[], string>({
            query: (studentId) => `/AcademicPeriodDetails/student(${studentId})`,
            providesTags: (result, err) =>
                result ?
                    [...result.map((p) => ({ type: TAG, id: p.mshiedAcademicperioddetailsId })), TAG] :
                    [TAG]
        }),

        updateAcademicPeriodDetail: builder.mutation<AcademicPeriodDetail, {
            id: string,
            entity: Partial<AcademicPeriodDetail>
        }>({
            query: ({ id, entity }) => ({
                url: `/AcademicPeriodDetails/${id}`,
                method: 'PUT',
                body: entity
            }),
            invalidatesTags: (result, err) =>
                result ?
                    [{ type: TAG, id: result.mshiedAcademicperioddetailsId }, TAG] : [TAG]
        }),

    })
})

export const {
    useGetAcademicPeriodDetailQuery,
    useGetAcademicPeriodDetailsQuery,
    useGetStudentAcademicPeriodDetailsQuery,
    useLazyGetStudentAcademicPeriodDetailsQuery,
    useUpdateAcademicPeriodDetailMutation
} = academicPeriodDetailsApi;