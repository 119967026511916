import { ServiceRequestApproval } from "../models";
import { rootApi } from './api';

const serviceRequestApprovalsApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getServiceRequestApproval: builder.query<ServiceRequestApproval, string>({
            query: (id) => `/ServiceRequestApprovals/${id}`,
        }),

        getServiceRequestApprovals: builder.query<ServiceRequestApproval[], void>({
            query: () => `/ServiceRequestApprovals`,
        }),

        getServiceRequestApprovalsByRequestId: builder.query<ServiceRequestApproval[], string>({
            query: (requestId) => `/ServiceRequestApprovals/request/${requestId}`,
        }),
    })
})

export const {
    useGetServiceRequestApprovalQuery,
    useLazyGetServiceRequestApprovalQuery,
    useGetServiceRequestApprovalsQuery,
    useGetServiceRequestApprovalsByRequestIdQuery,
} = serviceRequestApprovalsApi;