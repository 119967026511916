import { AcademicPeriodDetail, Contact } from '../../models'
import { CardPanel, CardPanelBody, CardPanelHeader, CardPanelToolbar } from '../../widgets-core'
import React, { useEffect, useState } from 'react'
import { useGetStudentAcademicPeriodDetailsQuery, useUpdateAcademicPeriodDetailMutation } from '../../services/academic-period-details.api';

import Accordion from 'react-bootstrap/esm/Accordion';
import { DemandAccordionItem } from "../fee-management/demand/DemandAccordionItem"
import { PayableAmount } from '../fee-management/demand/components/PayableAmount';
import clsx from 'clsx';
import { useGetDemandsByStudentIdQuery } from '../../services/demands.api';
import { useGetRegistrationStatusesQuery } from '../../services/registration-statuses.api';
import { useGetRegistrationWindowQuery } from '../../services/registration-windows.api';
import { useNavigate } from 'react-router-dom'

export const SemesterRegistrationPaymentPage = ({ contact, windowId, className }: {
    contact: Contact,
    windowId: string,
    className?: string
}) => {
    const { data: registration } = useGetRegistrationWindowQuery(windowId)
    const { data: academicPeriodDetails } = useGetStudentAcademicPeriodDetailsQuery(contact.contactId)
    const { data: statuses } = useGetRegistrationStatusesQuery()
    const { data: demands, refetch } = useGetDemandsByStudentIdQuery(contact.contactId)

    const [academicPeriodDetail, setAcademicPeriodDetails] = useState<AcademicPeriodDetail>()
    const navigate = useNavigate()
    const [updateAcademicPeriodDetails] = useUpdateAcademicPeriodDetailMutation()
    const [loading, setLoading] = useState<boolean>(false)
    const [submitButton, setSubmitButton] = useState<boolean>()
    const [alertMsg, setAlertMsg] = useState('')

    useEffect(() => {
        if (!registration || !academicPeriodDetails) return

        let academicPeriodDetail = academicPeriodDetails.find(
            (p) =>
                p.slcmRegistrationFrequency === registration.slcmRegistrationType &&
                p.slcmBatch?.id === registration.slcmBatchId?.id &&
                p.mshiedProgramId?.id === registration.slcmProgram?.id &&
                (!registration.slcmSemester?.id ? true : p.slcmSemester?.id === registration.slcmSemester?.id) &&
                (!registration.slcmYear ? true : p.slcmYear === registration.slcmYear)
        )

        if (academicPeriodDetail) {
            setAcademicPeriodDetails(academicPeriodDetail)
        }
        // eslint-disable-next-line
    }, [academicPeriodDetails, registration])

    const submitStep = () => {
        const pendingWithStudent = statuses?.find((p) => p.mshiedCode === (contact?.slcmOutstandingAmount === 0 ? "PWA" : "PWF"))

        if (!academicPeriodDetail || !contact || !pendingWithStudent) {
            return
        }

        const entityToUpdate: Partial<AcademicPeriodDetail> = {
            slcmSemesterRegistration: {
                id: pendingWithStudent.mshiedRegistrationstatusId,
                logicalName: 'mshied_registrationstatus',
            }
        }

        setLoading(true);
        updateAcademicPeriodDetails({
            id: academicPeriodDetail.mshiedAcademicperioddetailsId,
            entity: entityToUpdate
        })
            .unwrap()
            .then((data) => navigate('/'))
            .catch((reason) => {
                setAlertMsg('Failed to Selection Payment')
            })
            .finally(() => setLoading(false))
    }

    const submitRegistration = () => {
        const dataSemester = demands?.filter(
            (p) => p.slcmDemandgeneratedagainstdeferment === true
        )
        if (dataSemester && dataSemester.length > 1) {
            setSubmitButton(true)
        }

    }
    useEffect(() => {
        submitRegistration()
        // eslint-disable-next-line
    }, [demands])

    return (
        <CardPanel className={clsx(className)}>
            <CardPanelHeader title='Semester Registration > Payment'>
                {(contact?.slcmOutstandingAmount || 0) !== 0 &&
                    <CardPanelToolbar>
                        <PayableAmount
                            className='text-end'
                            label='Total Outstanding Amount'
                            amount={contact?.slcmOutstandingAmount || 0}
                            currencyId={contact?.transactionCurrencyId?.id} />
                    </CardPanelToolbar>
                }
            </CardPanelHeader>
            <CardPanelBody className='pt-0'>
                {alertMsg && <div className='alert alert-warning p-3'>{alertMsg}</div>}

                {(contact?.slcmOutstandingAmount && contact?.slcmOutstandingAmount !== 0) ?
                    <div className='text-muted fst-italic fs-7 my-2'>
                        Note: Please pay the outstanding amount to submit the registration.
                    </div> :
                    <div className='text-muted fst-italic fs-7 my-2'>
                        Note: Please click the submit button to complete the registration.
                    </div>}

                <Accordion defaultActiveKey={""} flush>
                    {demands?.map((demand) => {
                        return (
                            <React.Fragment key={demand.slcmDemandheaderId}>
                                {Number(demand.slcmCurrentbalance) > 0 &&
                                    <DemandAccordionItem
                                        onChange={refetch}
                                        demand={demand} />
                                }
                            </React.Fragment>
                        )
                    })}
                </Accordion>

                {/* {(contact?.slcmOutstandingAmount === 0 || submitButton) && */}
                {((!contact?.slcmOutstandingAmount && contact?.slcmOutstandingAmount === 0) || submitButton) &&
                    <div className='text-end mt-5'>
                        <button type='submit' className='btn btn-primary' onClick={submitStep}>
                            <span className='indicator-label'>Submit Registration</span>
                            {loading &&
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            }
                        </button>
                    </div>}


            </CardPanelBody>
        </CardPanel>
    )
}


