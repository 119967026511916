import { Contact, VisaHistory } from "../../../../models";
import { DateFormat, If, ListQueryStatus } from "../../../../widgets-core";

import Accordion from "react-bootstrap/esm/Accordion";
import React from "react"
import clsx from "clsx";
import { useGetVisaHistoryEntriesByStudentIdQuery } from "../../../../services/visa-histories.api";

export const StudentVisaHistory = ({ contact, className }: {
    contact: Contact,
    className?: string,
}) => {
    const { data: entities, isLoading, isError, isFetching, isSuccess, refetch }
        = useGetVisaHistoryEntriesByStudentIdQuery(contact.contactId);

    return (
        <Accordion className={className}>
            <Accordion.Item eventKey={""}>
                <Accordion.Header bsPrefix="accordion-header accordion-header-sm">
                    <h5 className='text-gray-700 my-0'>Visa History</h5>
                </Accordion.Header>
                <Accordion.Body>
                    <div className="text-end mb-3">
                        <button
                            onClick={refetch}
                            type="button"
                            title="Refresh"
                            className='btn btn-sm btn-link text-hover-primary p-0 me-3'>
                            <i className="las la-redo-alt fs-2"></i>
                        </button>
                    </div>
                    
                    <ListQueryStatus
                        isLoading={isLoading || isFetching}
                        isSuccess={isSuccess}
                        isError={isError}
                        data={entities} />

                    <If condition={!isLoading && !isFetching && isSuccess}>
                        {entities?.map(item => <React.Fragment key={item.slcmVisaHistoryId}>
                            <VisaHistoryPanel
                                className="mb-2"
                                visaHistory={item} />
                        </React.Fragment>)}
                    </If>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

const VisaHistoryPanel = ({ visaHistory, className }: {
    visaHistory: VisaHistory,
    className?: string
}) => {
    return (
        <div className={clsx(className, "border px-5 rounded py-3 fs-7")}>
            <div className="d-flex flex-wrap flex-sm-row">
                <div className="w-xl-125px flex-fill my-1 me-3">
                    <span className="text-gray-600">Passport Number</span>
                    <div className="text-gray-700 fw-bolder">
                        <span>{visaHistory.slcmPassportNumber}</span>
                    </div>
                </div>
                <div className="w-xl-125px my-1 me-3">
                    <span className="text-gray-600">Passport Valid From</span>
                    <div className="text-gray-700 fw-bolder">
                        <DateFormat date={visaHistory.slcmPassportValidFrom} />
                    </div>
                </div>
                <div className="w-xl-125px my-1 me-3">
                    <span className="text-gray-600">Passport Valid Till</span>
                    <div className="text-gray-700 fw-bolder">
                        <DateFormat date={visaHistory.slcmPassportValidTill} />
                    </div>
                </div>
                <div className="w-xl-125px my-1 me-3">
                    <span className="text-gray-600">Start Date</span>
                    <div className="text-gray-700 fw-bolder">
                        <DateFormat date={visaHistory.slcmVisaStartDate} />
                    </div>
                </div>
                <div className="w-xl-125px my-1 me-3">
                    <span className="text-gray-600">End Date</span>
                    <div className="text-gray-700 fw-bolder">
                        <DateFormat date={visaHistory.slcmVisaEndDate} />
                    </div>
                </div>
                <div className="w-xl-125px my-1 me-3">
                    <span className="text-gray-600">Renewal Date</span>
                    <div className="text-gray-700 fw-bolder">
                        <DateFormat date={visaHistory.slcmVisaRenewalDate} />
                    </div>
                </div>
                <div className="w-xl-125px my-1 me-3">
                    <span className="text-gray-600">Status</span>
                    <div className="text-gray-700 fw-bolder">
                        {visaHistory.slcmIsActive ? 'Active' : 'Not Active'}
                    </div>
                </div>
            </div>
        </div>
    )
}