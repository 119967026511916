import clsx from "clsx";
import { useGetCoursesQuery } from "../../../../services/courses.api";

export const SelectCourse = ({ value, className, onChange }: {
    value?: string,
    onChange?: (value: string) => void,
    className?: string,
}) => {
    const { data: courses, isSuccess } = useGetCoursesQuery();

    const SelectOptions = () => {
        if (!isSuccess) return <></>;

        return (
            <>
                <option></option>
                {courses?.map(
                    course => <option
                        key={course.mshiedCourseId}
                        value={course.mshiedCourseId}>
                        {course.mshiedName}
                    </option>)}
            </>)
    }

    return <select
        className={clsx(className, "form-select form-select-sm")}
        aria-label="Select the course"
        value={value}
        onChange={(event) => onChange && onChange(event.target.value)}
        placeholder={!courses ? 'Loading' : 'Select the course'}>
        {SelectOptions()}
    </select>
}