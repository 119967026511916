import { CardPanel, CardPanelBody, CardPanelHeader, CardPanelToolbar, CardRefreshButton, If, ListQueryStatus, TimePeriod, getTimePeriodName } from "../../../widgets-core";
import { TimetableCalendarView, TimetableWeekView } from "..";

import { Contact } from "../../../models";
import Dropdown from "react-bootstrap/esm/Dropdown"
import DropdownButton from "react-bootstrap/esm/DropdownButton"
import { useGetAppointmentsByPeriodQuery } from "../../../services/appointments.api";
import { useGetAttendanceEntriesByPeriodQuery } from "../../../services/attendances.api";
import { useState } from "react";

export const ViewTimetable = ({ contact, period }: {
    contact: Contact,
    period: TimePeriod
}) => {
    const [timePeriod, setTimePeriod] = useState<TimePeriod>(period)

    const { data: attendanceEntries } = useGetAttendanceEntriesByPeriodQuery({
        studentId: contact.contactId,
        period: timePeriod
    });
    const { data: appointments, isLoading, isError, isFetching, isSuccess, error, refetch } = useGetAppointmentsByPeriodQuery({
        studentId: contact.contactId,
        period: timePeriod
    });

    const getView = () => {
        switch (timePeriod) {
            case "ThisWeek":
            case "NextWeek":
            case "LastWeek":
                return <TimetableWeekView
                    contact={contact}
                    timePeriod={timePeriod}
                    appointments={appointments || []}
                />
            case "ThisMonth":
            case "NextMonth":
            case "LastMonth":
                return <TimetableCalendarView
                    appointments={appointments || []}
                    attendances={attendanceEntries || []} />
            default:
                return <></>;
        }
    }

    return (
        <CardPanel className="bg-transparent">
            <CardPanelHeader
                title="Timetable"
                subTitle={`${getTimePeriodName(timePeriod)}'s timetable`}
                className="mt-4">
                <CardPanelToolbar>
                    <CardRefreshButton
                        className="me-3"
                        isLoading={isLoading || isFetching}
                        refetch={refetch} />

                    <TimetableToolbar
                        onSelect={setTimePeriod} />
                </CardPanelToolbar>
            </CardPanelHeader>
            <CardPanelBody>
                <ListQueryStatus
                    isLoading={isLoading || isFetching}
                    isSuccess={isSuccess}
                    isError={isError}
                    error={error}
                    data={['appointments']} />

                <If condition={!isLoading && !isFetching && isSuccess}>
                    <div className="card card-body">
                        {getView()}
                    </div>
                </If>
            </CardPanelBody>

        </CardPanel>
    )
}

const TimetableToolbar = ({ onSelect, className }: {
    className?: string,
    onSelect?: (period: TimePeriod) => void,
}) => {
    const handleOnSelect = (period: TimePeriod) => {
        onSelect && onSelect(period)
    }

    return (
        <DropdownButton size="sm"
            variant="secondary"
            title="Select Period " className={className}>
            <Dropdown.Item
                onClick={() => handleOnSelect("ThisWeek")}>
                <span className="fw-bold text-gray-700 my-2 mx-5">This Week</span>
            </Dropdown.Item>
            <Dropdown.Item
                onClick={() => handleOnSelect("LastWeek")}>
                <span className="fw-bold text-gray-700 my-2 mx-5">Last Week</span>
            </Dropdown.Item>
            <Dropdown.Item
                onClick={() => handleOnSelect("NextWeek")}>
                <span className="fw-bold text-gray-700 my-2 mx-5">Next Week</span>
            </Dropdown.Item>
            <Dropdown.Item
                onClick={() => handleOnSelect("ThisMonth")}>
                <span className="fw-bold text-gray-700 my-2 mx-5">This Month</span>
            </Dropdown.Item>
            <Dropdown.Item
                onClick={() => handleOnSelect("LastMonth")}>
                <span className="fw-bold text-gray-700 my-2 mx-5">Last Month</span>
            </Dropdown.Item>
            <Dropdown.Item
                onClick={() => handleOnSelect("NextMonth")}>
                <span className="fw-bold text-gray-700 my-2 mx-5">Next Month</span>
            </Dropdown.Item>
        </DropdownButton>)
}