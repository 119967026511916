import { ApprovalCard } from '../models';
import { rootApi } from './api';

const approvalCardsApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getApprovalCard: builder.query<ApprovalCard, string>({
            query: (id) => `/ApprovalCards/${id}`,
        }),

        getApprovalCards: builder.query<ApprovalCard[], void>({
            query: () => `/ApprovalCards`,
        }),

        getApprovalCardsByRequestId: builder.query<ApprovalCard[], string>({
            query: (requestId) => `/ApprovalCards/registration(${requestId})`,
        }),
    })
})

export const {
    useGetApprovalCardQuery,
    useGetApprovalCardsQuery,
    useGetApprovalCardsByRequestIdQuery,
    useLazyGetApprovalCardsByRequestIdQuery,
} = approvalCardsApi;