import { ApiTags } from './api';
import { AssociatedDocument } from "../models";
import { AttachmentRequestModel } from "../client-models";
import { rootApi } from './api';

const TAG  = ApiTags.AssociatedDocument;
const getTags = (entities: AssociatedDocument[]) => [
    ...entities.map((p) => ({ type: TAG, id: p.slcmAssociatedDocumentsId })), TAG]

const associatedDocumentsApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getAssociatedDocument: builder.query<AssociatedDocument, string>({
            query: (id) => `/AssociatedDocuments/${id}`,
            providesTags: (result) =>
                result ? [{ type: TAG, id: result.slcmAssociatedDocumentsId }, TAG] : [TAG]
        }),

        getAssociatedDocuments: builder.query<AssociatedDocument[], void>({
            query: () => `/AssociatedDocuments`,
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),

        getAssociatedDocumentsByDemandId: builder.query<AssociatedDocument[], string>({
            query: (demandId) => `/AssociatedDocuments/demand(${demandId})`,
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),

        getAssociatedDocumentsByServiceRequestId: builder.query<AssociatedDocument[], string>({
            query: (requestId) => `/AssociatedDocuments/service-request(${requestId})`,
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),

        getAssociatedDocumentsByRequestId: builder.query<AssociatedDocument[], {
            requestId: string,
            checklistId: string
        }>({
            query: ({ requestId, checklistId }) =>
                `/AssociatedDocuments/request(${requestId})/checklist(${checklistId})`,
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),

        getFileContent: builder.mutation<string, string>({
            query: (documentUrl) => ({
                url: `/AssociatedDocuments/download-file`,
                method: 'POST',
                body: { filePath: documentUrl }
            }),
            transformResponse: (result: { content: string }) => {
                return result.content;
            }
        }),

        uploadAssociatedDocument: builder.mutation<AssociatedDocument, AttachmentRequestModel>({
            query: (model) => ({
                url: `/AssociatedDocuments/attachment`,
                method: 'POST',
                body: model
            }),
            invalidatesTags: (result) =>
                result ? [{ type: TAG, id: result.slcmAssociatedDocumentsId }, TAG] : [TAG]

        }),

        createAssociatedDocument: builder.mutation<AssociatedDocument, Partial<AssociatedDocument>>({
            query: (entity) => ({
                url: '/AssociatedDocuments',
                method: 'POST',
                body: entity
            }),
            invalidatesTags: (result) =>
                result ? [{ type: TAG, id: result.slcmAssociatedDocumentsId }, TAG] : [TAG]
        }),

        updateAssociatedDocument: builder.mutation<AssociatedDocument, {
            id: string,
            entity: Partial<AssociatedDocument>
        }>({
            query: ({ id, entity }) => ({
                url: `/AssociatedDocuments/${id}`,
                method: 'PUT',
                body: entity
            }),
            invalidatesTags: (result) =>
                result ? [{ type: TAG, id: result.slcmAssociatedDocumentsId }, TAG] : [TAG]
        }),

        deleteAssociatedDocument: builder.mutation<void, string>({
            query: (id: string) => ({
                url: `/AssociatedDocuments/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [TAG]
        }),
    })
})

export const {
    useGetAssociatedDocumentQuery,
    useGetAssociatedDocumentsQuery,
    useGetAssociatedDocumentsByDemandIdQuery,
    useGetAssociatedDocumentsByRequestIdQuery,
    useGetAssociatedDocumentsByServiceRequestIdQuery,
    useGetFileContentMutation,
    useUploadAssociatedDocumentMutation,
    useCreateAssociatedDocumentMutation,
    useUpdateAssociatedDocumentMutation,
    useDeleteAssociatedDocumentMutation
} = associatedDocumentsApi;