import { FC } from "react"

type CardToolbarProps = {
    className?: string,
}

export const CardPanelToolbar: FC<CardToolbarProps> = ({ className, children }) => {
    return (
        <div className={`card-toolbar ${className}`}>
            {children}
        </div>
    )
}
