import React, { useCallback, useEffect, useState } from "react"
import { Service, ServiceRequest } from "../../../../models"

import { RequestField } from "../../../../client-models"
import { ServiceRequestField } from "./ServiceRequestField"
import { requestFields } from "./service-request-fields"

export const ServiceRequestFields = ({ service, serviceRequest, onChange, className }: {
    className?: string,
    service: Service,
    serviceRequest: ServiceRequest,
    onChange: (request: ServiceRequest) => void,

}) => {
    const [fields, setFields] = useState<RequestField[]>([]);

    useEffect(() => {
        if (!service) return;

        const fields: RequestField[] = [];
        const showField = (name: string) => {
            const field = requestFields.find(p => p.name === name);
            if (field) {
                fields.push({ ...field });
            }
        }

        if (service.slcmYear) showField("slcmYear");
        if (service.slcmSemester) showField("slcmSemester");
        if (service.slcmCourseFlag) showField("slcmCourseFlag");
        if (service.slcmSemesterBreak) showField("slcmSemesterBreak");
        if (service.slcmOwnerName) showField("slcmOwnerName");
        if (service.slcmOwnerPhoneNo) showField("slcmOwnerPhoneNo");
        if (service.slcmRelationship) showField("slcmRelationship");
        if (service.slcmVolume) showField("slcmVolume");
        if (service.slcmCourse) showField("slcmCourse");
        if (service.slcmAwards) showField("slcmAwards");
        if (service.slcmNumberofPax) showField("slcmNumberOfPax");
        if (service.slcmCurrentEmployment) showField("slcmCurrentEmployment");
        if (service.slcmNewMobile) showField("slcmNewMobile");
        if (service.slcmStartDate) showField("slcmStartDate");
        if (service.slcmBookTitle) showField("slcmBookTitle");
        if (service.slcmEndDate) showField("slcmEndDate");
        if (service.slcmBarcode) showField("slcmBarcode");
        if (service.slcmArticleTitle) showField("slcmArticleTitle");
        if (service.slcmReason) showField("slcmReason");
        if (service.slcmCarPlateNo) showField("slcmCarPlateNo");
        if (service.slcmJournalTitle) showField("slcmJournalTitle");
        if (service.slcmDesignation) showField("slcmDesignation");
        if (service.slcmNewPassportNo) showField("slcmNewPassportNo");
        if (service.slcmDirectorName) showField("slcmDirectorName");
        if (service.slcmFeeName) showField("slcmFeeName");
        if (service.slcmCollectionType) showField("slcmCollectionType");
        if (service.slcmCampus) showField("slcmCampus");
        if (service.slcmExpectedOccupancy) showField("slcmExpectedOccupancy");
        if (service.slcmLocation) showField("slcmLocation");
        if (service.slcmHospitalName) showField("slcmHospitalName");
        if (service.slcmNewPersonalEmail) showField("slcmNewPersonalEmail");
        if (service.slcmNewAddress) showField("slcmNewAddress");
        if (service.slcmRegistrationType) showField("slcmRegistrationType");
        if (service.slcmAchievements) showField("slcmAchievements");
        if (service.slcmCorrespondenceAddress) showField("slcmCorrespondenceAddress");
        if (service.slcmRepeatSemester) showField("slcmRepeatSemester");
        if (service.slcmDepartmentSpeciality) showField("slcmDepartmentSpeciality");
        if (service.slcmIssue) showField("slcmIssue");
        if (service.slcmDate) showField("slcmDate");
        if (service.slcmSemesterRegisteringfor) showField("slcmSemesterRegisteringfor");
        if (service.slcmHostelRoomNo) showField("slcmHostelRoomNo");
        if (service.slcmPurpose) showField("slcmPurpose");
        if (service.slcmRemarks) showField("slcmRemarks");
        if (service.slcmQuantity) showField("slcmQuantity");
        if (service.slcmCarType) showField("slcmCarType");
        if (service.slcmCarColour) showField("slcmCarColour");
        if (service.slcmStudentEmailID) showField("slcmStudentEmailID");
        if (service.slcmTransactionID) showField("slcmTransactionID");
        if (service.slcmVehiclemake) showField("slcmVehiclemake");
        
        setFields(fields);
    }, [service]);

    const getValue = useCallback((field: RequestField) => {
        switch (field.target) {
            case "slcmReason":
                return serviceRequest.slcmReason?.id;
            case "slcmCourse":
                return serviceRequest.slcmCourse?.id;
            case "slcmCampus":
                return serviceRequest.slcmCampus?.id;
            case "slcmLocation":
                return serviceRequest.slcmLocation?.id;
            case "slcmDepartmentSpeciality":
                return serviceRequest.slcmDepartmentSpeciality?.id;
            case "slcmHostelRoomNo":
                return serviceRequest.slcmHostelRoomNo?.id;
            case "slcmSemesterRegisteringfor":
                return serviceRequest.slcmSemesterRegisteringfor?.id;
            default:
                return (serviceRequest as any)[field.target];
        }
    }, [serviceRequest])

    const onChangeHandler = useCallback((field, value) => {
        const request = { ...serviceRequest };
        switch (field.target) {
            case "slcmReason":
                request.slcmReason = { id: value, logicalName: "slcm_applicationreason" };
                break;
            case "slcmCourse":
                request.slcmCourse = { id: value, logicalName: "mshied_course" };
                break;
            case "slcmCampus":
                request.slcmCampus = { id: value, logicalName: "account" };
                break;
            case "slcmLocation":
                request.slcmLocation = { id: value, logicalName: "slcm_location" };
                break;
            case "slcmDepartmentSpeciality":
                request.slcmDepartmentSpeciality = { id: value, logicalName: "team" };
                break;
            case "slcmHostelRoomNo":
                request.slcmHostelRoomNo = { id: value, logicalName: "slcm_hostel" };
                break;
            case "slcmSemesterRegisteringfor":
                request.slcmSemesterRegisteringfor = { id: value, logicalName: "slcm_semester" };
                break;
            default:
                (request as any)[field.target] = value;
                break;
        }

        onChange(request);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceRequest]);

    return <>
        <div className="d-flex flex-wrap">
            {fields.map(field => <React.Fragment key={field.name}>
                <ServiceRequestField
                    serviceId={service.slcmRequestsId || ''}
                    value={getValue(field) || ''}
                    onChange={onChangeHandler}
                    field={field}
                    campusId={serviceRequest.slcmCampus?.id}
                    bookingType={service.slcmBookingType}
                    occupancy={serviceRequest.slcmExpectedOccupancy}
                />
            </React.Fragment>)}
        </div>
    </>
}
