import { FC } from "react";
import { PageTitle } from "../../layout/core";
import { PaymentsView } from "../../modules/fee-management/components";

export const PaymentsWrapper: FC = () => {
    return (<>
        <PageTitle breadcrumbs={[]}>{'Receipts'}</PageTitle>
        <PaymentsView />
    </>)
}
