import { SlcmDefermentStatuscode } from "./enums/slcmDefermentStatuscode";

export * from "./Deferment";


export const getDefermentStatusCode = (status?: SlcmDefermentStatuscode) => {
    switch (status) {
        case SlcmDefermentStatuscode.Draft: return "Draft"
        case SlcmDefermentStatuscode.Accepted: return "Accepted"
        case SlcmDefermentStatuscode.Rejected: return "Rejected"
        case SlcmDefermentStatuscode.Submitted: return "Submitted"
        case SlcmDefermentStatuscode.Inactive: return "Inactive"
        default: return "";
    }
}
