import { FC } from "react"
import { PageTitle } from "../../../_metronic/layout/core";
import { DoNotDisturbIcon } from "../../assets/icons";

export const SettingsWrapper: FC = ()=>{
    return (<>
        <PageTitle breadcrumbs={[]}>{'Settings'}</PageTitle>
        <Settings></Settings>
    </>)
}

const Settings: FC = () => {
    return (
        <span className="fs-3 text-danger fw-bolder">
            <DoNotDisturbIcon /> Page under construction
        </span>
    )
}
