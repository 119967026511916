import { Contact, HallTicket } from "../../models";
import React, { useEffect, useState } from "react"

import { DownloadButton } from "../payments/DownloadButton";
import { NotificationIcon } from "../../assets/icons";
import moment from "moment";
import { useLazyGetAcademicPeriodSchedulesByPeriodIdQuery } from "../../services/academic-period-scheduled.api";
import { useLazyGetStudentHallTicketsQuery } from "../../services/hallticket.api";

const today = moment(new Date()).format('yyyy-MM-DD')

export const HallTicketsAlert = ({ contact }: {
    contact: Contact
}) => {
    const [getAcademicPeriodSchedules, { data: academicPeriodSchedules, isSuccess }]
        = useLazyGetAcademicPeriodSchedulesByPeriodIdQuery()
    const [scheduleIds, setScheduleIds] = useState<string[]>()
    const [getHallTickets] = useLazyGetStudentHallTicketsQuery();
    const [hallTickets, setHallTickets] = useState<HallTicket[]>();

    useEffect(() => {
        if (!contact.mshiedCurrentAcademicPeriodId?.id) return;
        getAcademicPeriodSchedules(contact.mshiedCurrentAcademicPeriodId?.id)
        
        // eslint-disable-next-line
    }, [contact.mshiedCurrentAcademicPeriodId])

    useEffect(() => {
        if (!isSuccess) return;

        const startDateFilter = moment(today).add(1, 'day').add(-1, 'minute')
        const endDateFilter = moment(today).add(-1, 'minute')

        const scheduleIds = academicPeriodSchedules?.filter(p =>
            (p.slcmBatch?.id === contact.slcmBatch?.id || !contact.slcmBatch?.id) &&
            moment(p.slcmStartdate).isBefore(startDateFilter) &&
            moment(p.slcmEndDate).isAfter(endDateFilter)
        ).map(p => p.slcmAcademicperiodscheduleId)

        setScheduleIds(scheduleIds)

        // eslint-disable-next-line
    }, [academicPeriodSchedules])

    useEffect(() => {
        if (!contact.contactId || !scheduleIds || scheduleIds.length === 0) return;

        getHallTickets(contact.contactId)
            .unwrap()
            .then((data) => {
                const hallTickets = data.filter(p =>
                    p.slcmFinalExamId?.id && scheduleIds.indexOf(p.slcmFinalExamId?.id) !== -1
                )
                setHallTickets(hallTickets)
            })
            
        // eslint-disable-next-line
    }, [contact, scheduleIds])

    return <>
        {hallTickets?.map(item => <React.Fragment key={item.slcmHallticketId}>
            <HallTickerAlertInner hallTicket={item} />
        </React.Fragment>)}
    </>
}

const HallTickerAlertInner = ({ hallTicket }: { hallTicket: HallTicket }) => {
    if (!hallTicket?.slcmHallTicketLink) {
        return <></>
    }

    return (
        <div className="alert bg-secondary d-flex flex-column flex-sm-row px-5 py-3 mb-0 fs-7">
            <NotificationIcon sx={{ fontSize: 40 }} className="text-primary me-3" />
            <div className="d-flex flex-column pe-0 pe-sm-10 text-gray-800">
                <h6 className="mb-1">{hallTicket.slcmFinalExamIdName}</h6>
                <div className="d-flex">
                    <span className="me-1 fw-semibold">Download your hall Ticket</span>
                </div>
            </div>
            <div className="flex-fill" />
            <div>
                <DownloadButton
                    className={"btn btn-primary btn-sm"}
                    label='Download'
                    link={hallTicket.slcmHallTicketLink} />
            </div>
        </div>
    )
}
