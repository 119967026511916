import { DemandHeader, DemandLine } from "../../../../models";
import React, { useState } from "react";

import { DefermentPayment } from "../../DefermentPayment";
import { IFeeBreakup } from "../../../../client-models";
import { Money } from "../../../../widgets-business";
import { useEffect } from "react";

interface IDemandTotal {
    amount: number,
    discount: number,
    netAmount: number,
    paidAmount: number,
    ptptnAmount: number,
    ptptnEstAmount: number,
    interimAmount: number,
    currentBalance: number
}

export interface ILineSelection {
    checked: boolean,
    value: number,
    interimAmount: number,
    type?: string,
    id: string
}

export const DemandLinesTable = ({ demand, demandLines, selection, onChange }: {
    demand: DemandHeader,
    demandLines: DemandLine[],
    selection: "None" | "Checkbox" | "Expand",
    onChange?: (amount: number, breakup: IFeeBreakup, selection: ILineSelection[]) => void
}) => {
    const getPaymentBreakup = (selectedLines: ILineSelection[]): IFeeBreakup => {
        // TODO: Fee grouping should be based on Fee Codes in Demand Lines

        let hostelFee = 0;
        selectedLines?.filter((data) => data.checked && data.type?.includes('Hostel'))
            .forEach(p => hostelFee += p.value)

        let tuitionFee = 0;
        selectedLines?.filter((data) => data.checked && data.type?.includes('Tuition '))
            .forEach(p => tuitionFee += p.value)

        let otherFee = 0;
        selectedLines?.filter((data) => data.checked).forEach(p => otherFee += p.value)
        otherFee = otherFee - hostelFee - tuitionFee;

        return { tuitionFee, hostelFee, otherFee }
    }

    return (
        <div className="table-responsive">
            <table className='table table-striped border mb-0'>
                <DemandLinesHeaderRow demand={demand} />
                <DemandLineRows
                    demand={demand}
                    lines={demandLines}
                    selection={selection}
                    onChange={(selection) => {
                        if (!onChange) return;

                        let amount = 0
                        selection?.filter(p => p.checked).forEach(p => amount += p.value)
                        const breakup = getPaymentBreakup(selection)

                        onChange(amount, breakup, selection)
                    }} />
                <DemandLinesTotalsRow
                    demand={demand}
                    lines={demandLines}
                    currencyId={demand.transactionCurrencyId?.id} />
            </table>
        </div>
    )
}

export const DemandLinesHeaderRow = ({ demand }: { demand: DemandHeader }) => {
    return (
        <thead>
            <tr className='border-bottom border-grey-200 text-primary fw-bolder'>
                <th className='px-2 w-50px'></th>
                <th className='px-2 min-w-150px'>Component</th>
                <th className='px-2 w-xl-100px'>Amount</th>
                <th className='px-2 w-xl-100px'>Discount</th>
                <th className='px-2 w-xl-100px'>Net Amount</th>
                <th className='px-2 w-xl-100px'>Paid Amount</th>
                {/* {(demand && !!demand?.slcmPTPTNAmount) &&
                    <th className='px-2 w-xl-100px'>PTPTN Amount</th>}
                {(demand && !!demand.slcmPTPTNEstimatedAmount) &&
                    <th className='px-2 w-xl-100px'>PTPTN Est. Amount</th>} */}
                <th className='px-2 w-xl-100px'>PTPTN estimated amount</th>
                <th className='px-2 w-xl-100px'>Net Payable</th>
            </tr>
        </thead>)
}

export const DemandLineRows = ({ demand, lines, selection, onChange }: {
    demand: DemandHeader,
    lines: DemandLine[],
    selection: "None" | "Checkbox" | "Expand",
    onChange?: (selectedLines: ILineSelection[]) => void
}) => {
    const [selectedLines, setSelectedLines] = useState<ILineSelection[]>();

    useEffect(() => {
        setSelectedLines(lines.map<ILineSelection>(p => {
            return {
                id: p.slcmDemandlineId,
                value: (p.slcmCurrentbalance || 0) - (p.slcmPTPTNEstimatedAmount || 0),
                interimAmount: p.slcmPTPTNEstimatedAmount || 0,
                type: p.slcmFeeComponentName,
                checked: false
            }
        }));
    }, [lines])

    const getSelectionInfo = (demandLineId: string) => {
        return selectedLines?.find(p => p.id === demandLineId);
    }

    const handleSelection = (data: ILineSelection) => {
        const newSelection = selectedLines?.map(p => p.id === data.id ? data : p);
        setSelectedLines(newSelection)

        if (onChange && newSelection) {
            onChange(newSelection)
        }
    }

    return (
        <tbody className="fw-semibold text-gray-600 fw-bold">
            {lines?.map((line) =>
                <React.Fragment key={line.slcmDemandlineId}>
                    <DemandLineRow
                        demand={demand}
                        line={line}
                        selection={selection}
                        selectionInfo={getSelectionInfo(line.slcmDemandlineId)}
                        onChange={handleSelection}
                    />
                </React.Fragment>
            )}
        </tbody>
    )
}

export const DemandLineRow = ({ demand, line, selection, selectionInfo, onChange }: {
    demand: DemandHeader,
    line: DemandLine,
    selection: "None" | "Checkbox" | "Expand",
    selectionInfo?: ILineSelection,
    onChange?: (selectionInfo: ILineSelection) => void
}) => {
    if (line.slcmDeferment === true) return <></>

    const balanceAmount = (line?.slcmCurrentbalance || 0) - (line.slcmPTPTNEstimatedAmount || 0)

    return (
        <>
            <tr>
                <td className='px-2 text-center'>
                    {((balanceAmount > 0) && selection !== "None") &&
                        <DemandLineCheckbox
                            id={`checkbox-${line.slcmDemandlineId}`}
                            selectionInfo={selectionInfo}
                            onChange={onChange} />}
                </td>
                <td className='px-2'>{line.slcmFeeComponentName}&nbsp;</td>
                <td className='px-2'>
                    <Money
                        currencyId={line?.transactionCurrencyId?.id}
                        amount={line?.slcmAmount} />
                </td>
                <td className='px-2'>
                    <Money
                        currencyId={line?.transactionCurrencyId?.id}
                        amount={line.slcmDiscount} />
                </td>
                <td className='px-2'>
                    <Money
                        currencyId={line?.transactionCurrencyId?.id}
                        amount={line?.slcmNetAmount} />
                </td>
                <td className='px-2'>
                    <Money
                        currencyId={line?.transactionCurrencyId?.id}
                        amount={line?.slcmPaidAmount} />
                </td>
                {/* {(demand && !!demand.slcmPTPTNAmount) &&
                    <td className='px-2'>
                        <Money
                            currencyId={line?.transactionCurrencyId?.id}
                            amount={line?.slcmPTPTNAmount} />
                    </td>}
                {(demand && !!demand.slcmPTPTNEstimatedAmount) &&
                    <td className='px-2'>
                        <Money
                            currencyId={line?.transactionCurrencyId?.id}
                            amount={line?.slcmPTPTNEstimatedAmount} />
                    </td>} */}
                <td className='px-2'>
                    <Money
                        currencyId={line?.transactionCurrencyId?.id}
                        amount={line?.slcmPTPTNEstimatedAmount} />
                </td>
                <td className='px-2'>
                    <Money
                        currencyId={line?.transactionCurrencyId?.id}
                        amount={(line?.slcmCurrentbalance || 0) - (line?.slcmPTPTNEstimatedAmount || 0)} />
                </td>
            </tr>
            {(selectionInfo?.checked && selection === "Expand") &&
                <tr>
                    <td colSpan={8} className="p-0 m-0">
                        <DefermentPayment
                            className="bg-light px-xl-10 p-3 m-0"
                            demandLineId={line.slcmDemandlineId} />
                    </td>
                </tr>
            }
        </>
    )
}

export const DemandLinesTotalsRow = ({ demand, lines, currencyId }: {
    demand: DemandHeader,
    lines?: DemandLine[],
    currencyId?: string
}) => {
    const [totals, setTotals] = useState<IDemandTotal>();

    useEffect(() => {
        if (!lines) return;

        const totals: IDemandTotal = {
            amount: 0,
            discount: 0,
            netAmount: 0,
            paidAmount: 0,
            ptptnAmount: 0,
            ptptnEstAmount: 0,
            interimAmount: 0,
            currentBalance: 0,
        };

        lines.forEach(line => {
            totals.amount += (line.slcmAmount || 0);
            totals.discount += (line.slcmDiscount || 0);
            totals.netAmount += (line.slcmNetAmount || 0);
            totals.paidAmount += (line.slcmPaidAmount || 0);
            totals.ptptnAmount += (line.slcmPTPTNAmount || 0);
            totals.ptptnEstAmount += (line.slcmPTPTNEstimatedAmount || 0);
            totals.interimAmount += (line.slcmPTPTNEstimatedAmount || 0);
            totals.currentBalance += (line.slcmCurrentbalance || 0);
        })

        setTotals(totals);
    }, [lines])

    if (!totals) return <></>;

    return (
        <tfoot>
            <tr className="text-gray-700 fw-bolder">
                <th className='px-2 w-50px'></th>
                <td className='px-2'>Total</td>
                <td className='px-2'><Money currencyId={currencyId} amount={totals.amount} /></td>
                <td className='px-2'><Money currencyId={currencyId} amount={totals.discount} /></td>
                <td className='px-2'><Money currencyId={currencyId} amount={totals.netAmount} /></td>
                <td className='px-2'><Money currencyId={currencyId} amount={totals.paidAmount} /></td>
                {/* {(demand && !!demand.slcmPTPTNAmount) &&
                    <td className='px-2'>
                        <Money
                            currencyId={currencyId}
                            amount={totals.ptptnAmount} />
                    </td>}
                {(demand && !!demand.slcmPTPTNEstimatedAmount) &&
                    <td className='px-2'>
                        <Money
                            currencyId={currencyId}
                            amount={totals.ptptnEstAmount} />
                    </td>} */}
                <td className='px-2'><Money currencyId={currencyId} amount={totals.interimAmount} /></td>
                <td className='px-2'><Money currencyId={currencyId} amount={totals.currentBalance - totals.interimAmount} /></td>
            </tr>
        </tfoot>
    )
}

export const DemandLineCheckbox = ({ id, selectionInfo, onChange }: {
    id: string,
    selectionInfo: ILineSelection | undefined,
    onChange?: (selectionInfo: ILineSelection) => void
}) => {
    const handlePayableAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!selectionInfo || !onChange) return;
        onChange({ ...selectionInfo, checked: e.target.checked })
    }

    const checked = selectionInfo?.checked || false;

    return <div className='form-check form-check-sm form-check-custom'>
        <input
            className='form-check-input'
            type="checkbox"
            checked={checked}
            name={id}
            id={id}
            onChange={handlePayableAmount} />
    </div>
}
