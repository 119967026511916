import { Money } from "../../../../widgets-business";

export const PayableAmount = ({ label, amount, currencyId, className }: {
    label: string,
    amount: number,
    currencyId?: string,
    className?: string
}) => {
    if (!amount || amount <= 0) return <></>;

    return (
        <div className={className} >
            <span className="fw-bold">{label}</span>
            <h3 className="text-danger my-0">
                <Money
                    currencyId={currencyId}
                    amount={amount} />
            </h3>
        </div>
    )
}