import { SlcmDemandlineStatuscode } from "./enums/slcmDemandLineStatusCode";

export * from "./DemandLine";


export const getDemandLineStatusCode = (statusCode?: SlcmDemandlineStatuscode) => {
    switch (statusCode) {
        case SlcmDemandlineStatuscode.Pending: return "Pending";
        case SlcmDemandlineStatuscode.Accepted: return "Accepted";
        case SlcmDemandlineStatuscode.Rejected: return "Rejected";
        default: return ""
    }
}