import { SlcmLearningmethod } from './enums/slcmLearningmethod';
export * from "./CourseSection";

export const getLearningModeLabel = (mode?: SlcmLearningmethod) => {
    switch (mode) {
        case SlcmLearningmethod.Lecture: return "Lecture";
        case SlcmLearningmethod.Exam: return "Exam";
        case SlcmLearningmethod.ClinicalPosting: return "ClinicalPosting";
        case SlcmLearningmethod.Practical: return "Practical";
        case SlcmLearningmethod.Tutorial: return "Tutorial";
        default:
            return "";
    }
} 