import { Link, useLocation } from 'react-router-dom'
import { checkIsActive, toAbsoluteUrl } from '../../../helpers'

import clsx from 'clsx'

export function MenuInner() {
  const { pathname } = useLocation()
  const to = '/dashboard';

  return (
    <>
      <div className='menu-item px-3'>
        <Link
          className={clsx('btn py-3', {
            active: checkIsActive(pathname, to),
          })}
          to={to}
        >
          <div className='d-flex align-items-center px-3'>
            <div className='me-5 logo-background'>
              <img alt='Logo' style={{height: "50px"}} src={toAbsoluteUrl('/media/logos/logo-2.png')} />
            </div>
            <div className='d-flex flex-column'>
              <div className='fw-bolder d-flex align-items-center fs-5'>
                <h2 className='m-0 text-danger'>{process.env.REACT_APP_HEADER}</h2>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  )
}
