import { CardPanel, CardPanelBody, CardPanelHeader, CardPanelToolbar, If, ListQueryStatus } from "../../widgets-core"

import { NotificationPanel } from "./NotificationPanel"
import React from "react"
import { useGetNotifications } from "../../hooks/notification-event.hooks"

export const NotificationsView = () => {
  const { notifications, isLoading, isError, isFetching, isSuccess, refetch } = useGetNotifications()

  return (
    <CardPanel className="bg-transparent">
      <CardPanelHeader
        className="min-h-5px"
        title="Notifications"
        subTitle="Alerts and notifications">
        <CardPanelToolbar>
          <button
            onClick={refetch}
            type="button"
            title="Refresh"
            className='btn btn-sm btn-link text-hover-primary p-0 ms-3'>
            <i className="las la-redo-alt fs-2"></i>
          </button>
        </CardPanelToolbar>
      </CardPanelHeader>
      <CardPanelBody className="pt-5">
        <div className='dashboard-card__body'>
          <ListQueryStatus
            isLoading={isLoading || isFetching}
            isSuccess={isSuccess}
            isError={isError}
            data={notifications} />

          <If condition={!isLoading && !isFetching && isSuccess}>
            {notifications.map(
              notification => <React.Fragment key={notification.slcmNotificationeventId}>
                <NotificationPanel
                  className='card card-body py-3 fs-7 mb-2'
                  notificationEvent={notification} />
              </React.Fragment>)}
          </If>
        </div>
      </CardPanelBody>
    </CardPanel>
  )
}