import clsx from "clsx";
import { FC } from "react";

export interface ICardPanelProps {
    className?: string,
}

export const CardPanel: FC<ICardPanelProps> = ({ className, children }) => {
    return (
        <div className={clsx('card', className)}>
            {children}
        </div>
    );
}