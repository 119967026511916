/* eslint-disable jsx-a11y/anchor-is-valid */

import { BadgeIcon, EmailIcon, GroupIcon, PhoneIcon, ProgramIcon, VerifiedIcon } from '../../assets/icons';
import { useUser, useUserContact } from '../../hooks/user';

import { Achievements } from './components/Achievements';
import { Documents } from './components/Documents';
import { Link } from 'react-router-dom';
import { Overview } from './components/overview/Overview';
import { PTPTNDetail } from './components/PTPTNDetail';
import { ProfileProgramDetailsView } from './components/program-details/ProgramDetailsView';
import React from 'react';
import { SemesterProgression } from './components/semester-progression/SemesterProgression';
import { SemesterRegistrations } from './components/semester-registrations';
import { StudentConnections } from './components/StudentConnections';
import { StudentHostelAvailed } from './components/hostel-availed/StudentHostelAvailed';
import { StudentInsuranceDetails } from './components/StudentInsuranceDetails';
import { StudentVisaHistory } from './components/visa-history/StudentVisaHistory';
import { toAbsoluteUrl } from '../../helpers';

export const ProfileHeader = () => {
  const contact = useUserContact()
  const { image } = useUser()

  if (!contact)
    return <></>

  return (
    <div className='card bg-transparent'>
      <div className='card-body'>
        <div className='card card-body mb-5'>
          <div className='d-flex flex-wrap flex-sm-nowrap justify-content-center'>
            <div className='me-7'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                {
                  image ?
                    <img alt='user' src={`data:image/bmp;base64,${image}`} /> :
                    <img alt='user' src={toAbsoluteUrl('/media/avatars/user.png')} />
                }
              </div>
              <div className='text-center'>
                <Link to={'/profile-image'} className="btn btn-link btn-sm">
                  Update Image
                </Link>
              </div>
            </div>

            <div className='flex-grow-1'>
              <div className='d-flex flex-wrap justify-content-between align-items-start'>
                <div className='mb-2'>
                  <label className='text-gray-700 fs-2 fw-bolder'>
                    {contact?.fullName}
                    <VerifiedIcon className='text-primary ms-2' />
                  </label>
                </div>
              </div>
              <div className='fs-6'>
                <div className='mb-1'>
                  <EmailIcon className='text-gray-700 me-1' />
                  <label className='min-w-xl-100px fw-bold text-gray-700 me-2'>Email:</label>
                  <a
                    href={`mailto:${contact?.eMailAddress1}`}
                    className='fw-bolder text-lowercase'>
                    {contact?.eMailAddress1}
                  </a>
                </div>
                <div className='mb-1'>
                  <PhoneIcon className='text-gray-700 me-1' />
                  <label className='min-w-xl-100px fw-bold text-gray-700 me-2'>Mobile:</label>
                  <a
                    href={`tel:${contact?.mobilePhone}`}
                    className='fw-bolder text-lowercase'>
                    {contact?.mobilePhone}
                  </a>
                </div>
                <div className='mb-1'>
                  <GroupIcon className='text-gray-700 me-1' />
                  <label className='min-w-xl-100px fw-bold text-gray-700 me-2'>Batch:</label>
                  <span className='fw-bolder text-gray-800'>
                    {contact?.slcmBatchName}
                  </span>
                </div>
                <div className='mb-1'>
                  <BadgeIcon className='text-gray-700 me-1' />
                  <label className='min-w-xl-100px fw-bold text-gray-700 me-2'>Roll Number:</label>
                  <span className='fw-bolder text-gray-800 text-uppercase'>
                    {contact?.slcmRollNumber}
                  </span>
                </div>
                <div className='mb-1'>
                  <ProgramIcon className='text-gray-700 me-1' />
                  <label className='min-w-xl-100px fw-bold text-gray-700 me-2'>Program:</label>
                  <span className='fw-bolder text-gray-800'>
                    {contact?.mshiedCurrentProgramIdName}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Overview
          className='mt-2'
          contact={contact} />

        <SemesterRegistrations
          className='mt-2'
          contact={contact} />

        <SemesterProgression
          className='mt-2'
          contact={contact} />

        <ProfileProgramDetailsView
          className='mt-2'
          contact={contact} />

        <StudentVisaHistory
          className='mt-2'
          contact={contact} />

        <StudentHostelAvailed
          className='mt-2'
          contact={contact} />

        <PTPTNDetail
          className='mt-2'
          contact={contact} />

        <Achievements
          className='mt-2'
          contact={contact} />

        <StudentInsuranceDetails
          className='mt-2'
          contact={contact} />

        <StudentConnections
          className='mt-2'
          contact={contact} />

        <Documents
          className='mt-2'
          entityId={contact.contactId} />
      </div>
    </div>
  )
}
