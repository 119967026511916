import { Payment } from "../models";
import { rootApi } from './api';

const paymentsApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getPayment: builder.query<Payment, string>({
            query: (id) => `/Payments/${id}`,
        }),

        getPayments: builder.query<Payment[], void>({
            query: () => `/Payments`,
        }),

        getPaymentsByStudentId: builder.query<Payment[], string>({
            query: (studentId) => `/Payments/student/${studentId}`,
        }),

        getPaymentByReceiptNo: builder.query<Payment, string>({
            query: (receiptNo) => `/Payments/receipt/${receiptNo}`,
        }),
    })
})

export const {
    useGetPaymentQuery,
    useGetPaymentsQuery,
    useGetPaymentsByStudentIdQuery,
    useLazyGetPaymentsByStudentIdQuery,
    useGetPaymentByReceiptNoQuery,
    useLazyGetPaymentByReceiptNoQuery,
} = paymentsApi;