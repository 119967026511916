import clsx from "clsx";
import { useGetTeamsQuery } from "../../../../services/teams.api";

export const SelectTeam = ({ value, className, onChange }: {
    value?: string,
    onChange?: (value: string) => void,
    className?: string,
}) => {
    const { data: teams } = useGetTeamsQuery();

    const SelectOptions = () => {
        return (<>
            <option></option>
            {teams?.map(
                team => <option
                    key={team.teamId}
                    value={team.teamId}>
                    {team.name}
                </option>)}
        </>)
    }

    return <select
        className={clsx(className, "form-select form-select-sm")}
        aria-label="Select team"
        value={value}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            onChange && onChange(event.target.value);
        }}
        placeholder={'Select team'}>
        {SelectOptions()}
    </select>
}