import clsx from "clsx";
import { useGetCampusesQuery } from "../../../../services/accounts.api";

export const SelectCampus = ({ value, onChange, className }: {
    value?: string,
    onChange?: (value: string) => void,
    className?: string,
}) => {
    const { data: campuses } = useGetCampusesQuery();

    const SelectOptions = () => {
        return (
            <>
                <option></option>
                {
                    campuses?.map(
                        campus => <option
                            key={campus.accountId}
                            value={campus.accountId}>
                            {campus.name}
                        </option>)
                }
            </>
        )
    }

    return (
        <select
            className={clsx(className, "form-select form-select-sm")}
            aria-label="Select campus"
            value={value}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                onChange && onChange(event.target.value);
            }}
            placeholder='Select campus'>
            {SelectOptions()}
        </select>)
}