import { SlcmBatch } from "./enums/slcmBatch";
import {SlcmContactSlcmResult} from "./enums/slcmContactSlcmResult"
import { SlcmContactSlcmPtptnstatus } from "./enums/slcmContactSlcmPtptnstatus";

export * from "./Contact";

export const getPTPTNStatusLabel = (status?: SlcmContactSlcmPtptnstatus) => {
    switch (status) {
        case SlcmContactSlcmPtptnstatus.Active: return "Active";
        case SlcmContactSlcmPtptnstatus.Inactive: return "Inactive";
        default: return "";
    }
}

export const getPTPTNContactStatusResult = (status?: SlcmContactSlcmResult) => {
    switch (status) {
        case SlcmContactSlcmResult.Pass: return "Pass";
        case SlcmContactSlcmResult.Fail: return "Pass";
        default: return "";
    }
}

export const getIntakeLabel = (batch?: SlcmBatch)=>{
    switch(batch){
        case SlcmBatch.September: return "September";
        case SlcmBatch.March: return "March";
        case SlcmBatch.April: return "April";
        case SlcmBatch.August: return "August";
        case SlcmBatch.October: return "October";
        case SlcmBatch.December: return "December";
        default: return "";
    }
}