/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react"
import { Link } from "react-router-dom";
import { PageTitle } from "../../../_metronic/layout/core";
import { CardPanel, CardPanelBody, CardPanelHeader } from "../../widgets-core";


export const PerformanceWrapper: FC = () => {
    return (<>
        <PageTitle breadcrumbs={[]}>{'Performance'}</PageTitle>
        <Performance></Performance>
    </>)
}

const Performance: FC = () => {
    return (
        <>
            {/* <span className="fs-3 text-danger fw-bolder">
                <DoNotDisturbIcon /> construction started
            </span> */}
                <CardPanel>
                    <CardPanelHeader title='Results' />
                    <CardPanelBody>
                        <div className="alert alert-primary" role="alert">
                            If You Want To Know Your Grades <a href="#" className="alert-link"> Click Here</a>
                        </div>
                        <div className="alert alert-primary" role="alert">
                            If You Want To Know Your Annual Progression Report <a href="#" className="alert-link"> Click Here</a>
                        </div>
                        <div className="alert alert-primary" role="alert">
                            If You Want To Know Your Assessment Scores <Link to="/assessmentscores" className="alert-link"> Click Here</Link>
                        </div>
                    </CardPanelBody>

                </CardPanel>
        </>
    )
}
