import { CardPanel, CardPanelBody, CardPanelHeader, Loading } from "../../../../widgets-core";
import { ChangeEvent, FC, useState } from "react"
import { useUserContact, useUser, useUpdateUserImage } from "../../../../hooks/user";

import { useNavigate } from "react-router-dom";
import { useUpdateEntityImageMutation } from "../../../../services/entity-image.api";

type FileData = string | ArrayBuffer | null;

export const UpdateProfileImage: FC = () => {
    const contact = useUserContact()
    const { image } = useUser()
    const [file, setFile] = useState<File>();
    const [imageUrl, setImageUrl] = useState<FileData>();
    const [saving, setSaving] = useState<boolean>(false);
    const [alert, setAlert] = useState<string>();
    const setUserProfileImage = useUpdateUserImage();
    const [updateEntityImage] = useUpdateEntityImageMutation()
    const navigate = useNavigate();

    const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.item(0);
        if (file) {
            setFile(file);

            const reader = new FileReader();
            reader.onloadend = () => {
                setImageUrl(reader.result);
            };

            reader.readAsDataURL(file);
        }
    }

    const handleUpdateImage = () => {
        if (!contact?.contactId || !imageUrl || !file) {
            return;
        }

        setSaving(true);
        setAlert('');

        const reader = new FileReader();
        reader.onloadend = () => {
            const content = reader.result;

            var base64 = btoa(
                new Uint8Array(content as ArrayBuffer)
                    .reduce((data, byte) => data + String.fromCharCode(byte), '')
            );

            updateEntityImage({
                entity: "contacts",
                id: contact.contactId,
                image: base64 as string
            }).then(() => {
                setUserProfileImage(base64);
                navigate('/profile/overview');
            }).catch((err) => {
                setAlert(err.message)
            }).finally(() => {
                setSaving(false);
            });
        };

        reader.readAsArrayBuffer(file)
    }


    return (
        <CardPanel>
            <CardPanelHeader title="Profile Image" subTitle="Update profile image" />
            <CardPanelBody>
                <div className="row">
                    <div className='col-xl-6'>
                        <div className='mb-1'>
                            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                                {image && <img alt='user' src={`data:image/bmp;base64,${image}`} />}
                            </div>
                        </div>
                        <div className='d-flex align-items-center mb-2'>
                            <span className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                                {contact?.fullName}
                            </span>
                        </div>
                    </div>
                    <div className='col-xl-6'>
                        <div className="d-flex mb-5">
                            <input type="file"
                                className="form-control form-control-sm me-5"
                                accept="image/bmp, image/jpg, image/jpeg"
                                onChange={onFileChange} />
                            <button
                                disabled={!file}
                                onClick={handleUpdateImage}
                                className="btn btn-primary btn-sm">Update</button>
                        </div>

                        <div className="text-center mb-5">
                            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                                {imageUrl && <img alt="profile" src={imageUrl as string} />}
                            </div>
                        </div>

                        {saving && <Loading message="Saving..." />}
                        {alert && <div className="alert alert-danger">{JSON.stringify(alert)}</div>}
                    </div>
                </div>
            </CardPanelBody>
        </CardPanel>

    )
}