import { CardPanel, CardPanelBody, CardPanelHeader, CardPanelToolbar, If, ListQueryStatus } from "../../widgets-core"
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { Contact, ServiceRequest, SlcmServicerequestsStatuscode } from "../../models";
import { DateFilterParams, DefaultGridColDef, formatDate } from "../../helpers/grid";
import { useCallback, useState } from "react"

import { AgGridReact } from "ag-grid-react";
import { Link } from "react-router-dom";
import React from "react";
import _ from "lodash";
import clsx from "clsx";
import { useGetServiceRequestsByStudentIdQuery } from "../../services/service-requests.api";

export const ServiceRequestsView = ({ contact, status, className }: {
    contact: Contact,
    status: SlcmServicerequestsStatuscode,
    className?: string
}) => {
    const { data: requests, isLoading, isFetching, isSuccess, isError, refetch }
        = useGetServiceRequestsByStudentIdQuery({ studentId: contact.contactId, status });

    const [columnDefs] = useState<ColDef[]>([
        {
            field: 'slcmCode',
            headerName: 'Request ID',
            filter: true,
            flex: 0,
            initialWidth: 100
        },
        {
            field: 'slcmName',
            filter: true,
            headerName: 'Request Category',
            cellRenderer: (props: ICellRendererParams) => {
                const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
                return <ServiceRequestLink request={props.data} text={cellValue} />
            },
        },
        {
            field: 'slcmDate',
            headerName: 'Date',
            resizable: true,
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
            filterParams: DateFilterParams,
            cellClass: 'dateISO fw-bold fs-7 text-gray-700',
            valueFormatter: (params) => formatDate(params.value),
            flex: 0,
            initialWidth: 100
        },
    ]);

    const getSortedData = useCallback(() => {
        return _.sortBy(requests, p => p.slcmCode).reverse();
    }, [requests]);

    return (
        <CardPanel className={clsx(className, "bg-transparent")}>
            <CardPanelHeader
                className="min-h-5px"
                title="Service Requests"
                subTitle="Your service requests">
                <CardPanelToolbar>
                    <button
                        onClick={refetch}
                        type="button"
                        title="Refresh"
                        className='btn btn-sm btn-link text-hover-primary p-0 me-3 my-1'>
                        <i className="las la-redo-alt fs-2"></i>
                    </button>

                    <Link to="/services" className='btn btn-sm btn-secondary my-1 '>
                        <i className="las la-plus-circle fs-2 me-1"></i>
                        New Service Request
                    </Link>
                </CardPanelToolbar>
            </CardPanelHeader>
            <CardPanelBody>
                <ListQueryStatus
                    isLoading={isLoading || isFetching}
                    isSuccess={isSuccess}
                    isError={isError}
                    data={requests} />

                <If condition={!isLoading && !isFetching && isSuccess && requests?.length > 0}>
                    <div className="ag-theme-alpine" style={{ height: 400, width: 'auto' }}>
                        <AgGridReact
                            rowData={getSortedData()}
                            columnDefs={columnDefs}
                            pagination={true}
                            serverSideInfiniteScroll={true}
                            paginationPageSize={10}
                            cacheBlockSize={10}
                            rowHeight={30}
                            defaultColDef={DefaultGridColDef}
                            columnTypes={{ 'dateColumn': { field: '' } }}
                        />
                    </div>
                </If>
            </CardPanelBody>
        </CardPanel>
    )
}

const ServiceRequestLink = ({ request, text }: { request: ServiceRequest, text: string }) => {
    if (request.slcmRequestTypeName === "Deferment") {
        if (request.slcmDemandNo?.id) {
            return <Link to={`/demands/view-demand/${request.slcmDemandNo?.id}`}>
                {text}
            </Link>
        } else {
            return <>{text}</>
        }
    }

    if (request.statuscode === SlcmServicerequestsStatuscode.Draft) {
        return <Link to={`/services/edit-service-request/${request.slcmServicerequestsId}`}>
            {text}
        </Link>
    } else {
        return <Link to={`/services/view-service-request/${request.slcmServicerequestsId}`}>
            {text}
        </Link>
    }
}
