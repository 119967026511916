import { FC } from "react"
import { DateRangeIcon, TimetableIcon } from "../../assets/icons";
import clsx from "clsx";
import moment from "moment";

type DateRangeProps = {
    startDate: Date,
    format?: "Date Time" | "Date Only" | "Time Only",
    endDate?: Date,
    className?: string,
    iconClassName?: string,
}

export const DateRange: FC<DateRangeProps> = ({ startDate, endDate, format, className, iconClassName }) => {
    const timeFormat = 'hh:mmA';
    const dateFormat = 'DD MMM YYYY';
    const dateTimeFormat = `${dateFormat} ${timeFormat}`;

    const dateFormatter = (format: string, date: Date) => moment(date).format(format);
    const toTimeFormat = (date: Date) => dateFormatter(timeFormat, date);
    const toDateFormat = (date: Date) => dateFormatter(dateFormat, date);
    const toDateTimeFormat = (date: Date) => dateFormatter(dateTimeFormat, date);

    const getDateRangeText = () => {
        switch (format) {
            case "Time Only":
                if (endDate) {
                    return `${toTimeFormat(startDate)} - ${toTimeFormat(endDate)}`
                } else {
                    return `${toTimeFormat(startDate)}`
                }
            case "Date Only":
                if (endDate) {
                    return `${toDateFormat(startDate)} - ${toDateFormat(endDate)}`
                } else {
                    return `${toDateFormat(startDate)}`
                }
            default:
                if (endDate) {
                    if (moment(startDate).startOf('day').toDate() === moment(endDate).startOf('day').toDate()) {
                        return `${toDateTimeFormat(startDate)} - ${toTimeFormat(endDate)}`
                    } else {
                        return `${toDateTimeFormat(startDate)} - ${toDateTimeFormat(endDate)}`
                    }
                } else {
                    return `${toDateTimeFormat(startDate)}`
                }
        }
    }

    return (
        startDate &&
        <div className={clsx(className)}>
            {format !== "Time Only" &&
                <DateRangeIcon className={clsx("me-2", iconClassName)} />
            }
            {format === "Time Only" &&
                <TimetableIcon className={clsx("me-2", iconClassName)} />
            }
            <span>
                {getDateRangeText()}
            </span>
        </div>
    )
}