import { ViewAppointment } from "../../modules/appointments";
import { useParams } from "react-router-dom";
import { useUserContact } from "../../hooks/user";

export const TimetableEventWrapper = () => {
    const { id }: { id?: string } = useParams();
    const contact = useUserContact()

    return (<>
        {(contact && id) && <ViewAppointment contact={contact} appointmentId={id!} />}
    </>)
}