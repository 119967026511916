// import { Form, Formik } from "formik";

import { AcademicPeriodScheduleView } from "../../modules/academic-period-scheduled/AcademicPeriodScheduleView";
import { ApiExecutionStatus } from "../../store/types";
import { FC } from "react";

type Props = {
    status?: ApiExecutionStatus | ApiExecutionStatus[],
    value?: string,
    className?: string,
    onChange?: (value: string) => void,
};

export const AcademicScheduleWrapper: FC<Props> = () => {
    return (
        <AcademicPeriodScheduleView />
    )
}

