import { CardPanel, CardPanelBody, CardPanelHeader } from "../../widgets-core"
import React, { useState } from "react"

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import clsx from "clsx"
import { useGetPaymentInstructionsQuery } from "../../services/payment-instructions.api";

export const PaymentInfoView = ({ className }: { className?: string }) => {
    const [key, setKey] = useState('domestic');
    const { data: paymentInstructions } = useGetPaymentInstructionsQuery();

    return <>
        <CardPanel className={clsx(className)}>
            <CardPanelHeader title="Payment Instructions" />
            <CardPanelBody className="mt-0">
                <Tabs
                    defaultActiveKey="domestic"
                    id="controlled-tab-example"
                    className="mb-3"
                    onSelect={(k) => setKey(k || '')}
                    activeKey={key}
                >
                    <Tab eventKey="domestic" title="Domestic">
                        <div className="story px-5" id="domestic">
                            {paymentInstructions &&
                                paymentInstructions.map((entity) =>
                                    <React.Fragment key={entity.slcmPaymentinstructionsId}>
                                        <div dangerouslySetInnerHTML={{ __html: entity.slcmDomesticStudentPaymentInstructions || "" }}></div>
                                    </React.Fragment>)}
                        </div>
                    </Tab>
                    <Tab eventKey="international" title="International">
                        <div className="story px-5" id="international">
                            {paymentInstructions &&
                                paymentInstructions.map((entity) =>
                                    <React.Fragment key={entity.slcmPaymentinstructionsId}>
                                        <div dangerouslySetInnerHTML={{ __html: entity.slcmInternationalStudentPaymentInstructions || "" }}></div>
                                    </React.Fragment>)}
                        </div>
                    </Tab>
                </Tabs>
            </CardPanelBody>
        </CardPanel>
    </>
}