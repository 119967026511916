import { FC } from "react"
import { PageTitle } from "../../../_metronic/layout/core";
import { ServicesView } from "../../modules/services";

export const ServicesWrapper: FC = () => {
    return (<>
        <PageTitle breadcrumbs={[]}>{'Services'}</PageTitle>
        <ServicesView />
    </>)
}
