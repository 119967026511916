import { CardPanel, CardPanelBody, CardPanelHeader, CardPanelToolbar, If, ListQueryStatus } from "../../../widgets-core"
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { DateFilterParams, DefaultGridColDef, formatDate, formatDateTime, formatTime } from "../../../helpers/grid";
import { Link, useNavigate } from "react-router-dom";
import { preferredDay, preferredTime } from "../helper";
import { useCallback, useState } from "react";

import { AgGridReact } from "ag-grid-react";
import { Contact } from "../../../models";
import _ from "lodash";
import clsx from "clsx";
import { useGetMentorAppointmentsQuery } from "../../../services/appointments.api";

export const AppointmentsView = ({ contact, className }: {
    contact: Contact
    className?: string,
}) => {
    const { data: appointments, isLoading, isError, isFetching, isSuccess, refetch } = useGetMentorAppointmentsQuery(contact.contactId);
    const navigate = useNavigate();
    const [columnDefs] = useState<ColDef[]>([
        {
            field: 'slcmPreferredDate',
            headerName: 'Preferred Date',
            resizable: true,
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
            filterParams: DateFilterParams,
            cellClass: 'dateISO fw-bold fs-7 text-gray-700',
            valueFormatter: (params) => formatDate(params.value),
            cellRenderer: (props: ICellRendererParams) => {
                const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
                return (
                    <Link to={`/appointments/view-appointment/${props.data.activityId}`}
                        className="text-uppercase">
                        {cellValue}
                    </Link>)
            },
        },
        {
            field: 'slcmPreferredDay',
            headerName: 'Preferred Day',
            valueFormatter: (params) => preferredDay(params.value),
        },
        {
            field: 'slcmPreferredTime',
            headerName: 'Preferred Time',
            valueFormatter: (params) => preferredTime(params.value),
        },
        {
            field: 'slcmCampusName',
            headerName: 'Campus',
            minWidth: 200,
            valueFormatter: () => contact?.slcmCampusName || '',
        },
        {
            field: 'slcmFaculty',
            headerName: 'Faculty',
            minWidth: 250,
            valueFormatter: () => contact?.mshiedAdvisorIdName || '',
        },
        {
            field: 'slcmAppointstatusFlag',
            headerName: 'Status',
        },
        {
            headerName: 'Scheduled Date & Time',
            minWidth: 200,
            valueFormatter: (params) => {
                return params.data.slcmAppointstatusFlag === "Confirm" ?
                    `${formatDateTime(params.data.scheduledStart)} - ${formatTime(params.data.scheduledEnd)}` :
                    '';
            }
        }
    ]);

    const getSortedData = useCallback(() => {
        return _.orderBy(appointments, p => p.slcmPreferredDate, ['asc']);
    }, [appointments]);

    return <>
        <CardPanel className={clsx(className, "bg-transparent")}>
            <CardPanelHeader className="min-h-5px"
                title="Appointments"
                subTitle="Faculty / Mentor appointments">
                <CardPanelToolbar>
                    <button
                        onClick={refetch}
                        type="button"
                        title="Refresh"
                        className='btn btn-sm btn-link text-hover-primary p-0 my-1 me-3'>
                        <i className="las la-redo-alt fs-2"></i>
                    </button>

                    <button
                        className="btn btn-sm btn-secondary my-1"
                        onClick={() => navigate("/appointments/new-appointment")}
                    >
                        <i className="las la-plus-circle fs-2 me-1"></i>
                        New Appointment
                    </button>
                </CardPanelToolbar>
            </CardPanelHeader>
            <CardPanelBody>
                <ListQueryStatus
                    isLoading={isLoading || isFetching}
                    isSuccess={isSuccess}
                    isError={isError}
                    data={appointments} />

                <If condition={!isLoading && !isFetching && isSuccess && appointments?.length > 0}>
                    <div className="ag-theme-alpine" style={{ height: 400, width: 'auto' }}>
                        <AgGridReact
                            rowData={getSortedData()}
                            columnDefs={columnDefs}
                            pagination={true}
                            serverSideInfiniteScroll={true}
                            paginationPageSize={10}
                            cacheBlockSize={10}
                            rowHeight={30}
                            defaultColDef={DefaultGridColDef}
                            columnTypes={{ 'dateColumn': { field: '' } }}
                        />
                    </div>
                </If>
            </CardPanelBody>
        </CardPanel>
    </>
}