import { DemandHeader, DemandLine } from "../../../../models";
import { If, ListQueryStatus } from "../../../../widgets-core";
import { useEffect, useState } from "react";

import { DemandLinesTable } from "./DemandLinesTable";
import { DemandPayDefermentView } from "./DemandPayDefermentView";
import { DemandPayOfflineView } from "./DemandPayOfflineView";
import { DemandPayOnlineView } from "./DemandPayOnlineView";
import { DownloadButton } from "../../../payments/DownloadButton";
import clsx from "clsx";
import { useGetDemandLinesByDemandIdQuery } from "../../../../services/demand-lines.api";

type DemandLinesView = "Default" | "Offline" | "Online" | "Deferment";
export const DemandLinesToggleView = ({ demand, onChange, refreshCallback, className }: {
    demand: DemandHeader,
    refreshCallback?: (refresh: () => void) => void,
    onChange?: () => void,
    className?: string
}) => {
    const { data: lines, refetch, isLoading, isFetching, isSuccess, isError } = useGetDemandLinesByDemandIdQuery(demand.slcmDemandheaderId);
    const [view, setView] = useState<DemandLinesView>("Default");

    const payableAmount = (demand.slcmCurrentbalance || 0) - (demand.slcmInterimAmountPaid || 0);
    
    useEffect(() => {
        refreshCallback && refreshCallback(refetch)
        // eslint-disable-next-line
    }, [refetch])

    return (
        <div className={className}>
            <DemandLinesToolbar
                payableAmount={payableAmount}
                downloadLink={demand?.slcmLink}
                currency={demand?.transactionCurrencyIdName}
                view={view}
                refetch={refetch}
                onChange={setView} />

            <ListQueryStatus
                isLoading={isLoading || isFetching}
                isSuccess={isSuccess}
                isError={isError}
                data={lines} />

            <If condition={!isLoading && !isFetching && isSuccess}>
                <PageView
                    onChange={() => {
                        onChange && onChange()
                        setView("Default")
                    }}
                    view={view}
                    demand={demand}
                    demandLines={lines || []} />
            </If>
        </div>
    )
}

const PageView = ({ view, demand, demandLines, onChange, className }: {
    view: DemandLinesView,
    demand: DemandHeader,
    demandLines: DemandLine[],
    onChange?: () => void,
    className?: string
}) => {
    switch (view) {
        case 'Offline':
            return <DemandPayOfflineView
                demand={demand}
                demandLines={demandLines}
                onChange={onChange}
                className={className} />
        case 'Online':
            return <DemandPayOnlineView
                demand={demand}
                demandLines={demandLines}
                onChange={onChange}
                className={className} />
        case 'Deferment':
            return <DemandPayDefermentView
                demand={demand}
                demandLines={demandLines}
                className={className} />
        default:
            return <DemandLinesTable
                demand={demand}
                demandLines={demandLines}
                selection={"None"} />
    }
}

const DemandLinesToolbar = ({ payableAmount, currency, downloadLink, view, onChange, refetch }: {
    payableAmount: number,
    downloadLink?: string,
    view: DemandLinesView,
    currency?: string,
    onChange: (view: DemandLinesView) => void,
    refetch?: () => void,
}) => {

    return (
        <div className="d-flex flex-wrap justify-content-end align-items-center mb-2">
            <h5 className="text-gray-700 flex-fill my-1">Fee Components</h5>
            <div className="my-1">
                {(payableAmount > 0) &&
                    <>
                        {currency === "USD" &&
                            <button className={clsx('btn btn-sm me-3 my-1',
                                { 'btn-secondary': view !== 'Online' },
                                { 'btn-primary': view === 'Online' })}
                                value={"Online"}
                                type="button"
                                onClick={() => { onChange(view !== "Online" ? "Online" : "Default") }}>
                                <i className="bi bi-credit-card me-1"></i>
                                Pay Online
                            </button>}

                        <button className={clsx(
                            'btn btn-sm me-3 my-1',
                            { 'btn-secondary': view !== 'Offline' },
                            { 'btn-primary': view === 'Offline' })}
                            value={"Offline"}
                            type="button"
                            onClick={(e) => {
                                e.stopPropagation()
                                onChange(view !== "Offline" ? "Offline" : "Default")
                            }}>
                            <i className="las la-building me-1"></i>
                            Pay Offline
                        </button>

                        <button className={clsx(
                            'btn btn-sm me-3 my-1',
                            { 'btn-secondary': view !== 'Deferment' },
                            { 'btn-primary': view === 'Deferment' })}
                            value={"Offline"}
                            type="button"
                            onClick={(e) => {
                                e.stopPropagation()
                                onChange(view !== "Deferment" ? "Deferment" : "Default")
                            }}>
                            <i className="las la-calendar me-1"></i>
                            Create Deferment Plan
                        </button>
                    </>
                }

                <DownloadButton
                    label="Download"
                    link={downloadLink}
                    className="btn btn-sm btn-secondary my-1" />
                <button
                    onClick={refetch}
                    type="button"
                    title="Refresh"
                    className='btn btn-sm btn-link text-hover-primary p-0 ms-3'>
                    <i className="las la-redo-alt fs-2"></i>
                </button>
            </div>
        </div >
    )
}