import { FC } from "react"
import { useParams } from "react-router-dom";
import { PageLink, PageTitle } from "../../layout/core";
import { EditServiceRequest } from "../../modules/service-request/edit-service-request";

export const EditServiceRequestPageWrapper: FC = () => {
    const pageLinks: PageLink[] = [
        { title: 'Services', isActive: false, path: "/services" },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]

    return (<>
        <PageTitle breadcrumbs={pageLinks}>{'Service Request'}</PageTitle>
        <EditServiceRequestPage />
    </>)
}

const EditServiceRequestPage = () => {
    const { serviceId, }: { serviceId?: string } = useParams();

    if (!serviceId) return <></>;

    return <EditServiceRequest requestId={serviceId} />
}
