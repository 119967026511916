import { KTSVG } from "../../../helpers"
import clsx from "clsx";

export const StepsSubmitButton = ({ label, saving, stepIndex, totalSteps, enableSubmit, className }: {
    saving: boolean,
    stepIndex?: number,
    totalSteps?: number,
    enableSubmit?: boolean,
    label?: string,
    className?: string
}) => {
    const disable = (stepIndex === totalSteps && enableSubmit === false) || saving;

    return (
        <button type='submit'
            disabled={disable}
            className={clsx(className, 'btn btn-lg btn-primary')}>
            <span className='indicator-label'>
                {!saving && <>
                    {(stepIndex !== totalSteps) && <>
                        Save & Continue
                        <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-3 ms-2 me-0' />
                    </>}
                    {stepIndex === totalSteps && <>
                        <i className="las la-share fs-2x me-1"></i>
                        {label || 'Submit Registration'}
                    </>}
                </>}

                {saving && <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>}
            </span>
        </button>)
}
