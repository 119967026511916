import { configureStore } from '@reduxjs/toolkit';
import { rootApi } from '../services/api';
import { useDispatch } from "react-redux";

const store = configureStore({
    reducer: {
        [rootApi.reducerPath]: rootApi.reducer,
    },
    devTools: false,
    enhancers: [],
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware()
            .concat(rootApi.middleware)
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export default store;
