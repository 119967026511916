/* eslint-disable jsx-a11y/anchor-is-valid */

import { AssociatedDocument, DemandHeader, DemandLine } from "../../../../models";
import { DemandLinesTable, ILineSelection } from "./DemandLinesTable";
import React, { useState } from "react"

import { OfflineUploadDocument } from '../../../registrations/OfflineUploadDocument'
import { PayableAmount } from "./PayableAmount";
import { SlcmDemandlineSlcmModeofpayment } from "../../../../models/demand-lines/enums/slcmDemandlineSlcmModeofpayment";
import clsx from "clsx";
import { contactFolderPath } from '../../../../helpers'
import { useUpdateDemandLineMutation } from "../../../../services/demand-lines.api";
import { useUserContact } from '../../../../hooks/user'

export const DemandPayOfflineView = ({ demand, demandLines, onChange, className }: {
    demand: DemandHeader,
    demandLines: DemandLine[],
    onChange?: () => void,
    className?: string,
}) => {
    const contact = useUserContact()
    const [updateDemandLine] = useUpdateDemandLineMutation();

    const [amount, setAmount] = useState<number>(0);
    const [selection, setSelection] = useState<ILineSelection[]>([])
    const folderPath = contact ? `${contactFolderPath(contact)}/${demand?.slcmDemandNo}` : '';
    const [completed, setCompleted] = useState<boolean>(false);
    const [alertMsg, setAlertMsg] = useState<string>();

    const updateInterimAmounts = (document: AssociatedDocument) => {
        const promises: Promise<void>[] = []
        let remainingAmount = document.slcmAmountDeposited || 0;

        selection.forEach(item => {
            if (item.checked) {
                const amount = item.value > remainingAmount ? remainingAmount : item.value;
                remainingAmount -= amount; 

                const demandLine: Partial<DemandLine> = {
                    slcmInterimAmountPaid: item.interimAmount + amount,
                    slcmModeofPayment: SlcmDemandlineSlcmModeofpayment.Offline
                }
                const promise = updateDemandLine({ id: item.id, entity: demandLine }).unwrap()
                promises.push(promise);
            }
        })

        Promise.all(promises)
            .then(() => {
                onChange && onChange();
                setCompleted(true)
            })
            .catch(() => setAlertMsg('Could not update payment details'))
    }

    return (
        <div className={className}>
            <DemandLinesTable
                demand={demand}
                demandLines={demandLines}
                selection={"Checkbox"}
                onChange={(amount, breakup, selection) => {
                    setAmount(amount)
                    setSelection(selection)
                }}
            />

            {alertMsg && <div className="alert alert-danger my-2">{alertMsg}</div>}

            {!completed &&
                <PayOfflineDetails
                    className="my-5"
                    amount={amount}
                    demand={demand}
                    onComplete={(document) => updateInterimAmounts(document)}
                    folderPath={folderPath} />
            }
        </div>
    )
}

const PayOfflineDetails = ({ amount, demand, folderPath, onComplete, className }: {
    amount: number,
    demand: DemandHeader,
    folderPath: string,
    onComplete?: (document: AssociatedDocument) => void,
    className?: string,
}) => {
    if (amount <= 0) return <></>

    return <div className={clsx("d-flex flex-wrap", className)}>
        <PayableAmount
            className="text-gray-700 me-5 my-1"
            label="Deposit Amount"
            amount={amount}
            currencyId={demand.transactionCurrencyId?.id} />

        <div className="flex-fill my-1">
            <OfflineUploadDocument
                associatedEntity={{
                    entity: "Demand",
                    demandId: demand.slcmDemandheaderId
                }}
                amount={amount}
                onComplete={onComplete}
                folderPath={folderPath} />
        </div>
    </div>;
}
