import { ApiTags, rootApi } from './api';

import { PaymentTransaction } from '../models';

const TAG = ApiTags.PaymentTransaction;
const paymentTransactionsApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getPaymentTransactionByRef: builder.query<PaymentTransaction[], string>({
            query: (referenceNo) => `/PaymentTransactions/reference(${referenceNo})`,
            providesTags: (result, err, referenceNo) =>
                result ?
                    [...result.map((p) => ({ type: TAG, id: p.slcmPaymenttransactionId })),
                    { type: TAG, ref: referenceNo }, TAG] :
                    [{ type: TAG, ref: referenceNo }, TAG]
        }),

        getPaymentTransaction: builder.mutation<PaymentTransaction, string>({
            query: (id) => `/PaymentTransactions/${id}`,
            invalidatesTags: (result, error, id) =>
                result ?
                    [{ type: TAG, id },
                    { type: TAG, ref: result?.slcmExternalReference }] :
                    [{ type: TAG, id }]
        }),

        createPaymentTransaction: builder.mutation<PaymentTransaction, Partial<PaymentTransaction>>({
            query: (entity) => ({
                url: '/PaymentTransactions',
                method: 'POST',
                body: entity
            }),
            invalidatesTags: [TAG]
        }),

        updatePaymentTransaction: builder.mutation<PaymentTransaction, { id: string, entity: Partial<PaymentTransaction> }>({
            query: ({ id, entity }) => ({
                url: `/PaymentTransactions/${id}`,
                method: 'PUT',
                body: entity
            }),
            invalidatesTags: [TAG]
        }),

        deletePaymentTransaction: builder.mutation<void, string>({
            query: (id: string) => ({
                url: `/PaymentTransactions/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [TAG]
        }),
    })
})

export const {
    useGetPaymentTransactionByRefQuery,
    useGetPaymentTransactionMutation,
    useCreatePaymentTransactionMutation,
    useUpdatePaymentTransactionMutation,
    useDeletePaymentTransactionMutation,
} = paymentTransactionsApi;