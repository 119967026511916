import { FC } from "react"
import { RequestField } from "../../../../client-models"
import { SelectCollectionType } from "./SelectCollectionType"
import { SelectCourseFlag } from "./SelectCourseFlag"
import { SelectRegistrationType } from "./SelectRegistrationType"
import { SelectRepeatSemester } from "./SelectRepeatSemester"
import { SelectSemester } from "./SelectSemester"
import { SelectSemesterBreak } from "./SelectSemesterBreak"
import { SelectYear } from "./SelectYear"

type Props = {
    field: RequestField,
    value: number,
    onChange: (value: number) => void,
}
export const RequestOptionSetField: FC<Props> = ({
    field,
    value,
    onChange,
}) => {
    switch (field.target) {
        case "slcmCollectionType":
            return <SelectCollectionType
                value={value}
                onChange={onChange} />
        case "slcmRegistrationType":
            return <SelectRegistrationType
                value={value}
                onChange={onChange} />
        case "slcmRepeatSemester":
            return <SelectRepeatSemester
                value={value}
                onChange={onChange} />
        case "slcmYear":
            return <SelectYear
                value={value}
                onChange={onChange} />
        case "slcmCourseFlag":
            return <SelectCourseFlag
                value={value}
                onChange={onChange} />
        case "slcmSemesterBreak":
            return <SelectSemesterBreak
                value={value}
                onChange={onChange} />
        case "slcmSemester":
            return <SelectSemester
                value={value}
                onChange={onChange} />
        default:
            return <label>{field.name}</label>
    }
}