import { Money } from "../../widgets-business";
import { ServiceRequest } from "../../models";

export const ServiceRequestNetPayable = ({ label, amount, currencyId, className, serviceRequest }: {
    label: string,
    amount?: number,
    currencyId?: string,
    className?: string,
    serviceRequest?: ServiceRequest
}) => {

    if (!serviceRequest?.slcmTotalFeeAmount || serviceRequest?.slcmTotalFeeAmount === 0)
        return <></>

    return (
        <div className="text-left text-gray-600 py-3 me-6" >
            <span className="fw-bold">{label}</span>
            <h3 className="text-danger my-0 text-left">
                <Money
                    currencyId={serviceRequest.transactionCurrencyId?.id}
                    amount={amount} />
            </h3>
        </div>
    )
}