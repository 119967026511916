import 'react-big-calendar/lib/css/react-big-calendar.css';

import { Appointment, Attendance, MshiedIsPresent, getLearningModeLabel } from '../../../models';
import { Calendar, EventWrapperProps, momentLocalizer } from 'react-big-calendar';
import { FC, useEffect, useState } from "react";

import { AppointmentEvent } from './models';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import moment from "moment";
import { useLazyGetCourseSectionQuery } from '../../../services/course-sections.api';

const localizer = momentLocalizer(moment);

export const TimetableCalendarView = ({ appointments, attendances, className }: {
    appointments: Appointment[],
    attendances: Attendance[],
    className?: string,
}) => {
    type DataState = { isAvailable: boolean, data?: AppointmentEvent[] }
    const [dataState, setDataState] = useState<DataState>({ isAvailable: false });

    useEffect(() => {
        setDataState({ isAvailable: false, data: [] });
    }, [appointments, attendances]);

    useEffect(() => {
        if (dataState.isAvailable) return;

        const events = appointments.map<AppointmentEvent>(appointment => {
            const dayAttendance = attendances.find(attendance => {
                if (attendance.mshiedCourseSectionID?.id !== appointment.slcmCourseSection?.id) {
                    return false;
                }

                return moment(appointment.scheduledStart).format("yyyy-MM-DD") ===
                    moment(attendance.mshiedAttendanceDate).format("yyyy-MM-DD");
            });

            return {
                start: appointment.scheduledStart,
                end: appointment.scheduledEnd,
                appointment: appointment,
                isPresent: dayAttendance?.mshiedIsPresent
            }
        });

        setDataState({ isAvailable: true, data: events });
    }, [appointments, attendances, dataState, setDataState])

    if (!dataState.isAvailable) return <></>;

    return (
        <div className={clsx("h-600px", className)}>
            <Calendar
                components={{ eventWrapper: CalendarEvent }}
                popup={true}
                toolbar={false}
                localizer={localizer}
                events={dataState.data}
                startAccessor="start"
                endAccessor="end"
            />
        </div>
    );
}

type Props = EventWrapperProps<AppointmentEvent>;
const CalendarEvent: FC<Props> = ({ event: { isPresent, appointment } }) => {
    const [getCourseSection, { data: courseSection }] = useLazyGetCourseSectionQuery();

    useEffect(() => {
        if (appointment.slcmCourseSection?.id)
            getCourseSection(appointment.slcmCourseSection?.id);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointment.slcmCourseSection?.id])

    const getTextColor = () => {
        switch (isPresent) {
            case MshiedIsPresent.Yes:
                return "text-inverse-success";
            case MshiedIsPresent.No:
                return "text-inverse-danger";
            default:
                if (!appointment.scheduledStart) {
                    return "text-inverse-danger";
                }

                const today = new Date();
                today.setHours(0, 0, 0, 0);
                return moment(appointment.scheduledStart).isBefore(moment(today)) ?
                    "text-inverse-danger" : "text-inverse-secondary";
        }
    }

    const getBgColor = () => {
        switch (isPresent) {
            case MshiedIsPresent.Yes:
                return "bg-success";
            case MshiedIsPresent.No:
                return "bg-danger";
            default:
                if (!appointment.scheduledStart) {
                    return "bg-secondary";
                }

                const today = new Date();
                today.setHours(0, 0, 0, 0);
                return moment(appointment.scheduledStart).isBefore(moment(today)) ?
                    "bg-danger" : "bg-secondary";
        }
    }

    const getTitle = () => {
        const data = [];

        if (courseSection?.mshiedCourseIdName)
            data.push(courseSection?.mshiedCourseIdName);

        if (!courseSection?.mshiedCourseIdName && appointment.subject)
            data.push(appointment.subject);

        if (courseSection?.slcmLearningMethod)
            data.push(getLearningModeLabel(courseSection?.slcmLearningMethod));

        if (courseSection?.slcmInstructorName)
            data.push(courseSection?.slcmInstructorName);

        if (appointment.slcmLocationName)
            data.push(appointment.slcmLocationName);

        if (appointment.scheduledStart && appointment.scheduledEnd) {
            const start = moment(appointment.scheduledStart);
            const end = moment(appointment.scheduledEnd);
            
            if (start.format("yyyy-MM-DD") === end.format("yyyy-MM-DD")) {
                data.push(`${start.format("hh:mmA")} - ${end.format("hh:mmA")}`)
            } else {
                data.push(`${start.format("yyyy-MM-DD hh:mmA")} - ${end.format("yyyy-MM-DD hh:mmA")}`)
            }
        }

        return data.join(" | ");
    }

    return (
        <div className={clsx(
            getBgColor(),
            getTextColor(),
            "lh-1 pointer text-truncate p-2 m-1 fs-8")}>
            <Link
                to={`/timetable/event/${appointment.activityId}`}
                className={clsx(getTextColor(), 'text-hover-primary px-1')}
                title={getTitle()}>
                <span className="me-1">
                    {courseSection?.mshiedCourseIdName || appointment.subject}
                </span>
                <span className="me-1">
                    {courseSection?.slcmInstructorName}
                </span>
                <span>{appointment.slcmLocationName}</span>
            </Link>
        </div>
    )
}
