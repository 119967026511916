import { SlcmStudentledgerSlcmDocumenttype } from "./enums/slcmStudentledgerSlcmDocumenttype";

export * from "./StudentLedger";

export const getDocumentTypeLabel = (statusCode?: SlcmStudentledgerSlcmDocumenttype) => {
    switch (statusCode) {
        case SlcmStudentledgerSlcmDocumenttype.Demand: return "Demand";
        case SlcmStudentledgerSlcmDocumenttype.Receipt: return "Receipt";
        default: return "";
    }
}