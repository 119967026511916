import { CardPanelHeader, CardPanelToolbar, If, ListQueryStatus } from "../../../widgets-core"
import { ServiceRequestComment, SlcmServiceRequestCommentSlcmCommentedBy } from "../../../models"
import { useCreateCommentMutation, useGetCommentsByRegistrationIdQuery } from "../../../services/service-request-comments.api"
import { useEffect, useState } from "react"

import clsx from "clsx"
import moment from "moment"
import { toAbsoluteUrl } from "../../../helpers"
import { useLazyGetEntityImageQuery } from "../../../services/entity-image.api"
import { useUserContact } from "../../../hooks/user"

export const SemesterRegComments = ({ requestId, className }: {
    requestId: any,
    className?: string
}) => {
    const contact = useUserContact()
    const [message, setMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const { data: comments, isLoading, isError, isFetching, isSuccess, refetch } = useGetCommentsByRegistrationIdQuery(requestId);
    const [addServiceRequestComment] = useCreateCommentMutation();

    const sendMessage = () => {
        if (!contact?.contactId || !message) return;
        setLoading(true);

        const comment: Partial<ServiceRequestComment> = {
            slcmComment: message,
            slcmCommentDate: new Date(),
            slcmCommentedBy: SlcmServiceRequestCommentSlcmCommentedBy.Student,
            slcmStudent: { id: contact?.contactId, logicalName: "contact" },
            slcmSemesterRegistrationId: { id: requestId, logicalName: "mshied_academicperioddetails" }
        }

        addServiceRequestComment(comment)
            .then(() => setMessage(''))
            .finally(() => setLoading(false))
    }

    const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault()
            sendMessage()
        }
    }

    return (<>
        <div className={clsx('card', className)}>
            <CardPanelHeader title="Comments" className="min-h-5px">
                <CardPanelToolbar>
                    <button
                        onClick={refetch}
                        type="button"
                        title="Refresh"
                        className='btn btn-sm btn-link text-hover-primary p-0 ms-3'>
                        <i className="las la-redo-alt fs-2"></i>
                    </button>
                </CardPanelToolbar>
            </CardPanelHeader>
            <div className='card-body pt-0'>
                <ListQueryStatus
                    isLoading={isLoading || isFetching}
                    isSuccess={isSuccess}
                    isError={isError}
                    data={['comments']} />

                <If condition={!isLoading && !isFetching && isSuccess}>
                    <div className={clsx('scroll-y me-n5 pe-5 mh-300px')}>
                        {comments && comments.slice()
                            .sort((a, b) => moment(a.createdOn).diff(b.createdOn))
                            .map((message) => (
                                <ServiceRequestCommentLine
                                    comment={message}
                                    key={`message${message.slcmServicerequestcommentId}`} />
                            ))}
                    </div>

                    <div className='card-footer pt-4 pb-0 border-0 px-0'>
                        <div className='d-flex flex-stack'>
                            <textarea
                                className='form-control form-control-flush mb-3 me-3'
                                rows={1}
                                data-kt-element='input'
                                placeholder='Type a comment'
                                value={message}
                                name={'comment'}
                                onChange={(e) => setMessage(e.target.value)}
                                onKeyDown={onEnterPress}
                            ></textarea>

                            <button
                                className='btn btn-primary'
                                type='button'
                                disabled={loading}
                                data-kt-element='send'
                                onClick={sendMessage}
                            >
                                {!loading && <span className='indicator-label'>Send</span>}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </div>
                </If>
            </div>
        </div>
    </>)
}

const ServiceRequestCommentLine = ({ comment }: { comment: ServiceRequestComment }) => {
    const messageType = comment.slcmCommentedBy === SlcmServiceRequestCommentSlcmCommentedBy.Staff ? 'in' : 'out';
    const state = messageType === 'in' ? 'info' : 'primary'
    const contentClass = `d-flex justify-content-${messageType === 'in' ? 'start' : 'end'} mb-2`;

    return (
        <div className={clsx('d-flex', contentClass, 'mb-2')}>
            <div
                className={clsx(
                    'd-flex flex-column align-items',
                    `align-items-${messageType === 'in' ? 'start' : 'end'}`
                )}
            >
                <div className='d-flex align-items-center mb-2'>
                    {messageType === 'in' ? <StaffInfo message={comment} /> : <StudentInfo comment={comment} />}
                </div>

                <div
                    className={clsx(
                        'p-2 px-5 rounded',
                        `bg-light-${state}`,
                        'text-gray-700 fw-bold ',
                        `text-${messageType === 'in' ? 'start' : 'end'}`
                    )}
                    dangerouslySetInnerHTML={{ __html: comment.slcmComment || '' }}
                />
            </div>
        </div>
    )
}

const StudentInfo = ({ comment }: { comment: ServiceRequestComment }) => {
    const [getEntityImage, { data: image }] = useLazyGetEntityImageQuery();

    useEffect(() => {
        if (comment.slcmStudent?.id)
            getEntityImage({ id: comment.slcmStudent?.id, entity: "contacts" })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [comment.slcmStudent?.id])

    return (
        <>
            <div className='me-3'>
                <span className='text-muted fs-7 mb-1'>
                    {moment(comment.createdOn).fromNow()}
                </span>
                <span
                    className='fs-6 fw-bolder text-gray-700 text-hover-primary ms-1'
                >
                    You
                </span>
            </div>
            <div className='symbol symbol-25px symbol-circle '>
                {image ?
                    <img alt='user' src={`data:image/bmp;base64,${image}`} /> :
                    <img alt='user' src={toAbsoluteUrl('/media/avatars/user.png')} />}
            </div>
        </>
    )
}

const StaffInfo = ({ message }: { message: ServiceRequestComment }) => {
    const [getEntityImage, { data: image }] = useLazyGetEntityImageQuery();

    useEffect(() => {
        if (message.slcmStaff?.id)
            getEntityImage({ id: message.slcmStaff?.id, entity: "systemusers" })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message.slcmStaff?.id])

    return (
        <>
            <div className='symbol symbol-25px symbol-circle '>
                {image ?
                    <img alt='User' src={`data:image/bmp;base64,${image}`} /> :
                    <img alt='User' src={toAbsoluteUrl('/media/avatars/user.png')} />}
            </div>
            <div className='ms-3'>
                <span
                    className='fs-6 fw-bolder text-gray-700 text-hover-primary me-1'
                >
                    {message.slcmStaffName}
                </span>
                <span className='text-muted fs-7 mb-1'>
                    {moment(message.createdOn).fromNow()}
                </span>
            </div>
        </>
    )
}
