import { Contact, getPTPTNContactStatusResult, getPTPTNStatusLabel } from '../../../models';

import Accordion from 'react-bootstrap/esm/Accordion';
import { DateFormat } from '../../../widgets-core';
import { DateRangeIcon } from '../../../assets/icons';
import { Money } from '../../../widgets-business';

export const PTPTNDetail = ({ contact, className }: {
    contact: Contact,
    className?: string,
}) => {
    return (
        <Accordion className={className}>
            <Accordion.Item eventKey={""}>
                <Accordion.Header bsPrefix="accordion-header accordion-header-sm">
                    <h5 className='text-gray-700 my-0'>PTPTN Details</h5>
                </Accordion.Header>
                <Accordion.Body>
                    {!contact?.slcmPTPTNStatus ?
                        <span>Student not selected PTPTN</span> :
                        <PTPTNPanel contact={contact} />
                    }
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

const PTPTNPanel = ({ contact }: { contact: Contact }) => {
    return <div className='d-flex flex-wrap fs-7'>
        <div className="w-xl-150px my-1 pe-3">
            <span className="text-gray-600">Status</span>
            <div className="text-gray-700 fw-bolder">
                <span>{getPTPTNStatusLabel(contact?.slcmPTPTNStatus)}</span>
            </div>
        </div>

        <div className="w-xl-150px my-1 pe-3">
            <div className="form-check form-check-sm form-check-custom">
                <input
                    className="form-check-input"
                    type="checkbox"
                    disabled
                    id="slcmAutoDebitStatus"
                    checked={contact?.slcmAutoDebitStatus === true} />
                <label
                    className="form-check-label text-gray-600"
                    htmlFor="slcmAutoDebitStatus">
                    Auto Debit Status
                </label>
            </div>
        </div>

        <div className="w-xl-150px my-1 pe-3">
            <span className="text-gray-600">Approved Amount</span>
            <div className="text-gray-700 fw-bolder">
                <Money
                    currencyId={contact?.transactionCurrencyId?.id}
                    amount={contact?.slcmTotalPTPTNApprovedAmount} />
            </div>
        </div>

        <div className="w-xl-150px my-1 pe-3">
            <span className="text-gray-600">Approval Date</span>
            <div className="text-gray-700 fw-bolder">
                {contact?.slcmPTPTNApprovalDate && <DateRangeIcon className='me-1' />}
                <DateFormat date={contact?.slcmPTPTNApprovalDate} />
            </div>
        </div>

        <div className="w-xl-150px my-1 pe-3">
            <span className="text-gray-600">Cancellation Date</span>
            <div className="text-gray-700 fw-bolder">
                {contact?.slcmPTPTNCancellationDate && <DateRangeIcon className='me-1' />}
                <DateFormat date={contact?.slcmPTPTNCancellationDate} />
            </div>
        </div>

        <div className="w-xl-150px my-1 pe-3">
            <span className="text-gray-600">No Of Semester</span>
            <div className="text-gray-700 fw-bolder">
                <span>{contact?.slcmNoofSemester}</span>
            </div>
        </div>

        <div className="w-xl-150px my-1 pe-3">
            <span className="text-gray-600">Amount Per Semester</span>
            <div className="text-gray-700 fw-bolder">
                <Money
                    currencyId={contact?.transactionCurrencyId?.id}
                    amount={contact?.slcmAmountPerSemester} />
            </div>
        </div>
        <div className="w-xl-150px my-1 pe-3">
            <span className="text-gray-600">Bank Account No</span>
            <div className="text-gray-700 fw-bolder">
                <span>{contact?.slcmBankAccountNo}</span>
            </div>
        </div>
        <div className="w-xl-150px my-1 pe-3">
            <span className="text-gray-600">Latest Exam</span>
            <div className="text-gray-700 fw-bolder">
                <span>{contact?.slcmLatestExam}</span>
            </div>
        </div>
        <div className="w-xl-150px my-1 pe-3">
            <span className="text-gray-600">Exam Date</span>
            <div className="text-gray-700 fw-bolder">
                <DateFormat date={contact?.slcmExamDate} />
            </div>
        </div>
        <div className="w-xl-150px my-1 pe-3">
            <span className="text-gray-600">Result</span>
            <div className="text-gray-700 fw-bolder">
                <span>{getPTPTNContactStatusResult(contact?.slcmResult)}</span>
            </div>
        </div>
        <div className='w-xl-150px my-1 pe-3'>
            <span className='text-gray-600'>Insurance Id</span>
            <div className='text-gray-700 fw-bolder'>
                <span>{contact?.slcmInsuranceID}</span>
            </div>
        </div>

        <div className='w-xl-150px my-1 pe-3'>
            <span className='text-gray-600'>Expiry Date</span>
            <div className='text-gray-700 fw-bolder'>
                <span>
                    <DateFormat date={contact?.slcmPTPTNExpiryDate} />
                </span>
            </div>
        </div>

        <div className="w-xl-50 my-1 pe-3">
            <span className="text-gray-600">Approval Document</span>
            <div className="text-gray-700 fw-bolder">
                <a href={contact?.slcmPTPTNApprovaldocument} rel="noreferrer" target="_blank">
                    {contact?.slcmPTPTNApprovaldocument}
                </a>
            </div>
        </div>
    </div>
}

