import { AppointmentGroup } from "../models";
import { CalendarPeriod } from "../../../../client-models";
import { Contact } from "../../../../models";
import { TimelineGroup } from "../../../../widgets-core";
import { TimetableEventCard } from "./TimetableEventCard";

export const TimelineGroups = ({ contact, groups, timePeriod }: {
    contact: Contact,
    groups: AppointmentGroup[],
    timePeriod: CalendarPeriod,
}) => {
    return <>
        {groups?.map(item =>
            <TimelineGroup
                key={item.label}
                time={item.label}
                icon="PRIMARY">
                {item.appointments.map(item =>
                    <TimetableEventCard
                        className="mb-2"
                        contact={contact}
                        key={item.activityId}
                        appointment={item} />)}
            </TimelineGroup>
        )}
    </>;
}