import clsx from "clsx"

type TimelineProps = {
    className?: string,
}

export const Timeline: React.FC<TimelineProps> = ({ children, className }) => {
    return (
        <div className={clsx('timeline-label', className)}>
            {children}
        </div>
    )
}