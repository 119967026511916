import { SlcmAppointmentSlcmPreferredday, SlcmAppointmentSlcmPreferredtime } from "../../models";

export const preferredDay = (day?: SlcmAppointmentSlcmPreferredday) => {
    switch (day) {
        case 407450000: return "Monday";
        case 407450001: return "Tuesday";
        case 407450002: return "Wednesday";
        case 407450003: return "Thursday";
        case 407450004: return "Friday";
        case 407450005: return "Saturday";
        case 407450006: return "Sunday";
        default: return "";
    }
}

export const preferredTime = (time?: SlcmAppointmentSlcmPreferredtime) => {
    switch (time) {
        case 407450000: return "09AM - 10AM";
        case 407450001: return "10AM - 11AM";
        case 407450002: return "11AM - 12PM";
        case 407450003: return "12PM - 01PM";
        case 407450004: return "02PM - 03PM";
        case 407450005: return "03PM - 04PM";
        case 407450006: return "04PM - 05PM";
        default: return "";
    }
}