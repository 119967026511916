import clsx from "clsx";
import { useGetCurrencyQuery } from "../../services/currencies.api";

export const Money = ({ currencyId, amount, className }: {
    currencyId?: string;
    amount?: number;
    className?: string;
}) => {
    return (
        <span className={clsx(className)}>
            {currencyId && <Symbol currencyId={currencyId} />}
            {`${new Intl.NumberFormat().format(amount || 0)}`}
        </span>
    )
}

const Symbol = ({ currencyId }: { currencyId: string }) => {
    const { data: currency, isLoading, isFetching, isSuccess } = useGetCurrencyQuery(currencyId);

    switch (true) {
        case (isLoading || isFetching):
            return (
                <span className='indicator-progress me-2' style={{ display: 'inline-block' }}>
                    <span className='spinner-border spinner-border-sm align-middle'></span>
                </span>
            )
        case (isSuccess):
            return (
                <span className={clsx("me-1")}>
                    {currency?.currencySymbol}
                </span>
            )
        default:
            return <></>;
    }
}
