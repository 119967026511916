import clsx from "clsx"
import { FC } from "react"

type NavProps = {
    className?: string,
}

export const NavMenu: FC<NavProps> = ({ children, className }) => {
    return (
        <ul className={clsx('nav', className)}>
            {children}
        </ul>
    )
}