
import { Facility } from "../models";
import { rootApi } from './api';


const facilitiesApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getFacility: builder.query<Facility, string>({
            query: (id) => `/Facilities/${id}`,
        }),

        getFacilities: builder.query<Facility[], void>({
            query: () => `/Facilities`,
        }),
    })
})

export const {
    useGetFacilityQuery,
    useGetFacilitiesQuery,
} = facilitiesApi;