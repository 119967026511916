import { Appointment, Contact } from "../../../models"

import { CalendarPeriod } from "../../../client-models"
import Tab from "react-bootstrap/esm/Tab"
import Tabs from "react-bootstrap/esm/Tabs"
import { TimetableTimeline } from "./components"
import _ from "lodash"
import moment from "moment"
import { useCallback } from "react"

export const TimetableWeekView = ({ contact, appointments, timePeriod }: {
    contact: Contact,
    appointments: Appointment[],
    timePeriod: CalendarPeriod,
}) => {
    const filterAppointments = useCallback((weekday: number) => {
        return _.filter(appointments, p => moment(p.scheduledStart).weekday() === weekday);
    }, [appointments]);

    return (
        <>
            <Tabs className="mb-3">
                <Tab className="p-5" eventKey="Monday" title="Monday">
                    <TimetableTimeline
                        contact={contact}
                        timePeriod={timePeriod}
                        timetableEvents={filterAppointments(1)}
                    />
                </Tab>
                <Tab className="p-5" eventKey="Tuesday" title="Tuesday">
                    <TimetableTimeline
                        contact={contact}
                        timePeriod={timePeriod}
                        timetableEvents={filterAppointments(2)}
                    />
                </Tab>
                <Tab className="p-5" eventKey="Wednesday" title="Wednesday">
                    <TimetableTimeline
                        contact={contact}
                        timePeriod={timePeriod}
                        timetableEvents={filterAppointments(3)}
                    />
                </Tab>
                <Tab className="p-5" eventKey="Thursday" title="Thursday">
                    <TimetableTimeline
                        contact={contact}
                        timePeriod={timePeriod}
                        timetableEvents={filterAppointments(4)}
                    />
                </Tab>
                <Tab className="p-5" eventKey="Friday" title="Friday">
                    <TimetableTimeline
                        contact={contact}
                        timePeriod={timePeriod}
                        timetableEvents={filterAppointments(5)}
                    />
                </Tab>
                <Tab className="p-5" eventKey="Saturday" title="Saturday">
                    <TimetableTimeline
                        contact={contact}
                        timePeriod={timePeriod}
                        timetableEvents={filterAppointments(6)}
                    />
                </Tab>
                <Tab className="p-5" eventKey="Sunday" title="Sunday">
                    <TimetableTimeline
                        contact={contact}
                        timePeriod={timePeriod}
                        timetableEvents={filterAppointments(7)}
                    />
                </Tab>
            </Tabs>
        </>
    )
}