import { AcademicPeriodDetail, RegistrationWindow } from "../models"
import { useEffect, useState } from "react"

import { SlcmRegistrationtype } from "../models/registration-windows/enums/slcmRegistrationtype"
import { useGetRegistrationStatusCodes } from "./registration-statuses.hooks"
import { useGetStudentAcademicPeriodDetailsQuery } from "../services/academic-period-details.api"

export const useGetRegistrationPeriod = (studentId: string, registration: RegistrationWindow) => {
    const { data: entities, isSuccess } = useGetStudentAcademicPeriodDetailsQuery(studentId)
    const [period, setPeriod] = useState<AcademicPeriodDetail>()

    useEffect(() => {
        if (!registration || !isSuccess) return;

        const period = getRegistrationPeriod(registration, entities)
        if (period) setPeriod(period)
        
        // eslint-disable-next-line
    }, [entities, registration])

    return { period }
}

export const useGetRegistrationAcademicPeriod = (studentId: string, registration: RegistrationWindow) => {
    const { period } = useGetRegistrationPeriod(studentId, registration)
    const { SCH, PWS, PWF, PWA, COM } = useGetRegistrationStatusCodes()
    const [periodStatus, setPeriodStatus] = useState<"SCH" | "PWS" | "PWF" | "PWA" | "COM">()

    const updateStatus = (entity: AcademicPeriodDetail) => {
        switch (entity.slcmSemesterRegistration?.id) {
            case SCH:
                setPeriodStatus("SCH")
                break;
            case PWS:
                setPeriodStatus("PWS")
                break;
            case PWF:
                setPeriodStatus("PWF")
                break;
            case PWA:
                setPeriodStatus("PWA")
                break;
            case COM:
                setPeriodStatus("COM")
                break;
        }
    }

    useEffect(() => {
        if (!registration || !period) return;
        updateStatus(period)

        // eslint-disable-next-line
    }, [period, registration])

    return { period, periodStatus, statusIds: { SCH, PWS, PWF, PWA,COM } }
}

export const getRegistrationPeriod = (
    registration?: RegistrationWindow,
    entities?: AcademicPeriodDetail[]
) => {    
    if (registration?.slcmRegistrationType === SlcmRegistrationtype.Semester) {
        const academicPeriodDetail = entities?.find(
            (p) => p.slcmSemester?.id === registration.slcmSemester?.id &&
                p.slcmBatch?.id === registration.slcmBatchId?.id &&
                p.mshiedProgramId?.id === registration.slcmProgram?.id
        )

        if (academicPeriodDetail) {
            return academicPeriodDetail
        }
    }

    if (registration?.slcmRegistrationType === SlcmRegistrationtype.Year) {
        const academicPeriodDetail = entities?.find(
            (p) => p.slcmYear === registration.slcmYear &&
                p.slcmBatch?.id === registration.slcmBatchId?.id &&
                p.mshiedProgramId?.id === registration.slcmProgram?.id
        )

        if (academicPeriodDetail) {
            return academicPeriodDetail
        }
    }
}

