import { Appointment, Contact } from '../../../models';
import { CardPanel, CardPanelBody, CardPanelHeader, CardPanelToolbar, CardQueryStatus, CardRefreshButton, DateFormat, DateTimeFormat } from '../../../widgets-core'
import { DateRangeIcon, LocationIcon, TimeIcon } from '../../../assets/icons'
import { preferredDay, preferredTime } from '../helper'

import { AppointmentComments } from '../components/AppointmentComments';
import clsx from 'clsx'
import { useGetAppointmentQuery } from '../../../services/appointments.api'
import { useUserContact } from '../../../hooks/user'

export const ViewAppointment = ({ activityId, className }: {
  activityId: string
  className?: string
}) => {
  const contact = useUserContact()
  const { data: appointment, isLoading, isError, isFetching, isSuccess, error, refetch } = useGetAppointmentQuery(activityId)

  return (
    <CardPanel className={clsx(className, 'bg-transparent')}>
      <CardPanelHeader
        className='min-h-5px'
        title='Appointment Request'
        subTitle={appointment?.subject}>
        <CardPanelToolbar>
          <CardRefreshButton
            className="me-3"
            isLoading={isLoading || isFetching}
            refetch={refetch} />

          <div className='text-end'>
            <span className='text-gray-600'>Appointment Status</span>
            <div className='text-gray-700 fw-bolder'>
              <span>{appointment?.slcmAppointstatusFlag}</span>
            </div>
          </div>
        </CardPanelToolbar>
      </CardPanelHeader>
      <CardPanelBody>
        <CardQueryStatus
          isLoading={isLoading || isFetching}
          isSuccess={isSuccess}
          isError={isError}
          error={error}
          data={appointment} />

        {(contact && appointment) &&
          <>
            <AppointmentPanel appointment={appointment} contact={contact} />
            <AppointmentComments className="mt-5" requestId={appointment.activityId} />
          </>
        }
      </CardPanelBody>
    </CardPanel>
  )
}

const AppointmentPanel = ({ appointment, contact }: {
  appointment: Appointment,
  contact: Contact
}) => {
  return (
    <div className='card card-body py-3'>
      <div className='d-flex flex-wrap'>
        <div className='w-xl-150px flex-fill my-1 me-3'>
          <span className='text-gray-600'>Faculty / Mentor</span>
          <div className='text-gray-700 fw-bolder'>
            <span className='text-uppercase'>{contact.mshiedAdvisorIdName}</span>
          </div>
        </div>
        <div className='w-xl-150px flex-fill my-1 me-3'>
          <span className='text-gray-600'>Campus</span>
          <div className='text-gray-700 fw-bolder'>
            <span>{contact.slcmCampusName}</span>
          </div>
        </div>
        <div className='w-xl-150px my-1 me-3'>
          <span className='text-gray-600'>Preferred Date</span>
          <div className='text-gray-700 fw-bolder'>
            <DateRangeIcon className='me-3' />
            <DateFormat date={appointment.slcmPreferredDate} />
          </div>
        </div>
        <div className='w-xl-150px my-1 me-3'>
          <span className='text-gray-600'>Preferred Day</span>
          <div className='text-gray-700 fw-bolder'>
            <DateRangeIcon className='me-3' />
            <span>{preferredDay(appointment.slcmPreferredDay)}</span>
          </div>
        </div>
        <div className='w-xl-150px my-1 me-3'>
          <span className='text-gray-600'>Preferred Time Slot</span>
          <div className='text-gray-700 fw-bolder'>
            <TimeIcon className='me-3' />
            <span>{preferredTime(appointment.slcmPreferredTime)}</span>
          </div>
        </div>
        <div className="w-xl-150px me-3 my-1">
          <span className="text-gray-600">Remarks</span>
          <div className="text-gray-700 fw-bolder">
            <span>{appointment.slcmRemarks}</span>
          </div>
        </div>

        {appointment.slcmLocationName &&
          <div className='w-xl-150px my-1 me-3'>
            <span className='text-gray-600'>Location</span>
            <div className='text-gray-700 fw-bolder'>
              <LocationIcon className='me-3' />
              <span>{appointment.slcmLocationName}</span>
            </div>
          </div>}
        {appointment.slcmAppointmentStatus && <>
          <div className='w-xl-150px my-1 me-3'>
            <span className='text-gray-600'>Scheduled Start Date & Time</span>
            <div className='text-gray-700 fw-bolder'>
              <DateRangeIcon className='me-3' />
              <DateTimeFormat date={appointment.scheduledStart} />
            </div>
          </div>
          <div className='w-xl-150px my-1 me-3'>
            <span className='text-gray-600'>Scheduled End Date & Time</span>
            <div className='text-gray-700 fw-bolder'>
              <DateRangeIcon className='me-3' />
              <DateTimeFormat date={appointment.scheduledEnd} />
            </div>
          </div>
        </>}
      </div>
    </div>
  )
}