import { CardPanel, CardPanelBody, CardPanelHeader, CardPanelToolbar, Loading } from "../../../widgets-core";
import { Form, Formik } from "formik";
import { Service, ServiceRequest, SlcmServicerequestsStatuscode } from "../../../models";
import { ServiceRequestDocuments, ServiceRequestFields } from "../components";
import { useCreateServiceRequestMutation, useUpdateServiceRequestMutation } from "../../../services/service-requests.api";
import { useEffect, useRef, useState } from "react";

import { Money } from '../../../widgets-business'
import { ServicePayableAmount } from "../components/ServicePayableAmount";
import { ServicePaymentPage } from "../../payments/ServicePaymentPage";
import { ServiceRequestNetPayable } from "../ServiceRequestNetPayable";
import { StepperComponent } from "../../../assets/components";
import { StepsNavMenu } from "../components/StepsNavMenu";
import { StepsSubmitButton } from "../../wizards/components/StepsSubmitButton";
import clsx from "clsx";
import { contactFolderPath } from "../../../helpers";
import moment from "moment";
import { useGetServiceQuery } from "../../../services/services.api";
import { useNavigate } from "react-router-dom";
import { useUserContact } from "../../../hooks/user";

export const NewServiceRequest = ({ serviceId, className }: {
  serviceId: string
  className?: string
}) => {
  const { data: service, isLoading, isFetching } = useGetServiceQuery(serviceId)

  if (!service || isLoading || isFetching) {
    return <Loading status={"Pending"} />
  }

  return <NewServiceRequestInner service={service} className={className} />
}

const NewServiceRequestInner = ({ service, className }: {
  service: Service
  className?: string
}) => {
  const contact = useUserContact()
  const [addServiceRequest] = useCreateServiceRequestMutation()
  const [updateServiceRequest] = useUpdateServiceRequestMutation()

  const [serviceRequest, setServiceRequest] = useState<ServiceRequest>({} as ServiceRequest)
  const [baseServiceRequest, setBaseServiceRequest] = useState<ServiceRequest>({} as ServiceRequest)

  const [updated, setUpdated] = useState<boolean>(false)
  const [saving, setSaving] = useState(false)
  const [alertMsg, setAlertMsg] = useState<string>()
  const [documentsStatus, setDocumentsStatus] = useState<boolean>(false)

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const navigate = useNavigate()
  const folderPath = contact ? `${contactFolderPath(contact)}/${serviceRequest.slcmCode}` : '';
  const [netPayableAmount, setNetPayableAmount] = useState<number>();

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
    // eslint-disable-next-line
  }, [stepperRef.current, service])

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const submitRequest = (serviceRequest: ServiceRequest) => {
    if (!serviceRequest || !serviceRequest.slcmServicerequestsId) {
      return;
    }

    setSaving(true)
    const request: Partial<ServiceRequest> = {
      statuscode: SlcmServicerequestsStatuscode.Open,
      slcmDate: moment(new Date()).format('yyyy-MM-DD') as any as Date,
    }

    updateServiceRequest({
      id: serviceRequest.slcmServicerequestsId,
      entity: request
    })
      .unwrap()
      .then(() => {
        navigate(`/service-requests/${SlcmServicerequestsStatuscode.Open}`)
      })
      .catch((reason) => {
        setAlertMsg('Failed to submit service request.')
        setSaving(false)
      })
  }

  const validateServiceRequest = () => {
    if (service.slcmCampus && !serviceRequest.slcmCampus) {
      return 'Campus can not be blank';
    }

    if (service.slcmExpectedOccupancy && !serviceRequest.slcmExpectedOccupancy) {
      return 'Occupancy can not be blank';
    }

    if (service.slcmLocation && !serviceRequest.slcmLocation) {
      return 'Location can not be blank';
    }

    if (service.slcmStartDate && service.slcmEndDate && moment(moment(serviceRequest.slcmStartDate).format('MM-DD-YYYY')).isAfter(moment(serviceRequest.slcmEndDate).format('MM-DD-YYYY'))) {
      return 'End Date Should not be less than start date';
    }
  }

  const createRequest = (): Promise<ServiceRequest> => {
    if (!contact || !service)
      return Promise.reject()

    const message = validateServiceRequest();
    if (message) {
      setAlertMsg(message)
      return Promise.reject()
    }

    setSaving(true)
    const newRequest: Partial<ServiceRequest> = { ...serviceRequest }
    newRequest.slcmStudent = { id: contact.contactId, logicalName: 'contact' }
    newRequest.slcmRequestType = { id: service?.slcmRequestsId, logicalName: 'slcm_requests' }
    newRequest.slcmName = service?.slcmName
    newRequest.slcmDate = moment(new Date()).format('yyyy-MM-DD') as any as Date
    newRequest.statuscode = SlcmServicerequestsStatuscode.Draft
    if (contact.mshiedCurrentProgramId) {
      newRequest.slcmProgram = contact.mshiedCurrentProgramId
    }

    return addServiceRequest(newRequest)
      .unwrap()
      .then((request) => {
        setServiceRequest(request);
        setBaseServiceRequest(request)
                
        return Promise.resolve(request);
      })
      .catch(() => {
        setAlertMsg('Failed to submit service request.')
        return Promise.reject();
      })
      .finally(() => {
        setSaving(false)
      })
  }

  const updateRequest = (): Promise<ServiceRequest> => {
    if (!updated) {
      return Promise.resolve(serviceRequest);
    }

    const message = validateServiceRequest();
    if (message) {
      setAlertMsg(message)
      return Promise.reject()
    }

    setSaving(true)
    const request = getDeltaObject(baseServiceRequest, serviceRequest)
    return updateServiceRequest({
      id: serviceRequest.slcmServicerequestsId,
      entity: request
    })
      .unwrap()
      .then(() => {
        setUpdated(false)
        return Promise.resolve(serviceRequest)
      })
      .catch(() => {
        setAlertMsg('Failed to submit service request.')
        return Promise.reject()
      })
      .finally(() => {
        setSaving(false)
      })
  }

  const saveRequest = () => {
    if (!contact || !service)
      return Promise.reject();

    if (!serviceRequest.slcmServicerequestsId) {
      return createRequest()
    } else {
      return updateRequest()
    }
  }

  const submitStep = async () => {
    const step = stepper?.current
    if (!step) return

    function nextStepOrSubmit(serviceRequest: ServiceRequest) {
      const step = stepper?.current
      if (!step) return;

      if (step.currentStepIndex < step.totatStepsNumber)
        step.goNext();
      else if (step.totatStepsNumber === step.currentStepIndex)
        submitRequest(serviceRequest);
    }

    setAlertMsg('')
    switch (step.currentStepIndex) {
      case 1:
        const request = await saveRequest();
        nextStepOrSubmit(request);
        break
      case 2:
        switch (true) {
          case !!service?.slcmDocumentChecklist:
            if (documentsStatus) {
              nextStepOrSubmit(serviceRequest);
            } else {
              setAlertMsg('Please attach all required documents.')
            }
            break
          case service?.slcmPaidService:
            nextStepOrSubmit(serviceRequest);
            break
        }
        break
      case 3:
        nextStepOrSubmit(serviceRequest);
        break
      case 4:
        nextStepOrSubmit(serviceRequest);
        break
    }
  }

  const onChangeHandler = (request: ServiceRequest) => {
    setUpdated(true)
    setServiceRequest(request)
  }

  const handleDocumentsStatusChange = (status: boolean) => {
    if (documentsStatus === true) {
      setAlertMsg('')
    }

    setDocumentsStatus(status)
  }

  const amountNetPayable = () => {
    if (!contact?.slcmOutstandingAmount || contact?.slcmOutstandingAmount > 0) {
      setNetPayableAmount(Math.abs(contact?.slcmOutstandingAmount || 0) + Math.abs(serviceRequest.slcmTotalFeeAmount || 0))
    } else if (!contact?.slcmOutstandingAmount || contact?.slcmOutstandingAmount < 0) {
      setNetPayableAmount(Number(serviceRequest.slcmTotalFeeAmount || 0) + Number(contact?.slcmOutstandingAmount || 0))
    }
  }

  useEffect(() => {
    amountNetPayable()
    // eslint-disable-next-line
  }, [contact?.slcmOutstandingAmount, serviceRequest.slcmTotalFeeAmount])

  return (
    <CardPanel className={clsx(className, "bg-transparent")}>
      <CardPanelHeader
        title="New Service Request"
        subTitle={service.slcmName}
      >
        <CardPanelToolbar>
          <div className={"text-gray-600 py-3 me-6"} >
            <span className="fw-bold">Previous Outstanding Amount</span>
            <h3 className="text-danger my-0 text-left">
              <Money
                currencyId={contact?.transactionCurrencyId?.id}
                amount={contact?.slcmOutstandingAmount} />
            </h3>
          </div>

          <ServicePayableAmount
            serviceRequest={serviceRequest}
            label="Service request fee" />

          <ServiceRequestNetPayable
            amount={netPayableAmount}
            serviceRequest={serviceRequest}
            label="Net Payable" />
        </CardPanelToolbar>
      </CardPanelHeader>
      <CardPanelBody>
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_create_account_stepper'
        >
          <StepsNavMenu
            className="me-5"
            service={service}
            stepIndex={stepper.current?.getCurrentStepIndex()} />

          <div className='d-flex flex-row-fluid bg-white rounded'>
            <Formik initialValues={serviceRequest} onSubmit={submitStep}>
              {() => (
                <Form
                  className='card card-body py-5 fs-7'
                  noValidate
                  id='kt_create_account_form'
                >
                  <div className='current' data-kt-stepper-element='content'>
                    <div className='w-100'>
                      <ServiceRequestFields
                        key='fields'
                        onChange={onChangeHandler}
                        service={service}
                        serviceRequest={serviceRequest}
                      />
                    </div>
                  </div>

                  {(serviceRequest.slcmServicerequestsId && service.slcmDocumentChecklist?.id) && (
                    <div data-kt-stepper-element='content'>
                      <div className='w-100'>
                        <ServiceRequestDocuments
                          folderPath={folderPath}
                          onStatusChange={handleDocumentsStatusChange}
                          checklistId={service.slcmDocumentChecklist.id}
                          requestId={serviceRequest.slcmServicerequestsId}
                        />
                      </div>
                    </div>
                  )}

                  {!!service.slcmPaidService && (
                    <div data-kt-stepper-element='content'>
                      <div className='w-100'>
                        <ServicePaymentPage
                          folderPath={folderPath}
                          serviceId={serviceRequest.slcmServicerequestsId}
                          amount={netPayableAmount || 0}
                          currencyName={serviceRequest?.transactionCurrencyIdName}
                          currencyId={serviceRequest.transactionCurrencyId?.id} />
                      </div>
                    </div>
                  )}

                  <div data-kt-stepper-element='content'>
                    <div className='w-100'>
                      <span className='m-10 text-gray-600 fs-6'>
                        We have all necessary information to process your request, now you can submit
                        the request for approval.
                      </span>
                    </div>
                  </div>

                  {alertMsg && (
                    <div className='alert alert-warning mt-10'>
                      <span className='alert-text fw-bolder'>{alertMsg}</span>
                    </div>
                  )}

                  <div className="flex-fill">{/* To align bottom */}</div>
                  <div className='d-flex flex-stack pt-5'>
                  <div className='me-2'>
                      {/* <StepsBackButton className="btn-sm" onClick={prevStep} /> */}
                    </div>
                    <div>
                      <StepsSubmitButton
                        className="btn-sm"
                        label="Submit"
                        saving={saving}
                        stepIndex={stepper.current?.currentStepIndex}
                        totalSteps={stepper.current?.totatStepsNumber} />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </CardPanelBody>
    </CardPanel>)
}

const getDeltaObject = (original: any, updated: any) => {
  const delta: any = {};

  for (const key in updated) {
    if (updated[key] !== original[key]) {
      delta[key] = updated[key];
    }
  }

  return delta;
}
