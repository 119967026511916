import Accordion from "react-bootstrap/esm/Accordion";
import { AssociatedDocument } from "../../../../models";
import { DownloadButton } from "../../../payments/DownloadButton";
import clsx from "clsx";
import { useGetAssociatedDocumentsByRequestIdQuery } from "../../../../services/associated-documents.api"

export const ViewServiceRequestDocuments = ({ requestId, checklistId, className }: {
    requestId: string,
    checklistId: string,
    className?: string,
}) => {
    const { data: associatedDocuments }
        = useGetAssociatedDocumentsByRequestIdQuery({ checklistId, requestId });

    if (!associatedDocuments || associatedDocuments.length === 0)
        return <></>

    return (
        <Accordion className={className}>
            <Accordion.Item eventKey={requestId}>
                <Accordion.Header bsPrefix="accordion-header accordion-header-sm">
                    <h5 className='text-gray-700 my-0'>Associated Documents</h5>
                </Accordion.Header>
                <Accordion.Body className="py-2">
                    <div className="d-flex flex-wrap fs-7">
                        {associatedDocuments?.map(item => (
                            <ServiceRequestDocument
                                className="pe-3"
                                associatedDocument={item}
                                key={item.slcmAssociatedDocumentsId} />
                        ))}
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

const ServiceRequestDocument = ({ associatedDocument, className }: {
    associatedDocument: AssociatedDocument,
    className?: string,
}) => {
    return <>
        <div className={clsx(className, "min-w-50 my-1 flex-fill")}>
            <DownloadButton
                className="btn btn-sm btn-link"
                label={associatedDocument.slcmAssociatedDocumentName || 'Download'}
                link={associatedDocument.slcmDocumentLink} />
        </div>
    </>
}