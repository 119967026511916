import { CardPanel, CardPanelBody, CardPanelHeader, CardPanelToolbar, If, ListQueryStatus } from "../../widgets-core"
import { Contact, FeeStructure, getIntakeLabel } from "../../models"

import { Money } from "../../widgets-business"
import React, { useCallback } from "react"
import clsx from "clsx"
import { useGetFeeStructureByProgramIdQuery } from "../../services/fee-structures.api"
import { useUserContact } from "../../hooks/user"
import _ from "lodash"

export const FeeStructureView = () => {
    const contact = useUserContact();

    return (<>
        {(contact && contact?.mshiedCurrentProgramId?.id && contact.slcmBatch?.id) &&
            <FeeStructureViewInner
                programId={contact?.mshiedCurrentProgramId?.id}
                batchId={contact.slcmBatch?.id}
                contact={contact} />}
    </>)
}

const FeeStructureViewInner = ({ contact, programId, batchId }: {
    contact: Contact,
    programId: string,
    batchId: string
}) => {
    const { data: feeStructure, isLoading, isError, isFetching, isSuccess, refetch } = useGetFeeStructureByProgramIdQuery({
        programId: programId,
        batchId: batchId
    });

    const getSortedData = useCallback(() => {
        return _.sortBy(feeStructure, p => Number(p.slcmSemesterName?.replace('Semester', '').trim() || 0));
    }, [feeStructure]);

    return (
        <CardPanel className="bg-transparent">
            <CardPanelHeader
                title="Fee Structure"
                subTitle="Batch wise student fee structure"
            >
                <CardPanelToolbar>
                    <button
                        onClick={refetch}
                        type="button"
                        title="Refresh"
                        className='btn btn-sm btn-link text-hover-primary p-0 me-3'>
                        <i className="las la-redo-alt fs-2"></i>
                    </button>
                </CardPanelToolbar>
            </CardPanelHeader>
            <CardPanelBody>
                <ListQueryStatus
                    isLoading={isLoading || isFetching}
                    isSuccess={isSuccess}
                    isError={isError}
                    data={feeStructure} />
                <If condition={!isLoading && !isFetching && isSuccess}>
                    <div className='card card-body py-3'>
                        <div className='table-responsive'>
                            <table className='table table-striped fs-7'>
                                <FeeStructureLineHeader />
                                <tbody>
                                    {getSortedData()?.map(item =>
                                        <React.Fragment key={item.slcmFeestructureId}>
                                            <FeeStructureLineRow
                                                className="mb-2 fs-7"
                                                contact={contact}
                                                feeStructure={item} />
                                        </React.Fragment>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </If>

            </CardPanelBody>
        </CardPanel>
    )
}


const FeeStructureLineHeader = ({ className }: { className?: string }) => {
    return (
        <thead className={clsx(className, "fw-bolder")}>
            <tr>
                <th className='min-w-125px'>Year</th>
                <th className='min-w-125px'>Batch</th>
                <th className='min-w-250px'>Semester</th>
                <th className='min-w-125px'>Intake</th>
                <th className='min-w-125px'>Product</th>
                <th className='min-w-125px'>Unit Price</th>
            </tr>
        </thead>
    )
}

const FeeStructureLineRow = ({ contact, feeStructure, className }: {
    contact: Contact,
    feeStructure: FeeStructure,
    className?: string
}) => {
    if (!feeStructure) return <></>

    return (
        <tr>

            <td>{feeStructure.slcmYearCodeName}</td>
            <td>{feeStructure.slcmBatchName}</td>
            <td>{feeStructure.slcmSemesterName}</td>
            <td>{getIntakeLabel(contact?.slcmIntake)}</td>
            <td>{feeStructure.slcmProductNoName}</td>
            <td><Money currencyId={feeStructure.slcmCurrencyCode?.id} amount={feeStructure.slcmUnitPrice} /></td>
        </tr>
    )
}