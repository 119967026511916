import { CardPanel, CardPanelBody, CardPanelHeader, CardPanelToolbar, DateFormat, If, ListQueryStatus } from "../../widgets-core";
import React, { useEffect } from "react";

import { AcademicPeriodDetail } from "../../models";
import Accordion from "react-bootstrap/esm/Accordion";
import clsx from "clsx";
import moment from "moment";
import { useLazyGetAcademicPeriodSchedulesByPeriodIdQuery } from "../../services/academic-period-scheduled.api";
import { useLazyGetStudentAcademicPeriodDetailsQuery } from '../../services/academic-period-details.api';
import { useUserContact } from "../../hooks/user";

export const AcademicPeriodScheduleView = () => {
    const contact = useUserContact();
    const [getAcademicPeriodDetails, { data: academicPeriodDetails, isLoading, isError, isFetching, isSuccess }]
        = useLazyGetStudentAcademicPeriodDetailsQuery();

    useEffect(() => {
        if (!contact?.contactId)
            return;

        getAcademicPeriodDetails(contact?.contactId)

        // eslint-disable-next-line
    }, [contact])

    return (
        <CardPanel className="bg-transparent">
            <CardPanelHeader
                title="Academic Period Calendar"
            >
                <CardPanelToolbar>
                    <button
                        onClick={() => {
                            if (contact?.contactId)
                                getAcademicPeriodDetails(contact?.contactId)
                        }}
                        type="button"
                        title="Refresh"
                        className='btn btn-sm btn-link text-hover-primary p-0 me-3'>
                        <i className="las la-redo-alt fs-2"></i>
                    </button>
                </CardPanelToolbar>
            </CardPanelHeader>
            <CardPanelBody>
                <ListQueryStatus
                    isLoading={isLoading || isFetching}
                    isSuccess={isSuccess}
                    isError={isError}
                    data={academicPeriodDetails} />

                <If condition={!isLoading && !isFetching && isSuccess}>
                    {academicPeriodDetails &&
                        academicPeriodDetails.map((entity) =>
                            <React.Fragment key={entity.mshiedAcademicperioddetailsId}>
                                <AcademicPeriodPanel
                                    className="fs-7"
                                    entity={entity} />
                            </React.Fragment>)}
                </If>
            </CardPanelBody>
        </CardPanel>
    )
}

const AcademicPeriodPanel = ({ entity, className }: {
    entity: AcademicPeriodDetail
    className?: string
}) => {
    return (
        <Accordion className={className}>
            <Accordion.Item eventKey={entity.mshiedAcademicperioddetailsId} className="mb-2 bg-transparent">
                <Accordion.Header bsPrefix="accordion-header accordion-header-sm">
                    <div className="d-flex flex-wrap w-100 fs-7">
                        <div className='flex-fill fs-6'>
                            <span className="text-gray-700 fw-bolder me-2">
                                {`Year ${entity.slcmYear}`}
                            </span>
                            <span className="text-gray-700 fw-bolder">
                                {entity?.slcmSemesterName}
                            </span>
                        </div>
                        {(entity.slcmStartDate) && <>
                            <div className="w-xl-175px">
                                <span className="text-gray-600 fw-bold me-1">Start: </span>
                                <DateFormat
                                    date={entity.slcmStartDate}
                                    className="fw-bolder text-gray-700 me-3" />
                            </div>
                            <div className="w-xl-175px">
                                <span className="text-gray-600 fw-bold me-1">End: </span>
                                <DateFormat
                                    date={entity.slcmEndDate}
                                    className="fw-bolder text-gray-600" />
                            </div>
                        </>}
                    </div>
                </Accordion.Header>
                <Accordion.Body className="bg-transparent">
                    <AcademicPeriodSchedules
                        model={entity} periodId={entity?.mshiedAcademicPeriodID?.id} />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>)
}

const AcademicPeriodSchedules = ({ model, className, periodId }: {
    model: AcademicPeriodDetail  // last update
    className?: string,
    periodId?: string
}) => {
    const [getAcademicPeriodSchedule, { data: academicPeriodSchedule }]
        = useLazyGetAcademicPeriodSchedulesByPeriodIdQuery();

    useEffect(() => {
        if (!periodId) return;
        getAcademicPeriodSchedule(periodId)
        // eslint-disable-next-line
    }, [periodId])

    const sorted = () => {
        if (!academicPeriodSchedule)
            return [];

        return academicPeriodSchedule
            .slice()
            .sort((a, b) => moment(a.slcmStartdate).diff(b.slcmStartdate, 'days'))
    }

    return (
        <>

            <div className={clsx(className, "card card-body py-2 mb-2")}>
                <table className='table table-striped fs-7'>
                    <thead>
                        <tr className='fw-bolder fs-7 border-bottom border-gray-200 py-2'>
                            <th className='px-2 min-w-50px'>Activity</th>
                            <th className='px-2 min-w-50px'>Start Date</th>
                            <th className='px-2 min-w-50px'>End Date</th>
                            <th className='px-2 min-w-50px'>Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sorted().map((item) =>
                            <React.Fragment key={item.slcmAcademicperiodscheduleId}>
                                <tr className='text-gray-700'>
                                    <td className='px-2'>{item.slcmName}</td>
                                    <td className='px-2'><DateFormat date={item.slcmStartdate} /></td>
                                    <td className='px-2'><DateFormat date={item.slcmEndDate} /></td>
                                    <td>{item.slcmDetails && <Accordion className={className}>
                                        <Accordion.Item eventKey={""} className="mb-2 bg-transparent">
                                            <Accordion.Header bsPrefix="accordion-header accordion-header-sm">
                                                <div className="d-flex flex-wrap w-100 fs-7">
                                                    Details
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body className="bg-transparent">
                                                <div dangerouslySetInnerHTML={{ __html: item.slcmDetails || "" }}></div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>}</td>
                                </tr>
                            </React.Fragment>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    )
}