import { LocationFacility } from "../models";
import { rootApi } from './api';

const locationFacilitiesApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getLocationFacility: builder.query<LocationFacility, string>({
            query: (id) => `/LocationFacility/${id}`,
        }),

        getLocationFacilities: builder.query<LocationFacility[], void>({
            query: () => `/LocationFacility`,
        }),

        getLocationFacilityByFacilityId: builder.query<LocationFacility, string>({
            query: (facilityId) => `/LocationFacility/facility/${facilityId}`,
        }),

        getLocationFacilitiesByLocationIds: builder.query<LocationFacility[], string[]>({
            query: (ids) => ({
                url: '/LocationFacility/locations',
                method: 'POST',
                body: ids
            })
        }),
    })
})

export const {
    useGetLocationFacilityQuery,
    useGetLocationFacilitiesQuery,
    useGetLocationFacilityByFacilityIdQuery,
    useGetLocationFacilitiesByLocationIdsQuery,
} = locationFacilitiesApi