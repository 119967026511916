import { AppSvgIcon, SvgIconName } from "../../assets/icons"

import clsx from "clsx"

export const CardPanelHeader = ({ title, subTitle, iconName, className, children }: {
    title?: string,
    subTitle?: string,
    iconName?: SvgIconName,
    className?: string,
    children?: React.ReactNode
}) => {
    return (
        <div className={clsx(className, 'card-header min-h-5px border-0 pt-5')}>
            <div>
                <h3 className='card-title align-items-start mb-0'>
                    <span className='fw-bolder text-danger'>
                        {iconName &&
                            <AppSvgIcon
                                className="me-2"
                                sx={{ fontSize: 18 }}
                                iconName={iconName}
                            />}
                        {title}
                    </span>
                </h3>
                {subTitle &&
                    <span className='text-gray-600 mt-1 fw-bold'>{subTitle}</span>}
            </div>
            {children}
        </div>
    )
}
