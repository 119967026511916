import { Semester } from "../models";
import { rootApi } from "./api";

const semestersApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getSemester: builder.query<Semester, string>({
            query: (id) => `/Semesters/${id}`,
        }),

        getSemesters: builder.query<Semester[], void>({
            query: () => `/Semesters`,
        }),

        getSemestersByProgramId: builder.query<Semester[], string>({
            query: (programId) => `/Semesters/program(${programId})`,
        }),
    })
})

export const {
    useGetSemesterQuery,
    useLazyGetSemesterQuery,
    useGetSemestersQuery,
    useGetSemestersByProgramIdQuery,
    useLazyGetSemestersByProgramIdQuery
} = semestersApi;