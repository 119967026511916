// spellchecker:disable

import { AppointmentStatecode } from './enums/appointmentStatecode';
import { AppointmentStatuscode } from "./enums/appointmentStatuscode";
import { SlcmAppointmentSlcmLearningmethod } from "./enums/slcmAppointmentSlcmLearningmethod";

export * from "./enums/slcmAppointmentSlcmPreferredtime";
export * from "./enums/slcmAppointmentSlcmPreferredday";
export * from "./enums/slcmAppointmentSlcmPurpose";

export * from "./Appointment";

export const getAppointmentStatusCodeLabel = (statusCode?: AppointmentStatuscode)=>{
    switch(statusCode) {
        case AppointmentStatuscode.Open: return "Open";
        case AppointmentStatuscode.Tentative: return "Tentative";
        case AppointmentStatuscode.Completed: return "Completed";
        case AppointmentStatuscode.Canceled: return "Canceled";
        case AppointmentStatuscode.Busy: return "Busy";
        case AppointmentStatuscode.OutOfOffice: return "OutOfOffice";
        default: return "";
    }
}

export const getAppointmentStateCodeLabel = (stateCode?: AppointmentStatecode)=>{
    switch(stateCode) {
        case AppointmentStatecode.Open: return "Open";
        case AppointmentStatecode.Completed: return "Completed";
        case AppointmentStatecode.Canceled: return  "Canceled";
        case AppointmentStatecode.Scheduled: return  "Scheduled";
        default: return "";
    }
}

export const getAppointmentLearningModeLabel = (learningMode?: SlcmAppointmentSlcmLearningmethod) => {
    switch (learningMode) {
        case SlcmAppointmentSlcmLearningmethod.Practical: return "Lecture"; // Lecture
        case SlcmAppointmentSlcmLearningmethod.ClinicalPosting: return "Clinical Posting"; // Practical
        case SlcmAppointmentSlcmLearningmethod.Theory: return "Theory"; // Tutorial
        default: return "";
    }
}