import { AttendanceStatistics } from "../models";
import { rootApi } from './api';

const attendanceStatisticsApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getAttendanceStatisticsByStudentId: builder.query<AttendanceStatistics[], string>({
            query: (studentId) => `/AttendanceStatistics/student/${studentId}`,
        }),
    })
})

export const {
    useGetAttendanceStatisticsByStudentIdQuery,
    useLazyGetAttendanceStatisticsByStudentIdQuery,
} = attendanceStatisticsApi;