import { NotificationEvent } from "../models";
import { rootApi } from './api';

const notificationEventsApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getNotificationEvent: builder.query<NotificationEvent, string>({
            query: (id) => `/NotificationEvent/${id}`,
        }),

        getNotificationEvents: builder.query<NotificationEvent[], void>({
            query: () => `/NotificationEvent`,
        }),

        getNotificationEventsByStudentId: builder.query<NotificationEvent[], string>({
            query: (studentId) => `/NotificationEvent/student/${studentId}`,
        }),

        getNotificationEventsByCampusId: builder.query<NotificationEvent[], string>({
            query: (campusId) => `/NotificationEvent/campus/${campusId}`,
        }),

        getNotificationEventsByBatchId: builder.query<NotificationEvent[], string>({
            query: (batchId) => `/NotificationEvent/batch/${batchId}`,
        }),
    })
})

export const {
    useGetNotificationEventQuery,
    useGetNotificationEventsQuery,
    useGetNotificationEventsByStudentIdQuery,
    useLazyGetNotificationEventsByStudentIdQuery,
    useGetNotificationEventsByCampusIdQuery,
    useLazyGetNotificationEventsByCampusIdQuery,
    useGetNotificationEventsByBatchIdQuery,
    useLazyGetNotificationEventsByBatchIdQuery,
} = notificationEventsApi;