import React, { ChangeEvent, MouseEvent, useState } from 'react'
import { useDeleteAssociatedDocumentMutation, useUploadAssociatedDocumentMutation } from '../../../../services/associated-documents.api'

import { AssociatedDocument } from '../../../../models'
import { AttachmentRequestModel } from '../../../../client-models'
import { DoneIcon } from '../../../../assets/icons'
import { DownloadButton } from '../../../payments/DownloadButton'
import clsx from 'clsx'

export const ServiceRequestDocument = ({
  requestId,
  folderPath,
  associatedDocument,
  onChangeDocument,
  className,
}: {
  requestId: string,
  folderPath: string,
  associatedDocument: AssociatedDocument,
  onChangeDocument?: (associatedDocument: AssociatedDocument) => void,
  className?: string,
}) => {
  const [file, setFile] = useState<File>()
  const [saving, setSaving] = useState<boolean>(false)
  const [alertMsg, setAlertMsg] = useState<string>()
  const [uploadAssociatedDocument] = useUploadAssociatedDocumentMutation()
  const [removeAssociatedDocument] = useDeleteAssociatedDocumentMutation()

  const uploadDocumentHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    if (!file) {
      setAlertMsg('You must select a file to upload.')
      return
    }

    setSaving(true)
    setAlertMsg('')

    const reader = new FileReader()
    reader.onloadend = () => {
      const content = reader.result

      var base64 = btoa(
        new Uint8Array(content as ArrayBuffer).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          '')
      )

      const model: AttachmentRequestModel = {
        associatedEntity: { id: requestId, logicalName: "slcm_servicerequests" },
        entityId: associatedDocument?.slcmAssociatedDocumentsId,
        folderPath: folderPath,
        fileName: file.name,
        fileType: file.type,
        base64content: base64 as string,
      }

      uploadAssociatedDocument(model)
        .unwrap()
        .then((associatedDocument) => {
          if (onChangeDocument)
            onChangeDocument(associatedDocument)
        })
        .catch((err) => {
          setAlertMsg(err.message)
        })
        .finally(() => {
          setSaving(false)
        })
    }

    reader.readAsArrayBuffer(file)
  }

  const deleteDocumentHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    setSaving(true)
    setAlertMsg('')

    if (!associatedDocument?.slcmAssociatedDocumentsId) return

    removeAssociatedDocument(associatedDocument?.slcmAssociatedDocumentsId)
      .unwrap()
      .then(() => {
        if (onChangeDocument)
          onChangeDocument(associatedDocument)
      })
      .catch((err) => {
        setAlertMsg(err.message)
      })
      .finally(() => {
        setSaving(false)
      })
  }

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAlertMsg('')

    const file = event.target.files?.item(0)
    if (file) {
      var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i
      if (!allowedExtensions.exec(file.name)) {
        event.target.value = ''
        setAlertMsg('Upload file having extensions .jpeg/.jpg/.png/.pdf only.')
        return
      }

      const fileSize = file.size / 1024 / 1024 // in MiB
      if (fileSize > 2) {
        setAlertMsg(
          `Attached file size ${Math.round(fileSize * 100) / 100} MiB exceeds the 2 MiB limit.`
        )
        return
      }

      setFile(file)
    }
  }

  return (
    <>
      {!associatedDocument.slcmDocumentLink ? (
        <div className={clsx('flex col-xl-12', className)}>
          <div className='row'>
            <label
              className={clsx(
                'col-xl-3 form-label fs-5',
                { required: associatedDocument.slcmRequired },
                { 'text-danger': !associatedDocument }
              )}
            >
              {associatedDocument.slcmAssociatedDocumentName}
            </label>
            <div className='col-xl-7'>
              <input
                type='file'
                className='form-control p-2 me-2'
                accept='image/jpeg,image/png,application/pdf'
                onChange={onFileChange}
                placeholder={associatedDocument.slcmAssociatedDocumentName}
              />
            </div>
            <div className='col-xl-2'>
              <button
                type='button'
                disabled={saving}
                onClick={uploadDocumentHandler}
                className='btn btn-sm btn-secondary'
              >
                {saving ? (
                  <span className='indicator-label'>
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  </span>
                ) : (
                  <span>Upload</span>
                )}
              </button>
            </div>
            {alertMsg && <span className='col-xl-12 text-danger mt-1'>{alertMsg}</span>}
          </div>
        </div>
      ) : (
        <div className={clsx('col-xl-12', className)}>
          <div className='row'>
            <div className='col-xl-6'>
              <label
                className={clsx('form-label fs-5', { required: associatedDocument.slcmRequired })}
              >
                <DoneIcon className='text-primary fw-bolder me-2' />
                {associatedDocument.slcmAssociatedDocumentName}
              </label>
            </div>
            <div className='col-xl-6 text-end'>
              <DownloadButton
                className='btn btn-sm btn-secondary me-2'
                label='Preview'
                link={associatedDocument.slcmDocumentLink} />

              <button
                type='button'
                disabled={saving}
                onClick={deleteDocumentHandler}
                className='btn btn-sm btn-danger'
              >
                {saving ? (
                  <span className='indicator-label'>
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  </span>
                ) : (
                  <span>Delete</span>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
