import { CardPanel, CardPanelBody, CardPanelHeader, CardPanelToolbar, CardQueryStatus, CardRefreshButton, DateFormat, If } from "../../../widgets-core";

import { AssociatedDocumentsView } from '../../payments/AssociatedDocumentsView'
import { DemandHeader } from "../../../models";
import { DemandLinesToggleView } from "./components/DemandLinesToggleView";
import { Money } from "../../../widgets-business";
import { TrackPaymentTransactions } from "../../payments/TrackPaymentTransactions";
import clsx from "clsx";
import { useGetDemandQuery } from "../../../services/demands.api";
import { useRef } from "react";

export const ViewDemand = ({ demandId, className }: {
    demandId: string,
    className?: string,
}) => {
    const { data: demand, isLoading, isError, isFetching, isSuccess, error, refetch } = useGetDemandQuery(demandId);
    const cbRef = useRef<() => void>()

    const dueAmount = (demand?.slcmCurrentbalance || 0) - (demand?.slcmInterimAmountPaid || 0);

    return (
        <CardPanel className={clsx(className, "bg-transparent")}>
            <CardPanelHeader
                className="ribbon ribbon-top"
                title={demand?.slcmDemandNo}
                subTitle="Invoice Details">
                <CardPanelToolbar>
                    <CardRefreshButton
                        className="me-3"
                        isLoading={isLoading || isFetching}
                        refetch={() => {
                            refetch();
                            cbRef.current && cbRef.current()
                        }} />

                    {(dueAmount > 0 && demand) &&
                        <div className='text-end'>
                            <span className="text-gray-600 fw-bolder">Due Amount</span>
                            <div className="text-success-1500">
                                <h2 className="text-danger">
                                    <Money currencyId={demand?.transactionCurrencyId?.id}
                                        amount={dueAmount} />
                                </h2>
                            </div>
                        </div>
                    }
                </CardPanelToolbar>
            </CardPanelHeader>
            <CardPanelBody>
                <CardQueryStatus
                    isLoading={isLoading || isFetching}
                    isSuccess={isSuccess}
                    isError={isError}
                    error={error}
                    data={demand} />
                <If condition={!isLoading && !isFetching && isSuccess}>
                    {demand &&
                        <>
                            <div className="card card-body py-3 mb-2 fs-7">
                                <DemandHeaderPanel demand={demand} />
                            </div>
                            <div className="card card-body py-3 mb-5 fs-7">
                                <DemandLinesToggleView
                                    onChange={refetch}
                                    refreshCallback={(cb) => cbRef.current = cb}
                                    className="mb-5"
                                    demand={demand} />
                            </div>

                            <TrackPaymentTransactions
                                className="mb-5"
                                paymentReference={demandId} />

                            <AssociatedDocumentsView demandId={demandId} />
                        </>}
                </If>
            </CardPanelBody>
        </CardPanel>
    )
}

const DemandHeaderPanel = ({ demand, className }: { demand: DemandHeader, className?: string }) => {
    return (
        <div className={clsx(className, 'd-flex flex-wrap')}>
            <div className="w-xl-125px me-3 my-2">
                <span className="text-gray-600">Demand #</span>
                <div className="text-gray-700 fw-bolder">
                    <span>{demand?.slcmDemandNo}</span>
                </div>
            </div>

            <div className="w-xl-125px me-3 my-2">
                <span className="text-gray-600">Demand Date</span>
                <div className="text-gray-700 fw-bolder">
                    <DateFormat date={demand?.slcmDemandGenerationDate} />
                </div>
            </div>

            <div className="w-xl-125px me-3 my-2">
                <span className="text-gray-600">Due Date</span>
                <div className="text-gray-700 fw-bolder">
                    <DateFormat date={demand?.slcmDueDate} />
                </div>
            </div>
            <div className="flex-fill w-xl-125px me-3 my-2">
                <span className="text-gray-600">Semester</span>
                <div className="text-gray-700 fw-bolder">
                    <span>{demand?.slcmSemesterName}</span>
                </div>
            </div>
            <div className="flex-fill" />
            <div className="my-2 me-3 text-end">
                <span className="text-gray-600">Amount</span>
                <div className="text-gray-700 fw-bolder">
                    <Money
                        currencyId={demand?.transactionCurrencyId?.id}
                        amount={demand?.slcmTotalAmount} />
                </div>
            </div>
            <div className="my-2 text-end">
                <span className="text-gray-600">Net Payable</span>
                <div className="text-gray-700 fw-bolder"><Money currencyId={demand?.transactionCurrencyId?.id}
                    amount={(demand.slcmCurrentbalance || 0) - (demand.slcmInterimAmountPaid || 0)} /></div>
            </div>

            {(!!demand?.slcmPTPTNTotal || !!demand?.slcmPTPTNAmount || !!demand?.slcmPTPTNRemaining) &&
                <div className="d-flex w-100">
                    <div className="w-xl-125px me-3 my-2">
                        <span className="text-gray-600">PTPTN Total</span>
                        <div className="text-gray-700 fw-bolder">
                            <Money
                                currencyId={demand?.transactionCurrencyId?.id}
                                amount={demand?.slcmPTPTNTotal} />
                        </div>
                    </div>
                    <div className="w-xl-125px me-3 my-2">
                        <span className="text-gray-600">PTPTN Consumed</span>
                        <div className="text-gray-700 fw-bolder">
                            <Money
                                currencyId={demand?.transactionCurrencyId?.id}
                                amount={demand?.slcmPTPTNAmount} />
                        </div>
                    </div>
                    <div className="w-xl-125px me-3 my-2">
                        <span className="text-gray-600">PTPTN Remaining</span>
                        <div className="text-gray-700 fw-bolder">
                            <Money
                                currencyId={demand?.transactionCurrencyId?.id}
                                amount={demand?.slcmPTPTNRemaining} />
                        </div>
                    </div>
                </div>}
        </div>
    )
}
