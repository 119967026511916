import { If, ListQueryStatus } from '../../../../widgets-core';

import { Accordion } from 'react-bootstrap';
import { Contact } from '../../../../models';
import { useGetProgramRequirementsByProgramIdQuery } from '../../../../services/program-requirements.api';
import { ProgramRequirements } from '../../../program-requirements';

export const ProfileProgramDetailsView = ({ contact, className }: {
  contact: Contact,
  className?: string
}) => {
  return <>
    {contact?.mshiedCurrentProgramId?.id &&
      <ProgramRequirementsView
        className={className}
        programId={contact?.mshiedCurrentProgramId?.id} />}
  </>
}

const ProgramRequirementsView = ({ programId, className }: {
  programId: string,
  className?: string,
}) => {
  const { data: entities, isLoading, isError, isFetching, isSuccess, refetch }
    = useGetProgramRequirementsByProgramIdQuery(programId);

  return (
    <Accordion className={className}>
      <Accordion.Item eventKey={""}>
        <Accordion.Header bsPrefix="accordion-header accordion-header-sm">
          <h5 className='text-gray-700 my-0'>Program Details</h5>
        </Accordion.Header>
        <Accordion.Body>
          <div className="text-end mb-3">
            <button
              onClick={refetch}
              type="button"
              title="Refresh"
              className='btn btn-sm btn-link text-hover-primary p-0 me-3'>
              <i className="las la-redo-alt fs-2"></i>
            </button>
          </div>

          <ListQueryStatus
            isLoading={isLoading || isFetching}
            isSuccess={isSuccess}
            isError={isError}
            data={entities} />

          <If condition={!isLoading && !isFetching && isSuccess}>
            <ProgramRequirements
              className="mb-2"
              data={entities} />
          </If>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}
