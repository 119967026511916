import { OnResultFunction, QrReader } from 'react-qr-reader';

import { useNavigate } from "react-router-dom";

export const QRScanner = ({ className }: {
    className?: string,
}) => {
    const navigate = useNavigate();

    const handleResult: OnResultFunction = (result, err) => {
        if (!!result) {
            const text = result?.getText();
            navigate(text)
        }
    }

    return (
        <QrReader
            className={className}
            onResult={handleResult}
            constraints={{ facingMode: "environment" }}
            videoStyle={{ width: '100%', maxHeight: '100%', height: '400px' }}
            containerStyle={{ width: '100%', maxHeight: '100%', height: '400px' }}
        />
    );
}