import { ApiTags, rootApi } from './api';

import { AcademicPeriodSchedule } from "../models";

const TAG = ApiTags.AcademicPeriodDetail;

const academicPeriodScheduledApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getAcademicPeriodSchedule: builder.query<AcademicPeriodSchedule, string>({
            query: (id) => `/AcademicPeriodSchedules/${id}`,
            providesTags: (result, err) =>
                result ?
                    [{ type: TAG, id: result.slcmAcademicperiodscheduleId }, TAG] : [TAG]
        }),

        getAcademicPeriodSchedules: builder.query<AcademicPeriodSchedule[], void>({
            query: () => `/AcademicPeriodSchedules`,
            providesTags: (result, err) =>
                result ?
                    [...result.map((p) => ({ type: TAG, id: p.slcmAcademicperiodscheduleId })), TAG] :
                    [TAG]
        }),

        getAcademicPeriodSchedulesByPeriodId: builder.query<AcademicPeriodSchedule[], string>({
            query: (periodId) => `/AcademicPeriodSchedules/period(${periodId})`,
            providesTags: (result, err) =>
                result ?
                    [...result.map((p) => ({ type: TAG, id: p.slcmAcademicperiodscheduleId })), TAG] :
                    [TAG]
        }),
    })
})

export const {
    useGetAcademicPeriodScheduleQuery,
    useGetAcademicPeriodSchedulesQuery,
    useGetAcademicPeriodSchedulesByPeriodIdQuery,
    useLazyGetAcademicPeriodSchedulesByPeriodIdQuery
} = academicPeriodScheduledApi;