import { FC, createContext, useContext, useEffect, useState } from "react";

import { Contact } from "../../models";
import { useAuth } from "../../modules/auth";
import { useLazyGetContactByUserNameQuery } from "../../services/contacts.api";
import { useLazyGetEntityImageQuery } from "../../services/entity-image.api";

export interface LoginUser {
    userName?: string,
    contact?: Contact,
    image?: string,
    setImage: (image: string) => void,
    getContact: () => void,
}

const UserContext = createContext<LoginUser>({
    setImage: () => { },
    getContact: () => { },
})

export const useUser = () => {
    return useContext(UserContext)
}

export const UserProvider: FC = ({ children }) => {
    const { auth, logout } = useAuth()
    const [userName, setUserName] = useState<string>()
    const [getContactByUserName, { data: contact }] = useLazyGetContactByUserNameQuery()
    const [getEntityImage, { data: contactImage }] = useLazyGetEntityImageQuery()
    const [image, setImage] = useState<string>();

    const getContact = () => {
        if (!auth) return;

        getContactByUserName({ authType: auth.authType, userName: auth.userName })
            .unwrap()
            .catch(err => {
                console.error("Login Contact error", err)
                logout()
            });
    }

    useEffect(() => {
        if (!auth) return;

        setUserName(auth.userName)
        getContact()
        // eslint-disable-next-line
    }, [auth])

    useEffect(() => {
        if (!contact) return;

        getEntityImage({ entity: "contacts", id: contact.contactId })

        // eslint-disable-next-line
    }, [contact])

    useEffect(() => {
        if (!contactImage) return;
        setImage(contactImage)

        // eslint-disable-next-line
    }, [contactImage])

    return (
        <UserContext.Provider value={{ userName, contact, image, setImage, getContact }}>
            {children}
        </UserContext.Provider>
    )
}