import { Contact, InsuranceDetails } from '../../../models'
import { DateFormat, If, ListQueryStatus } from '../../../widgets-core'

import Accordion from 'react-bootstrap/esm/Accordion'
import React from 'react'
import clsx from 'clsx'
import { useGetInsuranceEntriesByStudentIdQuery } from '../../../services/insurance-details.api'

export const StudentInsuranceDetails = ({ contact, className }: {
    contact: Contact,
    className?: string,
}) => {
    const { data: entities, isLoading, isError, isFetching, isSuccess, refetch }
        = useGetInsuranceEntriesByStudentIdQuery(contact.contactId);

    return (
        <Accordion className={className}>
            <Accordion.Item eventKey={""}>
                <Accordion.Header bsPrefix="accordion-header accordion-header-sm">
                    <h5 className='text-gray-700 my-0'>Insurance Details</h5>
                </Accordion.Header>
                <Accordion.Body>
                    <div className="text-end mb-3">
                        <button
                            onClick={refetch}
                            type="button"
                            title="Refresh"
                            className='btn btn-sm btn-link text-hover-primary p-0 me-3'>
                            <i className="las la-redo-alt fs-2"></i>
                        </button>
                    </div>

                    <ListQueryStatus
                        isLoading={isLoading || isFetching}
                        isSuccess={isSuccess}
                        isError={isError}
                        data={entities} />

                    <If condition={!isLoading && !isFetching && isSuccess}>
                        {entities?.map(item => <React.Fragment key={item.slcmInsurancedetailsId}>
                            <InsuranceDetailsPanel
                                className="mb-2"
                                insurance={item} />
                        </React.Fragment>)}
                    </If>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

const InsuranceDetailsPanel = ({ insurance, className }: {
    insurance: InsuranceDetails,
    className?: string
}) => {
    return (
        <div className={clsx(className, "border px-5 rounded py-3 fs-7")}>
            <div className="d-flex flex-wrap flex-sm-row">
                <div className="w-xl-150px my-2 pe-3">
                    <span className="text-gray-600">Year</span>
                    <div className="text-gray-700 fw-bolder">
                        <span>{insurance?.slcmYear}</span>
                    </div>
                </div>
                <div className="w-xl-150px flex-fill my-2 pe-3">
                    <span className="text-gray-600">Semester</span>
                    <div className="text-gray-700 fw-bolder">
                        <span>{insurance?.slcmSemesterName}</span>
                    </div>
                </div>
                <div className="w-xl-150px flex-fill my-2 pe-3">
                    <span className="text-gray-600">Policy No</span>
                    <div className="text-gray-700 fw-bolder">
                        <span>{insurance?.slcmPolicyNoName}</span>
                    </div>
                </div>
                <div className="w-xl-150px my-2 pe-3">
                    <span className="text-gray-600">Policy Type</span>
                    <div className="text-gray-700 fw-bolder">
                        <span>{insurance?.slcmPolicyTypeName}</span>

                    </div>
                </div>
                <div className="w-xl-150px my-2 pe-3">
                    <span className="text-gray-600">Start Date</span>
                    <div className="text-gray-700 fw-bolder">
                        <DateFormat date={insurance?.slcmStartDate} />
                    </div>
                </div>
                <div className="w-xl-150px my-2 pe-3">
                    <span className="text-gray-600">Expiry Date</span>
                    <div className="text-gray-700 fw-bolder">
                        <DateFormat date={insurance?.slcmExpiryDate} />
                    </div>
                </div>
            </div>
        </div>
    )
}
