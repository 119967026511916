import "./timeline-item.css";

import { FC } from "react";
import clsx from "clsx";

export type TimelineGroupProps = {
    icon: "PRIMARY" | "SUCCESS" | "WARNING" | "DANGER" | undefined,
    time: string
}

export const TimelineGroup: FC<TimelineGroupProps> = ({ children, icon, time }) => {
    return (
        <div className='timeline-item mb-0'>
            <div className='timeline-label fw-bolder text-gray-700'>{time}</div>
            <div className='timeline-badge'>
                <i className={clsx(
                    'fa fa-genderless fs-1',
                    icon === "PRIMARY" && "text-primary",
                    icon === "SUCCESS" && "text-success",
                    icon === "WARNING" && "text-warning",
                    icon === "DANGER" && "text-danger",
                )}></i>
            </div>
            <div className='timeline-content'>
                {children}
            </div>
        </div>
    )
}