import { Deferment, getDefermentStatusCode } from '../../models/deferment'
import React, { useEffect, useState } from 'react'
import { useCreateDefermentMutation, useDeleteDefermentMutation, useGetDefermentByDemandLineIdQuery, useUpdateDefermentMutation } from '../../services/deferments.api'

import { SlcmDefermentStatuscode } from "../../models/deferment/enums/slcmDefermentStatuscode"
import clsx from 'clsx'
import moment from 'moment'
import { useGetDemandLineQuery } from '../../services/demand-lines.api'

export const DefermentPayment = ({ demandLineId, className }: {
    demandLineId: any
    className?: string
}) => {
    const { data: demandLine } = useGetDemandLineQuery(demandLineId)
    const { data: deferredPaymentLines } = useGetDefermentByDemandLineIdQuery(demandLineId)
    const [addDeferment] = useCreateDefermentMutation()
    const [updateDeferment] = useUpdateDefermentMutation()
    const [deleteDeferment] = useDeleteDefermentMutation()

    const [paymentLines, setPaymentLines] = useState<Partial<Deferment>[]>([])
    const [draftLine, setDraftLine] = useState<Partial<Deferment>>({
        slcmDemandLine: { id: demandLineId, logicalName: 'slcm_demandline' },
    })
    const [amountValidation, setAmountValidation] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        if (!deferredPaymentLines) return;
        setPaymentLines([...deferredPaymentLines])

    }, [deferredPaymentLines])

    const savePaymentLines = () => {
        const paymentsLinesToUpdate = paymentLines.filter(item => item.slcmDefermentId)
        const paymentsLinesToCreate = paymentLines.filter(item => !item.slcmDefermentId)

        setLoading(true)
        const promises: Promise<any>[] = []
        paymentsLinesToCreate.forEach((deferment) => {
            const promise = addDeferment(deferment)
            promises.push(promise)
        })

        paymentsLinesToUpdate.forEach((deferment) => {
            if (deferment.slcmDefermentId) {
                const promise = updateDeferment({ id: deferment.slcmDefermentId, entity: deferment })
                promises.push(promise)
            }
        })

        Promise.all(promises)
            .finally(() => setLoading(false))
    }

    const removePaymentLine = (id: string) => {
        deleteDeferment(id)
            .then(() => {
                setPaymentLines(paymentLines.filter(item => item.slcmDefermentId !== id))
            })
    }

    const getTotalAmount = () => {
        let totalAmount = 0;
        paymentLines.forEach((item) => {
            totalAmount += (item.slcmAmount || 0)
        })

        return totalAmount;
    }

    const defermentAmount = getTotalAmount();
    const lineAmount = (demandLine?.slcmCurrentbalance || 0) - (demandLine?.slcmPTPTNEstimatedAmount || 0);
    const balanceAmount = lineAmount - defermentAmount

    return (
        <div className={clsx(className, "p-5")}>
            <h6 className='text-gray-600'>Deferment Plan</h6>

            {paymentLines.map((data, index) =>
                <EditPaymentLine
                    key={index}
                    deferment={data}
                    onChange={(value) => {
                        setPaymentLines(paymentLines.map((item, i) => index === i ? value : item))
                    }}

                    onRemove={() => {
                        if (!data.slcmDefermentId)
                            setPaymentLines(paymentLines.filter(item => item !== data))
                        else
                            removePaymentLine(data.slcmDefermentId)
                    }}
                />
            )}

            {(paymentLines.length < 6 &&
                balanceAmount > 0 &&
                paymentLines[0]?.statuscode !== SlcmDefermentStatuscode.Submitted &&
                paymentLines[0]?.statuscode !== SlcmDefermentStatuscode.Accepted) &&
                <AddPaymentLine
                    deferment={draftLine}
                    onChange={(value) => {
                        setDraftLine(value)

                        if ((!value.slcmAmount && value.slcmAmount) || (!value.slcmRequestedDueDate && value.slcmRequestedDueDate) || (!value.slcmRemarks && value.slcmRemarks)) {
                            setAmountValidation("Required field");
                            return <></>
                        } else {
                            setAmountValidation("");
                        }
                    }}
                    amountValidation={amountValidation}
                    onSave={(value) => {
                        if (!value.slcmAmount || !value.slcmRequestedDueDate || !value.slcmRemarks) {
                            setAmountValidation("Required field"); return <></>
                        } else {
                            setAmountValidation("");
                        }

                        setDraftLine({
                            slcmDemandLine: { id: demandLineId, logicalName: 'slcm_demandline' },
                        });
                        setPaymentLines([...paymentLines, value])
                    }}
                />
            }

            <div className='d-flex flex-wrap mt-3'>
                <div className='my-1 me-5'>
                    <span className='text-gray-600'>Line Amount</span>
                    <div className='text-gray-700 fw-bolder'>
                        <span>{lineAmount}</span>
                    </div>
                </div>
                <div className='my-1 me-5'>
                    <span className='text-gray-600'>Deferment Amount</span>
                    <div className='text-gray-700 fw-bolder'>
                        <span>{defermentAmount}</span>
                    </div>
                </div>
                <div className='my-1'>
                    <span className='text-gray-600'>Balance Amount</span>
                    <div className='text-gray-700 fw-bolder'>
                        <span>{balanceAmount}</span>
                    </div>
                </div>
                <div className='flex-fill'> {/** filler */}</div>
                <div className='text-end'>
                    {paymentLines[0]?.statuscode !== SlcmDefermentStatuscode.Submitted && paymentLines[0]?.statuscode !== SlcmDefermentStatuscode.Accepted &&
                        <button
                            disabled={(defermentAmount !== lineAmount) || loading}
                            type="button"
                            className='btn btn-sm btn-primary' onClick={savePaymentLines}>
                            {!loading && <>
                                <i className="las la-save fs-3 my-0"></i>
                                <span className='indicator-label'>Save</span>
                            </>}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    }
                </div>
            </div>

            {defermentAmount !== lineAmount &&
                <div className='text-danger flex-fill text-wrap fs-8 fst-italic text-center my-2'>
                    You will not be able to save the screen if the amount entered is not equals to the demand line's amount.
                </div>}
        </div>
    )
}

const EditPaymentLineFields = ({ deferment, onChange, amountValidation }: {
    deferment: Partial<Deferment>,
    onChange: (deferment: Partial<Deferment>) => void,
    amountValidation?: string
}) => {
    const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange({
            ...deferment,
            slcmAmount: Number(e.target.value || 0)
        })
    }

    const onChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange({
            ...deferment,
            slcmRequestedDueDate: e.target.value ? moment(e.target.value).toDate() : undefined
        })
    }

    const onChangeRemarks = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange({
            ...deferment,
            slcmRemarks: e.target.value || ""
        })
    }

    return (
        <>
            <div className='w-xl-125px my-1 me-3'>
                <div className='text-gray-700 fw-bolder'>
                    <input
                        type='text'
                        onChange={onChangeAmount}
                        disabled={(deferment.statuscode === SlcmDefermentStatuscode.Submitted || deferment.statuscode === SlcmDefermentStatuscode.Accepted)}
                        value={deferment.slcmAmount || ''}
                        name='slcmAmount'
                        className='form-control form-control-sm'
                        placeholder='Amount'
                    />
                </div>
                <span className='text-danger fs-8 fst-italic'>{amountValidation || ""}</span>
            </div>

            <div className='w-xl-150px my-1 me-3'>
                <div className='text-gray-700 fw-bolder'>
                    <input
                        type='date'
                        onChange={onChangeDate}
                        value={deferment.slcmRequestedDueDate ? moment(deferment.slcmRequestedDueDate).format("yyyy-MM-DD") : ''}
                        name='slcmRequestedDueDate'
                        disabled={deferment.statuscode === SlcmDefermentStatuscode.Submitted || deferment.statuscode === SlcmDefermentStatuscode.Accepted}
                        min={moment(new Date()).format("yyyy-MM-DD")}
                        className='form-control form-control-sm'
                        placeholder='startDate'
                    />
                </div>
                <span className='text-danger fs-8 fst-italic'>{amountValidation || ""}</span>
            </div>

            <div className='flex-fill w-xl-150px my-1 me-3'>
                <div className='text-gray-700 fw-bolder'>
                    <input
                        type='text'
                        onChange={onChangeRemarks}
                        value={deferment.slcmRemarks || ""}
                        disabled={deferment.statuscode === SlcmDefermentStatuscode.Submitted || deferment.statuscode === SlcmDefermentStatuscode.Accepted}
                        name='slcmRemarks'
                        className='form-control form-control-sm'
                        placeholder='Reason'
                    />
                </div>
                <span className='text-danger fs-8 fst-italic'>{amountValidation || ""}</span>
            </div>

            {(deferment.statuscode && deferment.statuscode !== SlcmDefermentStatuscode.Draft) &&
                <div className='w-xl-125px my-1 me-2'>
                    <span className="text-gray-700">Status</span>
                    <div className='text-gray-700 fw-bolder'>
                        {getDefermentStatusCode(deferment.statuscode)}
                    </div>
                </div>}
        </>
    )
}

const EditPaymentLine = ({ deferment, onChange, onRemove }: {
    deferment: Partial<Deferment>,
    onChange: (deferment: Partial<Deferment>) => void,
    onRemove: (deferment: Partial<Deferment>) => void
}) => {
    return (
        <div className='d-flex flex-wrap'>
            <EditPaymentLineFields deferment={deferment} onChange={onChange} />

            {deferment.statuscode !== SlcmDefermentStatuscode.Submitted && deferment.statuscode !== SlcmDefermentStatuscode.Accepted &&
                <div className='text-end'>
                    <button className='btn btn-sm btn-danger'
                        type="button"
                        onClick={() => onRemove(deferment)}>
                        <i className="las la-trash fs-3 my-0"></i>
                        Remove
                    </button>
                </div>
            }
        </div>
    )
}

const AddPaymentLine = ({ deferment, onChange, onSave, amountValidation }: {
    deferment: Partial<Deferment>,
    onChange: (deferment: Partial<Deferment>) => void,
    onSave: (deferment: Partial<Deferment>) => void,
    amountValidation?: string
}) => {
    return (
        <div className='d-flex flex-wrap'>
            <EditPaymentLineFields deferment={deferment} onChange={onChange} amountValidation={amountValidation} />
            <div className='my-1'>
                <button className='btn btn-sm btn-primary'
                    type="button"
                    onClick={() => onSave(deferment)}>
                    <i className="las la-plus my-0 fs-3"></i>
                    Add
                </button>
            </div>
        </div>
    )
}