import { FC } from "react"
import { PageTitle } from "../../../_metronic/layout/core";


export const FlywirePaymentWrapper: FC = () => {
    return (<>
        <PageTitle breadcrumbs={[]}>{'Fee Structure'}</PageTitle>
        
    </>)
}
