import { ApiTags, rootApi } from './api';

import { DemandHeader } from "../models";

const TAG = ApiTags.Demand;
const getTags = (entities: DemandHeader[]) => [...entities.map((p) => ({ type: TAG, id: p.slcmDemandheaderId })), TAG]

const demandsApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getDemand: builder.query<DemandHeader, string>({
            query: (id) => `/Demands/${id}`,
            providesTags: (result) =>
                result ? [{ type: TAG, id: result.slcmDemandheaderId }, TAG] : [TAG]
        }),

        getDemands: builder.query<DemandHeader[], void>({
            query: () => `/Demands`,
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),

        getDemandsByStudentId: builder.query<DemandHeader[], string>({
            query: (studentId) => `/Demands/student/${studentId}`,
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),

        getDemandByDocumentNo: builder.query<DemandHeader, string>({
            query: (documentNo) => `/Demands/document/${documentNo}`,
            providesTags: (result) =>
                result ? [{ type: TAG, id: result.slcmDemandheaderId }, TAG] : [TAG]
        }),
    })
})

export const {
    useGetDemandQuery,
    useGetDemandsQuery,
    useGetDemandsByStudentIdQuery,
    useGetDemandByDocumentNoQuery,
    useLazyGetDemandByDocumentNoQuery,
} = demandsApi;