import { Service, ServiceRequest, getServiceRequestStatusLabel} from "../../../../models"
import { useEffect, useState } from "react"

import { RequestField } from "../../../../client-models"
import { ViewServiceRequestField } from "./ViewServiceRequestField"
import clsx from "clsx"
import { requestFields } from "./service-request-fields"

export const ViewServiceRequestFields = ({ service, serviceRequest, className }: {
    serviceRequest: ServiceRequest,
    service: Service,
    className?: string,
}) => {
    const [fields, setFields] = useState<RequestField[]>([]);

    useEffect(() => {
        if (!service) return;

        const fields: RequestField[] = [];
        const showField = (name: string) => {
            const field = requestFields.find(p => p.name === name);
            if (field) {
                fields.push({ ...field });
            }
        }

        if (service.slcmYear) showField("slcmYear");
        if (service.slcmSemester) showField("slcmSemester");
        if (service.slcmCourseFlag) showField("slcmCourseFlag");
        if (service.slcmSemesterBreak) showField("slcmSemesterBreak");
        if (service.slcmOwnerName) showField("slcmOwnerName");
        if (service.slcmOwnerPhoneNo) showField("slcmOwnerPhoneNo");
        if (service.slcmRelationship) showField("slcmRelationship");
        if (service.slcmVolume) showField("slcmVolume");
        if (service.slcmCourse) showField("slcmCourse");
        if (service.slcmAwards) showField("slcmAwards");
        if (service.slcmNumberofPax) showField("slcmNumberOfPax");
        if (service.slcmCurrentEmployment) showField("slcmCurrentEmployment");
        if (service.slcmNewMobile) showField("slcmNewMobile");
        if (service.slcmStartDate) showField("slcmStartDate");
        if (service.slcmBookTitle) showField("slcmBookTitle");
        if (service.slcmEndDate) showField("slcmEndDate");
        if (service.slcmBarcode) showField("slcmBarcode");
        if (service.slcmArticleTitle) showField("slcmArticleTitle");
        if (service.slcmReason) showField("slcmReason");
        if (service.slcmCarPlateNo) showField("slcmCarPlateNo");
        if (service.slcmJournalTitle) showField("slcmJournalTitle");
        if (service.slcmDesignation) showField("slcmDesignation");
        if (service.slcmNewPassportNo) showField("slcmNewPassportNo");
        if (service.slcmDirectorName) showField("slcmDirectorName");
        if (service.slcmFeeName) showField("slcmFeeName");
        if (service.slcmCollectionType) showField("slcmCollectionType");
        if (service.slcmCampus) showField("slcmCampus");
        if (service.slcmLocation) showField("slcmLocation");
        if (service.slcmHospitalName) showField("slcmHospitalName");
        if (service.slcmNewPersonalEmail) showField("slcmNewPersonalEmail");
        if (service.slcmNewAddress) showField("slcmNewAddress");
        if (service.slcmRegistrationType) showField("slcmRegistrationType");
        if (service.slcmAchievements) showField("slcmAchievements");
        if (service.slcmCorrespondenceAddress) showField("slcmCorrespondenceAddress");
        if (service.slcmRepeatSemester) showField("slcmRepeatSemester");
        if (service.slcmDepartmentSpeciality) showField("slcmDepartmentSpeciality");
        if (service.slcmIssue) showField("slcmIssue");
        if (service.slcmDate) showField("slcmDate");
        if (service.slcmSemesterRegisteringfor) showField("slcmSemesterRegisteringfor");
        if (service.slcmHostelRoomNo) showField("slcmHostelRoomNo");
        if (service.slcmPurpose) showField("slcmPurpose");
        if (service.slcmQuantity) showField("slcmQuantity");
        if (service.slcmTotalFeeAmount) showField("slcmTotalFeeAmount");
        if (service.slcmCarType) showField("slcmCarType");
        if (service.slcmCarColour) showField("slcmCarColour");
        if (service.slcmStudentEmailID) showField("slcmStudentEmailID");
        if (service.slcmTransactionID) showField("slcmTransactionID");
        if (service.slcmVehiclemake) showField("slcmVehiclemake");
        if (service.slcmRemarks) showField("slcmRemarks");

        setFields(fields);
    }, [service]);

    return (
        <div className={clsx(className, "d-flex flex-wrap fs-7")}>
            <div className="w-xl-150px me-3 my-1">
                <span className="text-gray-600">Request #</span>
                <div className="text-gray-700 fw-bolder">
                    <span>{serviceRequest.slcmCode}</span>
                </div>
            </div>

            {fields.map(field => <ViewServiceRequestField
                key={field.name}
                serviceId={service.slcmRequestsId || ''}
                request={serviceRequest}
                field={field} />)}

            <div className="flex-fill">{/** filler */}</div>

            <div className="w-xl-125px my-1">
                <span className="text-gray-600">Status</span>
                <div className="text-gray-700 fw-bolder">
                    <span>
                        {getServiceRequestStatusLabel(serviceRequest.statuscode)}
                    </span>
                </div>
            </div>
        </div>
    )
}