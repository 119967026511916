import { SlcmRegistrationtype } from "./enums/slcmRegistrationtype"
import { SlcmRegistrationwindowSlcmPayments } from "./enums/slcmRegistrationwindowSlcmPayments";
import { SlcmYear } from "./enums/slcmYear"

export * from "./RegistrationWindow";


export const getRegistrationLabel = (statusCode?: SlcmRegistrationtype) => {
    switch (statusCode) {
        case SlcmRegistrationtype.Semester: return "Semester";
        case SlcmRegistrationtype.Year: return "Year";
        default: return "";
    }
}

export const getYear = (statusCode?: SlcmYear) => {
    switch (statusCode) {
        case SlcmYear.Year1: return "Year 1";
        case SlcmYear.Year2: return "Year 2";
        case SlcmYear.Year3: return "Year 3";
        case SlcmYear.Year4: return "Year 4";
        default: return "";
    }
}

export const getRegistrationwindowSlcmPayments = (statusCode?: SlcmRegistrationwindowSlcmPayments) => {
    switch (statusCode) {
        case SlcmRegistrationwindowSlcmPayments.Yes: return "Yes";
        case SlcmRegistrationwindowSlcmPayments.No: return "No";
        default: return "";
    }
}