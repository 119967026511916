import { CardPanel, CardPanelBody, CardPanelHeader, CardPanelToolbar, If, ListQueryStatus } from "../../widgets-core"

import { Link } from "react-router-dom"
import { NotificationPanel } from "./NotificationPanel"
import React from "react"
import { RightArrowIcon } from "../../assets/icons"
import clsx from "clsx"
import { useGetNotifications } from "../../hooks/notification-event.hooks"

export const NotificationsCard = ({ limit, className }: {
  limit: number,
  className?: string,
}) => {
  const { notifications, isLoading, isError, isFetching, isSuccess, refetch } = useGetNotifications(limit)

  return (
    <CardPanel className={clsx(className)}>
      <CardPanelHeader
        className="min-h-5px"
        title="Notifications"
        subTitle="Alerts and notifications">
        <CardPanelToolbar>
          <If condition={!isLoading && !isFetching && isSuccess}>
            <ViewAll />
          </If>
          <button
            onClick={refetch}
            type="button"
            title="Refresh"
            className='btn btn-sm btn-link text-hover-primary p-0 ms-3'>
            <i className="las la-redo-alt fs-2"></i>
          </button>
        </CardPanelToolbar>
      </CardPanelHeader>
      <CardPanelBody className="pt-5">
        <div className='dashboard-card__body'>
          <ListQueryStatus
            isLoading={isLoading || isFetching}
            isSuccess={isSuccess}
            isError={isError}
            data={notifications} />

          <If condition={!isLoading && !isFetching && isSuccess}>
            {notifications.map(
              notification => <React.Fragment key={notification.slcmNotificationeventId}>
                <NotificationPanel
                  className='mb-2'
                  notificationEvent={notification} />
              </React.Fragment>)}
          </If>
        </div>
      </CardPanelBody>
    </CardPanel>
  )
}

const ViewAll = () => (
  <div className='py-1 text-center'>
    <Link
      to='/notifications'
      className='btn btn-sm btn-light text-primary py-2'
    >
      View All <RightArrowIcon />
    </Link>
  </div>)
