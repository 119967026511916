import clsx from "clsx"
import { FC } from "react"

type NavItemLinkProps = {
    active?: boolean,
    className?: string,
    href: string,
    onClick?: (event: React.MouseEvent) => void,
}
export const NavMenuLink: FC<NavItemLinkProps> = ({
    active,
    className,
    href,
    children,
    onClick }
) => {
    return (
        <a
            className={
                clsx('nav-link',
                    'btn btn-sm btn-color-muted btn-active btn-active-light-primary',
                    'fw-bolder px-4 me-1',
                    className,
                    { 'active': active })}
            href={href}
            onClick={onClick}
        >
            {children}
        </a>
    )
}