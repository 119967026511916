import clsx from "clsx"
import { FC } from "react"

type NavItemProps = {
    className?: string,
}

export const NavMenuItem: FC<NavItemProps> = ({ className, children }) => {
    return (
        <li className={clsx('nav-item', className)}>
            {children}
        </li >
    )
}
