import { InsuranceDetails } from '../models';
import { rootApi } from './api';

const insuranceDetailsApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getInsurance: builder.query<InsuranceDetails, string>({
            query: (id) => `/InsuranceDetails/${id}`,
        }),

        getInsuranceEntries: builder.query<InsuranceDetails[], void>({
            query: () => `/InsuranceDetails`,
        }),

        getInsuranceEntriesByStudentId: builder.query<InsuranceDetails[], string>({
            query: (studentId) => `/InsuranceDetails/student(${studentId})`,
        }),
    })
})

export const {
    useGetInsuranceQuery,
    useGetInsuranceEntriesQuery,
    useGetInsuranceEntriesByStudentIdQuery,
} = insuranceDetailsApi