import { OfflinePaymentButton } from './OfflinePaymentButton';
import { OnlinePaymentButton } from './OnlinePaymentButton';
import { ServiceRequestDocuments } from './ServiceRequestDocuments'
import { TrackPaymentTransactions } from './TrackPaymentTransactions'
import clsx from 'clsx';


import { useState } from 'react'

export const ServicePaymentPage = ({ serviceId, folderPath, amount, currencyId, currencyName }: {
    serviceId: string,
    folderPath: string,
    amount: number,
    currencyId?: string,
    currencyName?: string
}) => {
    return (
        <div className="card w-100">
             <div className='text-danger p-2 me-3'>
                Kindly make the payment for the requested service using below payment options. If service request submitted without payment, request will be approved by finance based on the outstanding

            </div>
            <div className="card-body p-0">
                <PaymentOptions
                    className='text-center'
                    serviceId={serviceId}
                    amount={amount}
                    currencyName={currencyName}
                    currencyId={currencyId}
                    folderPath={folderPath} />

                <TrackPaymentTransactions
                    className="mt-3 mb-2"
                    paymentReference={serviceId} />

                <ServiceRequestDocuments serviceId={serviceId} />
            </div>
        </div>
    )
}

const PaymentOptions = ({ serviceId, amount, currencyId, currencyName, folderPath, className }: {
    serviceId: string,
    amount: number,
    currencyId?: string,
    folderPath: string,
    className?: string,
    currencyName?: string
}) => {


    type PaymentMode = "Default" | "Online" | "Offline";
    const [paymentMode, setPaymentMode] = useState<PaymentMode>("Default")

    if (amount === 0 || amount < 0)
        return <></>

    return <div className={clsx('d-flex flex-wrap', className)}>
        {(currencyName === "USD" && (paymentMode === "Default" || paymentMode === "Online")) &&
            <div className='flex-fill my-2'>

                <OnlinePaymentButton
                    invoiceId={serviceId}
                    amount={amount}
                    currencyId={currencyId}
                    onClick={() => setPaymentMode("Online")}
                    feeBreakup={{
                        otherFee: Math.round(amount || 0),
                        tuitionFee: 0,
                        hostelFee: 0,
                    }}
                    paymentType={407450001} />
            </div>}

        {(paymentMode === "Default" || paymentMode === "Offline") &&
            <div className='flex-fill my-2'>
                <OfflinePaymentButton
                    folderPath={folderPath}
                    serviceId={serviceId}
                    amount={amount}
                    onClick={() => setPaymentMode("Offline")} />
            </div>}
    </div>;
}
