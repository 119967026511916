import { CardPanel, CardPanelBody, CardPanelHeader, CardPanelToolbar, If, ListQueryStatus } from "../../../widgets-core";

import React from "react"
import moment from "moment";
import { useGetApprovalCardsByRequestIdQuery } from "../../../services/approval-card.api";

export const SemesterRegApproval = ({ requestId, className }: {
    requestId: any,
    className?: string,
}) => {
    const { data: approvals, isLoading, isError, isFetching, isSuccess, refetch } = useGetApprovalCardsByRequestIdQuery(requestId);

    return (
        <CardPanel className={className}>
            <CardPanelHeader title='Approvals'>
                <CardPanelToolbar>
                    <button
                        onClick={refetch}
                        type="button"
                        title="Refresh"
                        className='btn btn-sm btn-link text-hover-primary p-0 me-3'>
                        <i className="las la-redo-alt fs-2"></i>
                    </button>
                </CardPanelToolbar>
            </CardPanelHeader>
            <CardPanelBody className="pt-0 scroll-y mh-300px">
                <div className='timeline-label timeline-label__empty' >
                    <ListQueryStatus
                        isLoading={isLoading || isFetching}
                        isSuccess={isSuccess}
                        isError={isError}
                        data={approvals} />

                    <If condition={!isLoading && !isFetching && isSuccess}>
                        {approvals &&
                            approvals.map(approval => <React.Fragment key={approval.activityId}>
                                <div key={approval.activityId} className='timeline-item'>
                                    <div className='timeline-badge'>
                                        <i className='fa fa-genderless text-warning fs-1'></i>
                                    </div>
                                    <div>
                                        <div className='fw-bolder text-gray-800 fs-6 ps-3'>
                                            {moment(approval.modifiedOn).fromNow()}
                                        </div>
                                        <div className='fw-normal timeline-content text-muted ps-3'>
                                            <p className="p-0 m-0">{approval.subject}</p>
                                            <p className="p-0 m-0">{approval.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>)}
                    </If>
                </div>
            </CardPanelBody>
        </CardPanel>
    )
}