import { FC, useEffect, useRef, useState } from "react";

import { LayoutSplashScreen } from "../../../layout/core";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { getMSALConfig } from "../core/_requests";
import { useAuth } from "../core/Auth";

export const AuthProvider: FC = ({ children }) => {
    const { logout } = useAuth()

    const [msalInstance, setMSALInstance] = useState<PublicClientApplication>()
    const didRequest = useRef(false)

    const getConfig = () => {
        getMSALConfig()
            .then(({ data }) => {
                const instance = new PublicClientApplication({
                    auth: {
                        clientId: data.clientId,
                        authority: `https://login.microsoftonline.com/${data.tenantId}`,
                        redirectUri: data.redirectUri,
                    },
                    cache: {
                        cacheLocation: "sessionStorage",
                        storeAuthStateInCookie: false,
                    }
                })

                setMSALInstance(instance);
            })
            .catch((err) => {
                console.error(err)
                logout()
            })
    }

    useEffect(() => {
        if (!didRequest.current) {
            getConfig()
        }

        return () => { didRequest.current = true }
        // eslint-disable-next-line
    }, [didRequest])

    return (
        <>
            {!!msalInstance ?
                <MsalProvider instance={msalInstance}>
                    {children}
                </MsalProvider> : <LayoutSplashScreen />}
        </>
    )
}