import { Service } from "../../../models";
import { StepperNavItem } from "../../wizards/components/StepperNavItem";
import clsx from "clsx";

export const StepsNavMenu = ({ service, stepIndex, className }: {
    service: Service,
    stepIndex?: number,
    className?: string
}) => {
    let stepNo = 0;

    return <div className={clsx(className, 'd-flex',
        'justify-content-center justify-content-xl-start flex-row-auto',
        'bg-white rounded',
        'w-100 w-xl-300px w-xxl-400px min-h-xl-300px')}>
        <div className='p-10'>
            <div className='stepper-nav'>
                <StepperNavItem
                    className={clsx({ "current": stepIndex === 1 })}
                    no={++stepNo}
                    name="General"
                    description="Enter request details" />

                {service.slcmDocumentChecklist &&
                    <StepperNavItem
                        no={++stepNo}
                        name="Documents"
                        description="Submit documents" />}

                {service.slcmPaidService &&
                    <StepperNavItem
                        no={++stepNo}
                        name="Payment"
                        description="Make payment" />}

                {/* <StepperNavItem
                    no={++stepNo}
                    name="Submitted"
                    description="Request submitted." /> */}
            </div>
        </div>
    </div>;
}

