import { ApiTags, rootApi } from './api';

import { HallTicket } from "../models";

const TAG = ApiTags.HallTicket;

const hallTicketApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getHallTicket: builder.query<HallTicket, string>({
            query: (id) => `/Hallticket/${id}`,
            providesTags: (result) =>
                result ?
                    [{ type: TAG, id: result?.slcmStudent?.id }, TAG] : [TAG]
        }),

        getHallTickets: builder.query<HallTicket[], void>({
            query: () => `/Hallticket`,
            providesTags: (result) =>
                result ?
                    [...result.map((p) => ({ type: TAG, id: p?.slcmStudent?.id })), TAG] :
                    [TAG]
        }),

        getStudentHallTickets: builder.query<HallTicket[], string>({
            query: (studentId) => `/Hallticket/student(${studentId})`,
            providesTags: (result) =>
                result ?
                    [...result.map((p) => ({ type: TAG, id: p?.slcmStudent?.id })), TAG] :
                    [TAG]
        }),



    })
})

export const {
    useGetStudentHallTicketsQuery,
    useLazyGetStudentHallTicketsQuery,
} = hallTicketApi;