import { FC } from "react"
import { PageTitle } from "../../../_metronic/layout/core";
import { ServiceRequestsView } from "../../modules/service-request";
import { SlcmServicerequestsStatuscode } from "../../models";
import { useParams } from "react-router-dom";
import { useUserContact } from "../../hooks/user";

export const ServiceRequestsWrapper: FC = () => {
    return (<>
        <PageTitle breadcrumbs={[]}>{'Service Requests'}
        </PageTitle>
        <ServiceRequests />
    </>)
}

const ServiceRequests: FC = () => {
    const contact = useUserContact()
    const { status }: { status?: string } = useParams();

    const statusCode = Number(status) as SlcmServicerequestsStatuscode;
    if (!statusCode) return <></>;

    return (
        <>
            {contact && <ServiceRequestsView
                contact={contact}
                status={statusCode} />}
        </>)
}
