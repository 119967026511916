/* eslint-disable jsx-a11y/anchor-is-valid */

import "./FeeSummary.scss";

import React, { FC, useEffect, useState } from 'react'

import { Contact } from "../../../models";
import { KTSVG } from '../../../../_metronic/helpers';
import { Loading } from '../../../widgets-core';
import { Money } from '../../../widgets-business';
import clsx from 'clsx';
import { useGetDemandsByStudentIdQuery } from "../../../services/demands.api";
import { useGetPaymentsByStudentIdQuery } from '../../../services/payments.api';

type FeeSummaryInfo = {
    currencyId: string,
    totalFee: number,
    totalDue: number,
    paidFee: number,
    lateFee: number,
    advanceFee: number,
}

export const FeeSummary = ({ contact, className }: {
    contact: Contact,
    className?: string
}) => {
    const [feeSummaryInfo, setFeeSummaryInfo] = useState<FeeSummaryInfo>({
        currencyId: '',
        totalFee: 0,
        totalDue: 0,
        paidFee: 0,
        lateFee: 0,
        advanceFee: 0
    });

    const { data: payments, isLoading: paymentsLoading } = useGetPaymentsByStudentIdQuery(contact.contactId);
    const { data: demands, isLoading } = useGetDemandsByStudentIdQuery(contact.contactId);

    useEffect(() => {
        if (!payments || !demands) return;

        const feeInfo = {
            currencyId: contact?.transactionCurrencyId?.id || '',
            totalFee: 0,
            totalDue: 0,
            paidFee: 0,
            lateFee: 0,
            advanceFee: 0,
        }

        demands?.forEach(demand => feeInfo.totalFee += demand.slcmTotalAmount || 0);
        payments?.forEach(payment => feeInfo.paidFee += payment.slcmAmount || 0);

        feeInfo.advanceFee = feeInfo.paidFee >= feeInfo.totalFee ?
            feeInfo.paidFee - feeInfo.totalFee : 0;
        feeInfo.totalDue = feeInfo.paidFee <= feeInfo.totalFee ?
            feeInfo.totalFee - feeInfo.paidFee : 0;

        setFeeSummaryInfo(feeInfo);
    }, [payments, demands, contact?.transactionCurrencyId]);

    const FeeCard: FC<{ className?: string }> = ({ className, children }) => {
        return <>
            <div className={clsx(className, 'p-5 min-h-175px')}>
                {children}
            </div>
        </>
    }

    const FeeInfo: FC<{ text: string, currencyId: string, amount: number }> = ({ text, currencyId, amount }) => {
        return <a href='#' className='text-success fw-bold fs-6 mt-2'>
            <p className="m-0">{text}</p>
            <p className="m-0">
                <Money currencyId={currencyId} amount={amount} />
            </p>
        </a>
    };


    const TotalFee = () => {
        return <>
            <FeeCard className='col bg-light-danger me-7'>
                <KTSVG
                    path='/media/icons/duotune/general/gen032.svg'
                    className='svg-icon-3x svg-icon-warning d-block my-2'
                />
                <FeeInfo text='Total Fee' currencyId={feeSummaryInfo.currencyId} amount={feeSummaryInfo.totalFee} />
            </FeeCard>
        </>
    }

    const PaidFee = () => {
        return <>
            <FeeCard className='col bg-light-success text-right'>
                <KTSVG
                    path='/media/icons/duotune/general/gen032.svg'
                    className='svg-icon-3x svg-icon-info d-block my-2'
                />
                <FeeInfo text='Paid Fee' currencyId={feeSummaryInfo.currencyId} amount={feeSummaryInfo.paidFee} />
            </FeeCard>
        </>
    }

    const LateFee = () => {
        return <>
            <FeeCard className='col bg-light-danger me-7'>
                <KTSVG
                    path='/media/icons/duotune/abstract/abs027.svg'
                    className='svg-icon-3x svg-icon-danger d-block my-2'
                />
                <FeeInfo text='Late Fee' currencyId={feeSummaryInfo.currencyId} amount={feeSummaryInfo.lateFee} />
            </FeeCard>
        </>
    }

    const AdvanceFee = () => {
        return <>
            <FeeCard className='col bg-light-success text-right'>
                <KTSVG
                    path='/media/icons/duotune/communication/com010.svg'
                    className='svg-icon-3x svg-icon-success d-block my-2' />
                <FeeInfo text="Advance Fee" currencyId={feeSummaryInfo.currencyId} amount={feeSummaryInfo.advanceFee} />
            </FeeCard>
        </>;
    }

    const DueFee: FC<{ className?: string }> = ({ className }) => {
        return <FeeCard className={clsx('bg-light-primary', className)}>
            <KTSVG
                path='/media/icons/duotune/arrows/arr075.svg'
                className='svg-icon-3x svg-icon-primary d-block my-2'
            />
            <FeeInfo text='Due Fee' currencyId={feeSummaryInfo.currencyId} amount={feeSummaryInfo.totalDue} />
        </FeeCard>
    }

    if (paymentsLoading || isLoading) {
        return <Loading status={"Pending"} />
    }

    return (
        <div className={clsx('card', className)}>
            <div className='circle-outer absolute-center p-5'>
                <DueFee className="round size-200px flex-center flex-direction-column" />
            </div>
            <div className='row g-0 mb-5'>
                <TotalFee />
                <PaidFee />
            </div>
            <div className='row g-0 '>
                <LateFee />
                <AdvanceFee />
            </div>
        </div>
    )
}

