import clsx from "clsx"
import { FC } from "react"

export interface ICardPanelBodyProps {
    className?: string,
}

export const CardPanelBody: FC<ICardPanelBodyProps> = ({ children, className }) => {
    return (
        <div className={clsx('card-body', className)}>
            {children}
        </div>
    )
}
