import { CardPanel, CardPanelBody, CardPanelHeader, CardPanelToolbar, If, ListQueryStatus } from '../../../widgets-core'
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { Contact, DemandHeader, getDemandDocumentType } from '../../../models'
import { DateFilterParams, DefaultGridColDef, formatDate } from '../../../helpers/grid';
import React, { useEffect, useState } from 'react'

import { AgGridReact } from 'ag-grid-react';
import { DownloadButton } from '../../payments/DownloadButton';
import { Link } from 'react-router-dom'
import { Money } from '../../../widgets-business'
import clsx from 'clsx';
import moment from 'moment';
import { useGetDemandsByStudentIdQuery } from '../../../services/demands.api';
import { useUserContact } from '../../../hooks/user';

export const DemandsView = ({ className }: {
    className?: string
}) => {
    const contact = useUserContact()

    return <>
        {contact && <Demands contact={contact} />}
    </>
}

type FilterView = "All" | "Paid" | "Due" | "Overdue";

const Demands = ({ contact, className }: {
    contact: Contact
    className?: string
}) => {
    const { data: demands, isLoading, isError, isFetching, isSuccess, refetch } = useGetDemandsByStudentIdQuery(contact.contactId);
    const [view, setView] = useState<FilterView>("All")
    const [filteredDemands, setFilteredDemands] = useState<DemandHeader[]>([]);

    const [columnDefs] = useState<ColDef[]>([
        {
            field: 'slcmDocumentType',
            headerName: 'Document Type',
            flex: 0,
            initialWidth: 150,
            cellClass: 'fw-bold fs-7',
            valueFormatter: (params) => getDemandDocumentType(params.value),
        },
        {
            field: 'slcmDemandNo',
            filter: true,
            headerName: 'Demand',
            flex: 0,
            initialWidth: 175,
            cellClass: 'fw-bold fs-7',
            cellRenderer: (props: ICellRendererParams) => {
                const demand: DemandHeader = props.data;
                return <Link to={`/demands/view-demand/${demand.slcmDemandheaderId}`}>
                    <span className='me-3 text-dark'>{demand.slcmDemandNo}</span>
                </Link>
            },
        },
        {
            field: 'slcmDemandGenerationDate',
            headerName: 'Demand Date',
            resizable: true,
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
            filterParams: DateFilterParams,
            cellClass: 'dateISO fw-bold fs-7',
            valueFormatter: (params) => formatDate(params.value),
            flex: 0,
            initialWidth: 100
        },
        {
            field: 'slcmTotalAmount',
            headerName: 'Amount',
            filter: 'agNumberColumnFilter',
            flex: 0,
            initialWidth: 125,
            cellClass: 'fw-bold fs-7',
            cellRenderer: (props: ICellRendererParams) => {
                const demand: DemandHeader = props.data;
                return <Money currencyId={demand.transactionCurrencyId?.id} amount={demand.slcmTotalAmount} />
            },
        },
        {
            field: 'slcmDueDate',
            headerName: 'Due Date',
            resizable: true,
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
            filterParams: DateFilterParams,
            cellClass: 'dateISO fw-bold fs-7',
            valueFormatter: (params) => formatDate(params.value),
            flex: 0,
            initialWidth: 100
        },
        {
            field: 'slcmCurrentbalance',
            headerName: 'Net Payable',
            filter: 'agNumberColumnFilter',
            flex: 0,
            initialWidth: 125,
            cellClass: 'fw-bold fs-7',
            cellRenderer: (props: ICellRendererParams) => {
                const demand: DemandHeader = props.data;
                return <Money currencyId={demand.transactionCurrencyId?.id} amount={demand.slcmCurrentbalance} />
            },
        },
        {
            field: 'slcmLink',
            headerName: 'Download',
            flex: 0,
            filter: false,
            initialWidth: 125,
            cellClass: 'fw-bold fs-7',
            cellRenderer: (props: ICellRendererParams) => {
                const demand: DemandHeader = props.data;
                return <DownloadButton
                    className='btn btn-sm btn-link py-0 text-dark'
                    label='Download'
                    link={demand?.slcmLink} />
            },
        },
        {
            headerName: 'Remarks',
            resizable: true,
            flex: 0,
            initialWidth: 250,
            cellClass: 'fw-bold fs-7',
            cellRenderer: (props: ICellRendererParams) => {
                const demand: DemandHeader = props.data;
                const balance = (demand.slcmCurrentbalance || 0) - (demand.slcmInterimAmountPaid || 0)
                if (balance <= 0) {
                    return <span>Payment for this demand is complete</span>
                } else if (moment(demand.slcmDueDate).isBefore(new Date(), "date")) {
                    return <span>Payment for this demand is overdue.</span>
                } else {
                    return <span>Payment for this demand is pending.</span>
                }
            },
        },
    ]);

    useEffect(() => {
        if (!demands) return;

        const today = new Date();
        let filteredDemands: DemandHeader[] = [];

        switch (view) {
            case "All":
                filteredDemands = demands;
                break;
            case "Paid":
                filteredDemands = demands.filter(p => (p.slcmCurrentbalance || 0) <= 0);
                break;
            case "Due":
                filteredDemands = demands.filter(p => (p.slcmCurrentbalance || 0) > 0);
                break;
            case "Overdue":
                filteredDemands = demands.filter(p =>
                    ((p.slcmCurrentbalance || 0) > 0) &&
                    (moment(p.slcmDueDate).isBefore(today, "date"))
                );
                break;
        }

        setFilteredDemands(filteredDemands)

    }, [view, demands])

    return (
        <CardPanel className={clsx(className, "bg-transparent")} >
            <CardPanelHeader
                title="Invoices"
                subTitle='Invoices generated'
                className='min-h-5px'>
                <CardPanelToolbar>
                    <button
                        onClick={refetch}
                        type="button"
                        title="Refresh"
                        className='btn btn-sm btn-link text-hover-primary p-0 me-3'>
                        <i className="las la-redo-alt fs-2"></i>
                    </button>
                    <DemandFilters
                        view={view}
                        onChange={view => setView(view)}
                        className="flex-fill" />
                </CardPanelToolbar>
            </CardPanelHeader>
            <CardPanelBody>
                <ListQueryStatus
                    isLoading={isLoading || isFetching}
                    isSuccess={isSuccess}
                    isError={isError}
                    data={demands} />

                <If condition={!isLoading && !isFetching && isSuccess && demands.length > 0}>
                    <div className="ag-theme-alpine" style={{ height: 400, width: 'auto' }}>
                        <AgGridReact
                            rowData={filteredDemands}
                            columnDefs={columnDefs}
                            pagination={true}
                            serverSideInfiniteScroll={true}
                            paginationPageSize={10}
                            cacheBlockSize={10}
                            rowHeight={30}
                            noRowsOverlayComponent={() => <span className='text-info fw-bold fs-7'>No invoices in this view.</span>}
                            getRowClass={(params) => {
                                const demand: DemandHeader = params.data;
                                const balance = (demand.slcmCurrentbalance || 0) - (demand.slcmInterimAmountPaid || 0)
                                if (balance <= 0) {
                                    return "bg-success bg-opacity-100 text-white";
                                } else if (moment(demand.slcmDueDate).isBefore(new Date(), "date")) {
                                    return "bg-danger bg-opacity-100 text-white";
                                } else {
                                    return "bg-warning bg-opacity-100 text-white";
                                }
                            }}
                            defaultColDef={DefaultGridColDef}
                            columnTypes={{ 'dateColumn': { field: '' } }}
                        />
                    </div>
                </If>
            </CardPanelBody>
        </CardPanel>
    )
}

const DemandFilters = ({ view, className, onChange }: {
    view: FilterView,
    className?: string,
    onChange?: (view: FilterView) => void,
}) => {
    return (
        <div className={className}>
            <input type="radio"
                className="btn-check"
                name="options"
                id="all"
                autoComplete="off"
                onChange={() => onChange && onChange("All")}
                checked={view === "All"} />
            <label className="btn btn-sm btn-light py-2 my-1 me-2" htmlFor="all">
                <i className="las la-filter fs-4"></i>
                All
            </label>

            <input type="radio"
                className="btn-check"
                name="options"
                id="paid"
                autoComplete="off"
                onChange={() => onChange && onChange("Paid")}
                checked={view === "Paid"} />
            <label className="btn btn-sm btn-light py-2 my-1 me-2" htmlFor="paid">
                <i className="las la-filter fs-4"></i>
                Paid
            </label>

            <input type="radio"
                className="btn-check"
                name="options"
                id="due"
                autoComplete="off"
                onChange={() => onChange && onChange("Due")}
                checked={view === "Due"} />
            <label className="btn btn-sm btn-light py-2 my-1 me-2" htmlFor="due">
                <i className="las la-filter fs-4"></i>
                Due
            </label>

            <input type="radio"
                className="btn-check btn-sm"
                name="options"
                id="overdue"
                autoComplete="off"
                onChange={() => onChange && onChange("Overdue")}
                checked={view === "Overdue"} />
            <label className="btn btn-sm btn-light py-2 my-1 me-2" htmlFor="overdue">
                <i className="las la-filter fs-4"></i>
                Overdue
            </label>
        </div>
    )
}