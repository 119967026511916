import { FC, Suspense } from 'react'
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom'

import { AcademicScheduleWrapper } from '../pages/academicschedule/AcademicScheduleWrapper'
import { AppointmentsWrapper } from '../pages/appointments/AppointmentsWrapper'
import { AttendanceScannerWrapper } from '../pages/attendance/AttendanceScannerWrapper'
import { CommunityWrapper } from '../pages/community/CommunityWrapper'
import { AppointmentsWrapper as CounsellorAppointmentsWrapper } from '../pages/appointments/counsellor/AppointmentsWrapper'
import { CourseHistoryWrapper } from '../pages/course-history/CourseHistoryWrapper'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { AppointmentsWrapper as DeanAppointmentsWrapper } from '../pages/appointments/dean/AppointmentsWrapper'
import { DemandsWrapper } from '../pages/fee-management/DemandsWrapper'
import { EditServiceRequestPageWrapper } from '../pages/service-request/EditServiceRequestPageWrapper'
import { FeeStructureWrapper } from '../pages/fee-structure/FeeStructureWrapper'
import { FeeSummaryWrapper } from '../pages/fee-management/FeeSummaryWrapper'
import { FlywirePaymentWrapper } from '../pages/flywire-payment/FlywirePaymentWrapper'
import { AppointmentsWrapper as HodAppointmentsWrapper } from '../pages/appointments/hod/AppointmentsWrapper'
import { MasterLayout } from '../layout/MasterLayout'
import { NewAppointmentWrapper } from '../pages/appointments/NewAppointmentWrapper'
import { NewAppointmentWrapper as NewCounsellorAppointmentWrapper } from '../pages/appointments/counsellor/NewAppointmentWrapper'
import { NewAppointmentWrapper as NewDeanAppointmentWrapper } from '../pages/appointments/dean/NewAppointmentWrapper'
import { NewAppointmentWrapper as NewHodAppointmentWrapper } from '../pages/appointments/hod/NewAppointmentWrapper'
import { NewServiceRequestPageWrapper } from '../pages/service-request/NewServiceRequestPageWrapper'
import { NotificationsView } from '../modules/notifications/NotificationsView'
import { PaymentInfoWrapper } from '../pages/fee-management/PaymentInfoWrapper'
import { PaymentSelectionWrapper } from '../pages/semester-registration'
import { PaymentsWrapper } from '../pages/fee-management/PaymentsWrapper'
import { PerformanceWrapper } from '../pages/performance/PerformanceWrapper'
import { ProfilePage } from '../modules/profile/ProfilePage'
import { ProgramDetailsWrapper } from '../pages/program-details/ProgramDetailsWrapper'
import { QRScannerWrapper } from '../pages/attendance/QRScannerWrapper'
import { SemesterRegistrationPageWrapper } from '../pages/semester-registration'
import { ServiceRequestsWrapper } from '../pages/service-request'
import { ServicesWrapper } from '../pages/services/ServicesWrapper'
import { SettingsWrapper } from '../pages/settings/SettingsWrapper'
import { SlcmContactSlcmUndertaking } from '../models/contacts/enums/slcmContactSlcmUndertaking'
import { StudentHandbookWrapper } from '../pages/student-handbook/StudentHandbookWrapper'
import { StudentLedgerWrapper } from '../pages/fee-management/StudentLedgerWrapper'
import { TimetableEventWrapper } from '../pages/timetable'
import { TimetableWrapper } from '../pages/timetable/TimetableWrapper'
import TopBarProgress from 'react-topbar-progress-indicator'
import { TranscriptWrapper } from '../pages/performance/TranscriptWrapper'
import { UpdateProfileImage } from '../modules/profile/components/profile-image'
import { UtilityChargesWrapper } from '../pages/utility-charges/UtilityChargesWrapper'
import { ViewAppointmentWrapper } from '../pages/appointments/ViewAppointmentWrapper'
import { ViewAppointmentWrapper as ViewCounsellorAppointmentWrapper } from '../pages/appointments/counsellor/ViewAppointmentWrapper'
import { ViewAppointmentWrapper as ViewDeanAppointmentWrapper } from '../pages/appointments/dean/ViewAppointmentWrapper'
import { ViewDemandWrapper } from '../pages/fee-management/ViewDemandWrapper'
import { ViewAppointmentWrapper as ViewHodAppointmentWrapper } from '../pages/appointments/hod/ViewAppointmentWrapper'
import { ViewSemesterRegistrationWrapper } from '../pages/semester-registration'
import { ViewServiceRequestPageWrapper } from '../pages/service-request/ViewServiceRequestPageWrapper'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { useUserContact } from '../hooks/user'

export const PrivateRoutes = () => {
  const contact = useUserContact();

  if (!contact) return <></>

  switch (contact?.slcmUndertaking) {
    case SlcmContactSlcmUndertaking.Required:
      return <RequiredRoutes />
    case SlcmContactSlcmUndertaking.Submitted:
      return <SubmittedRoutes />
    case SlcmContactSlcmUndertaking.RegistrationCompleted:
      return <CompletedRoutes />
    default:
      return <DefaultRoutes />
  }
}

const SuspenseView: FC = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

const NavigateLandingPage = () => {
  const [searchParams] = useSearchParams();
  const next = searchParams.get("next");

  return <Navigate to={next || '/dashboard'} />
}

const RequiredRoutes = () => {
  return <Routes>
    <Route element={<MasterLayout />}>
      <Route path='auth/*' element={<NavigateLandingPage />} />

      {/* Pages */}
      <Route path='dashboard' element={<DashboardWrapper />} />
      <Route path='studenthandbook' element={<StudentHandbookWrapper />} />
      <Route path='/profile/*'
        element={<SuspenseView><ProfilePage /></SuspenseView>} />
      <Route path='notifications' element={<NotificationsView />} />
      <Route path='service-requests/:status' element={<ServiceRequestsWrapper />} />
    </Route>
    {/* Page Not Found */}
    <Route path='*' element={<Navigate to='/error/404' />} />
  </Routes>
}

const SubmittedRoutes = () => {
  return <Routes>
    <Route element={<MasterLayout />}>
      <Route path='auth/*' element={<NavigateLandingPage />} />
      {/* Pages */}
      <Route path='dashboard' element={<DashboardWrapper />} />
      <Route path='studenthandbook' element={<StudentHandbookWrapper />} />
      <Route path='fee-summary' element={<FeeSummaryWrapper />} />
      <Route path='payments' element={<PaymentsWrapper />} />
      <Route path='payment-instructions' element={<PaymentInfoWrapper />} />
      <Route path='demands' element={<DemandsWrapper />} />
      <Route path='demands/view-demand/:demandId' element={<ViewDemandWrapper />} />
      <Route path='student-ledger' element={<StudentLedgerWrapper />} />
      <Route path='fee-structure' element={<FeeStructureWrapper />} />
      <Route path='utility-charges' element={<UtilityChargesWrapper />} />
      <Route path='semester-registration/:windowId' element={<SemesterRegistrationPageWrapper />} />
      <Route path='semester-registration/payment-selection/:windowId' element={<PaymentSelectionWrapper />} />
      <Route path='view-semester-registration/:windowId' element={<ViewSemesterRegistrationWrapper />} />
      <Route path='/profile/*' element={<SuspenseView><ProfilePage /></SuspenseView>} />
      <Route path='notifications' element={<NotificationsView />} />
      <Route path='service-requests/:status' element={<ServiceRequestsWrapper />} />
      <Route path='academicschedule' element={<AcademicScheduleWrapper />} />
    </Route>
    {/* Page Not Found */}
    <Route path='*' element={<Navigate to='/error/404' />} />
  </Routes>
}

const CompletedRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<NavigateLandingPage />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='timetable' element={<TimetableWrapper />} />
        <Route path='timetable/event/:id' element={<TimetableEventWrapper />} />
        <Route path='studenthandbook' element={<StudentHandbookWrapper />} />
        <Route path='scan-attendance' element={<QRScannerWrapper />} />
        <Route path='scan-attendance/:id' element={<AttendanceScannerWrapper />} />
        <Route path='program-details' element={<ProgramDetailsWrapper />} />
        <Route path='academicschedule' element={<AcademicScheduleWrapper />} />

        <Route path='counsellor-appointments' element={<CounsellorAppointmentsWrapper />} />
        <Route path='counsellor-appointments/new-appointment' element={<NewCounsellorAppointmentWrapper />} />
        <Route path='counsellor-appointments/view-appointment/:activityId' element={<ViewCounsellorAppointmentWrapper />} />

        <Route path='hod-appointments' element={<HodAppointmentsWrapper />} />
        <Route path='hod-appointments/new-appointment' element={<NewHodAppointmentWrapper />} />
        <Route path='hod-appointments/view-appointment/:activityId' element={<ViewHodAppointmentWrapper />} />

        <Route path='dean-appointments' element={<DeanAppointmentsWrapper />} />
        <Route path='dean-appointments/new-appointment' element={<NewDeanAppointmentWrapper />} />
        <Route path='dean-appointments/view-appointment/:activityId' element={<ViewDeanAppointmentWrapper />} />

        <Route path='appointments' element={<AppointmentsWrapper />} />
        <Route path='appointments/new-appointment' element={<NewAppointmentWrapper />} />
        <Route path='appointments/view-appointment/:activityId' element={<ViewAppointmentWrapper />} />
        <Route path='settings' element={<SettingsWrapper />} />
        <Route path='course-history/:id' element={<CourseHistoryWrapper />} />
        <Route path='fee-summary' element={<FeeSummaryWrapper />} />
        <Route path='payments' element={<PaymentsWrapper />} />
        <Route path='payment-instructions' element={<PaymentInfoWrapper />} />
        <Route path='demands' element={<DemandsWrapper />} />
        <Route path='demands/view-demand/:demandId' element={<ViewDemandWrapper />} />

        <Route path='student-ledger' element={<StudentLedgerWrapper />} />
        <Route path='services' element={<ServicesWrapper />} />
        <Route path='service-requests/:status' element={<ServiceRequestsWrapper />} />
        <Route path='services/new-service-request/:serviceId' element={<NewServiceRequestPageWrapper />} />
        <Route path='services/view-service-request/:serviceId' element={<ViewServiceRequestPageWrapper />} />
        <Route path='services/edit-service-request/:serviceId' element={<EditServiceRequestPageWrapper />} />
        <Route path='semester-registration/:windowId' element={<SemesterRegistrationPageWrapper />} />
        <Route path='view-semester-registration/:windowId' element={<ViewSemesterRegistrationWrapper />} />
        <Route path='performance' element={<PerformanceWrapper />} />
        <Route path='transcript' element={<TranscriptWrapper />} />
        <Route path='community' element={<CommunityWrapper />} />
        <Route path='settings' element={<SettingsWrapper />} />
        <Route path='fee-structure' element={<FeeStructureWrapper />} />
        <Route path='profile-image' element={<UpdateProfileImage />} />
        <Route path='semester-registration/payment-selection/:windowId' element={<PaymentSelectionWrapper />} />
        <Route path='payment-test' element={<FlywirePaymentWrapper />} />
        <Route path='utility-charges' element={<UtilityChargesWrapper />} />
        <Route path='notifications' element={<NotificationsView />} />

        {/* Lazy Modules */}
        <Route
          path='/profile/*'
          element={<SuspenseView><ProfilePage /></SuspenseView>}
        />
      </Route>
      {/* Page Not Found */}
      <Route path='*' element={<Navigate to='/error/404' />} />
    </Routes>
  )
}

const DefaultRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<NavigateLandingPage />} />

        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='timetable' element={<TimetableWrapper />} />
        <Route path='studenthandbook' element={<StudentHandbookWrapper />} />
        <Route path='academicschedule' element={<AcademicScheduleWrapper />} />
        <Route path='timetable/event/:id' element={<TimetableEventWrapper />} />
        <Route path='scan-attendance' element={<QRScannerWrapper />} />
        <Route path='scan-attendance/:id' element={<AttendanceScannerWrapper />} />
        <Route path='program-details' element={<ProgramDetailsWrapper />} />

        <Route path='counsellor-appointments' element={<CounsellorAppointmentsWrapper />} />
        <Route path='counsellor-appointments/new-appointment' element={<NewCounsellorAppointmentWrapper />} />
        <Route path='counsellor-appointments/view-appointment/:activityId' element={<ViewCounsellorAppointmentWrapper />} />

        <Route path='hod-appointments' element={<HodAppointmentsWrapper />} />
        <Route path='hod-appointments/new-appointment' element={<NewHodAppointmentWrapper />} />
        <Route path='hod-appointments/view-appointment/:activityId' element={<ViewHodAppointmentWrapper />} />

        <Route path='dean-appointments' element={<DeanAppointmentsWrapper />} />
        <Route path='dean-appointments/new-appointment' element={<NewDeanAppointmentWrapper />} />
        <Route path='dean-appointments/view-appointment/:activityId' element={<ViewDeanAppointmentWrapper />} />

        <Route path='appointments' element={<AppointmentsWrapper />} />
        <Route path='appointments/new-appointment' element={<NewAppointmentWrapper />} />
        <Route path='appointments/view-appointment/:activityId' element={<ViewAppointmentWrapper />} />
        <Route path='settings' element={<SettingsWrapper />} />
        <Route path='course-history/:id' element={<CourseHistoryWrapper />} />
        <Route path='fee-summary' element={<FeeSummaryWrapper />} />
        <Route path='payments' element={<PaymentsWrapper />} />
        <Route path='payment-instructions' element={<PaymentInfoWrapper />} />
        <Route path='demands' element={<DemandsWrapper />} />
        <Route path='demands/view-demand/:demandId' element={<ViewDemandWrapper />} />
        <Route path='student-ledger' element={<StudentLedgerWrapper />} />
        <Route path='services' element={<ServicesWrapper />} />
        <Route path='service-requests/:status' element={<ServiceRequestsWrapper />} />
        <Route path='services/new-service-request/:serviceId' element={<NewServiceRequestPageWrapper />} />
        <Route path='services/view-service-request/:serviceId' element={<ViewServiceRequestPageWrapper />} />
        <Route path='services/edit-service-request/:serviceId' element={<EditServiceRequestPageWrapper />} />
        <Route path='semester-registration/:windowId' element={<SemesterRegistrationPageWrapper />} />
        <Route path='view-semester-registration/:windowId' element={<ViewSemesterRegistrationWrapper />} />
        <Route path='performance' element={<PerformanceWrapper />} />
        <Route path='transcript' element={<TranscriptWrapper />} />

        <Route path='community' element={<CommunityWrapper />} />
        <Route path='settings' element={<SettingsWrapper />} />
        <Route path='fee-structure' element={<FeeStructureWrapper />} />
        <Route path='profile-image' element={<UpdateProfileImage />} />
        <Route path='semester-registration/payment-selection/:windowId' element={<PaymentSelectionWrapper />} />
        <Route path='payment-test' element={<FlywirePaymentWrapper />} />
        <Route path='utility-charges' element={<UtilityChargesWrapper />} />
        <Route path='notifications' element={<NotificationsView />} />

        <Route
          path='/profile/*'
          element={<SuspenseView><ProfilePage /></SuspenseView>}
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}