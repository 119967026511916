import { CardPanel, CardPanelBody, CardPanelHeader, CardPanelToolbar } from "../../widgets-core";

import { Money } from '../../widgets-business'
import { ServicesRoot } from "./components";
import clsx from "clsx";
import { useUserContact } from "../../hooks/user";

export const ServicesView = ({ className }: {
    className?: string
}) => {
    const contact = useUserContact();

    return (
        <div className={clsx(className)}>
            <CardPanel className="bg-transparent">
                <CardPanelHeader
                    className="min-h-5px"
                    title='Services'
                    subTitle={`Expand categories and select the service to request`}>
                    <CardPanelToolbar>
                        <div className={"text-center text-gray-600 py-2 me-3"} >
                            <span className="fw-bold">Previous Outstanding Amount</span>
                            <h3 className="text-danger my-0">
                                <Money
                                    currencyId={contact?.transactionCurrencyId?.id}
                                    amount={contact?.slcmOutstandingAmount} />
                            </h3>
                        </div>
                    </CardPanelToolbar>
                </CardPanelHeader>
                <CardPanelBody>
                    {contact && <ServicesRoot
                        className="mb-3"
                        contact={contact} />}
                </CardPanelBody>
            </CardPanel>
        </div>
    )
}
