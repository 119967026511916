import { BadgeIcon, EmailIcon, GroupIcon, ProgramIcon, VerifiedUserIcon } from "../../assets/icons";

import clsx from "clsx";
import { useUserContact } from "../../hooks/user";

export const UserBasicProfile = ({ className }: { className?: string }) => {
    const contact = useUserContact();

    return (
        <div className={clsx(className, "card card-body py-3")}>
                <div className='d-flex flex-wrap'>
                    <div className="w-xl-150px flex-fill my-1 me-3">
                        <span className="text-gray-600">Student</span>
                        <div className="text-gray-700 fw-bolder">
                            <VerifiedUserIcon className='me-2' />
                            <span className="text-uppercase">{contact?.fullName}</span>
                        </div>
                    </div>

                    <div className="w-xl-200px my-1 me-3">
                        <span className="text-gray-600">E-mail</span>
                        <div className="text-gray-700 fw-bolder">
                            <EmailIcon className='me-2' />
                            <a href={`mailto:${contact?.eMailAddress2}`}>
                                {contact?.eMailAddress2}
                            </a>
                        </div>
                    </div>
                    <div className="w-xl-150px my-1 me-3">
                        <span className="text-gray-600">Batch</span>
                        <div className="text-gray-700 fw-bolder">
                            <GroupIcon className='me-2' />
                            <span>{contact?.slcmBatchName}</span>
                        </div>
                    </div>
                    <div className="w-xl-150px my-1 me-3">
                        <span className="text-gray-600">Roll Number</span>
                        <div className="text-gray-700 fw-bolder">
                            <BadgeIcon className='me-2' />
                            <span>{contact?.slcmRollNumber}</span>
                        </div>
                    </div>
                    <div className="w-xl-250px flex-fill my-1 me-3">
                        <span className="text-gray-600">Program</span>
                        <div className="text-gray-700 fw-bolder">
                            <ProgramIcon className='me-2' />
                            <span>{contact?.mshiedCurrentProgramIdName}</span>
                        </div>
                    </div>
                </div>
        </div>
    )
}
