// spellchecker: disable

import * as Yup from 'yup'

import { AcademicPeriodDetail, Contact } from '../../models'
import { CardPanel, CardPanelBody, CardPanelHeader, CardPanelToolbar, CardQueryStatus, CardRefreshButton, If } from '../../widgets-core'
import { Field, Form, Formik, FormikErrors, FormikTouched } from 'formik'
import { useEffect, useRef, useState } from 'react'

import Accordion from 'react-bootstrap/esm/Accordion'
import { CoursesList } from './CoursesList'
import { DemandAccordionItem } from '../fee-management/demand/DemandAccordionItem'
import { PayableAmount } from '../fee-management/demand/components/PayableAmount'
import React from 'react'
import { RegistrationDetails } from './RegistrationDetails'
import { RegistrationWindow } from "../../models/registration-windows"
import { SemesterRegApproval } from "./components/SemesterRegApproval"
import { SemesterRegComments } from "./components/SemesterRegComments"
import { SlcmRegistrationwindowSlcmPayments } from '../../models/registration-windows/enums/slcmRegistrationwindowSlcmPayments'
import { StepperComponent } from '../../assets/components'
import { StepperNavItem } from '../wizards/components/StepperNavItem'
import { StepsBackButton } from '../wizards/components/StepsBackButton'
import { StepsSubmitButton } from '../wizards/components/StepsSubmitButton'
import clsx from 'clsx'
import { useGetDemandsByStudentIdQuery } from '../../services/demands.api'
import { useGetRegistrationAcademicPeriod } from '../../hooks/academic-period-details.hooks'
import { useGetRegistrationWindowQuery } from '../../services/registration-windows.api'
import { useNavigate } from 'react-router-dom'
import { useUpdateAcademicPeriodDetailMutation } from '../../services/academic-period-details.api'
import { useUser } from '../../store'

export const SemesterRegistration = ({ contact, windowId }: {
    contact: Contact,
    windowId: string
}) => {
    const { data: registration, isLoading, isError, isFetching, isSuccess, error, refetch } = useGetRegistrationWindowQuery(windowId)

    return (
        <CardPanel className='bg-transparent'>
            <CardPanelHeader
                title='Semester Registration'
                subTitle='Complete the semester registration steps'
            >
                <CardPanelToolbar>
                    <CardRefreshButton
                        className="me-3"
                        isLoading={isLoading || isFetching}
                        refetch={refetch} />

                    {(contact?.slcmOutstandingAmount || 0) !== 0 &&
                        <PayableAmount
                            className='flex-fill text-end text-gray-600'
                            label='Total Outstanding Amount'
                            amount={contact?.slcmOutstandingAmount || 0}
                            currencyId={contact?.transactionCurrencyId?.id} />
                    }
                </CardPanelToolbar>
            </CardPanelHeader>
            <CardPanelBody>
                <CardQueryStatus
                    isLoading={isLoading || isFetching}
                    isSuccess={isSuccess}
                    isError={isError}
                    error={error}
                    data={registration} />

                <If condition={!isLoading && !isFetching && isSuccess}>
                    {registration && <SemesterRegistrationInner
                        contact={contact}
                        registration={registration} />}
                </If>
            </CardPanelBody>
        </CardPanel>
    )
}

const SemesterRegistrationInner = ({ contact, registration }: {
    registration: RegistrationWindow,
    contact: Contact,
}) => {
    const { period, periodStatus, statusIds: { PWS, PWA, PWF, COM } } =
        useGetRegistrationAcademicPeriod(contact.contactId, registration);
    const [updateAcademicPeriodDetails] = useUpdateAcademicPeriodDetailMutation()
    const { getContact } = useUser()

    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const [stepIndex, setStepIndex] = useState<number>(1);
    const [alertMsg, setAlertMsg] = useState<string>()
    const [saving, setSaving] = useState<boolean>(false)
    const navigate = useNavigate()
    const [enableSubmit, setEnableSubmit] = useState<boolean>(false)

    const validationSchema = Yup.object().shape({
        iagree: Yup.boolean()
            .required("The terms and conditions must be accepted.")
            .oneOf([true], "The terms and conditions must be accepted.")
    });

    const updateSemesterRegistrationStatus = async (id: string, statusId: string) => {
        setSaving(true)
        const entity: Partial<AcademicPeriodDetail> = {
            slcmSemesterRegistration: {
                id: statusId,
                logicalName: 'mshied_registrationstatus',
            },
            slcmUndertakingStatus: true,
            slcmRegistrationDate: new Date(),
        }

        await updateAcademicPeriodDetails({
            id: id,
            entity: entity
        }).unwrap();

        await getContact()

        setSaving(false)
    }

    useEffect(() => {
        if (!period || !stepperRef.current) {
            return
        }

        loadStepper()
        // eslint-disable-next-line
    }, [stepperRef.current, period])

    useEffect(() => {
        if (stepper?.current) {
            if (periodStatus === "PWS") {
                stepper?.current?.goto(2)
                setStepIndex(2)
            }
        }
        // eslint-disable-next-line
    }, [stepper?.current, periodStatus])

    const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    }

    const submitStep = async () => {
        const step = stepper?.current;

        setAlertMsg('')
        if (!step || !period || !PWS) {
            setAlertMsg('Invalid State')
            return;
        }

        if (step.currentStepIndex < step.totatStepsNumber) {
            switch (step.currentStepIndex) {
                case 1:
                    if (periodStatus === "SCH") {
                        await updateSemesterRegistrationStatus(period.mshiedAcademicperioddetailsId, PWS);
                    }
                    setStepIndex(stepIndex + 1);
                    step.goNext();
                    break;
            }
        } else {
            if (periodStatus === "SCH") {
                const status = (contact?.slcmOutstandingAmount || 0) <= 0 && COM;
                await updateSemesterRegistrationStatus(period.mshiedAcademicperioddetailsId, status || "");

                navigate('/');
            } else if (periodStatus === "PWS") {
                const status = (contact?.slcmOutstandingAmount || 0) <= 0 ? PWA : PWF;
                if (enableSubmit && status) {
                    await updateSemesterRegistrationStatus(period.mshiedAcademicperioddetailsId, status || "");
                }
                navigate('/')
            }
        }
    }

    const prevStep = () => {
        if (!stepper.current) {
            return
        }

        setStepIndex(stepIndex - 1);
        stepper.current.goPrev()
    }

    return (
        <div>
            <div
                ref={stepperRef}
                className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                id='kt_create_account_stepper'
            >
                <StepsNavMenu
                    className="me-9"
                    stepIndex={stepper.current?.getCurrentStepIndex()} registration={registration} />

                <div className='d-flex flex-row-fluid bg-white rounded'>
                    <Formik
                        className='px-6 px-lg-10 px-xxl-15 py-0 w-100 w-xl-700px'
                        initialValues={{ iagree: false }}
                        id='kt_edit_service_request'
                        validationSchema={periodStatus === "SCH" ? validationSchema : null}
                        onSubmit={submitStep}>

                        {({ errors, touched }) => (
                            <Form
                                className='card card-body py-5 fs-7'>
                                {alertMsg &&
                                    <div className='alert alert-warning'>
                                        {alertMsg}
                                    </div>}
                                <div className='current' data-kt-stepper-element='content'>
                                    <div>
                                        <RegistrationDetails
                                            className='mb-5'
                                            period={period}
                                            registration={registration} />

                                        <CoursesList
                                            className='mb-5'
                                            programId={registration.slcmProgram?.id}
                                            registration={registration} />


                                        <RegistrationStep1
                                            periodStatus={periodStatus}
                                            errors={errors}
                                            touched={touched}
                                            loading={saving} />
                                    </div>
                                </div>

                                <div data-kt-stepper-element='content'>
                                    <div className='w-100 mb-5'>
                                        <RegistrationStep2
                                            enableSubmit={(enabled) => setEnableSubmit(enabled)}
                                            contact={contact} />
                                    </div>
                                </div>

                                <div className="flex-fill">{/* To align bottom */}</div>
                                <div className='d-flex flex-stack pt-5 align-bottom'>
                                    <div className='me-2'>
                                        <StepsBackButton
                                            className="btn-sm"
                                            onClick={prevStep} />
                                    </div>
                                    <div>
                                        <StepsSubmitButton
                                            className="btn-sm"
                                            enableSubmit={enableSubmit && (periodStatus === "PWS" || periodStatus === "SCH")}
                                            saving={saving}
                                            stepIndex={stepper.current?.currentStepIndex}
                                            totalSteps={stepper.current?.totatStepsNumber} />
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>

            {(periodStatus === "PWF" || periodStatus === "PWA") &&
                <div className="row">
                    <div className="col-xl-8">
                        <SemesterRegComments
                            className="mt-5"
                            requestId={period?.mshiedAcademicperioddetailsId} />
                    </div>
                    <div className="col-xl-4">
                        <SemesterRegApproval
                            className="mt-5"
                            requestId={period?.mshiedAcademicperioddetailsId} />
                    </div>
                </div>
            }
        </div>
    )
}


const RegistrationStep1 = ({ periodStatus, errors, touched, loading }: {
    periodStatus?: string,
    errors: FormikErrors<{ iagree: boolean }>,
    touched: FormikTouched<{ iagree: boolean }>,
    loading: boolean
}) => {
    if (periodStatus !== "SCH")
        return <></>

    return <div className='py-0 mt-5'>
        <div className='mb-4 w-100'>
            <div className='form-check form-check-custom form-check-solid'>
                <Field
                    type="checkbox"
                    id="iagree"
                    name="iagree"
                    className={clsx('form-check-input')} />
                <label className='form-check-label fw-bold text-gray-700 fs-6' htmlFor='iagree'>
                    I agree to register.
                </label>
            </div>
            {errors.iagree && touched.iagree ? (
                <div className='text-danger py-1 fs-7 fst-italic'>You need to agree to continue the semester register process</div>
            ) : null}
        </div>
    </div>
}

const RegistrationStep2 = ({ contact, enableSubmit }: {
    contact: Contact,
    enableSubmit?: (status: any) => void
}) => {
    const { data: demands, refetch } = useGetDemandsByStudentIdQuery(contact.contactId)

    useEffect(() => {
        if (!demands) return;

        const outstandingDemands = demands?.filter((p) => !p.slcmDemandgeneratedagainstdeferment && (p.slcmCurrentbalance || 0) - (p.slcmInterimAmountPaid || 0) > 0)
        let totalBalance = 0;
        outstandingDemands?.forEach(p => totalBalance += (p.slcmCurrentbalance || 0) - (p.slcmInterimAmountPaid || 0))

        const enabled = (totalBalance <= 0) || ((contact.slcmOutstandingAmount || 0) <= 0);

        if (enableSubmit) {
            enableSubmit(enabled)
        }

        // eslint-disable-next-line
    }, [demands])

    return (
        <>
            {(contact?.slcmOutstandingAmount && contact?.slcmOutstandingAmount > 0) ?
                <div className='text-muted fst-italic fs-7 my-2'>
                    Note: Please pay the outstanding amount to submit the registration.
                </div> :
                <div className='text-muted fst-italic fs-7 my-2'>
                    Note: Please click the submit button to complete the registration.
                </div>}

            <Accordion defaultActiveKey={""} flush>
                {demands?.map((demand) => {
                    return (
                        <React.Fragment key={demand.slcmDemandheaderId}>
                            {(demand.slcmCurrentbalance || 0) - (demand.slcmInterimAmountPaid || 0) > 0 &&
                                <DemandAccordionItem onChange={refetch} demand={demand} />
                            }
                        </React.Fragment>
                    )
                })}
            </Accordion>
        </>
    )
}

const StepsNavMenu = ({ stepIndex, className, registration }: {
    stepIndex?: number,
    registration: RegistrationWindow,
    className?: string
}) => {
    let stepNo = 0;

    return <div className={clsx(className, 'd-flex',
        'justify-content-center justify-content-xl-start flex-row-auto',
        'bg-white rounded',
        'w-100 w-xl-300px w-xxl-400px min-h-xl-300px')}>
        <div className='p-10'>
            <div className='stepper-nav'>
                <StepperNavItem
                    className={clsx({ "current": stepIndex === 1 })}
                    no={++stepNo}
                    name="General"
                    description="Accept the registration" />

                {registration.slcmPayments === SlcmRegistrationwindowSlcmPayments.Yes && <StepperNavItem
                    no={++stepNo}
                    name="Payment"
                    description="Pay the outstandings" />}
            </div>
        </div>
    </div>
}