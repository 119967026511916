import { ApiTags, rootApi } from './api';

import { ServiceRequestComment } from "../models";

const TAG = ApiTags.Comment;
const getTags = (entities: ServiceRequestComment[]) => [...entities.map((p) => ({ type: TAG, id: p.slcmServicerequestcommentId })), TAG]

const commentsApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getComment: builder.query<ServiceRequestComment, string>({
            query: (id) => `/ServiceRequestComments/${id}`,
            providesTags: (result) =>
                result ? [{ type: TAG, id: result.slcmServicerequestcommentId }, TAG] : [TAG]
        }),

        getComments: builder.query<ServiceRequestComment[], void>({
            query: () => `/ServiceRequestComments`,
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),

        getCommentsByRequestId: builder.query<ServiceRequestComment[], string>({
            query: (requestId) => `/ServiceRequestComments/request(${requestId})`,
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),

        getCommentsByRegistrationId: builder.query<ServiceRequestComment[], string>({
            query: (registrationId) => `/ServiceRequestComments/registration(${registrationId})`,
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),

        getCommentsByAppointmentId: builder.query<ServiceRequestComment[], string>({
            query: (appointmentId) => `/ServiceRequestComments/appointment(${appointmentId})`,
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),

        createComment: builder.mutation<ServiceRequestComment, Partial<ServiceRequestComment>>({
            query: (entity) => ({
                url: '/ServiceRequestComments',
                method: 'POST',
                body: entity
            }),
            invalidatesTags: (result) =>
                result ? [{ type: TAG, id: result.slcmServicerequestcommentId }, TAG] : [TAG]
        }),

        updateComment: builder.mutation<ServiceRequestComment, {
            id: string,
            entity: Partial<ServiceRequestComment>
        }>({
            query: ({ id, entity }) => ({
                url: `/ServiceRequestComments/${id}`,
                method: 'PUT',
                body: entity
            }),
            invalidatesTags: (result) =>
                result ? [{ type: TAG, id: result.slcmServicerequestcommentId }, TAG] : [TAG]
        }),

        deleteComment: builder.mutation<void, string>({
            query: (id: string) => ({
                url: `/ServiceRequestComments/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [TAG]
        }),
    })
})

export const {
    useGetCommentQuery,
    useGetCommentsQuery,
    useGetCommentsByRequestIdQuery,
    useGetCommentsByAppointmentIdQuery,
    useGetCommentsByRegistrationIdQuery,
    useCreateCommentMutation,
    useUpdateCommentMutation,
    useDeleteCommentMutation
} = commentsApi;