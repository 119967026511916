import { FC } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import { PaymentInfoView } from "../../modules/fee-management/PaymentInfoView";

export const PaymentInfoWrapper: FC = () => {
    return (<>
        <PageTitle breadcrumbs={[]}>{'Payment Information'}</PageTitle>
        <PaymentInfoView />
    </>)
}
