//spellchecker: disable

import { CardPanel, CardPanelBody, CardPanelHeader, DateFormat } from "../../widgets-core"
import { Contact, Course, CourseHistory } from "../../models"
import { useEffect, useState } from "react"

import { Documents } from "../profile/components/Documents"
import clsx from "clsx"
import { useGetCourseHistoriesByStudentIdQuery } from "../../services/course-histories.api"
import { useGetCourseQuery } from "../../services/courses.api"
import { useLazyGetCourseSectionByBatchIdQuery } from "../../services/course-sections.api"

export const CourseHistoryView = ({ contact, courseId, className }: {
    contact: Contact,
    courseId: string,
    className?: string,
}) => {
    const { data: course } = useGetCourseQuery(courseId)
    const { data: courseHistories } = useGetCourseHistoriesByStudentIdQuery(contact.contactId);
    const [model, setModel] = useState<CourseHistory>();
    const [getCourseSection, { data: courseSection }] = useLazyGetCourseSectionByBatchIdQuery()

    useEffect(() => {
        const model = courseHistories?.find(p => p.mshiedCourseId?.id === courseId);
        setModel(model);

        // eslint-disable-next-line
    }, [courseId, courseHistories])

    useEffect(() => {
        if (!contact.slcmBatch?.id) return;
        getCourseSection({ batchId: contact.slcmBatch?.id, courseId })

        // eslint-disable-next-line
    }, [contact])

    return (
        <CardPanel className="bg-transparent">
            <CardPanelHeader
                title="Course Details"
                subTitle={course?.mshiedName}>
            </CardPanelHeader>
            <CardPanelBody>
                {course && <>
                    <CoursePanel model={course} className="fs-7 mb-5" />
                    <LearningHours model={course} className="fs-7 mb-5" />
                    <Documents
                        title="Course Material"
                        entityId={course.mshiedCourseId} />
                </>}

                {courseSection && <>
                    <Documents
                        className='mt-5'
                        title="Course Section Documents"
                        entityId={courseSection.mshiedCoursesectionId} />
                </>}

                {model && <CourseHistoryPanel model={model} className="mt-5 fs-7" />}
            </CardPanelBody>
        </CardPanel>)
}

const CoursePanel = ({ model, className }: {
    model: Course,
    className?: string,
}) => {
    return (
        <div className={clsx(className, "card card-body py-3")}>
            <div className="d-flex flex-wrap">
                <div className="w-xl-125px me-3 my-2">
                    <span className="text-gray-600">Course #</span>
                    <div className="text-gray-700 fw-bolder">
                        {model?.mshiedCourseNumber}
                    </div>
                </div>

                <div className='w-xl-150px flex-fill me-3 my-2'>
                    <span className='text-gray-600'>Name</span>
                    <div className='fw-bold text-gray-800'>
                        {model?.mshiedName}
                    </div>
                </div>
                <div className='w-xl-125px me-3 my-2'>
                    <span className='text-gray-600'>No. of credits</span>
                    <div className='fw-bold text-gray-800'>
                        {model?.mshiedCredits}
                    </div>
                </div>
            </div>
        </div>)
}

const CourseHistoryPanel = ({ model, className }: {
    model: CourseHistory,
    className?: string,
}) => {
    return (
        <div className={clsx(className, "card card-body")}>
            <h6 className="my-3 p-0 fw-bolder text-gray-600">Course History</h6>
            <div className="d-flex flex-wrap fs-7">
                <div className="w-xl-150px my-1 pe-3">
                    <span className='fw-bold min-w-150px me-5'>Registration Status:</span>
                    <div className='fw-bold text-gray-800'>
                        {model?.mshiedRegistrationStatusIdName}
                    </div>
                </div>
                <div className="w-xl-150px flex-fill my-1 pe-3">
                    <span className='fw-bold min-w-150px me-5'>Academic Period:</span>
                    <div className='fw-bold text-gray-800'>
                        {model?.mshiedAcademicPeriodIdName}
                    </div>
                </div>
                <div className="w-xl-150px my-1 pe-3">
                    <span className='fw-bold min-w-150px me-5'>Grade Points:</span>
                    <div className='fw-bold text-gray-800'>
                        {model?.mshiedGradePoints}
                    </div>
                </div>
                <div className="w-xl-150px my-1 pe-3">
                    <span className='fw-bold min-w-150px me-5'>Is Required:</span>
                    <div className='fw-bold text-gray-800'>
                        {model?.mshiedIsRequired ? "Yes" : "No"}
                    </div>
                </div>
                <div className="w-xl-150px my-1 pe-3">
                    <span className='fw-bold min-w-150px me-5'>Is Completed:</span>
                    <div className='fw-bold text-gray-800'>
                        {model?.mshiedIsCompleted ? "Yes" : "No"}
                    </div>
                </div>
                <div className="w-xl-150px my-1 pe-3">
                    <span className='fw-bold min-w-150px me-5'>Last Attendance Date:</span>
                    <div>
                        <DateFormat className="fw-bold text-gray-800" date={model?.mshiedLastDateOfAttendance} />
                    </div>
                </div>
            </div>

            <h5 className="my-3 p-0 fw-bolder text-gray-600">Performance</h5>
            <div className="d-flex flex-wrap fs-7">
                <div className="w-xl-150px my-1 pe-3">
                    <span className='fw-bold min-w-150px me-5'>Credits Attempted:</span>
                    <div className='fw-bold text-gray-800'>
                        {model?.mshiedCreditsAttempted}
                    </div>
                </div>
                <div className="w-xl-150px my-1 pe-3">
                    <span className='fw-bold min-w-150px me-5'>Credits Earned:</span>
                    <div className='fw-bold text-gray-800'>
                        {model?.mshiedCreditsEarrned}
                    </div>
                </div>
                <div className="w-xl-150px my-1 pe-3">
                    <span className='fw-bold min-w-150px me-5'>Mid Term Numeric Grade:</span>
                    <div className='fw-bold text-gray-800'>
                        {model?.mshiedMidTermNumericGrade}
                    </div>
                </div>
                <div className="w-xl-150px my-1 pe-3">
                    <span className='fw-bold min-w-150px me-5'>Mid Term Letter Grade:</span>
                    <div className='fw-bold text-gray-800'>
                        {model?.mshiedMidTermLetterGrade}
                    </div>
                </div>
                <div className="w-xl-150px my-1 pe-3">
                    <span className='fw-bold min-w-150px me-5'>Letter Grade:</span>
                    <div className='fw-bold text-gray-800'>
                        {model?.mshiedLetterGrade}
                    </div>
                </div>
            </div>
        </div>)
}
const LearningHours = ({ model, className }: {
    model: Course,
    className?: string
}) => {
    return <div className={clsx(className, "card")}>
        <div className="card-header min-h-5px border-0">
            <h6 className="mt-5 text-gray-700">Learning Hours</h6>
        </div>
        <div className="card-body py-3">
            <div className="d-flex flex-wrap">
                <div className='w-xl-125px me-3 my-2'>
                    <span className='text-gray-600'>Lecture</span>
                    <div className='fw-bold text-gray-800'>
                        {model?.slcmLecture}
                    </div>
                </div>
                <div className='w-xl-125px me-3 my-2'>
                    <span className='text-gray-600'>Practical</span>
                    <div className='fw-bold text-gray-800'>
                        {model?.slcmPractical}
                    </div>
                </div>
                <div className='w-xl-125px me-3 my-2'>
                    <span className='text-gray-600'>Tutorial</span>
                    <div className='fw-bold text-gray-800'>
                        {model?.slcmTutorial}
                    </div>
                </div>
                <div className='w-xl-125px me-3 my-2'>
                    <span className='text-gray-600'>Clinical</span>
                    <div className='fw-bold text-gray-800'>
                        {model?.slcmClinical}
                    </div>
                </div>
            </div>
        </div>
    </div>
}

