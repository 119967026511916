import { CardPanel, CardPanelBody, CardPanelHeader, CardPanelToolbar, DateFormat, If, ListQueryStatus } from '../../widgets-core'
import { Contact, StudentLedger, getDocumentTypeLabel } from '../../models'
import React, { useCallback, useRef } from 'react'
import { toAbsoluteUrl } from '../../helpers'

import { Link } from 'react-router-dom'
import { Money } from '../../widgets-business'
import _ from 'lodash'
import { jsPDF } from "jspdf"
import { useGetStudentLedgerByStudentIdQuery } from '../../services/student-ledgers.api'
import { useUserContact } from '../../hooks/user'

export const StudentLedgerView = ({ className }: {
  className?: string
}) => {
  const contact = useUserContact()
  return <>
    {contact && <StudentLedgerCard contact={contact} />}
  </>
}

const StudentLedgerCard = ({ contact }: { contact: Contact }) => {
  const { data: studentLedger, isLoading, isFetching, isError, isSuccess, refetch } = useGetStudentLedgerByStudentIdQuery(contact.contactId)
  const componentRef = useRef<any>()

  const handlePrint = () => {
    const doc = new jsPDF({
      format: 'a4',
      unit: 'px',
    });

    doc.setFont('Inter-Regular', 'normal');
    doc.html(componentRef.current, {
      async callback(doc) {
        await doc.save('student-ledger');
      },
      margin: [10, 10, 10, 10],
      autoPaging: 'slice',
      x: 0,
      y: 0,
      width: 250, //target width in the PDF document
      windowWidth: 710 //window width in CSS pixels
    });
  };

  return (
    <CardPanel className='bg-transparent'>
      <CardPanelHeader
        className='min-h-5px'>
        <CardPanelToolbar>
          <button
            onClick={refetch}
            type="button"
            title="Refresh"
            className='btn btn-sm btn-link text-hover-primary p-0 me-3'>
            <i className="las la-redo-alt fs-2"></i>
          </button>

          <button
            className='btn btn-sm btn-secondary'
            onClick={handlePrint}>
            <i className="las la-download fs-2"></i>
            Download
          </button>
        </CardPanelToolbar>
      </CardPanelHeader>
      <CardPanelBody>
        <ListQueryStatus
          isLoading={isLoading || isFetching}
          isSuccess={isSuccess}
          isError={isError}
          data={studentLedger} />
        <If condition={!isLoading && !isFetching && isSuccess}>
          <div className='card card-body py-3'>
            <div className='table-responsive'>
              <LedgerEntries ref={componentRef} studentLedger={studentLedger} />
            </div>
          </div>
          <div className='text-gray-700 fst-italic text-center fs-7 fw-bold my-5'>
            In case of any clarifications required in student ledger, kindly contact Student finance by clicking on the link to raise service request or email us at  <a href="mailto:finance@manipal.edu.my" target="_blank" rel="noreferrer">finance@manipal.edu.my</a>
          </div>
          <div className='text-gray-700 fst-italic text-center fs-7 fw-bold my-5'>
            <Link to={`/services/new-service-request/7c4aaf8e-046b-ed11-81ac-000d3a856eb1`}>
              Click here for service request
            </Link>
          </div>
        </If>
      </CardPanelBody>
    </CardPanel>
  )
}

const LedgerEntries = React.forwardRef(({ studentLedger }: {
  studentLedger?: StudentLedger[],
}, ref) => {
  let runningBalance = 0
  const contact = useUserContact()

  const getSortedData = useCallback(() => {
    return _.orderBy(studentLedger, p => p.slcmDueDate, ['desc']);
  }, [studentLedger]);

  return (
    <div ref={ref as React.RefObject<HTMLTableElement>} className='mb-5'>
      <div className='text-end'>
        <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-2.png')} className='h-60px' />
      </div>
      <h3 className="card-title align-items-start mb-0"><span className="fw-bolder">Student Ledger Report</span></h3>
      <div className=''>
        <div className={"card card-body py-3"}>
          <div className='d-flex flex-wrap'>
            <div className="w-xl-150px">
              <span className="text-gray-600">Student</span>
              <div className="text-gray-700 fw-bolder">
                <span className="text-uppercase">{contact?.fullName}</span>
              </div>
            </div>

            <div className="w-xl-150px">
              <span className="text-gray-600">Batch</span>
              <div className="text-gray-700 fw-bolder">
                <span>{contact?.slcmBatchName}</span>
              </div>
            </div>
            <div className="w-xl-150px">
              <span className="text-gray-600">Roll Number</span>
              <div className="text-gray-700 fw-bolder">
                <span>{contact?.slcmRollNumber}</span>
              </div>
            </div>
            <div className="w-xl-250px flex-fill">
              <span className="text-gray-600">Program</span>
              <div className="text-gray-700 fw-bolder">
                <span>{contact?.mshiedCurrentProgramIdName}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <table className='table table-striped fs-7'>
        <thead>
          <tr className='fw-bolder fs-7 border-bottom border-gray-200 py-2'>
            <th className='px-2 min-w-50px'>S. No.</th>
            <th className='px-2 min-w-100px'>Semester</th>
            <th className='px-2 min-w-100px'>Date</th>
            <th className='px-2 min-w-100px'>Particulars</th>
            <th className='px-2 min-w-100px'>Invoice No.</th>
            <th className='px-2 min-w-200px'>Fee Component Name</th>
            <th className='px-2 min-w-100px text-right'>Amount</th>
            {contact?.slcmIsInternational &&
              <th className='px-2 min-w-100px text-right'>Balance Amount</th>
            }
            <th className='px-2 min-w-100px text-right'>Balance Amount MYR</th>
            <th className='px-2 min-w-100px'>Due Date</th>
          </tr>
        </thead>
        <tbody className='fw-bold'>
          {getSortedData()?.map((entry, i) => (
            <LedgerEntry
              ledgerEntry={entry}
              sno={i + 1}
              balance={(runningBalance += entry.slcmBalanceAmount || 0)}
              key={entry.slcmStudentledgerId}
            />
          ))}
        </tbody>
      </table>
      <div className="text-gray-700 fst-italic text-center fs-5 fw-bold my-6">This is computer generated ledger statement, kindly contact <a href="mailto:finance@manipal.edu.my" target="_blank" rel="noreferrer">finance@manipal.edu.my</a> for any clarifications</div>

    </div>
  )
})

const LedgerEntry = ({ ledgerEntry, sno, balance, }: {
  ledgerEntry: StudentLedger;
  sno: number;
  balance: number
}) => {

  const contact = useUserContact()

  return (
    <tr className='text-gray-700'>
      <td className='px-2'>{sno}</td>
      <td className='px-2'>{ledgerEntry.slcmSemesterName}</td>
      <td className='px-2'>
        <DateFormat date={ledgerEntry.slcmDate} />
      </td>
      <td className='px-2'>{getDocumentTypeLabel(ledgerEntry.slcmDocumentType)}</td>
      <td className='px-2'>{ledgerEntry.slcmDocumentNo}</td>
      <td className='px-2'>{ledgerEntry.slcmFeeComponentNoName}</td>
      <td className='px-2 text-right'>
        <Money currencyId={ledgerEntry.transactionCurrencyId?.id} amount={ledgerEntry.slcmAmount} />
      </td>
      {contact?.slcmIsInternational &&
        <td className='px-2 text-right'>
          <Money currencyId={ledgerEntry.transactionCurrencyId?.id} amount={ledgerEntry.slcmAmount} />
        </td>
      }
      <td className='px-2 text-right'>
        <Money currencyId={"b1b607a8-ffd8-ec11-bb3d-0022485a1807"} amount={balance} />
      </td>
      <td className='px-2 text-right'>
        <DateFormat date={ledgerEntry.slcmDueDate} />
      </td>
    </tr>
  )
}
