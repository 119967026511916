import { DateFormat } from '../../widgets-core'
import { NotificationEvent } from "../../models"
import { NotificationIcon } from "../../assets/icons"
import clsx from "clsx"
import { useGetNotificationQuery } from "../../services/notifications.api"
import { useState } from "react"

export const NotificationPanel = ({ notificationEvent, className }: {
  notificationEvent: NotificationEvent,
  className?: string
}) => {
  const { data: notification } = useGetNotificationQuery(notificationEvent.slcmNotification?.id!);
  const [checked, setChecked] = useState<boolean>(true);

  const getText = (html: string) => {
    var divContainer = document.createElement("div");
    divContainer.innerHTML = html;
    return divContainer.textContent || divContainer.innerText || "";
  }

  return <div className={clsx(className, 'border rounded p-3')}>
    <div className="position-relative w-100">
      <div className="position-absolute h-100 d-flex align-items-center">
        <NotificationIcon
          sx={{ fontSize: 30 }}
          className="text-primary" />
      </div>

      <div className="ms-12 me-5">
        <div className="d-flex">
          <p className='fs-7 flex-fill fw-bolder text-gray-600 text-hover-primary my-0 text-truncate pe-2'>
            {notificationEvent.slcmNotificationName}
          </p>

          <DateFormat
            className='w-xl-100px fs-7 fw-bolder text-gray-600 text-end'
            date={notificationEvent.slcmStartDate} />
        </div>

        <div className="position-relative">
          {checked ?
            <div className="text-gray-600 text-truncate fs-8" style={{ maxWidth: "100%" }}>
              {getText(notification?.slcmMessage || '')}
            </div> :
            <p className="py-0 my-0 fs-8" dangerouslySetInnerHTML={{ __html: notification?.slcmMessage || '' }} />
          }
          <button
            className="btn btn-link btn-sm my-0 py-0 fs-8"
            onClick={() => setChecked(!checked)}>
            {checked ? 'read more' : 'read less'}
          </button>
        </div>
      </div>
    </div>
  </div>
}