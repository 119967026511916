import { SlcmRequestsSlcmReopen } from "./enums/slcmRequestsSlcmReopen";

export * from "./Service";

export const getRequestsSlcmReopenLabel = (statusCode?: SlcmRequestsSlcmReopen) => {
    switch (statusCode) {
        case SlcmRequestsSlcmReopen.Yes: return "Yes";
        case SlcmRequestsSlcmReopen.No: return "No";
        default: return "";
    }
}