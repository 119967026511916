import { FC } from "react"
import { FeeStructureView } from "../../modules/fee-structure";
import { PageTitle } from "../../../_metronic/layout/core";

export const FeeStructureWrapper: FC = () => {
    return (<>
        <PageTitle breadcrumbs={[]}>{'Fee Structure'}</PageTitle>
        <FeeStructureView />
    </>)
}
    