import clsx from "clsx";

export const StepperNavItem = ({no, name, description, className}: {
    no: number,
    name: string,
    description: string,
    className?: string,
}) => {
    return (
        <div
            className={clsx(className, 'stepper-item')}
            data-kt-stepper-element='nav'>

            <div className='stepper-line w-40px'></div>

            <div className='stepper-icon w-40px h-40px'>
                <i className='stepper-check fas fa-check'></i>
                <span className='stepper-number'>{no}</span>
            </div>

            <div className='stepper-label'>
                <h3 className='stepper-title my-0'>{name}</h3>
                <div className='stepper-desc'>{description}</div>
            </div>
        </div>
    )
};