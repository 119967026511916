import { FC, useEffect, useState } from "react"

import { ServiceRequestDocument } from "./ServiceRequestDocument"
import { useGetAssociatedDocumentsByRequestIdQuery } from "../../../../services/associated-documents.api";

type Props = {
    className?: string,
    checklistId: any,
    requestId: string,
    folderPath: string,
    onStatusChange?: (isCompleted: boolean) => void;
}

export const ServiceRequestDocuments: FC<Props> = ({ onStatusChange, checklistId, requestId, folderPath }) => {
    const { data: associatedDocuments } = useGetAssociatedDocumentsByRequestIdQuery({ requestId, checklistId });
    const [completionStatus, setCompletionStatus] = useState<boolean>(false);

    useEffect(() => {
        updateCompletionStatus();
        // eslint-disable-next-line
    }, [associatedDocuments])

    const handleDocumentChange = () => {
        updateCompletionStatus();
    }

    const updateCompletionStatus = () => {
        const status = isCompleted();
        if (status !== completionStatus) {
            setCompletionStatus(status);

            if (onStatusChange)
                onStatusChange(status);
        }
    }

    const isCompleted = () => {
        if (!associatedDocuments) return false;

        const attached = associatedDocuments?.filter(p => p.slcmRequired && !p.slcmDocumentLink).length;
        return attached === 0;
    }
    return (
        <div className="row w-100">
            {associatedDocuments?.map(item => (
                <ServiceRequestDocument
                    requestId={requestId}
                    folderPath={folderPath}
                    onChangeDocument={handleDocumentChange}
                    associatedDocument={item}
                    key={item.slcmAssociatedDocumentsId} />
            ))}
        </div>
    )
}