import { CardPanel, CardPanelBody, CardPanelHeader } from "../../widgets-core";

import { RouterLinkImageButton } from "../../widgets-core/buttons/RouterLinkImageButton"

export const QuickLinksCard = ({ className }: {
    className?: string
}) => {
    return (
        <CardPanel className={className}>
            <CardPanelHeader title="Quick Links" className='h-35px min-h-35px' />
            <CardPanelBody>
                <QuickLinks />
            </CardPanelBody>
        </CardPanel>
    )
}

const QuickLinks = () => {
    return (
        <div className="row">
            <div className='col-xl-6'>
                <RouterLinkImageButton
                    to="/fee-summary"
                    title='Fee'
                    description='Fee summary'
                    color='light'
                    svgIcon='FeeManagement'
                    className='mb-3'
                />
            </div>

            <div className='col-xl-6'>
                <RouterLinkImageButton
                    to="/services"
                    title='Communication'
                    description='Self services requests'
                    color='light'
                    svgIcon='Community'
                    className='mb-3'
                />
            </div>

            <div className='col-xl-6'>
                <RouterLinkImageButton
                    to="/timetable"
                    title='Attendance'
                    description='Timetable and attendance'
                    color='light'
                    svgIcon='Timetable'
                    className='mb-3'
                />
            </div>
            <div className='col-xl-6'>
                <RouterLinkImageButton
                    to="/performance"
                    title='Results'
                    description='Performance and results'
                    color='light'
                    svgIcon='Performance'
                    className='mb-3'
                />
            </div>
            <div className='col-xl-6'>
                <RouterLinkImageButton
                    to="/performance"
                    title='Digital learning'
                    description='Learning materials'
                    color='light'
                    svgIcon='Performance'
                    className='mb-3'
                />
            </div>
            <div className='col-xl-6'>
                <RouterLinkImageButton
                    to="/program-details"
                    title='Syllabus'
                    description='Program details'
                    color='light'
                    svgIcon='Program'
                    className='mb-3'
                />
            </div>
        </div>
    )
}