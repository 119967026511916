import { AttendanceScanner } from "../../modules/appointments/attendance/AttendanceScanner";
import { FC } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import { useParams } from "react-router-dom";
import { useUserContact } from "../../hooks/user";

export const AttendanceScannerWrapper: FC = () => {
    const { id }: { id?: string } = useParams();
    const contact = useUserContact();

    return (<>
        <PageTitle breadcrumbs={[]}>{'Attendance Scanner'}</PageTitle>
        {(id && contact) && <AttendanceScanner appointmentId={id} contact={contact} />}
    </>)
}
