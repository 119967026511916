import { BadgeIcon, DateRangeIcon, EmailIcon, LocationIcon, PassportIcon, PersonIcon, PhoneIcon, TimelapseIcon } from '../../../../assets/icons'

import Accordion from 'react-bootstrap/esm/Accordion'
import { Contact } from '../../../../models'
import { DateFormat } from '../../../../widgets-core'

export const Overview = ({ contact, className }: {
  contact: Contact,
  className?: string
}) => {
  return (
    <Accordion className={className}>
      <Accordion.Item eventKey={""}>
        <Accordion.Header bsPrefix="accordion-header accordion-header-sm">
          <h5 className='text-gray-700 my-0'>Overview</h5>
        </Accordion.Header>
        <Accordion.Body className='py-3'>
          <OverviewPro contact={contact} />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

const OverviewPro = ({ contact }: { contact: Contact }) => {
  return (
    <div className='d-flex flex-wrap fs-7'>
      <div className="w-xl-150px flex-fill my-2 me-3">
        <span className="text-gray-600">Mobile Number</span>
        <div className="text-gray-700 fw-bolder">
          <PhoneIcon className='me-1' />
          <span>{contact?.mobilePhone}</span>
        </div>
      </div>

      <div className="w-xl-150px flex-fill my-2 me-3">
        <span className="text-gray-600">Personal Email</span>
        <div className="text-gray-700 fw-bolder">
          <EmailIcon className='me-1' />
          <span>{contact?.eMailAddress2}</span>
        </div>
      </div>

      <div className="w-xl-150px my-2 me-3">
        <span className="text-gray-600">Passport Number</span>
        <div className="text-gray-700 fw-bolder">
          <PassportIcon className='me-1' />
          <span>{contact?.slcmPassportNumber}</span>
        </div>
      </div>

      <div className="w-xl-150px my-2 me-3">
        <span className="text-gray-600">MYKAD Number</span>
        <div className="text-gray-700 fw-bolder">
          <BadgeIcon className='me-1' />
          <span>{contact?.slcmMYKADNo}</span>
        </div>
      </div>

      <div className="w-xl-150px my-2 me-3">
        <span className="text-gray-600">Date of Birth</span>
        <div className="text-gray-700 fw-bolder">
          <DateRangeIcon className='me-1' />
          <DateFormat date={contact?.birthDate} />
        </div>
      </div>

      <div className="w-xl-50 flex-fill my-2 pe-3">
        <span className="text-gray-600">Address</span>
        <div className="text-gray-700 fw-bolder">
          <LocationIcon className='me-1' />
          <span>{contact?.address1Composite}</span>
        </div>
      </div>

      <div className="w-xl-250px flex-fill my-2 pe-3">
        <span className="text-gray-600">Academic Period</span>
        <div className="text-gray-700 fw-bolder">
          <TimelapseIcon className='me-1' />
          <span>{contact?.mshiedCurrentAcademicPeriodIdName}</span>
        </div>
      </div>

      <div className="w-xl-150px my-2 me-3">
        <span className="text-gray-600">Mentor/Advisor</span>
        <div className="text-gray-700 fw-bolder">
          <PersonIcon className='me-1' />
          <span>{contact?.mshiedAdvisorIdName}</span>
        </div>
      </div>

      <div className="w-xl-150px my-2 me-3">
        <span className="text-gray-600">International</span>
        <div className="text-gray-700 fw-bolder">
          <LocationIcon className='me-1' />
          <span>{contact?.slcmIsInternational ? "Yes" : "No"}</span>
        </div>
      </div>
    </div>
  )
}
