import { Achievement, Contact, getAchievementTypeLabel } from "../../../models";
import { DateFormat, If, ListQueryStatus } from "../../../widgets-core";

import Accordion from "react-bootstrap/esm/Accordion";
import React from "react"
import clsx from "clsx";
import { useGetAchievementsByStudentIdQuery } from "../../../services/achievement.api";

export const Achievements = ({ contact, className }: {
    contact: Contact,
    className?: string,
}) => {
    const { data: entities, isLoading, isError, isFetching, isSuccess, refetch }
        = useGetAchievementsByStudentIdQuery(contact.contactId);

    return (
        <Accordion className={className}>
            <Accordion.Item eventKey={""}>
                <Accordion.Header bsPrefix="accordion-header accordion-header-sm">
                    <h5 className='text-gray-700 my-0'>Achievements</h5>
                </Accordion.Header>
                <Accordion.Body>
                    <div className="text-end mb-3">
                        <button
                            onClick={refetch}
                            type="button"
                            title="Refresh"
                            className='btn btn-sm btn-link text-hover-primary p-0 me-3'>
                            <i className="las la-redo-alt fs-2"></i>
                        </button>
                    </div>

                    <ListQueryStatus
                        isLoading={isLoading || isFetching}
                        isSuccess={isSuccess}
                        isError={isError}
                        data={entities} />

                    <If condition={!isLoading && !isFetching && isSuccess}>
                        {entities?.map(item => <React.Fragment key={item.mshiedAchievementId}>
                            <AchievementsPanel
                                className="mb-2"
                                data={item} />
                        </React.Fragment>)}
                    </If>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

const AchievementsPanel = ({ data, className }: {
    data: Achievement,
    className?: string
}) => {
    return (
        <div className={clsx(className, "border px-5 rounded py-3 fs-7")}>
            <div className="d-flex flex-wrap flex-sm-row">
                <div className="w-xl-125px flex-fill my-1 me-3">
                    <span className="text-gray-600">Title</span>
                    <div className="text-gray-700 fw-bolder">
                        <span>{data.mshiedName}</span>
                    </div>
                </div>
                <div className="w-xl-125px my-1 me-3">
                    <span className="text-gray-600">Achievement</span>
                    <div className="text-gray-700 fw-bolder">
                        <span>{getAchievementTypeLabel(data.mshiedAchievementType)}</span>
                    </div>
                </div>
                <div className="w-xl-125px my-1 me-3">
                    <span className="text-gray-600">Achievement Type</span>
                    <div className="text-gray-700 fw-bolder">
                        <span>{data.slcmAchievementTypeName}</span>
                    </div>
                </div>
                <div className="w-xl-125px my-1 me-3">
                    <span className="text-gray-600">Issue Date</span>
                    <div className="text-gray-700 fw-bolder">
                        <DateFormat date={data.mshiedIssueDate} />
                    </div>
                </div>
                <div className="w-xl-125px my-1 me-3">
                    <span className="text-gray-600">Expiry Date</span>
                    <div className="text-gray-700 fw-bolder">
                        <DateFormat date={data.mshiedExpiryDate} />
                    </div>
                </div>
                <div className="w-xl-125px my-1 me-3">
                    <span className="text-gray-600">Issuer</span>
                    <div className="text-gray-700 fw-bolder">
                        <span>{data.mshiedIssuerName}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}