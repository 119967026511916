import Accordion from "react-bootstrap/esm/Accordion";
import { DownloadButton } from "./DownloadButton";
import React from "react"
import { useGetAssociatedDocumentsByServiceRequestIdQuery } from "../../services/associated-documents.api"

export const ServiceRequestDocuments = ({ serviceId, className }: {
    serviceId: string
    className?: string,
}) => {
    const { data: associatedDocuments } = useGetAssociatedDocumentsByServiceRequestIdQuery(serviceId);

    if (!associatedDocuments || associatedDocuments.length === 0)
        return <></>

    return (
        <Accordion className={className}>
            <Accordion.Item eventKey={""}>
                <Accordion.Header bsPrefix="accordion-header accordion-header-sm">
                    <h5 className='text-gray-700 my-0'>Attachments</h5>
                </Accordion.Header>
                <Accordion.Body>
                    <div className="scroll-y mh-250px">
                        {associatedDocuments?.map((entity) =>
                            <React.Fragment key={entity.slcmAssociatedDocumentsId}>
                                <DownloadButton
                                    label={`${entity.slcmAssociatedDocumentName}`}
                                    link={entity.slcmDocumentLink}
                                    className="btn btn-sm btn-link my-2" />
                            </React.Fragment>
                        )}
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}