export enum SlcmAppointmentSlcmPreferredtime {
  TIME_SLOT_07AM_08AM = 407450007, // 07AM _ 08AM
  TIME_SLOT_08AM_09AM = 407450008, // 08AM _ 09AM
  TIME_SLOT_09AM_10AM = 407450000, // 09AM _ 10AM
  TIME_SLOT_10AM_11AM = 407450001, // 10AM _ 11AM
  TIME_SLOT_11AM_12PM = 407450002, // 11AM _ 12PM
  TIME_SLOT_12PM_01PM = 407450003, // 12PM _ 01PM
  TIME_SLOT_02PM_03PM = 407450004, // 02PM _ 03PM
  TIME_SLOT_03PM_04PM = 407450005, // 03PM _ 04PM
  TIME_SLOT_04PM_05PM = 407450006, // 04PM _ 05PM
  TIME_SLOT_05PM_06PM = 407450009, // 05PM _ 06PM
  TIME_SLOT_06PM_07PM = 407450010, // 06PM _ 07PM
}