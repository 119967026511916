import { ApiTags, rootApi } from './api';

import { Appointment } from "../models";
import { CalendarPeriod } from '../client-models';

const TAG = ApiTags.Appointment;

const getTags = (entities: Appointment[]) => [...entities.map((p) => ({ type: TAG, id: p.activityId })), TAG]

const appointmentsApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getAppointment: builder.query<Appointment, string>({
            query: (id) => `/Appointments/${id}`,
            providesTags: (result) =>
                result ? [{ type: TAG, id: result.activityId }, TAG] : [TAG]
        }),

        getAppointments: builder.query<Appointment[], void>({
            query: () => `/Appointments`,
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),

        getMentorAppointments: builder.query<Appointment[], string>({
            query: (studentId) => `/Appointments/mentor-appointments(${studentId})`,
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),

        getCounsellorAppointments: builder.query<Appointment[], string>({
            query: (studentId) => `/Appointments/counsellor-appointments(${studentId})`,
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),

        getDeanAppointments: builder.query<Appointment[], string>({
            query: (studentId) => `/Appointments/dean-appointments(${studentId})`,
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),

        getHODAppointments: builder.query<Appointment[], string>({
            query: (studentId) => `/Appointments/hod-appointments(${studentId})`,
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),

        getAppointmentsByPeriod: builder.query<Appointment[], {
            studentId: string, period: CalendarPeriod
        }>({
            query: ({ studentId, period }) => ({
                url: `/Appointments/Contact/${period}/${studentId}`,
                method: 'POST'
            }),
            providesTags: (result) => result ? getTags(result) : [TAG]
        }),

        createAppointment: builder.mutation<Appointment, Partial<Appointment>>({
            query: (entity) => ({
                url: '/Appointments',
                method: 'POST',
                body: entity
            }),
            invalidatesTags: (result) =>
                result ? [{ type: TAG, id: result.activityId }, TAG] : [TAG]
        }),

        updateAppointment: builder.mutation<Appointment, {
            id: string,
            entity: Partial<Appointment>
        }>({
            query: ({ id, entity }) => ({
                url: `/Appointments/${id}`,
                method: 'PUT',
                body: entity
            }),
            invalidatesTags: (result) =>
                result ? [{ type: TAG, id: result.activityId }, TAG] : [TAG]
        }),

        deleteAppointment: builder.mutation<void, string>({
            query: (id: string) => ({
                url: `/Appointments/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [TAG]
        }),
    })
})

export const {
    useGetAppointmentQuery,
    useLazyGetAppointmentQuery,
    useGetAppointmentsQuery,
    useGetMentorAppointmentsQuery,
    useGetCounsellorAppointmentsQuery,
    useGetDeanAppointmentsQuery,
    useGetHODAppointmentsQuery,
    useGetAppointmentsByPeriodQuery,
    useCreateAppointmentMutation,
    useUpdateAppointmentMutation,
    useDeleteAppointmentMutation
} = appointmentsApi;