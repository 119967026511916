import { AuthenticationResult, IPublicClientApplication } from "@azure/msal-browser";

import clsx from "clsx";
import { loginRequest } from "./authConfig";
import { msalLogin } from "../core/_requests";
import { toAbsoluteUrl } from "../../../helpers";
import { useAuth } from "../core/Auth";
import { useMsal } from "@azure/msal-react";
import { useState } from "react";

export const SignInButton = ({ className }: { className?: string }) => {
    const { logout, saveAuth } = useAuth();
    const { instance } = useMsal();
    const [loading, setLoading] = useState<boolean>(false);

    const appLogin = async (userName: string, token: string) => {
        const { data: auth } = await msalLogin(userName, token);
        saveAuth(auth);
    }

    const handleLogin = async (instance: IPublicClientApplication) => {
        setLoading(true)
        logout();

        try {
            const login = await instance.acquireTokenSilent(loginRequest);
            const { accessToken, account } = login;
            await appLogin(account?.username || '', accessToken);
        } catch (err) {
            const login = await instance.loginPopup(loginRequest)
            const { accessToken, account } = login;
            await appLogin(account?.username || '', accessToken);
        }

        setLoading(false)
    }

    return (
        <button
            type='button'
            disabled={loading}
            className={clsx('btn btn-flex flex-center btn-primary btn-lg w-100 mb-5', className)}
            onClick={() => handleLogin(instance)}
        >
            {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
            )}
            {!loading && <>
                <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/svg/brand-logos/microsoft-5.svg')}
                    className='h-20px me-3'
                />
                Continue with Scholar Email-ID
            </>
            }
        </button>
    )
}