import { StudentLedger } from "../models";
import { rootApi } from './api';

const studentLedgerApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getStudentLedger: builder.query<StudentLedger, string>({
            query: (id) => `/StudentLedger/${id}`,
        }),

        getStudentLedgers: builder.query<StudentLedger[], void>({
            query: () => `/StudentLedger`,
        }),

        getStudentLedgerByStudentId: builder.query<StudentLedger[], string>({
            query: (studentId) => `/StudentLedger/student/${studentId}`,
        }),
    })
})

export const {
    useGetStudentLedgerQuery,
    useGetStudentLedgersQuery,
    useGetStudentLedgerByStudentIdQuery,
} = studentLedgerApi;