import { RegistrationStatus } from "../models";
import { rootApi } from './api';

const registrationStatusesApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getRegistrationStatus: builder.query<RegistrationStatus, string>({
            query: (id) => `/RegistrationStatuses/${id}`,
        }),

        getRegistrationStatuses: builder.query<RegistrationStatus[], void>({
            query: () => `/RegistrationStatuses`,
        }),
    })
})

export const {
    useGetRegistrationStatusQuery,
    useGetRegistrationStatusesQuery,
} = registrationStatusesApi;