import { Service } from "../models";
import { rootApi } from './api';

const servicesApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getService: builder.query<Service, string>({
            query: (id) => `/Services/${id}`,
        }),

        getServices: builder.query<Service[], void>({
            query: () => `/Services`,
        }),

        getStudentServices: builder.query<Service[], void>({
            query: () => `/Services/student`,
        }),

        getServicesByCategoryId: builder.query<Service[], string>({
            query: (categoryId) => `/Services/category(${categoryId})`,
        })
    })
})

export const {
    useGetServiceQuery,
    useLazyGetServiceQuery,
    useGetServicesQuery,
    useGetServicesByCategoryIdQuery
} = servicesApi;