import clsx from "clsx";
import { useGetHostelsQuery } from "../../../../services/hostels.api";

export const SelectHostel = ({ value, onChange, className }: {
    value?: string,
    onChange?: (value: string) => void,
    className?: string,
}) => {
    const { data: hostels, isSuccess } = useGetHostelsQuery();


    const SelectOptions = () => {
        if (!isSuccess) return <></>;

        return (
            <>
                <option></option>
                {
                    hostels?.map(
                        hostel => <option
                            key={hostel.slcmHostelId}
                            value={hostel.slcmHostelId}>
                            {hostel.slcmName}
                        </option>)
                }
            </>
        )
    }

    return <select
        className={clsx(className, "form-select form-select-sm")}
        aria-label="Select hostel"
        value={value}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            onChange && onChange(event.target.value);
        }}
        placeholder="Select hostel">
        {SelectOptions()}
    </select>
}