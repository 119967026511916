import { CardPanel, CardPanelBody, CardPanelHeader, CardPanelToolbar, If, ListQueryStatus } from "../../widgets-core"
import { Contact, UtilityCharges } from '../../models'

import { DateFormat } from '../../widgets-core'
import { Link } from 'react-router-dom'
import { Money } from '../../widgets-business'
import React from 'react'
import clsx from 'clsx'
import { useGetUtilityChargesByStudentIdQuery } from "../../services/utility-charges.api"
import { useUserContact } from "../../hooks/user"

export const UtilityChargesView = () => {
    const contact = useUserContact();

    return <>
        {contact && <UtilityChargesViewInner contact={contact} />}
    </>
}

const UtilityChargesViewInner = ({ contact, className }: {
    contact: Contact,
    className?: string
}) => {
    const { data: utilityCharges, isLoading, isError, isFetching, isSuccess, refetch } = useGetUtilityChargesByStudentIdQuery(contact.contactId);

    return <>
        <CardPanel className="bg-transparent">
            <CardPanelHeader
                title="Utility Charges"
                subTitle="Utility charges generated"
            >
                <CardPanelToolbar>
                    <button
                        onClick={refetch}
                        type="button"
                        title="Refresh"
                        className='btn btn-sm btn-link text-hover-primary p-0 me-3'>
                        <i className="las la-redo-alt fs-2"></i>
                    </button>
                </CardPanelToolbar>
            </CardPanelHeader>
            <CardPanelBody>
                <ListQueryStatus
                    isLoading={isLoading || isFetching}
                    isSuccess={isSuccess}
                    isError={isError}
                    data={utilityCharges} />

                <If condition={!isLoading && !isFetching && isSuccess}>
                    {utilityCharges?.map(item =>
                        <React.Fragment key={item.slcmUtilitychargesId}>
                            <UtilityChargesPanel
                                className="mb-2 fs-7"
                                utilityCharge={item} />
                        </React.Fragment>)}
                </If>
            </CardPanelBody>
        </CardPanel>
    </>
}

const UtilityChargesPanel = ({ utilityCharge, className }: {
    utilityCharge: UtilityCharges
    className?: string,
}) => {
    return (
        <div className={clsx(className, "card card-body py-3")}>
            <div className='d-flex flex-wrap'>
                <div className="w-xl-150px flex-fill me-3 my-1">
                    <span className="text-gray-600">Utility Type</span>
                    <div className="text-gray-700 fw-bolder">
                        <Link
                            to={`/demands/view-demand/${utilityCharge.slcmDemand?.id}`}
                            className='w-120px text-truncate fw-bold'>
                            <span>{utilityCharge?.slcmUtilityTypeName}</span>
                        </Link>
                    </div>
                </div>

                <div className="w-xl-100px me-3 my-1">
                    <span className="text-gray-600">Bill Date</span>
                    <div className="text-gray-700 fw-bolder">
                        <DateFormat date={utilityCharge?.slcmBillDate} />
                    </div>
                </div>
                <div className="w-xl-100px me-3 my-1">
                    <span className="text-gray-600">Bill Rate</span>
                    <div className="text-gray-700 fw-bolder">
                        <Money currencyId={utilityCharge.transactionCurrencyId?.id}
                            amount={utilityCharge.slcmBillRate} />
                    </div>
                </div>
                <div className="w-xl-150px me-3 my-1">
                    <span className="text-gray-600">Current Bill Reading</span>
                    <div className="text-gray-700 fw-bolder">
                        <span>{utilityCharge.slcmCurrentBillReading}</span>
                    </div>
                </div>
                <div className="w-xl-150px me-3 my-1">
                    <span className="text-gray-600">Net Consumption</span>
                    <div className="text-gray-700 fw-bolder">
                        <span>{utilityCharge.slcmNetConsumption}</span>
                    </div>
                </div>
                <div className="w-xl-150px me-3 my-1">
                    <span className="text-gray-600">Total Payable Amount</span>
                    <div className="text-gray-700 fw-bolder">
                        <span>{utilityCharge.slcmTotalPayableAmount}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
