import { FC } from "react"
import { PageTitle } from "../../layout/core";
import { SemesterRegistrationPaymentPage } from "../../modules/registrations";
import { useParams } from "react-router-dom";
import { useUserContact } from "../../hooks/user";

export const PaymentSelectionWrapper: FC = () => {
    return (<>
        <PageTitle>{'Semester Registration'}</PageTitle>
        <PaymentSelectionPage />
    </>)
}

const PaymentSelectionPage = () => {
    const { windowId }: { windowId?: string } = useParams();
    const contact = useUserContact();

    if (!windowId || !contact) return <></>;

    return <SemesterRegistrationPaymentPage
        windowId={windowId}
        contact={contact} />
}
