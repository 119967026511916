import { rootApi } from './api';

const entityImagesApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getEntityImage: builder.query<string, { entity: string, id: string }>({
            query: ({ entity, id }) => `/EntityImage/${entity}/${id}?fullSize=true`,
        }),

        updateEntityImage: builder.mutation<void, { entity: string, id: string, image: string }>({
            query: (model) => ({
                url: `/EntityImage`,
                method: 'POST',
                body: model
            }),
        }),
    })
})

export const {
    useGetEntityImageQuery,
    useLazyGetEntityImageQuery,
    useUpdateEntityImageMutation,
} = entityImagesApi;