/* eslint-disable jsx-a11y/anchor-is-valid */

import { ChangeEvent, FC, MouseEvent, useRef, useState } from 'react'

import { AssociatedDocument } from '../../models'
import { AttachmentRequestModel } from '../../client-models'
import clsx from 'clsx'
import { useUploadAssociatedDocumentMutation } from '../../services/associated-documents.api'
import { CardPanel, CardPanelBody, CardPanelHeader } from '../../widgets-core'

type DemandEntity = {
    entity: "Demand"
    demandId: string
}

type ServiceRequestEntity = {
    entity: "ServiceRequest"
    requestId: string
}

type Props = {
    associatedEntity: DemandEntity | ServiceRequestEntity
    folderPath: string,
    className?: string,
    onComplete?: (document: AssociatedDocument) => void
};

export const DocumentUpload: FC<Props> = ({ associatedEntity, folderPath, onComplete, className }) => {
    const [file, setFile] = useState<File>()
    const [saving, setSaving] = useState<boolean>(false)
    const [alertMsg, setAlertMsg] = useState<string>()
    const [uploadAssociatedDocument] = useUploadAssociatedDocumentMutation()
    const inputRef = useRef<HTMLInputElement>(null)

    const uploadDocumentHandler = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        
        
        if (!file) {
            setAlertMsg('You must select a file to upload')
            return
        }

        setSaving(true)
        setAlertMsg('')

        const reader = new FileReader()
        reader.onloadend = () => {
            const content = reader.result

            var base64 = btoa(
                new Uint8Array(content as ArrayBuffer)
                    .reduce((data, byte) => data + String.fromCharCode(byte), ''))

            const request: AttachmentRequestModel = associatedEntity.entity === "Demand" ? {
                associatedEntity: {
                    logicalName: "slcm_demandheader",
                    id: associatedEntity.demandId
                },
                folderPath: folderPath,
                fileName: file.name,
                fileType: file.type,
                base64content: base64 as string,
            } : {
                associatedEntity: {
                    logicalName: "slcm_servicerequests",
                    id: associatedEntity.requestId
                },
              
                folderPath: folderPath,
                fileName: file.name,
                fileType: file.type,
                base64content: base64 as string,
            };

            uploadAssociatedDocument(request)
                .unwrap()
                .then((document) => {
                    setSaving(false)
                    onComplete && onComplete(document)
                })
                .catch((err) => {
                    setAlertMsg("please change file name and upload or try again after some time")
                })
                .finally(() => {
                    if (inputRef.current)
                        inputRef.current.value = '';

                    setSaving(false)
                })
        }

        reader.readAsArrayBuffer(file)
    }

    const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        setAlertMsg('')

        const file = event.target.files?.item(0)
        if (!file) return;

        var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i
        if (!allowedExtensions.exec(file.name)) {
            event.target.value = ''
            setAlertMsg('Upload file having extensions .jpeg/.jpg/.png/.pdf only.')
            return
        }

        const fileSize = file.size / 1024 / 1024 // in MiB
        if (fileSize > 2) {
            event.target.value = ''
            setAlertMsg(
                `Attached file size ${Math.round(fileSize * 100) / 100} MiB exceeds the 2 MiB limit.`
            )

            return
        }

        setFile(file)
    }

    return (
        <>
         <CardPanel className={className}>
            <CardPanelHeader title='Document Upload' className="min-h-5px" />
            <CardPanelBody className="pt-0 scroll-y mh-300px">
            <div className={clsx("d-flex align-items-center", className)}>
                    <input
                        ref={inputRef}
                        type="file"
                        className="form-control p-2 me-2"
                        accept="image/jpeg,image/png,application/pdf"
                        onChange={onFileChange}
                        placeholder="Proof Of Payment"
                    />
                    {alertMsg && <div className='text-danger fst-italic my-1 fs-7'>{alertMsg}</div>}
                </div>
                <div className=''>
                    <button
                        type='button'
                        disabled={saving}
                        onClick={uploadDocumentHandler}
                        className='btn btn-sm btn-secondary'
                    >
                        {saving ? (
                            <span className='indicator-label'>
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            </span>
                        ) : (
                            <span>Upload</span>
                        )}
                    </button>
                </div>
            </CardPanelBody>
        </CardPanel>

        </>
    )
}
