import { ApplicationReason } from "../models";
import { rootApi } from './api';

const applicationReasonsApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getApplicationReason: builder.query<ApplicationReason, string>({
            query: (id) => `/ApplicationReasons/${id}`,
        }),

        getApplicationReasons: builder.query<ApplicationReason[], void>({
            query: () => `/ApplicationReasons`,
        }),

        getApplicationReasonsByServiceId: builder.query<ApplicationReason[], string>({
            query: (serviceId) => `/ApplicationReasons/service/${serviceId}`,
        }),
    })
})

export const {
    useGetApplicationReasonQuery,
    useGetApplicationReasonsQuery,
    useGetApplicationReasonsByServiceIdQuery,
    useLazyGetApplicationReasonsByServiceIdQuery,
} = applicationReasonsApi;