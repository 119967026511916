import { CardPanel, CardPanelBody, CardPanelHeader, CardPanelToolbar, CardQueryStatus, CardRefreshButton } from "../../../widgets-core";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { ServiceRequest, SlcmServicerequestsStatuscode, getServiceRequestStatusLabel, getRequestsSlcmReopenLabel, Service } from "../../../models";
import { ServiceRequestComments, ViewServiceRequestFields } from "../components";
import { useEffect, useState } from "react";
import { useGetServiceRequestQuery, useUpdateServiceRequestMutation } from "../../../services/service-requests.api";

import { Button } from "react-bootstrap";
import { DocumentUpload } from "../DocumentUpload";
import { ServicePayableAmount } from "../components/ServicePayableAmount";
import { ServiceRequestApprovals } from "./ServiceRequestApprovals";
import { ServiceRequestDocuments } from "../../payments/ServiceRequestDocuments";
import { TrackPaymentTransactions } from "../../payments/TrackPaymentTransactions";
import { ViewServiceRequestDocuments } from "../components/documents/ViewServiceRequestDocuments";
import { contactFolderPath } from "../../../helpers";
import { useLazyGetServiceQuery } from "../../../services/services.api";
import { useNavigate } from "react-router-dom";
import { useUserContact } from "../../../hooks/user";

export const ViewServiceRequest = ({ requestId }: { requestId: string }) => {
    const contact = useUserContact()
    const { data: serviceRequest, isLoading, isError, isFetching, isSuccess, error, refetch } = useGetServiceRequestQuery(requestId);
    const [getService, { data: service }] = useLazyGetServiceQuery();
    const folderPath = contact ? `${contactFolderPath(contact)}/${serviceRequest?.slcmCode}` : '';

    useEffect(() => {
        if (serviceRequest?.slcmRequestType?.id) {
            getService(serviceRequest.slcmRequestType?.id);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceRequest])

    return (
        <CardPanel className='bg-transparent'>
            <CardPanelHeader
                title={serviceRequest?.slcmCode}
                subTitle={service?.slcmName}>
                <CardPanelToolbar>
                    <CardRefreshButton
                        className="me-3"
                        isLoading={isLoading || isFetching}
                        refetch={refetch} />
                    {serviceRequest &&
                        <ServicePayableAmount serviceRequest={serviceRequest} />}
                </CardPanelToolbar>
            </CardPanelHeader>
            <CardPanelBody>
                <CardQueryStatus
                    isLoading={isLoading || isFetching}
                    isSuccess={isSuccess}
                    isError={isError}
                    error={error}
                    data={serviceRequest} />

                {(service && serviceRequest) &&
                    <>
                        <div className="card card-body py-5 fs-7">
                            <ViewServiceRequestFields
                                service={service}
                                serviceRequest={serviceRequest} />
                            <UpdateStatus requestId={requestId} service={service} />
                        </div>

                        {service?.slcmDocumentChecklist?.id && <>
                            <ViewServiceRequestDocuments
                                checklistId={service.slcmDocumentChecklist?.id}
                                requestId={serviceRequest.slcmServicerequestsId}
                                className="mt-5" />
                        </>}

                        <div className="row">
                            <div className="col-xl-8">
                                <ServiceRequestComments className="mt-5" requestId={requestId} />
                            </div>
                            <div className="col-xl-4">
                                <ServiceRequestApprovals className="mt-5" requestId={requestId} />
                            </div>
                            <div className="col-xl-6">
                                <DocumentUpload
                                    className="mt-3 mb-6"
                                    folderPath={folderPath}
                                    associatedEntity={{
                                        entity: "ServiceRequest",
                                        requestId: serviceRequest.slcmServicerequestsId
                                    }} />
                            </div>
                            <div className="col-xl-8">
                                <TrackPaymentTransactions
                                    className="mt-3 mb-2"
                                    paymentReference={serviceRequest.slcmServicerequestsId} />
                            </div>

                            <div className="col-xl-8">
                                <ServiceRequestDocuments className="mt-5" serviceId={serviceRequest.slcmServicerequestsId} />
                            </div>
                        </div>
                    </>}
            </CardPanelBody>
        </CardPanel>
    )
}

const UpdateStatus = ({ requestId,service }: { requestId: string,service: Service  }) => {
    const navigate = useNavigate()
    const { data: serviceRequest } = useGetServiceRequestQuery(requestId);
    const [open, setOpen] = useState(false)
    const [updateToReopen] = useUpdateServiceRequestMutation();

    const onSubmitHandle = () => {
        if (!serviceRequest || !serviceRequest.slcmServicerequestsId) {
            return;
        }

        const request: Partial<ServiceRequest> = {
            statuscode: SlcmServicerequestsStatuscode.Open,
            slcmReopened: true
        }

        updateToReopen({
            id: serviceRequest.slcmServicerequestsId,
            entity: request
        })
            .unwrap()
            .then(() => {
                navigate(`/service-requests/${SlcmServicerequestsStatuscode.Open}`)
            })
            .catch((reason) => {
                setOpen(false)
            })
    }

    return (
        <div>
            {getServiceRequestStatusLabel(serviceRequest?.statuscode) === "Closed" && getRequestsSlcmReopenLabel(service.slcmReopen)==="Yes"&&
                <div className='text-end mt-5' style={{ marginRight: "45px" }}>
                    <button type='submit' className='btn btn-primary' onClick={() => setOpen(true)}>
                        <span className='indicator-label'>Re-Open</span>
                    </button>
                </div>}
            <div>
                <Dialog open={open}>
                    <DialogContent>
                        <span>Clicking on this will reopen the old ticket and initiate the approval process again.</span><br />
                        <span>Do you still want to proceed?</span>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onSubmitHandle}>Submit</Button>
                        <Button onClick={() => setOpen(false)}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}
