import { ProgramRequirement } from "../models";
import { rootApi } from './api';

const programRequirementsApi = rootApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getProgramRequirement: builder.query<ProgramRequirement, string>({
            query: (id) => `/ProgramRequirements/${id}`,
        }),

        getProgramRequirements: builder.query<ProgramRequirement[], void>({
            query: () => `/ProgramRequirements`,
        }),

        getProgramRequirementsByProgramId: builder.query<ProgramRequirement[], string>({
            query: (programId) => `/ProgramRequirements/program/${programId}`,
        }),
    })
})

export const {
    useGetProgramRequirementQuery,
    useGetProgramRequirementsQuery,
    useGetProgramRequirementsByProgramIdQuery,
    useLazyGetProgramRequirementsByProgramIdQuery,
} = programRequirementsApi;